export enum FILTRES_COMPTES_RECUPERABLES_NON_RECUPERABLES {
	ID = '@id',
	COMPTE = 'compte',
	COMPTE_RECUPERABLE = 'compteRecuperable',
	COMPTE_NON_RECUPERABLE = 'compteNonRecuperable',
	RUBRIQUE_ANALYTIQUE = 'rubriqueAnalytique',
	DATE_DEBUT = 'dateDebut',
	DATE_FIN = 'dateFin',
	NOM_MODIFICATEUR = 'nomModificateur',
	ID_MODIFICATEUR = 'idModificateur',
	PAGE = 'page'
}

export const EMPTY_FORM = {
	[FILTRES_COMPTES_RECUPERABLES_NON_RECUPERABLES.ID]: null,
	[FILTRES_COMPTES_RECUPERABLES_NON_RECUPERABLES.COMPTE]: '',
	[FILTRES_COMPTES_RECUPERABLES_NON_RECUPERABLES.COMPTE_RECUPERABLE]: '',
	[FILTRES_COMPTES_RECUPERABLES_NON_RECUPERABLES.COMPTE_NON_RECUPERABLE]: '',
	[FILTRES_COMPTES_RECUPERABLES_NON_RECUPERABLES.RUBRIQUE_ANALYTIQUE]: ''
}