import axios from 'axios'
import { CommunApiPlatformModel } from '../../../types/communApiPlatformModel'
import { MatriculesTauxModel } from '../types/matriculesTauxModel'

export const getListeMatriculesTaux = (page: number, matricule?: string, referenceImmeuble?: string): Promise<CommunApiPlatformModel<MatriculesTauxModel[]>> => axios.get(`/api/matricules_taux?page=${page}${matricule ? `&matricule=${matricule}` : ''}${referenceImmeuble ? `&referenceImmeuble=${referenceImmeuble}` : ''}`)
	.then((response) => response.data)

export const postMatriculesTaux = (data: MatriculesTauxModel): Promise<MatriculesTauxModel> => axios.post('/api/matricules_taux', data)
	.then((response) => response.data)

export const putMatriculesTaux = (data: MatriculesTauxModel): Promise<MatriculesTauxModel> => axios.put(`/api/matricules_taux/${data['@id']!.split(data['@id']!.includes('tauxes') ? '/api/matricules_tauxes/' : '/api/matricules_taux/')[1]}`, data)
		.then((response) => response.data)

export const deleteMatriculesTaux = (data: MatriculesTauxModel): Promise<MatriculesTauxModel> => axios.delete(`/api/matricules_taux/${data['@id']!.split(data['@id']!.includes('tauxes') ? '/api/matricules_tauxes/' : '/api/matricules_taux/')[1]}`)
	.then((response) => response.data)