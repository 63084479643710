//TODO SEPARER LES GROSSES ENUMS

export enum champsFormulaire {
	recherche = 'recherche'
}

// Enum pour les clés de la réponse de la récupération de la liste des avis
export enum detailAvisResponseEnum {
	id = 'id',
	fraisGestion = 'fraisGestion',
	montantAPayer = 'montantAPayer',
	immeubles = 'immeubles',
	tauxTaxes = 'tauxTaxes',

	// Éléments dans les objets "immeubles"
	caseVide = 'caseVide',
	total = 'total',
	adresse = 'adresse',
	immeubleExterne = 'immeubleExterne',
	totalCotisation = 'totalCotisation',
	communeCotisation = 'communeCotisation',
	interCommCotisation = 'interCommCotisation',
	departementCotisation = 'departementCotisation',
	taxeSpecialeCotisation = 'taxeSpecialeCotisation',
	syndicatCotisation = 'syndicatCotisation',
	teomCotisation = 'teomCotisation',
	gemapiCotisation = 'gemapiCotisation',

	// Éléments dans l'objet "tauxTaxes"
	annee = 'annee',
	commune = 'commune',
	departement = 'departement',
	gemapi = 'gemapi',
	repartitionGpTaxeSpeciale = 'repartition_gp_taxe_speciale',
	repartitionGpTaxeSpecialeGp = 'repartiton_gp_taxe_speciale_gp',
	syndicatCommune = 'syndicat_commune',
	taxeSpeciale = 'taxe_speciale',
	taxeSpecialeGp = 'taxe_speciale_gp',
	teom = 'teom',

	totalLigne = 'totalLigne',
	cotisation = 'cotisation',
	frais = 'frais',
	type = 'type'
}

export enum detailAvisPostEnum {
	fichier = 'fichier',
	anneeRedition = 'anneeRedition'
}

export enum detailAvisFormEnum {
	totalCotisation = 'totalCotisation',
	totalCotisations = 'totalCotisations',
	fraisGestion = 'fraisGestion',
	montantAPayer = 'montantAPayer',
	grandParis = 'grandParis',
	immeubles = 'immeubles',
	ajustementFrais = 'ajustementFrais'
}

export enum enteteTableauAffichageImmeubleLabelEnum {
	caseVide = '',
	commune = 'Commune',
	interComm = 'Inter comm.',
	departement = 'Département',
	taxeSpe = 'Taxe spéciale',
	syndicat = 'Syndicat',
	teom = 'TEOM',
	gemapi = 'GEMAPI',
	total = 'Total',
	frais = 'Frais',
	cotisation = 'Cotisation'
}

export enum enteteTableauAffichageImmeubleEnum {
	caseVide = '',
	communeCotisation = 'communeCotisation',
	interCommCotisation = 'interCommCotisation',
	departementCotisation = 'departementCotisation',
	taxeSpecialeCotisation = 'taxeSpecialeCotisation',
	syndicatCotisation = 'syndicatCotisation',
	teomCotisation = 'teomCotisation',
	gemapiCotisation = 'gemapiCotisation',
	totalLigne = 'totalLigne',
	cotisation = 'cotisation',
	frais = 'frais',
	total = 'total'
}

export enum colonneTableauTraitementAvisImmeuble {
	communeCotisation = 'communeCotisation',
	interCommCotisation = 'interCommCotisation',
	departementCotisation = 'departementCotisation',
	taxeSpecialeCotisation = 'taxeSpecialeCotisation',
	syndicatCotisation = 'syndicatCotisation',
	teomCotisation = 'teomCotisation',
	gemapiCotisation = 'gemapiCotisation'
}

export enum colonneTableauTraitementAvisImmeubleAvecTotal {
	communeCotisation = 'communeCotisation',
	interCommCotisation = 'interCommCotisation',
	departementCotisation = 'departementCotisation',
	taxeSpecialeCotisation = 'taxeSpecialeCotisation',
	syndicatCotisation = 'syndicatCotisation',
	teomCotisation = 'teomCotisation',
	gemapiCotisation = 'gemapiCotisation',
	total = 'total'
}

export const nomLigne = {
	[detailAvisResponseEnum.communeCotisation]: 'communeCotisation',
	[detailAvisResponseEnum.frais]: 'frais',
	[detailAvisResponseEnum.totalLigne]: 'total'
}

export const LigneAdditionTotalEnum = {
	[detailAvisResponseEnum.communeCotisation]: 'communeCotisation',
	[detailAvisResponseEnum.frais]: 'frais'
}

export enum immeubleExterneEnum {
	id = 'id',
	reference = 'reference',
	adresse = 'adresse',
	ville = 'ville'
}