import React, { createContext, Dispatch, SetStateAction, useEffect, useState } from 'react'
import { CommunApiPlatformModel, INITIAL_COMMUN_API } from '../../../types/communApiPlatformModel'
import { getListeComptesRecuperablesNonRecuperables } from '../api/comptesRecuperablesNonRecuperablesAPI'
import {
	ComptesRecuperablesNonRecuperablesModel,
	FiltresComptesRecuperablesNonRecuperablesModel,
	INITIAL_FILTRE_CRNR,
	INITIAL_POPIN_CRNR,
	PopinComptesRecuperablesNonRecuperablesModel
} from '../types/comptesRecuperablesNonRecuperablesModel'
import { FILTRES_COMPTES_RECUPERABLES_NON_RECUPERABLES } from '../enums/filtresComptesRecuperablesNonRecuperablesEnum'

export interface ComptesRecuperablesNonRecuperablesContextProps {
	comptesRecuperablesNonRecuperables: CommunApiPlatformModel<ComptesRecuperablesNonRecuperablesModel[]>,
	setComptesRecuperablesNonRecuperables: Dispatch<SetStateAction<CommunApiPlatformModel<ComptesRecuperablesNonRecuperablesModel[]>>>
	chargementComptesRecuperablesNonRecuperables: boolean,
	setChargementComptesRecuperablesNonRecuperables: Dispatch<SetStateAction<boolean>>,
	ouverturePopin: PopinComptesRecuperablesNonRecuperablesModel,
	setOuverturePopin: Dispatch<SetStateAction<PopinComptesRecuperablesNonRecuperablesModel>>,
	popinSuppression: PopinComptesRecuperablesNonRecuperablesModel,
	setPopinSuppression: Dispatch<SetStateAction<PopinComptesRecuperablesNonRecuperablesModel>>,
	filtresCRNR: FiltresComptesRecuperablesNonRecuperablesModel,
	setFiltresCRNR: Dispatch<SetStateAction<FiltresComptesRecuperablesNonRecuperablesModel>>
}

export const ComptesRecuperablesNonRecuperablesContext = createContext<ComptesRecuperablesNonRecuperablesContextProps>({
	comptesRecuperablesNonRecuperables: INITIAL_COMMUN_API<ComptesRecuperablesNonRecuperablesModel[]>([]), // Partage des comptes récupérables / non récupérables aux éléments enfants
	setComptesRecuperablesNonRecuperables: () => {}, // Mise à jour des CRNR
	chargementComptesRecuperablesNonRecuperables: true, // Détermine si les CRNR sont en cours de chargement
	setChargementComptesRecuperablesNonRecuperables: () => {}, // Modification du statut de chargement
	ouverturePopin: INITIAL_POPIN_CRNR, // Ouverture de la popin de création / modification d'un CRNR
	setOuverturePopin: () => {}, // Mise à jour du statut d'ouverture de la popin de création / modification
	popinSuppression: INITIAL_POPIN_CRNR, // Ouverture de la popin de suppression d'un CRNR
	setPopinSuppression: () => {}, // Mise à jour du statut d'ouverture de la popin de suppression
	filtresCRNR: INITIAL_FILTRE_CRNR, // Partage du filtre sur les CRNR
	setFiltresCRNR: () => {} // Mise à jour du filtre sur les CRNR
})

export const ComptesRecuperablesNonRecuperablesProvider: React.FC = (
	{
		children
	}
) => {
	const [ComptesRecuperablesNonRecuperables, setComptesRecuperablesNonRecuperables] = useState<CommunApiPlatformModel<ComptesRecuperablesNonRecuperablesModel[]>>(INITIAL_COMMUN_API<ComptesRecuperablesNonRecuperablesModel[]>([]))
	const [chargementComptesRecuperablesNonRecuperables, setChargementComptesRecuperablesNonRecuperables] = useState(true)
	const [ouverturePopin, setOuverturePopin] = useState<PopinComptesRecuperablesNonRecuperablesModel>(INITIAL_POPIN_CRNR)
	const [popinSuppression, setPopinSuppression] = useState<PopinComptesRecuperablesNonRecuperablesModel>(INITIAL_POPIN_CRNR)
	const [filtresCRNR, setFiltresCRNR] = useState<FiltresComptesRecuperablesNonRecuperablesModel>(INITIAL_FILTRE_CRNR)

	useEffect(
		() => {
			getListeComptesRecuperablesNonRecuperables(filtresCRNR[FILTRES_COMPTES_RECUPERABLES_NON_RECUPERABLES.PAGE])
				.then((response) => {
					setComptesRecuperablesNonRecuperables(response)
					setChargementComptesRecuperablesNonRecuperables(false)
				})
		}, []
	)

	return (
		<ComptesRecuperablesNonRecuperablesContext.Provider
			value={{
				comptesRecuperablesNonRecuperables: ComptesRecuperablesNonRecuperables,
				setComptesRecuperablesNonRecuperables,
				chargementComptesRecuperablesNonRecuperables,
				setChargementComptesRecuperablesNonRecuperables,
				ouverturePopin,
				setOuverturePopin,
				popinSuppression,
				setPopinSuppression,
				filtresCRNR,
				setFiltresCRNR
			}}
		>
			{children}
		</ComptesRecuperablesNonRecuperablesContext.Provider>
	)
}