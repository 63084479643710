import { APPLICATIONS_ENUM, FILTRES_UTILISATEURS, PROFILS_ENUM } from '../enums/filtresUtilisateursEnum'

export interface DroitsModel {
	application: APPLICATIONS_ENUM,
	profil: PROFILS_ENUM
}

export interface UtilisateursModel {
	'@id': string | null,
	nom: string,
	prenom: string,
	email: string,
	droits: DroitsModel[]
}

export interface UtilisateursFormModel {
	'@id': string | null,
	[FILTRES_UTILISATEURS.NOM]: string,
	[FILTRES_UTILISATEURS.PRENOM]: string,
	[FILTRES_UTILISATEURS.EMAIL]: string,
	[FILTRES_UTILISATEURS.ADMINISTRATION]: boolean,
	[FILTRES_UTILISATEURS.TAXES_FONCIERES]: boolean,
	[FILTRES_UTILISATEURS.SALAIRES_GARDIENS]: boolean,
	[FILTRES_UTILISATEURS.PROFIL]: PROFILS_ENUM
}

export interface FiltresUtilisateursModel {
	[FILTRES_UTILISATEURS.EMAIL]: string
	[FILTRES_UTILISATEURS.NOM]: string
	[FILTRES_UTILISATEURS.PAGE]: number
}

export interface PopinUtilisateursModel {
	open: boolean,
	utilisateurs: UtilisateursFormModel | undefined
}

export const INITIAL_DROITS: DroitsModel = {
	application: APPLICATIONS_ENUM.ADMINISTRATION,
	profil: PROFILS_ENUM.UTILISATEUR
}

export const INITIAL_UTILISATEURS: UtilisateursFormModel = {
	'@id': '',
	[FILTRES_UTILISATEURS.ADMINISTRATION]: false,
	[FILTRES_UTILISATEURS.EMAIL]: '',
	[FILTRES_UTILISATEURS.NOM]: '',
	[FILTRES_UTILISATEURS.PRENOM]: '',
	[FILTRES_UTILISATEURS.PROFIL]: PROFILS_ENUM.ADMINISTRATEUR,
	[FILTRES_UTILISATEURS.SALAIRES_GARDIENS]: true,
	[FILTRES_UTILISATEURS.TAXES_FONCIERES]: false
}

export const INITIAL_POPIN_UTILISATEURS: PopinUtilisateursModel = {
	open: false,
	utilisateurs: INITIAL_UTILISATEURS
}

export const INITIAL_FILTRE_UTILISATEURS: FiltresUtilisateursModel = {
	[FILTRES_UTILISATEURS.EMAIL]: '',
	[FILTRES_UTILISATEURS.NOM]: '',
	[FILTRES_UTILISATEURS.PAGE]: 1
}