import React, { createContext, Dispatch, SetStateAction, useEffect, useState } from 'react'
import { CommunApiPlatformModel, INITIAL_COMMUN_API } from '../../../types/communApiPlatformModel'
import { getListeComptesNomenclatureCharges } from '../api/comptesNomenclatureChargesAPI'
import { ComptesNomenclatureChargesModel, FiltresComptesNomenclatureChargesModel, INITIAL_FILTRE_CNC, INITIAL_POPIN_CNC, PopinComptesNomenclatureChargesModel } from '../types/comptesNomenclatureChargesModel'
import { FILTRES_COMPTES_NOMENCLATURE_CHARGES } from '../enums/filtresComptesNomenclatureChargesEnum'

export interface ComptesNomenclatureChargesContextProps {
	comptesNomenclatureCharges: CommunApiPlatformModel<ComptesNomenclatureChargesModel[]>,
	setComptesNomenclatureCharges: Dispatch<SetStateAction<CommunApiPlatformModel<ComptesNomenclatureChargesModel[]>>>
	chargementComptesNomenclatureCharges: boolean,
	setChargementComptesNomenclatureCharges: Dispatch<SetStateAction<boolean>>,
	ouverturePopin: PopinComptesNomenclatureChargesModel,
	setOuverturePopin: Dispatch<SetStateAction<PopinComptesNomenclatureChargesModel>>,
	popinSuppression: PopinComptesNomenclatureChargesModel,
	setPopinSuppression: Dispatch<SetStateAction<PopinComptesNomenclatureChargesModel>>,
	filtresCNC: FiltresComptesNomenclatureChargesModel,
	setFiltresCNC: Dispatch<SetStateAction<FiltresComptesNomenclatureChargesModel>>
}

export const ComptesNomenclatureChargesContext = createContext<ComptesNomenclatureChargesContextProps>({
	comptesNomenclatureCharges: INITIAL_COMMUN_API<ComptesNomenclatureChargesModel[]>([]), // Partage des comptes nomenclature charges aux éléments enfants
	setComptesNomenclatureCharges: () => {
	}, // Mise à jour des CNC
	chargementComptesNomenclatureCharges: true, // Détermine si les CNC sont en cours de chargement
	setChargementComptesNomenclatureCharges: () => {
	}, // Modification du statut de chargement
	ouverturePopin: INITIAL_POPIN_CNC, // Ouverture de la popin de création / modification d'un CNC
	setOuverturePopin: () => {
	}, // Mise à jour du statut d'ouverture de la popin de création / modification
	popinSuppression: INITIAL_POPIN_CNC, // Ouverture de la popin de suppression d'un CNC
	setPopinSuppression: () => {
	}, // Mise à jour du statut d'ouverture de la popin de suppression
	filtresCNC: INITIAL_FILTRE_CNC, // Partage du filtre sur les CNC
	setFiltresCNC: () => {
	} // Mise à jour du filtre sur les CNC
})

export const ComptesNomenclatureChargesProvider: React.FC = (
	{
		children
	}
) => {
	const [comptesNomenclatureCharges, setComptesNomenclatureCharges] = useState<CommunApiPlatformModel<ComptesNomenclatureChargesModel[]>>(INITIAL_COMMUN_API<ComptesNomenclatureChargesModel[]>([]))
	const [chargementComptesNomenclatureCharges, setChargementComptesNomenclatureCharges] = useState(true)
	const [ouverturePopin, setOuverturePopin] = useState<PopinComptesNomenclatureChargesModel>(INITIAL_POPIN_CNC)
	const [popinSuppression, setPopinSuppression] = useState<PopinComptesNomenclatureChargesModel>(INITIAL_POPIN_CNC)
	const [filtresCNC, setFiltresCNC] = useState<FiltresComptesNomenclatureChargesModel>(INITIAL_FILTRE_CNC)

	useEffect(() => {
		getListeComptesNomenclatureCharges(filtresCNC[FILTRES_COMPTES_NOMENCLATURE_CHARGES.PAGE])
			.then((response) => {
				setComptesNomenclatureCharges(response)
				setChargementComptesNomenclatureCharges(false)
			})
	}, [])

	return (
		<ComptesNomenclatureChargesContext.Provider
			value={{
				comptesNomenclatureCharges,
				setComptesNomenclatureCharges,
				chargementComptesNomenclatureCharges,
				setChargementComptesNomenclatureCharges,
				ouverturePopin,
				setOuverturePopin,
				popinSuppression,
				setPopinSuppression,
				filtresCNC,
				setFiltresCNC
			}}
		>
			{children}
		</ComptesNomenclatureChargesContext.Provider>
	)
}