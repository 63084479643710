import { Box, Grid } from '@mui/material'
import { fontFamilyBlackerDisplay, gecinaBlueMain, gecinaBrownMain, WHITE } from '../../../theme'
import Button from '../../../components/button/Button'
import { TauxPopinStateModel } from '../types/TauxResponseModel'
import React, { Dispatch, SetStateAction } from 'react'

interface EnteteListeProps {
	setPopinState: Dispatch<SetStateAction<TauxPopinStateModel>>
}

type AcceptingProps = EnteteListeProps

const EnteteListe: React.FC<AcceptingProps> = (
	{
		setPopinState
	}
) => {
	return (
		<Box sx={{ background: gecinaBlueMain, minHeight: '192px', paddingX: '172px' }}>
			<Grid container item xs={12} justifyContent="space-between" sx={{ pt: 5 }}>
				<Box sx={{ fontFamily: fontFamilyBlackerDisplay, fontSize: 28, fontWeight: 900 }}>
					Paramètrage des taux
				</Box>
			</Grid>
			<Grid container item xs={12} justifyContent="end" sx={{ pt: 5 }}>
				<Button
					bgcolor={gecinaBrownMain}
					border={false}
					color={WHITE}
					disabled={false}
					onClick={() => setPopinState({ open: true, taux: undefined })}>
					Ajouter un taux
				</Button>
			</Grid>
		</Box>
	)
}

export default EnteteListe