import axios from 'axios'
import { CommunApiPlatformModel } from '../../../types/communApiPlatformModel'
import { NaturesRubriquesPaieModel } from '../types/naturesRubriquesPaieModel'

export const getListeNaturesRubriquesPaie = (page: number, compte?: string): Promise<CommunApiPlatformModel<NaturesRubriquesPaieModel[]>> => axios.get(`/api/natures_rubriques_paies?page=${page}${compte ? `&compte=${compte}` : ''}`)
	.then((response) => response.data)

export const postNaturesRubriquesPaie = (data: NaturesRubriquesPaieModel): Promise<NaturesRubriquesPaieModel> => axios.post('/api/natures_rubriques_paies', data)
	.then((response) => response.data)

export const putNaturesRubriquesPaie = (data: NaturesRubriquesPaieModel): Promise<NaturesRubriquesPaieModel> => axios.put('/api/updateNaturesRubriquesPaie', data)
	.then((response) => response.data)

export const deleteNaturesRubriquesPaie = (data: NaturesRubriquesPaieModel): Promise<NaturesRubriquesPaieModel> => axios.delete(`/api/natures_rubriques_paies/${data['@id']!.split('/api/natures_rubriques_paies/')[1]}`)
	.then((response) => response.data)