import React from 'react'
import EnteteUtilisateurs from './components/EnteteUtilisateurs'
import ListeUtilisateurs from './components/ListeUtilisateurs'
import PopinGestionUtilisateurs from './components/PopinGestionUtilisateurs'
import { UtilisateursProvider } from './context/UtilisateursContext'

const Utilisateurs = () => (
	<UtilisateursProvider>
		<EnteteUtilisateurs />
		<ListeUtilisateurs />
		<PopinGestionUtilisateurs />
	</UtilisateursProvider>
)

export default Utilisateurs