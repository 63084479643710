export const formatDate = (date: Date, local?: string) => {
	return date && date.toString() !== 'Invalid Date' ? new Intl.DateTimeFormat(local ?? 'fr-FR', {
		day: '2-digit',
		month: '2-digit',
		year: 'numeric',
		hour: 'numeric',
		minute: 'numeric',
		timeZone: 'UTC'
	}).format(date).replace(/\//g, '/') : ''
}

export const formatDateLong = (date: Date, locale: string) => {
	return date && date.toString() !== 'Invalid Date' ? new Intl.DateTimeFormat(locale, {
		day: '2-digit',
		month: 'long',
		year: 'numeric',
		hour: 'numeric',
		minute: 'numeric'
	}).format(new Date(date)) : ''
}
