import React from 'react'
import { IconsModel } from './types/iconsModel'

const SvgEdit = (
	{
		color,
		width = 17,
		height = 17,
		...props
	}: IconsModel
) => (
	<svg width={width} height={height} viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} >
		<path d="M1.68332 16.1492C1.56771 16.149 1.4534 16.1247 1.34766 16.0779C1.24193 16.0312 1.14708 15.9629 1.06916 15.8775C0.989824 15.7928 0.929286 15.6923 0.891515 15.5826C0.853745 15.4729 0.839594 15.3564 0.849991 15.2408L1.05416 12.9958L10.4858 3.5675L13.4333 6.51417L4.00416 15.9417L1.75916 16.1458C1.73395 16.1482 1.70864 16.1493 1.68332 16.1492ZM14.0217 5.925L11.075 2.97834L12.8425 1.21084C12.9199 1.13336 13.0118 1.07189 13.113 1.02995C13.2141 0.988016 13.3226 0.966431 13.4321 0.966431C13.5416 0.966431 13.65 0.988016 13.7512 1.02995C13.8524 1.07189 13.9443 1.13336 14.0217 1.21084L15.7892 2.97834C15.8666 3.05573 15.9281 3.14764 15.97 3.2488C16.012 3.34997 16.0336 3.45841 16.0336 3.56792C16.0336 3.67743 16.012 3.78587 15.97 3.88704C15.9281 3.9882 15.8666 4.08011 15.7892 4.1575L14.0225 5.92417L14.0217 5.925Z" fill={color}/>
	</svg>
)

export default SvgEdit
