import { gecinaBlueMain, WHITE } from '../../theme'
import React, { ReactNode } from 'react'
import { styled, SxProps } from '@mui/system'
import MuiTooltip from '@mui/material/Tooltip'
import { Theme } from '@mui/material/styles'

const customSpan = styled('span', {
	shouldForwardProp: (prop) => prop !== 'open'
})<{ open: boolean }>(({ theme, open }) => ({
	tooltipContainer: {
		position: 'absolute',
		zIndex: open ? 2000 : 1
	},
	tooltipContainerStyle: {
		position: 'relative',
		marginTop: 5,
		marginLeft: 5
	}
}))

const CustomTooltip = styled(MuiTooltip)(({ theme }) => (
	{
		popper: {
			top: '-10px !important',
			left: '-40px !important',
			pointerEvents: 'initial'
		},
		tooltip: {
			background: WHITE,
			color: gecinaBlueMain,
			border: `1px solid ${gecinaBlueMain}`,
			borderRadius: 10,
			paddingLeft: 25,
			paddingTop: 25
		},
		tooltipPlacementBottom: {
			marginTop: -25,
			paddingTop: 30
		}
	}
))


interface AcceptingProps {
	title: string | ReactNode,
	open: boolean,
	tooltipContainerStyle?: SxProps<Theme>,
}

type TooltipProps = AcceptingProps

const Tooltip: React.FC<TooltipProps> = (
	{
		title,
		children,
		open,
		tooltipContainerStyle
	}
) => {

	return (
		<CustomTooltip
			sx={[...(Array.isArray(tooltipContainerStyle) ? tooltipContainerStyle : [tooltipContainerStyle])]}
			title={title as string}
			placement="right-start"
			open={open}
			PopperProps={{
				disablePortal: true
			}}
		>
			{React.createElement(customSpan, { open: open }, children)}
		</CustomTooltip>
	)
}

export default Tooltip
