import React, { useContext } from 'react'
import { NaturesRubriquesPaieContext } from '../context/NaturesRubriquesPaieContext'
import { EMPTY_FORM, FILTRES_NATURES_RUBRIQUES_PAIE, NATURE_VALUES } from '../enums/filtresNaturesRubriquesPaieEnum'
import PopinGestionFormulaire from '../../../components/form/PopinGestionFormulaire'
import { ChampsPopinModel } from '../../../components/form/types/popinGestionFormulaire'
import PopinSuppressionContenu from '../../../components/dialog/components/PopinSuppressionContenu'
import { deleteNaturesRubriquesPaie, postNaturesRubriquesPaie, putNaturesRubriquesPaie } from '../api/naturesRubriquesPaieAPI'

//Champs de la popin
const POPIN_FIELDS: ChampsPopinModel[] = [
	{
		colonnes: 1,
		champs: [
			{
				id: FILTRES_NATURES_RUBRIQUES_PAIE.ID,
				type: 'hidden'
			},
			{
				id: FILTRES_NATURES_RUBRIQUES_PAIE.COMPTE,
				type: 'text',
				maxLength: 20,
				label: 'Compte *',
				disableOnUpdate: true
			}
		]
	},
	{
		colonnes: 2,
		champs: [
			{
				id: FILTRES_NATURES_RUBRIQUES_PAIE.NATURE_CHARGE_RESIDENTIEL,
				type: 'radio',
				label: 'Nature de charge résidentiel *',
				options: [
					{
						label: NATURE_VALUES.NR,
						value: NATURE_VALUES.NR
					},
					{
						label: NATURE_VALUES.R,
						value: NATURE_VALUES.R
					}
				]
			},
			{
				id: FILTRES_NATURES_RUBRIQUES_PAIE.NATURE_CHARGE_BUREAU,
				type: 'radio',
				label: 'Nature de charge bureau *',
				options: [
					{
						label: NATURE_VALUES.NR,
						value: NATURE_VALUES.NR
					},
					{
						label: NATURE_VALUES.R,
						value: NATURE_VALUES.R
					}
				]
			}
		]
	}
]

const PopinGestionNaturesRubriquesPaie = () => {
	const {
		ouverturePopin,
		setOuverturePopin,
		naturesRubriquesPaie,
		setNaturesRubriquesPaie,
		popinSuppression,
		setPopinSuppression
	} = useContext(NaturesRubriquesPaieContext)

	return (
		<>
			{/* Popin permettant d'ajouter / modifier un NRP */}
			<PopinGestionFormulaire
				champsPopin={POPIN_FIELDS}
				formulaireVide={EMPTY_FORM}
				donneesPopin={{
					open: ouverturePopin.open,
					donnees: ouverturePopin.naturesRubriquesPaie
				}}
				onCloseDialog={(closeData) => setOuverturePopin({
					open: closeData.open,
					naturesRubriquesPaie: closeData.donnees
				})}
				contenu={naturesRubriquesPaie['hydra:member']}
				modificationContenu={(nouveauContenu) => setNaturesRubriquesPaie({
					'hydra:totalItems': ouverturePopin.naturesRubriquesPaie ? naturesRubriquesPaie['hydra:totalItems'] : naturesRubriquesPaie['hydra:totalItems']+1,
					'hydra:member': nouveauContenu
				})}
				titreCreation="Ajouter les natures de charge d'un compte"
				titreModification="Modifier les natures de charge d'un compte"
				envoiDonnees={ouverturePopin.naturesRubriquesPaie ? putNaturesRubriquesPaie : postNaturesRubriquesPaie}
			/>

			{/* Popin permettant de supprimer un NRP */}
			<PopinSuppressionContenu
				donneesPopin={{
					open: popinSuppression.open,
					donnees: popinSuppression.naturesRubriquesPaie
				}}
				onCloseDialog={(closeData) => setPopinSuppression({
					open: closeData.open,
					naturesRubriquesPaie: closeData.donnees
				})}
				titreSuppression="Suppression de la nature de charge"
				texteSuppression="Confirmer la suppression de la nature de charge ?"
				contenu={naturesRubriquesPaie['hydra:member']}
				modificationContenu={(nouveauContenu) => setNaturesRubriquesPaie({
					'hydra:totalItems': naturesRubriquesPaie['hydra:totalItems']-1,
					'hydra:member': nouveauContenu
				})}
				suppressionContenu={deleteNaturesRubriquesPaie}
			/>
		</>
	)

}

export default PopinGestionNaturesRubriquesPaie