import { useLocation, useNavigate } from 'react-router-dom'
import liens from '../../enum/liens'
import { ListeAvisResponseModel } from '../listeAvis/types/filtresListeAvisModel'
import { fontFamilyBlackerDisplay, fontFamilyMonserrat, gecinaBlueMain, gecinaBrownMain, WHITE } from '../../theme'
import { Box, Grid } from '@mui/material'
import { filtresListeAvisResponseEnum } from '../listeAvis/enums/listeAvisEnum'
import React, { useContext, useEffect, useState } from 'react'
import { creationTaxesFoncieres, getAvisDetail } from './api/traitementAvisAPI'
import { detailAvisFormEnum, detailAvisPostEnum, detailAvisResponseEnum, immeubleExterneEnum } from './enums/detailAvisEnum'
import { INITAL_TRAITEMENT_AVIS, TRAITEMENT_IMMEUBLE_VIDE, TraitementAvisModel } from './types/detailAvisModel'
import { FormProvider, useForm } from 'react-hook-form'
import CustomTextField from '../../components/fields/CustomTextField'
import thousandsSeparator from '../../utils/thousandsSeparator'
import { mapperAvisPourTraitement } from './utils/traitementAvisUtils'
import FormNumberFormatField from '../../components/fields/FormNumberFormatField'
import CustomCheckbox from '../../components/checkbox/CustomCheckbox'
import BlocImmeuble from './components/BlocImmeuble'
import Button from '../../components/button/Button'
import { buttonTypesEnum } from '../../components/button/enums/buttonTypesEnum'
import TableauTotaux from './components/TableauTotaux'
import { displaySnackbar } from '../../components/snackBar/reducer/actions'
import { useDispatch } from 'react-redux'
import { ERREUR_ID } from '../../components/snackBar/SnackBar'
import { TraitementAvisContext } from './context/TraitementAvisContext'
import Loader from '../../components/loader/Loader'


interface DetailAvisState {
	avis: ListeAvisResponseModel
}

const TraitementAvis = () => {
	const location = useLocation()
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const { formValues, setFormValues } = useContext(TraitementAvisContext)
	const [loading, setLoading] = useState<boolean>(true)
	const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false)
	const methods = useForm<TraitementAvisModel>({
		defaultValues: formValues
	})
	const { register, handleSubmit, watch, setValue, getValues } = methods

	const avis = location.state
		? (location.state as DetailAvisState).avis
		: null

	useEffect(() => {
		setFormValues(INITAL_TRAITEMENT_AVIS)
		// @ts-ignore
		setValue(detailAvisFormEnum.montantAPayer, '0')
		// @ts-ignore
		setValue(detailAvisFormEnum.fraisGestion, '0')
		if (!!avis) {
			getAvisDetail({ [detailAvisPostEnum.fichier]: avis![filtresListeAvisResponseEnum.fichierDepose], [detailAvisPostEnum.anneeRedition]: avis![filtresListeAvisResponseEnum.anneeReddition] })
				.then((data) => {
					const avisMapper = mapperAvisPourTraitement(data)
					setFormValues(avisMapper)
					setValue(detailAvisResponseEnum.id, avis[filtresListeAvisResponseEnum.id])
					Object.keys(avisMapper).forEach((avis) => setValue(avis as keyof TraitementAvisModel, avisMapper[avis as keyof typeof avisMapper]))
				})
				.catch(() => {
						dispatch(displaySnackbar(
							{
								id: ERREUR_ID,
								message: 'Fichier de l\'avis introuvable',
								open: true,
								hideIcon: false
							})
						)
						navigate(liens.avis, { state: { rafraichir: true } })
					}
				)
				.finally(() => setLoading(false))
		} else {
			navigate(liens.avis)
		}
	}, [avis])

	if (loading) {
		return <Loader />
	}

	const onSubmit = (value: TraitementAvisModel) => {
		setLoadingSubmit(true)
		let erreurValidation = false
		if (thousandsSeparator(parseFloat(value[detailAvisResponseEnum.fraisGestion]), true) !== thousandsSeparator(parseFloat(value[detailAvisResponseEnum.frais][detailAvisResponseEnum.total] as string), true)) {
			erreurValidation = true
			setLoadingSubmit(false)
			dispatch(displaySnackbar(
				{
					id: ERREUR_ID,
					message: 'Le montant total de frais saisi pour l\'avis ne correspond pas au total global de frais calculé',
					open: true,
					hideIcon: false
				})
			)
		} else if (thousandsSeparator(parseFloat(value[detailAvisResponseEnum.montantAPayer]), true) !== thousandsSeparator(parseFloat(value[detailAvisResponseEnum.totalLigne][detailAvisResponseEnum.total] as string), true)) {
			erreurValidation = true
			setLoadingSubmit(false)
			dispatch(displaySnackbar(
				{
					id: ERREUR_ID,
					message: 'Le montant total saisi pour l\'avis ne correspond pas au total global calculé',
					open: true,
					hideIcon: false
				})
			)
		}
		value[detailAvisResponseEnum.immeubles].forEach((immeuble, index) => {
			if (thousandsSeparator(parseFloat(immeuble[detailAvisResponseEnum.totalCotisation]), true) !== thousandsSeparator(parseFloat(immeuble[detailAvisResponseEnum.communeCotisation][detailAvisResponseEnum.total] as string), true)) {
				erreurValidation = true
				setLoadingSubmit(false)
				dispatch(displaySnackbar(
					{
						id: ERREUR_ID,
						message: 'Le total des cotisations de l\'immeuble ' + watch(`${detailAvisResponseEnum.immeubles}.${index}.${detailAvisResponseEnum.immeubleExterne}.${immeubleExterneEnum.reference}`) + ' ne correspond pas à la somme des cotisations saisies',
						open: true,
						hideIcon: false
					})
				)
			}
		})

		if (!erreurValidation) {
			creationTaxesFoncieres(value)
				.then(() => navigate(liens.avis, { state: { rafraichir: true } }))
				.catch(() => {
						setLoadingSubmit(false)
						dispatch(displaySnackbar(
							{
								id: ERREUR_ID,
								message: 'Le traitement de l\'avis a échoué',
								open: true,
								hideIcon: false
							})
						)
					}
				)
		}
	}

	return (
		<Box>
			<Box sx={{ background: gecinaBlueMain, minHeight: '192px', paddingX: '172px' }}>
				<Box onClick={() => navigate(liens.avis, { state: { rafraichir: true } })}
				     sx={{
					     width: 'fit-content',
					     fontFamily: fontFamilyMonserrat,
					     fontSize: 16,
					     color: gecinaBrownMain,
					     display: 'flex',
					     flexDirection: 'row',
					     paddingTop: '21px',
					     '&:hover': { cursor: 'pointer' }
				     }}
				>
					<img src="/img/back_arrow.png" height={20} alt="Retour" />
					<Box sx={{ marginLeft: '10px' }}>Retour à la liste</Box>
				</Box>

				<Box sx={{ paddingTop: '22px', fontFamily: fontFamilyBlackerDisplay, fontSize: 28, fontWeight: 900 }}>
					{avis![filtresListeAvisResponseEnum.reference]}
				</Box>

				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						paddingTop: '40px',
						fontFamily: fontFamilyMonserrat
					}}
				>
					<Box>
						<Box sx={{ fontSize: 12 }}>Département</Box>
						<Box sx={{ fontWeight: 600 }}>{avis![filtresListeAvisResponseEnum.departement]}</Box>
					</Box>

					<Box sx={{ marginLeft: '180px' }}>
						<Box sx={{ fontSize: 12 }}>Commune</Box>
						<Box sx={{ fontWeight: 600 }}>{avis![filtresListeAvisResponseEnum.commune]}</Box>
					</Box>

					<Box sx={{ marginLeft: '180px' }}>
						<Box sx={{ fontSize: 12 }}>Date d'établissement</Box>
						<Box sx={{ fontWeight: 600 }}>{new Date(avis![filtresListeAvisResponseEnum.dateComptable]).toLocaleDateString()}</Box>
					</Box>

					<Box sx={{ marginLeft: '180px' }}>
						<Box sx={{ fontSize: 12 }}>Date limite de paiement</Box>
						<Box sx={{ fontWeight: 600 }}>{new Date(avis![filtresListeAvisResponseEnum.dateExigibilite]).toLocaleDateString()}</Box>
					</Box>

					<Box sx={{ marginLeft: '180px' }}>
						<Box sx={{ fontSize: 12 }}>Statut</Box>
						<Box sx={{ fontWeight: 600 }}>{avis![filtresListeAvisResponseEnum.statut]}</Box>
					</Box>
				</Box>
			</Box>
			<Grid container sx={{ paddingX: '172px' }}>
				<FormProvider {...methods}>
					<form onSubmit={handleSubmit(onSubmit)}>
						<Grid container sx={{ mt: '40px' }}>
							<Box
								sx={{
									color: gecinaBlueMain,
									paddingTop: '42px',
									fontFamily: fontFamilyBlackerDisplay,
									fontSize: 22,
									fontWeight: 900
								}}
							>
								Caractéristiques de l’avis
							</Box>

							<Grid mt="22px" flexDirection="row" display="flex" width="100%" alignItems="center">
								<Box sx={{ width: 150, color: gecinaBlueMain, minHeight: 90 }}>
									<CustomTextField
										sx={{ height: 51, width: 150, backgroundColor: 'transparent !important' }}
										id={detailAvisFormEnum.totalCotisations}
										label="Total cotisations"
										inputProps={{
											disabled: true,
											value: thousandsSeparator((watch(detailAvisFormEnum.montantAPayer) as number) - (watch(detailAvisFormEnum.fraisGestion) as number), true)
										}}
									/>
								</Box>
								<Box sx={{ width: 150, ml: '20px', color: gecinaBlueMain, minHeight: 90 }}>
									<FormNumberFormatField
										sx={{ height: 51, width: 150, backgroundColor: WHITE }}
										register={register(detailAvisFormEnum.fraisGestion, {
											required: 'Champ requis'
										})}
										value={watch(detailAvisFormEnum.fraisGestion)}
										// @ts-ignore
										onChange={(value: string) => setValue(detailAvisFormEnum.fraisGestion, value)}
										id={detailAvisFormEnum.fraisGestion}
										label="Frais de gestion *"
										decimalScale={0}
										withBorder
										maxLength={8}
									/>
								</Box>
								<Box sx={{ width: 150, ml: '20px', color: gecinaBlueMain, minHeight: 90 }}>
									<FormNumberFormatField
										sx={{ height: 51, width: 150, backgroundColor: WHITE }}
										register={register(detailAvisFormEnum.montantAPayer, {
											required: 'Champ requis'
										})}
										value={watch(detailAvisFormEnum.montantAPayer)}
										// @ts-ignore TODO ts-ignore
										onChange={(value: string) => setValue(detailAvisFormEnum.montantAPayer, value)}
										id={detailAvisFormEnum.montantAPayer}
										label="Montant à payer *"
										decimalScale={0}
										withBorder
										maxLength={8}
									/>
								</Box>
								<Box sx={{ width: 150, ml: '20px', color: gecinaBlueMain, minHeight: 90 }}>
									<Grid container>
											<span
												style={{
													width: '100%',
													fontSize: 16,
													marginBottom: 14,
													marginLeft: 0,
													boxSizing: 'border-box',
													textAlign: 'left',
													fontFamily: fontFamilyMonserrat,
													fontWeight: 600
												}}
											>
												Grand Paris
											</span>
										<CustomCheckbox
											checked={watch(detailAvisFormEnum.grandParis)}
											register={register(detailAvisFormEnum.grandParis)}
											onChange={(event) => {
												// @ts-ignore TODO ts-ignore
												setValue(detailAvisFormEnum.grandParis, event.target.checked)
												setFormValues(getValues())
											}}

										/>
									</Grid>
								</Box>
							</Grid>

							{
								formValues.immeubles.map((immeuble, index) =>
									<Grid mt="22px" width="100%" alignItems="center" key={`bloc-immeuble-${index}`}>
										<BlocImmeuble immeuble={immeuble} index={index} />
									</Grid>
								)
							}

							<Button
								sx={{
									height: 51,
									p: 0,
									width: 195,
									fontWeight: 600,
									lineHeight: '18.29px',
									mt: '45px'
								}}
								border
								disabled={false}
								bgcolor="transparent"
								color={gecinaBlueMain}
								type={buttonTypesEnum.button}
								onClick={() => {
									setFormValues({
											...formValues,
											[detailAvisResponseEnum.immeubles]: [
												...formValues[detailAvisResponseEnum.immeubles],
												TRAITEMENT_IMMEUBLE_VIDE
											]
										}
									)
									setValue(`${detailAvisResponseEnum.immeubles}.${formValues.immeubles.length}.${detailAvisFormEnum.ajustementFrais}`, '0' as never)
									setValue(`${detailAvisResponseEnum.immeubles}.${formValues.immeubles.length}.${detailAvisResponseEnum.adresse}`, 'Immeuble complémentaire' as never)
								}}
							>
								Ajouter un immeuble
							</Button>

							<TableauTotaux />
							<Grid container item justifyContent="flex-end" sx={{ mb: '20px', mt: '60px', ml: 0 }}>
								<Grid item sx={{ maxWidth: 'max-content', ml: '24px', minWidth: 'fit-content' }}>
									{
										loadingSubmit ?
											<Loader />
											:
											<Button
												sx={{
													height: 51,
													p: 0,
													width: 195,
													fontWeight: 600,
													lineHeight: '18.29px'
												}}
												bgcolor={gecinaBlueMain}
												color={gecinaBrownMain}
												border={false}
												disabled={loading}
												type={buttonTypesEnum.submit}
											>
												Valider
											</Button>
									}
								</Grid>
							</Grid>
						</Grid>
					</form>
				</FormProvider>
			</Grid>
		</Box>
	)
}

export default TraitementAvis