import axios from 'axios'
import { ResponseType, TauxListData, TauxResponseModel, TauxResponsePageModel } from '../types/TauxResponseModel'


export const getListeTaux = (page: number): Promise<TauxResponsePageModel> => axios.get(`/api/taux/list?page=${page}`)
	.then((response) => response.data)

export const supressionTauxApi = (tauxId: number): Promise<ResponseType> => axios.post('/api/taux/delete', JSON.stringify({ id: tauxId }))
	.then((response) => response.data)

export const updateTaux = (taux: TauxResponseModel): Promise<ResponseType> => axios.post('/api/taux/update', JSON.stringify(taux))
	.then((response) => response.data)