import EnteteListe from './components/EnteteListe'
import { Box } from '@mui/material'
import AffichageListe from './components/AffichageListe'
import { useLocation } from 'react-router-dom'
import React, { useState } from 'react'
import Loader from '../../components/loader/Loader'
import PopinSuppressionAvis from './components/PopinSuppressionAvis'

interface rafraichirListeState {
	rafraichir: boolean
}

const ListeAvis = () => {
	const [loading, setLoading] = useState<boolean>(true)
	const location = useLocation()
	const rafraichir = location.state
		? (location.state as rafraichirListeState).rafraichir
		: false

	return (
		<>
			<EnteteListe rafraichir={rafraichir} setLoading={setLoading} />

			{loading ?
				<Box height="150px">
					<Loader size={50} />
				</Box>
				:
				<Box
					sx={
						{
							mt: '10px'
						}
					}>
					<AffichageListe />
					<PopinSuppressionAvis />
				</Box>
			}
		</>
	)
}

export default ListeAvis