import axios from 'axios'
import { FiltresListeTaxeModel } from '../types/filtresListeTaxeModel'

export const getAnnees = () => axios.get('/api/taxes/annees')
	.then((response) => response.data)

export const getListeTaxes = (filtres: FiltresListeTaxeModel) => axios.post('/api/taxes/liste',filtres)
	.then((response) => response.data)

export const listeImmeubles = (search:string) => axios.get(`api/immeubles/search?value=${search}`)
	.then((response) => response.data)