export enum GestionTauxTableauLabelEnum {
	id = '',
	annee = 'Reddition',
	commune = 'Commune',
	departement = 'Département',
	taxe_speciale_affichage = 'Taxe spéciale',
	taxe_speciale_gp_affichage = 'Taxe spéciale GP',
	syndicat_commune = 'Syndicat',
	teom = 'TEOM',
	gemapi = 'GEMAPI'
}

export enum TauxReponseEnum {
	id = 'id',
	annee = 'annee',
	commune = 'commune',
	departement = 'departement',
	taxe_speciale = 'taxe_speciale',
	taxe_speciale_gp = 'taxe_speciale_gp',
	repartition_gp_taxe_speciale = 'repartition_gp_taxe_speciale',
	repartition_gp_taxe_speciale_gp = 'repartition_gp_taxe_speciale_gp',
	syndicat_commune = 'syndicat_commune',
	teom = 'teom',
	gemapi = 'gemapi',
}