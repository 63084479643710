import { Grid } from '@mui/material'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import TextField from '../../../components/fields/FormTextField'
import { buttonTypesEnum } from '../../../components/button/enums/buttonTypesEnum'
import { fontFamilyMonserrat, gecinaBrownMain, WHITE } from '../../../theme'
import Button from '../../../components/button/Button'
import AutorenewIcon from '@mui/icons-material/Autorenew'
import React, { useContext } from 'react'
import { FILTRES_NATURES_RUBRIQUES_PAIE } from '../enums/filtresNaturesRubriquesPaieEnum'
import { NaturesRubriquesPaieContext } from '../context/NaturesRubriquesPaieContext'
import { FiltresNaturesRubriquesPaieModel } from '../types/naturesRubriquesPaieModel'
import { getListeNaturesRubriquesPaie } from '../api/naturesRubriquesPaieAPI'

const INITIAL_VALEURS_FORMULAIRE = {
	[FILTRES_NATURES_RUBRIQUES_PAIE.COMPTE]: ''
}

const FiltresNaturesRubriquesPaie = () => {
	const { setChargementNaturesRubriquesPaie, setNaturesRubriquesPaie, setOuverturePopin, filtresNRP, setFiltresNRP } = useContext(NaturesRubriquesPaieContext)
	const methods = useForm<FiltresNaturesRubriquesPaieModel>({
		defaultValues: INITIAL_VALEURS_FORMULAIRE
	})
	const { register, handleSubmit, reset } = methods

	const onSubmit: SubmitHandler<FiltresNaturesRubriquesPaieModel> = (values) => {
		setChargementNaturesRubriquesPaie(true)
		const data = {
			...filtresNRP,
			[FILTRES_NATURES_RUBRIQUES_PAIE.PAGE]: 1
		}
		setFiltresNRP(filtresNRP)

		// Récupération de la liste des comptes selon les valeurs du filtre
		return getListeNaturesRubriquesPaie(1, values.compte)
			.then((response) => {
				setNaturesRubriquesPaie(response)
				setChargementNaturesRubriquesPaie(false)
			})
	}

	const ajoutCompte = () => setOuverturePopin({ open: true, naturesRubriquesPaie: undefined })

	return (
		<FormProvider {...methods}>
			<form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
				<Grid container spacing={2}>
					<Grid item xs={3}>
						<TextField
							register={register(FILTRES_NATURES_RUBRIQUES_PAIE.COMPTE, {
								maxLength: {
									value: 20,
									message: 'Le compte est limité à 20 caractères'
								}
							})}
							maxLength={20}
							id={FILTRES_NATURES_RUBRIQUES_PAIE.COMPTE}
							label="Compte"
							placeholder="Saisir un n° de compte"
						/>
					</Grid>

					<Grid container item xs={3} spacing={2} sx={{ m: 0 }} alignContent="flex-start">
						<span
							style={{
								width: '100%',
								paddingRight: 15,
								paddingTop: 7,
								fontSize: 14,
								marginLeft: 0,
								boxSizing: 'border-box',
								textAlign: 'left',
								fontFamily: fontFamilyMonserrat,
								fontWeight: 500
							}}
						> </span>

						<Grid item xs={7} sx={{ pt: '0px !important', height: 'fit-content' }}>
							<Button
								sx={{
									height: '32px',
									p: 0,
									minWidth: '100%',
									fontWeight: 600
								}}
								bgcolor={gecinaBrownMain}
								color={WHITE}
								border={false}
								disabled={false}
								type={buttonTypesEnum.submit}
							>
								Filtrer
							</Button>
						</Grid>

						<Grid item xs={3} md={2} sx={{ pt: '0px !important', height: 'fit-content' }}>
							<Button
								sx={{
									height: 32,
									p: 0,
									minWidth: '100%'
								}}
								bgcolor={gecinaBrownMain}
								color={WHITE}
								border={false}
								disabled={false}
								type={buttonTypesEnum.submit}
								onClick={() => reset(INITIAL_VALEURS_FORMULAIRE)}
							>
								<AutorenewIcon />
							</Button>
						</Grid>
					</Grid>

					<Grid container item xs={3} spacing={2} sx={{ m: 0, ml: 'auto' }} alignContent="flex-start">
						<span
							style={{
								width: '100%',
								paddingRight: 15,
								paddingTop: 7,
								fontSize: 14,
								marginLeft: 0,
								boxSizing: 'border-box',
								textAlign: 'left',
								fontFamily: fontFamilyMonserrat,
								fontWeight: 500
							}}
						> </span>

						<Grid item xs={12} sx={{ pt: '0px !important', height: 'fit-content' }}>
							<Button
								sx={{
									height: '32px',
									p: 0,
									minWidth: '100%',
									fontWeight: 600
								}}
								bgcolor={gecinaBrownMain}
								color={WHITE}
								border={false}
								disabled={false}
								type={buttonTypesEnum.button}
								onClick={ajoutCompte}
							>
								Ajouter un compte
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</form>
		</FormProvider>
	)
}

export default FiltresNaturesRubriquesPaie