export enum FiltresListeTaxesEnum {
	annee = 'annee',
	reference = 'reference',
	status = 'status',
	page = 'page',
	avis = 'avis'
}

// Enumération des statuts des avis
export enum statutListeTaxesEnum {
	all = 'Tous',
	ABSENTE = 'Absente',
	VALIDEE = 'Validée',
	GENEREE = 'Générée',
	TRANSMISE = 'Transmise',
}

export enum enteteTableauAffichageListeEnum {
	annee_reddition = 'Reddition',
	immeuble = 'Immeuble',
	cotisations = 'Cotisations',
	frais = 'Frais',
	total = 'Total à payer',
	statut = 'Statut',
	date = 'Date',
	n_depense = 'N° dépense',
	referenceAvis= 'Référence avis'
}