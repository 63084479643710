import React, { createContext, useState } from 'react'
import { INITAL_TRAITEMENT_AVIS, TraitementAvisContextModel, TraitementAvisModel } from '../types/detailAvisModel'

export const TraitementAvisContext = createContext<TraitementAvisContextModel>({
	formValues: INITAL_TRAITEMENT_AVIS,
	setFormValues: () => {
	}
})

export const TraitementAvisProvider: React.FC = ({children}) => {
	const [formValues, setFormValues] = useState<TraitementAvisModel>(INITAL_TRAITEMENT_AVIS)

	return (
		<TraitementAvisContext.Provider
			value={{
				formValues,
				setFormValues
			}}
		>
			{children}
		</TraitementAvisContext.Provider>
	)
}