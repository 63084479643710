import React from 'react'
import { ImmeublesQuotesPartsResidentielProvider } from './context/ImmeublesQuotesPartsResidentielContext'
import EnteteImmeublesQuotesPartsResidentiel from './components/EnteteImmeublesQuotesPartsResidentiel'
import ListeImmeublesQuotesPartsResidentiel from './components/ListeImmeublesQuotesPartsResidentiel'
import PopinGestionImmeublesQuotesPartsResidentiel from './components/PopinGestionImmeublesQuotesPartsResidentiel'

const ImmeublesQuotesPartsResidentiel = () => (
	<ImmeublesQuotesPartsResidentielProvider>
		<EnteteImmeublesQuotesPartsResidentiel />

		<ListeImmeublesQuotesPartsResidentiel />

		<PopinGestionImmeublesQuotesPartsResidentiel />
	</ImmeublesQuotesPartsResidentielProvider>
)

export default ImmeublesQuotesPartsResidentiel