import React, { useContext } from 'react'
import { ComptesRecuperablesNonRecuperablesContext } from '../context/ComptesRecuperablesNonRecuperablesContext'
import { EMPTY_FORM, FILTRES_COMPTES_RECUPERABLES_NON_RECUPERABLES } from '../enums/filtresComptesRecuperablesNonRecuperablesEnum'
import PopinGestionFormulaire from '../../../components/form/PopinGestionFormulaire'
import { ChampsPopinModel } from '../../../components/form/types/popinGestionFormulaire'
import PopinSuppressionContenu from '../../../components/dialog/components/PopinSuppressionContenu'
import { deleteComptesRecuperablesNonRecuperables, postComptesRecuperablesNonRecuperables, putComptesRecuperablesNonRecuperables } from '../api/comptesRecuperablesNonRecuperablesAPI'

//Champs de la popin
const POPIN_FIELDS: ChampsPopinModel[] = [
	{
		colonnes: 1,
		champs: [
			{
				id: FILTRES_COMPTES_RECUPERABLES_NON_RECUPERABLES.ID,
				type: 'hidden'
			},
			{
				id: FILTRES_COMPTES_RECUPERABLES_NON_RECUPERABLES.COMPTE,
				type: 'text',
				maxLength: 20,
				label: 'Compte *',
				disableOnUpdate: true
			}
		]
	},
	{
		colonnes: 2,
		champs: [
			{
				id: FILTRES_COMPTES_RECUPERABLES_NON_RECUPERABLES.COMPTE_RECUPERABLE,
				type: 'text',
				maxLength: 20,
				label: 'Compte récupérable *'
			},
			{
				id: FILTRES_COMPTES_RECUPERABLES_NON_RECUPERABLES.COMPTE_NON_RECUPERABLE,
				type: 'text',
				maxLength: 20,
				label: 'Compte non récupérables *'
			}
		]
	},
	{
		colonnes: 2,
		champs: [
			{
				id: FILTRES_COMPTES_RECUPERABLES_NON_RECUPERABLES.RUBRIQUE_ANALYTIQUE,
				type: 'text',
				maxLength: 20,
				label: 'Rubrique analytique *'
			}
		]
	}
]

const PopinGestionComptesRecuperablesNonRecuperables = () => {
	const {
		ouverturePopin,
		setOuverturePopin,
		comptesRecuperablesNonRecuperables,
		setComptesRecuperablesNonRecuperables,
		popinSuppression,
		setPopinSuppression
	} = useContext(ComptesRecuperablesNonRecuperablesContext)

	return (
		<>
			{/* Popin permettant d'ajouter / modifier un CRNR */}
			<PopinGestionFormulaire
				champsPopin={POPIN_FIELDS}
				formulaireVide={EMPTY_FORM}
				donneesPopin={{
					open: ouverturePopin.open,
					donnees: ouverturePopin.comptesRecuperablesNonRecuperables
				}}
				onCloseDialog={(closeData) => setOuverturePopin({
					open: closeData.open,
					comptesRecuperablesNonRecuperables: closeData.donnees
				})}
				contenu={comptesRecuperablesNonRecuperables['hydra:member']}
				modificationContenu={(nouveauContenu) => setComptesRecuperablesNonRecuperables({
					'hydra:totalItems': ouverturePopin.comptesRecuperablesNonRecuperables ? comptesRecuperablesNonRecuperables['hydra:totalItems'] : comptesRecuperablesNonRecuperables['hydra:totalItems']+1,
					'hydra:member': nouveauContenu
				})}
				titreCreation="Ajouter un compte récupérable / non récupérable"
				titreModification="Modifier la nomenclature du compte"
				envoiDonnees={ouverturePopin.comptesRecuperablesNonRecuperables ? putComptesRecuperablesNonRecuperables : postComptesRecuperablesNonRecuperables}
			/>

			{/* Popin permettant de supprimer un CRNR */}
			<PopinSuppressionContenu
				donneesPopin={{
					open: popinSuppression.open,
					donnees: popinSuppression.comptesRecuperablesNonRecuperables
				}}
				onCloseDialog={(closeData) => setPopinSuppression({
					open: closeData.open,
					comptesRecuperablesNonRecuperables: closeData.donnees
				})}
				titreSuppression="Suppression du paramétrage"
				texteSuppression="Confirmer la suppression du paramétrage ?"
				contenu={comptesRecuperablesNonRecuperables['hydra:member']}
				modificationContenu={(nouveauContenu) => setComptesRecuperablesNonRecuperables({
					'hydra:totalItems': comptesRecuperablesNonRecuperables['hydra:totalItems']-1,
					'hydra:member': nouveauContenu
				})}
				suppressionContenu={deleteComptesRecuperablesNonRecuperables}
			/>
		</>
	)

}

export default PopinGestionComptesRecuperablesNonRecuperables