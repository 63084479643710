import React from 'react'
import { Box, Grid } from '@mui/material'
import { fontFamilyBlackerDisplay, fontFamilyMonserrat, gecinaBlueMain } from '../../theme'

const NonAutorise = () => (
	<Grid container item xs={12} sx={{ background: gecinaBlueMain, height: '100%' }}>
		<Grid container item xs={9} direction="column" sx={{ margin: 'auto', background: 'white', px: 5, py: 3 }}>
			<Box component="h2" sx={{ color: gecinaBlueMain, fontSize: '20px', fontFamily: fontFamilyBlackerDisplay, textAlign: 'center' }}>Accès non autorisé</Box>
			<Box component="p" sx={{ color: gecinaBlueMain, fontSize: '14px', fontWeight: 600, fontFamily: fontFamilyMonserrat }}>Vous ne disposez pas de droits suffisants pour accéder à cette application</Box>
		</Grid>
	</Grid>
)

export default NonAutorise