import axios from 'axios'
import { CommunApiPlatformModel } from '../../../types/communApiPlatformModel'
import { ComptesNomenclatureChargesModel } from '../types/comptesNomenclatureChargesModel'

export const getListeComptesNomenclatureCharges = (page: number, compte?: string): Promise<CommunApiPlatformModel<ComptesNomenclatureChargesModel[]>> => axios.get(`/api/comptes_nomenclature_charges?page=${page}${compte ? `&compte=${compte}` : ''}`)
	.then((response) => response.data)

export const postCompteNomenclatureCharge = (data: ComptesNomenclatureChargesModel): Promise<ComptesNomenclatureChargesModel> => axios.post('/api/comptes_nomenclature_charges', data)
	.then((response) => response.data)

export const putCompteNomenclatureCharge = (data: ComptesNomenclatureChargesModel): Promise<ComptesNomenclatureChargesModel> => axios.put('/api/updateComptesNomenclatureCharges', data)
	.then((response) => response.data)

export const deleteCompteNomenclatureCharge = (data: ComptesNomenclatureChargesModel): Promise<ComptesNomenclatureChargesModel> => axios.delete(`/api/comptes_nomenclature_charges/${data['@id']!.split('/api/comptes_nomenclature_charges/')[1]}`)
	.then((response) => response.data)