import { ComptesTauxRecuperabiliteSouspostesModel } from '../../comptesTauxRecuperabiliteSouspostes/types/comptesTauxRecuperabiliteSouspostesModel'

export enum FILTRES_MATRICULES_TAUX {
	ID = '@id',
	MATRICULE = 'matricule',
	REFERENCE_IMMEUBLE = 'referenceImmeuble',
	TAUX_RECUP_RESIDENTIEL = 'tauxRecupResidentiel',
	DATE_DEBUT = 'dateDebut',
	DATE_FIN = 'dateFin',
	NOM_MODIFICATEUR = 'nomModificateur',
	ID_MODIFICATEUR = 'idModificateur',
	PAGE = 'page'
}

export const EMPTY_FORM = (tauxRecupResidentiel: ComptesTauxRecuperabiliteSouspostesModel[]) => ({
	[FILTRES_MATRICULES_TAUX.ID]: null,
	[FILTRES_MATRICULES_TAUX.MATRICULE]: '',
	[FILTRES_MATRICULES_TAUX.REFERENCE_IMMEUBLE]: '',
	[FILTRES_MATRICULES_TAUX.TAUX_RECUP_RESIDENTIEL]: tauxRecupResidentiel.length > 0 ? tauxRecupResidentiel[0].tauxRecupResidentiel : '',
	[FILTRES_MATRICULES_TAUX.DATE_DEBUT]: new Date(),
	[FILTRES_MATRICULES_TAUX.DATE_FIN]: null
})