import React, { createContext, Dispatch, SetStateAction, useEffect, useState } from 'react'
import { CommunApiPlatformModel, INITIAL_COMMUN_API } from '../../../types/communApiPlatformModel'
import { FiltresUtilisateursModel, INITIAL_FILTRE_UTILISATEURS, INITIAL_POPIN_UTILISATEURS, PopinUtilisateursModel, UtilisateursModel } from '../types/utilisateursModel'
import { FILTRES_UTILISATEURS } from '../enums/filtresUtilisateursEnum'
import { getListeUtilisateurs } from '../api/utilisateursAPI'

export interface UtilisateursContextProps {
	utilisateurs: CommunApiPlatformModel<UtilisateursModel[]>,
	setUtilisateurs: Dispatch<SetStateAction<CommunApiPlatformModel<UtilisateursModel[]>>>
	chargementUtilisateurs: boolean,
	setChargementUtilisateurs: Dispatch<SetStateAction<boolean>>,
	ouverturePopin: PopinUtilisateursModel,
	setOuverturePopin: Dispatch<SetStateAction<PopinUtilisateursModel>>,
	popinSuppression: PopinUtilisateursModel,
	setPopinSuppression: Dispatch<SetStateAction<PopinUtilisateursModel>>,
	filtresUtilisateurs: FiltresUtilisateursModel,
	setFiltresUtilisateurs: Dispatch<SetStateAction<FiltresUtilisateursModel>>
}

export const UtilisateursContext = createContext<UtilisateursContextProps>({
	utilisateurs: INITIAL_COMMUN_API<UtilisateursModel[]>([]), // Partage des comptes nomenclature charges aux éléments enfants
	setUtilisateurs: () => {
	}, // Mise à jour des CNC
	chargementUtilisateurs: true, // Détermine si les CNC sont en cours de chargement
	setChargementUtilisateurs: () => {
	}, // Modification du statut de chargement
	ouverturePopin: INITIAL_POPIN_UTILISATEURS, // Ouverture de la popin de création / modification d'un CNC
	setOuverturePopin: () => {
	}, // Mise à jour du statut d'ouverture de la popin de création / modification
	popinSuppression: INITIAL_POPIN_UTILISATEURS, // Ouverture de la popin de suppression d'un CNC
	setPopinSuppression: () => {
	}, // Mise à jour du statut d'ouverture de la popin de suppression
	filtresUtilisateurs: INITIAL_FILTRE_UTILISATEURS, // Partage du filtre sur les CNC
	setFiltresUtilisateurs: () => {
	} // Mise à jour du filtre sur les CNC
})

export const UtilisateursProvider: React.FC = (
	{
		children
	}
) => {
	const [utilisateurs, setUtilisateurs] = useState<CommunApiPlatformModel<UtilisateursModel[]>>(INITIAL_COMMUN_API<UtilisateursModel[]>([]))
	const [chargementUtilisateurs, setChargementUtilisateurs] = useState(true)
	const [ouverturePopin, setOuverturePopin] = useState<PopinUtilisateursModel>(INITIAL_POPIN_UTILISATEURS)
	const [popinSuppression, setPopinSuppression] = useState<PopinUtilisateursModel>(INITIAL_POPIN_UTILISATEURS)
	const [filtresUtilisateurs, setFiltresUtilisateurs] = useState<FiltresUtilisateursModel>(INITIAL_FILTRE_UTILISATEURS)

	useEffect(() => {
		getListeUtilisateurs(filtresUtilisateurs[FILTRES_UTILISATEURS.PAGE])
			.then((response) => {
				setUtilisateurs(response)
				setChargementUtilisateurs(false)
			})
	}, [])

	return (
		<UtilisateursContext.Provider
			value={{
				utilisateurs: utilisateurs,
				setUtilisateurs: setUtilisateurs,
				chargementUtilisateurs: chargementUtilisateurs,
				setChargementUtilisateurs: setChargementUtilisateurs,
				ouverturePopin,
				setOuverturePopin,
				popinSuppression,
				setPopinSuppression,
				filtresUtilisateurs: filtresUtilisateurs,
				setFiltresUtilisateurs: setFiltresUtilisateurs
			}}
		>
			{children}
		</UtilisateursContext.Provider>
	)
}