import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './containers/App'
import './font/montserrat/montserrat.css'
import './font/blackerdisplay/blackerdisplay.css'
import { BrowserRouter } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ListeAvisProvider } from './modules/listeAvis/context/ListeAvisContext'
import { ListeTaxesProvider } from './modules/listeTaxes/context/ListeTaxesContext'
import { Provider } from 'react-redux'
import reducers from '../src/config/reducers'
import { combineReducers, createStore } from 'redux'
import { TraitementAvisProvider } from './modules/traitementAvis/context/TraitementAvisContext'
import { createTheme } from './theme'
import { ThemeProvider } from '@mui/material/styles'
import AppProvider from './containers/context/AppContext'

const rootReducer = combineReducers(reducers)
const root = ReactDOM.createRoot(document.getElementById('root')!)
const store = createStore(
	rootReducer, {}
)

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false
		}
	}
})

root.render(
	<BrowserRouter>
		<ThemeProvider theme={createTheme(false)}>
			<QueryClientProvider client={queryClient}>
				<TraitementAvisProvider>
					<ListeAvisProvider>
						<ListeTaxesProvider>
							<Provider store={store}>
								<AppProvider>
									<App />
								</AppProvider>
							</Provider>
						</ListeTaxesProvider>
					</ListeAvisProvider>
				</TraitementAvisProvider>
			</QueryClientProvider>
		</ThemeProvider>
	</BrowserRouter>
)