import React, { useContext, useState } from 'react'
import { Box, ClickAwayListener, Collapse, Grid } from '@mui/material'
import TextField from '../../../components/fields/FormTextField'
import { detailAvisResponseEnum } from '../enums/detailAvisEnum'
import FormNumberFormatField from '../../../components/fields/FormNumberFormatField'
import { useFormContext } from 'react-hook-form'
import SvgCheckmark from '../../../icons/SvgCheckmark'
import SvgClose from '../../../icons/SvgClose'
import { gecinaBlueMain, WHITE } from '../../../theme'
import SvgEdit from '../../../icons/SvgEdit'
import thousandsSeparator from '../../../utils/thousandsSeparator'
import { gestionNombre } from '../../../utils/utils'

interface AcceptingProps {
	registerName: string,
	index: number,
	calculerTotaux: () => void
}

type CelluleTableauTraitementAvis = AcceptingProps
const CelluleTableauTraitementAvis: React.FC<CelluleTableauTraitementAvis> = (
	{
		registerName,
		index,
		calculerTotaux
	}
) => {
	const [open, setOpen] = useState<boolean>(false)
	const { register, watch, setValue, getValues } = useFormContext()
	const [newValue, setNewValue] = useState<string>(getValues(`${detailAvisResponseEnum.immeubles}.${index}.${detailAvisResponseEnum.communeCotisation}.${registerName}`))

	return (
		<ClickAwayListener onClickAway={() => {
			setOpen(false)
		}}>
			<Box
				sx={{
					width: '120px',
					cursor: 'pointer !important',
					'>svg': {
						display: 'none'
					},
					':hover': {
						'>svg': {
							display: 'block'
						}
					}
				}}
				onClick={() => !open && setOpen(true)}
			>
				<TextField
					register={register(`${detailAvisResponseEnum.immeubles}.${index}.${detailAvisResponseEnum.communeCotisation}.${registerName}`, {
						maxLength: {
							value: 11,
							message: 'Le champs est limité à 11 caractères'
						}
					})}
					maxLength={11}
					sx={{
						width: 150,
						backgroundColor: 'transparent !important'
					}}
					id={`${detailAvisResponseEnum.immeubles}.${index}.${detailAvisResponseEnum.communeCotisation}.${registerName}`}
					inputProps={{
						disabled: true,
						value: thousandsSeparator(gestionNombre(watch(`${detailAvisResponseEnum.immeubles}.${index}.${detailAvisResponseEnum.communeCotisation}.${registerName}`)))
					}}
				/>
				<SvgEdit color={gecinaBlueMain} style={{ position: 'absolute', top: '35%', right: '10%' }} />
				{open &&
					<Collapse
						in={open}
						timeout="auto"
						unmountOnExit
						addEndListener={() => {
						}}
						sx={{
							cursor: 'default',
							zIndex: 10,
							position: 'absolute',
							top: '-5px',
							left: 0,
							width: '100%',
							background: WHITE,
							backgroundColor: 'transparent'
						}}
					>
						<FormNumberFormatField
							sx={{
								backgroundColor: WHITE,
								height: '98%'
							}}
							defaultValue={watch(`${detailAvisResponseEnum.immeubles}.${index}.${detailAvisResponseEnum.communeCotisation}.${registerName}`) || 0}
							onChange={(value: string) => setNewValue(value || '0')}
							id={`${detailAvisResponseEnum.immeubles}.${index}.${detailAvisResponseEnum.communeCotisation}.${registerName}`}
							decimalScale={2}
							withBorder
							maxLength={11}
						/>
						<Grid container sx={{ backgroundColor: gecinaBlueMain, width: 60, height: 30, zIndex: 0 }} flexDirection="column">
							<Grid item sx={{ cursor: 'pointer !important', marginTop: '13%' }} onClick={() => {
								setOpen(false)
							}}>
								<SvgClose height={12} width={12} color={WHITE} />
							</Grid>
							<Grid item sx={{ cursor: 'pointer !important', marginTop: '13%' }} onClick={() => {
								setValue(`${detailAvisResponseEnum.immeubles}.${index}.${detailAvisResponseEnum.communeCotisation}.${registerName}`, newValue)
								setOpen(false)
								calculerTotaux()
							}}>
								<SvgCheckmark height={12} width={12} color={WHITE} />
							</Grid>
						</Grid>
					</Collapse>}
			</Box>
		</ClickAwayListener>
	)
}

export default CelluleTableauTraitementAvis