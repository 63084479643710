export enum typeFichierTraitementSGNFEnum {
	TOUT = 'Tous les types',
	TALENTIA = 'Talentia',
	SALAIRES_CASSIOPAE = 'Salaires Cassiopae',
	NDF_CASSIOPAE = 'Ndf Cassiopae'
}

export enum statutFichierTraitementSGNFEnum {
	TOUT = 'Tous les statuts',
	GENERE = 'Généré',
	TRANSMIS = 'Transmis',
	ERREUR = 'En erreur'
}