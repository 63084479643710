export enum FILTRES_IMMEUBLES_QUOTES_PARTS_RESIDENTIEL {
	ID = '@id',
	REFERENCE_IMMEUBLE = 'referenceImmeuble',
	QUOTE_PART = 'quotePartResidentiel',
	DATE_DEBUT = 'dateDebut',
	DATE_FIN = 'dateFin',
	NOM_MODIFICATEUR = 'nomModificateur',
	ID_MODIFICATEUR = 'idModificateur',
	PAGE = 'page'
}

export const EMPTY_FORM = {
	[FILTRES_IMMEUBLES_QUOTES_PARTS_RESIDENTIEL.ID]: null,
	[FILTRES_IMMEUBLES_QUOTES_PARTS_RESIDENTIEL.REFERENCE_IMMEUBLE]: '',
	[FILTRES_IMMEUBLES_QUOTES_PARTS_RESIDENTIEL.QUOTE_PART]: ''
}