import React, { useCallback, useContext, useMemo } from 'react'
import { HeadRow } from '../../../components/table/types/tableModel'
import { getKeyFromEnumValue } from '../../../utils/utils'
import { Box, IconButton } from '@mui/material'
import { remplissageLigneSelonEnteteTableau } from '../../../components/table/utils/tableUtils'
import CustomTable from '../../../components/table/Table'
import { enteteTableauListeTraitementSGNFEnum } from '../enums/enteteTableauListeTraitementSGNFEnum'
import { TraitementSGNFModel } from '../types/traitementSGNFModel'
import { TraitementSGNFContext } from '../context/TraitementSGNFContext'
import Loader from '../../../components/loader/Loader'
import DeleteIcon from '@mui/icons-material/Delete'
import { gecinaBrownMain } from '../../../theme'
import { columnsEnum } from '../../../components/table/enums/columnsEnum'
import { FILTRES_TRAITEMENT_SG_NF } from '../enums/filtresTraitementSGNFEnum'
import { getListeTraitementSGNF, telechargementFichierDepose, telechargementFichierGenere } from '../api/traitementSGNFAPI'
import { statutFichierTraitementSGNFEnum, typeFichierTraitementSGNFEnum } from '../enums/fichierTraitementSGNFEnum'
import DownloadForOfflineOutlinedIcon from '@mui/icons-material/DownloadForOfflineOutlined'

const ListeTraitementSGNF = () => {
	const {
		traitementSGNF,
		setTraitementSGNF,
		chargementTraitementSGNF,
		setPopinSuppression,
		filtresTraitementSGNF,
		setFiltresTraitementSGNF
	} = useContext(TraitementSGNFContext)

	const suppressionTraitementSGNF = (traitementSGNFConcerned: TraitementSGNFModel) => setPopinSuppression({
		open: true,
		traitementSGNF: traitementSGNFConcerned
	})

	const handleTelechargementFichierDepose = useCallback(async (fichierDepose: string) => {
		const type = traitementSGNF['hydra:member'].find((SGNF) => SGNF.fichierDepose === fichierDepose)?.type ?? ''
		const fichier = await telechargementFichierDepose(fichierDepose, type)

		const url = URL.createObjectURL(fichier)
		const a = document.createElement('a')
		a.href = url
		a.download = fichierDepose
		a.click()
	}, [traitementSGNF])

	const handleTelechargementCompteRendu = useCallback((compteRendu: string) => {
		const fichierDepose = traitementSGNF['hydra:member'].find((SGNF) => SGNF.compteRendu === compteRendu)?.fichierDepose ?? ''

		const blob = new Blob([compteRendu], { type: 'text/plain' })
		const url = URL.createObjectURL(blob)
		const a = document.createElement('a')
		a.href = url
		// On splitte le "." pour ne pas avoir l'extension du fichier, on ne veut que le nom
		a.download = `compteRendu_${fichierDepose.split('.')[0]}.txt`
		a.click()
	}, [traitementSGNF])

	const handleTelechargementFichierGenere = useCallback(async (fichierGenere: string) => {
		const type = traitementSGNF['hydra:member'].find((SGNF) => SGNF.fichierGenere === fichierGenere)?.type ?? ''
		const fichier = await telechargementFichierGenere(fichierGenere, type)

		const url = URL.createObjectURL(fichier)
		const a = document.createElement('a')
		a.href = url
		a.download = fichierGenere
		a.click()
	}, [traitementSGNF])

	const entetesTableau: HeadRow[] = useMemo(
		() => (
			[
				{
					label: enteteTableauListeTraitementSGNFEnum.fichierDepose,
					id: getKeyFromEnumValue<enteteTableauListeTraitementSGNFEnum>(enteteTableauListeTraitementSGNFEnum, enteteTableauListeTraitementSGNFEnum.fichierDepose),
					render: (fichierDepose) => (
						<Box
							sx={{
								overflow: 'hidden',
								textOverflow: 'ellipsis',
								whiteSpace: 'nowrap',
								width: '17vw'
							}}
							title={fichierDepose}
							onClick={() => handleTelechargementFichierDepose(fichierDepose)}
						>
							{fichierDepose}
						</Box>
					)
				},
				{
					label: enteteTableauListeTraitementSGNFEnum.type,
					id: getKeyFromEnumValue<enteteTableauListeTraitementSGNFEnum>(enteteTableauListeTraitementSGNFEnum, enteteTableauListeTraitementSGNFEnum.type),
					render: (type) => (
						<>{typeFichierTraitementSGNFEnum[type as keyof typeof typeFichierTraitementSGNFEnum]}</>
					)
				},
				{
					label: enteteTableauListeTraitementSGNFEnum.dateTraitement,
					id: getKeyFromEnumValue<enteteTableauListeTraitementSGNFEnum>(enteteTableauListeTraitementSGNFEnum, enteteTableauListeTraitementSGNFEnum.dateTraitement),
					render: (dateTraitement) => (
						// On splitte pour ne récupérer que la date (sans les heures)
						<>{new Date(dateTraitement).toLocaleString().split(' ')[0]}</>
					)
				},
				{
					label: enteteTableauListeTraitementSGNFEnum.statut,
					id: getKeyFromEnumValue<enteteTableauListeTraitementSGNFEnum>(enteteTableauListeTraitementSGNFEnum, enteteTableauListeTraitementSGNFEnum.statut)
				},
				{
					label: enteteTableauListeTraitementSGNFEnum.compteRendu,
					id: getKeyFromEnumValue<enteteTableauListeTraitementSGNFEnum>(enteteTableauListeTraitementSGNFEnum, enteteTableauListeTraitementSGNFEnum.compteRendu),
					render: (compteRendu) => (
						<IconButton
							color="secondary"
							aria-label="Téléchargement compte rendu"
							onClick={() => handleTelechargementCompteRendu(compteRendu)}
						>
							<DownloadForOfflineOutlinedIcon />
						</IconButton>
					)
				},
				{
					label: enteteTableauListeTraitementSGNFEnum.fichierGenere,
					id: getKeyFromEnumValue<enteteTableauListeTraitementSGNFEnum>(enteteTableauListeTraitementSGNFEnum, enteteTableauListeTraitementSGNFEnum.fichierGenere),
					render: (fichierGenere) => (
						<Box
							sx={{
								overflow: 'hidden',
								textOverflow: 'ellipsis',
								whiteSpace: 'nowrap',
								width: '17vw'
							}}
							title={fichierGenere}
							onClick={() => handleTelechargementFichierGenere(fichierGenere)}
						>
							{fichierGenere}
						</Box>
					)
				},
				{
					label: '',
					id: columnsEnum.customColonne,
					width: '15%',
					render: (ligneTableau: TraitementSGNFModel) => (
						<Box
							className="elementCache"
							sx={{
								visibility: 'hidden',
								textAlign: 'right',

								'& svg': {
									color: gecinaBrownMain
								}
							}}
						>
							{ligneTableau.statut === statutFichierTraitementSGNFEnum.ERREUR && (
								<IconButton aria-label="delete" title="Suppression du compte" onClick={() => suppressionTraitementSGNF(ligneTableau)}>
									<DeleteIcon />
								</IconButton>
							)}
						</Box>
					)
				}
			]
		), [traitementSGNF]
	)

	const auChangementDePage = (
		(nouvellePage: number) => {
			const data = {
				...filtresTraitementSGNF,
				[FILTRES_TRAITEMENT_SG_NF.PAGE]: nouvellePage
			}

			getListeTraitementSGNF(nouvellePage, filtresTraitementSGNF[FILTRES_TRAITEMENT_SG_NF.FICHIER_INTEGRE], filtresTraitementSGNF[FILTRES_TRAITEMENT_SG_NF.TYPE] ?? undefined, filtresTraitementSGNF[FILTRES_TRAITEMENT_SG_NF.STATUT] ?? undefined, filtresTraitementSGNF[FILTRES_TRAITEMENT_SG_NF.MATRICULE], filtresTraitementSGNF[FILTRES_TRAITEMENT_SG_NF.RECU_ENTRE_LE] ?? undefined, filtresTraitementSGNF[FILTRES_TRAITEMENT_SG_NF.ET_LE] ?? undefined)
				.then((response) => {
					setTraitementSGNF(response)
					setFiltresTraitementSGNF(data)
				})
				.finally(() => window.scrollTo(0, 0))
		}
	)

	if (chargementTraitementSGNF) {
		return (
			<Box height="150px">
				<Loader size={50} />
			</Box>
		)
	}

	return (
		<Box sx={{ paddingX: '172px' }}>
			<CustomTable
				id="listeTraitementSGNF"
				headRows={entetesTableau}
				rows={remplissageLigneSelonEnteteTableau<TraitementSGNFModel>(traitementSGNF['hydra:member'], entetesTableau)}
				total={traitementSGNF['hydra:totalItems']}
				borderBottom
				auCliqueSurLigne={() => {
				}}
				hasPagination
				auChangementDePage={auChangementDePage}
				page={filtresTraitementSGNF[FILTRES_TRAITEMENT_SG_NF.PAGE] - 1}
				hasSort={false}
			/>
		</Box>
	)
}

export default ListeTraitementSGNF