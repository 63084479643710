import React from 'react'
import { Box, Grid, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TableSortLabel } from '@mui/material'
import { HeadRow, TableType } from './types/tableModel'
import { orderEnum } from './enums/orderEnum'
import { fontFamilyMonserrat, gecinaBlueMain, gecinaBrownMain } from '../../theme'

const CustomTable: React.FC<TableType> = (
	{
		id,
		headRows,
		rows,
		defaultOrderBy,
		defaultOrder,
		tableContainerStyle,
		hasPagination = true,
		rowBackgroundColor,
		tableStyle,
		total = 0,
		auChangementDePage,
		page = 0,
		auCliqueSurLigne,
		titre,
		hasSort = true,
		borderBottom = false,
		allBorders = false
	}
) => {
	const [order, setOrder] = React.useState<orderEnum>(defaultOrder ?? orderEnum.asc)
	const [orderBy, setOrderBy] = React.useState<string | undefined>(
		defaultOrderBy ?? undefined
	)
	// States utilisés pour les tris custom à l'aide de la propriété onClick d'un HeadRow (i.e cas des dates)
	const [orderByCustom, setOrderByCustom] = React.useState<string | undefined>(
		defaultOrderBy ?? undefined
	)
	const [orderCustom, setOrderCustom] = React.useState<orderEnum>(defaultOrder ?? orderEnum.asc)

	const rowsPerPage = React.useMemo(() => 30, [])

	const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
		const isAsc = orderBy === property && order === orderEnum.asc
		setOrder(isAsc ? orderEnum.desc : orderEnum.asc)
		setOrderBy(property)
		setOrderByCustom(undefined)
	}

	const handleChangePage = (event: any, pageActuelle: number) => {
		auChangementDePage && auChangementDePage(pageActuelle + 1)
	}

	// Tri par colonne
	const rowsDataOrdered = rows.sort((a: object, b: object) => {
		if (a[orderBy as keyof typeof a] < b[orderBy as keyof typeof b]) {
			return order === orderEnum.asc ? -1 : 1
		}
		if (a[orderBy as keyof typeof a] > b[orderBy as keyof typeof b]) {
			return order === orderEnum.asc ? 1 : -1
		}
		return 0
	})

	// Premier cas pour les tris auto du composant
	// Deuxieme cas pour les tris custom avec le custom onClick
	// Dernier cas pas de tri
	const headRowRender = (headRow: HeadRow) => {
		if (hasSort || headRow.hasSort) {
			return <TableSortLabel
				active={orderBy === headRow.id}
				direction={orderBy === headRow.id ? order : orderEnum.asc}
				onClick={createSortHandler(headRow.id)}
			>
				<Box
					component="strong"
				>
					{headRow.label}
				</Box>
			</TableSortLabel>
		} else if (headRow.onClick) {
			return <TableSortLabel
				active={orderByCustom === headRow.id}
				direction={orderByCustom === headRow.id ? orderCustom : orderEnum.asc}
				onClick={() => {
					const isAsc = orderByCustom === headRow.id && orderCustom === orderEnum.asc
					setOrderCustom(isAsc ? orderEnum.desc : orderEnum.asc)
					setOrderByCustom(headRow.id)
					setOrderBy(undefined)
					headRow.onClick && headRow.onClick()
				}}
			>
				<Box
					component="strong"
				>
					{headRow.label}
				</Box>
			</TableSortLabel>
		} else {
			return <Box
				component="strong"
			>
				{headRow.label}
			</Box>
		}
	}

	// Pagination.
	const rowsData =
		rowsPerPage !== -1
			? rowsDataOrdered
			: rows

	return (
		<Grid
			container
			item
			xs={12}
			sx={{
				marginTop: '50px',
				...tableContainerStyle
			}}
		>
			{titre && (
				<Box
					component="div"
					sx={{ width: '100%' }}
				>
					<Box
						component="h1"
						sx={{
							m: 0,
							fontFamily: 'BlackerDisplay',
							color: gecinaBlueMain,
							fontSize: '28px'
						}}
					>
						{titre}
					</Box>
				</Box>
			)}

			<TableContainer>
				<Table sx={{ minWidth: 700, ...tableStyle }}>
					{headRows && (
						<TableHead>
							<TableRow>
								{headRows.map((headRow, index) => (
									<TableCell
										key={`${headRow.id}-${index}`}
										sortDirection={orderBy === headRow.id ? order : false}
										sx={[
											{
												textAlign: 'left',
												padding: '10px',
												color: gecinaBlueMain,
												fontSize: 16,
												fontWeight: 700,
												fontFamily: fontFamilyMonserrat
											},
											orderBy === headRow.id || orderByCustom === headRow.id ? {
												borderBottom: `3px solid ${gecinaBlueMain}`
											} : {
												borderBottom: `1px solid ${gecinaBlueMain}`
											},
											headRow.width ? {
												width: headRow.width
											} : {},
											allBorders && {
												border: `1px solid ${gecinaBlueMain}`
											}
										]}
									>
										{headRowRender(headRow)}
									</TableCell>
								))}
							</TableRow>
						</TableHead>
					)}
					<TableBody>
						{rowsData.map((row, index) => (
							index < rowsPerPage &&
							<TableRow
								key={`${id}-table-row-${page}-${index}`}
								sx={{
									backgroundColor: rowBackgroundColor,
									color: gecinaBlueMain,
									cursor: auCliqueSurLigne ? 'pointer' : 'default',

									':hover': {
										backgroundColor: auCliqueSurLigne ? gecinaBlueMain : 'inherit',
										color: auCliqueSurLigne ? gecinaBrownMain : gecinaBlueMain
									},
									':hover svg': {
										fill: auCliqueSurLigne ? gecinaBrownMain : gecinaBlueMain
									},
									':hover .elementCache': {
										visibility: 'visible'
									},
									'& .elementCache svg': {
										marginLeft: '10px'
									}
								}}
							>
								{Object.values(row).map((value: string, indexValue) => (
									<TableCell
										key={`table-row-${value}-${page}-${indexValue}-${index}`}
										sx={[
											{
												textAlign: 'left',
												padding: '10px',
												fontSize: 14,
												fontWeight: 500,
												color: 'inherit',
												position: 'relative',
												fontFamily: fontFamilyMonserrat

											},
											borderBottom && {
												borderBottom: `1px solid ${gecinaBlueMain}`
											},
											allBorders && {
												border: `1px solid ${gecinaBlueMain}`
											}
										]}
										onClick={() => auCliqueSurLigne ? auCliqueSurLigne(row) : undefined}
									>
										{value}
									</TableCell>
								))}
							</TableRow>
						))}
					</TableBody>
					{hasPagination && rowsData.length > 0 && (
						<TableFooter>
							<TableRow>
								<TablePagination
									rowsPerPageOptions={[rowsPerPage]}
									colSpan={Object.keys(rows[0]).length / 2}
									count={total}
									rowsPerPage={rowsPerPage}
									page={page}
									sx={{
										borderBottom: 'none'
									}}
									SelectProps={{
										inputProps: {
											'aria-label': 'Lignes par page'
										},
										native: true
									}}
									labelRowsPerPage="Lignes par page :"
									labelDisplayedRows={(recapTableau) => `${recapTableau.from}-${recapTableau.to} sur ${recapTableau.count}`}
									onPageChange={handleChangePage}
								/>
							</TableRow>
						</TableFooter>
					)}
				</Table>
			</TableContainer>
		</Grid>
	)
}

export default CustomTable
