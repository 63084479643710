import React, { createContext, Dispatch, SetStateAction, useEffect, useState } from 'react'
import { CommunApiPlatformModel, INITIAL_COMMUN_API } from '../../../types/communApiPlatformModel'
import { getListeComptesTauxRecuperabiliteSouspostes } from '../api/comptesTauxRecuperabiliteSouspostesAPI'
import {
	ComptesTauxRecuperabiliteSouspostesModel,
	FiltresComptesTauxRecuperabiliteSouspostesModel,
	INITIAL_FILTRE_CTRS,
	INITIAL_POPIN_CTRS,
	PopinComptesTauxRecuperabiliteSouspostesModel
} from '../types/comptesTauxRecuperabiliteSouspostesModel'
import { FILTRES_COMPTES_TAUX_RECUPERABILITE_SOUSPOSTES } from '../enums/filtresComptesTauxRecuperabiliteSouspostesEnum'

export interface ComptesRecuperablesNonRecuperablesContextProps {
	comptesTauxRecuperabiliteSouspostes: CommunApiPlatformModel<ComptesTauxRecuperabiliteSouspostesModel[]>,
	setComptesTauxRecuperabiliteSouspostes: Dispatch<SetStateAction<CommunApiPlatformModel<ComptesTauxRecuperabiliteSouspostesModel[]>>>
	chargementComptesTauxRecuperabiliteSouspostes: boolean,
	setChargementComptesTauxRecuperabiliteSouspostes: Dispatch<SetStateAction<boolean>>,
	ouverturePopin: PopinComptesTauxRecuperabiliteSouspostesModel,
	setOuverturePopin: Dispatch<SetStateAction<PopinComptesTauxRecuperabiliteSouspostesModel>>,
	popinSuppression: PopinComptesTauxRecuperabiliteSouspostesModel,
	setPopinSuppression: Dispatch<SetStateAction<PopinComptesTauxRecuperabiliteSouspostesModel>>,
	filtresCTRS: FiltresComptesTauxRecuperabiliteSouspostesModel,
	setFiltresCTRS: Dispatch<SetStateAction<FiltresComptesTauxRecuperabiliteSouspostesModel>>
}

export const ComptesTauxRecuperabiliteSouspostesContext = createContext<ComptesRecuperablesNonRecuperablesContextProps>({
	comptesTauxRecuperabiliteSouspostes: INITIAL_COMMUN_API<ComptesTauxRecuperabiliteSouspostesModel[]>([]), // Partage des comptes / codes sous postes aux éléments enfants
	setComptesTauxRecuperabiliteSouspostes: () => {}, // Mise à jour des CTRS
	chargementComptesTauxRecuperabiliteSouspostes: true, // Détermine si les CTRS sont en cours de chargement
	setChargementComptesTauxRecuperabiliteSouspostes: () => {}, // Modification du statut de chargement
	ouverturePopin: INITIAL_POPIN_CTRS, // Ouverture de la popin de création / modification d'un CTRS
	setOuverturePopin: () => {}, // Mise à jour du statut d'ouverture de la popin de création / modification
	popinSuppression: INITIAL_POPIN_CTRS, // Ouverture de la popin de suppression d'un CTRS
	setPopinSuppression: () => {}, // Mise à jour du statut d'ouverture de la popin de suppression
	filtresCTRS: INITIAL_FILTRE_CTRS, // Partage du filtre sur les CTRS
	setFiltresCTRS: () => {} // Mise à jour du filtre sur les CTRS
})

export const ComptesTauxRecuperabiliteSouspostesProvider: React.FC = (
	{
		children
	}
) => {
	const [comptesTauxRecuperabiliteSouspostes, setComptesTauxRecuperabiliteSouspostes] = useState<CommunApiPlatformModel<ComptesTauxRecuperabiliteSouspostesModel[]>>(INITIAL_COMMUN_API<ComptesTauxRecuperabiliteSouspostesModel[]>([]))
	const [chargementComptesTauxRecuperabiliteSouspostes, setChargementComptesTauxRecuperabiliteSouspostes] = useState(true)
	const [ouverturePopin, setOuverturePopin] = useState<PopinComptesTauxRecuperabiliteSouspostesModel>(INITIAL_POPIN_CTRS)
	const [popinSuppression, setPopinSuppression] = useState<PopinComptesTauxRecuperabiliteSouspostesModel>(INITIAL_POPIN_CTRS)
	const [filtresCTRS, setFiltresCTRS] = useState<FiltresComptesTauxRecuperabiliteSouspostesModel>(INITIAL_FILTRE_CTRS)

	useEffect(
		() => {
			getListeComptesTauxRecuperabiliteSouspostes(filtresCTRS[FILTRES_COMPTES_TAUX_RECUPERABILITE_SOUSPOSTES.PAGE], true)
				.then((response) => {
					setComptesTauxRecuperabiliteSouspostes(response)
					setChargementComptesTauxRecuperabiliteSouspostes(false)
				})
		}, []
	)

	return (
		<ComptesTauxRecuperabiliteSouspostesContext.Provider
			value={{
				comptesTauxRecuperabiliteSouspostes,
				setComptesTauxRecuperabiliteSouspostes,
				chargementComptesTauxRecuperabiliteSouspostes,
				setChargementComptesTauxRecuperabiliteSouspostes,
				ouverturePopin,
				setOuverturePopin,
				popinSuppression,
				setPopinSuppression,
				filtresCTRS,
				setFiltresCTRS
			}}
		>
			{children}
		</ComptesTauxRecuperabiliteSouspostesContext.Provider>
	)
}