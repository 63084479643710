import React, { Dispatch } from 'react'
import { connect } from 'react-redux'
import { hideSnackbar } from './reducer/actions'
import { SnackbarTypes } from './reducer/types'
import { Snackbar } from '@mui/material'
import SvgClose from '../../icons/SvgClose'
import { fontFamilyMonserrat, WHITE } from '../../theme'
import ReasonEnum from '../../enum/reasonEnum'


interface ModelSnackBarData {
	id: string,
	message: string,
	open: boolean,
	hideIcon?: boolean
}

interface StoreProps {
	snackBarData: ModelSnackBarData,
	hideSnackbar: () => void
}

type SnackBarProps = StoreProps

export const ERREUR_ID = 'ERR'
const UnstyledSnackBar: React.FC<SnackBarProps> = (
	{
		snackBarData,
		hideSnackbar
	}
) => {
	const vertical = 'bottom'
	const horizontal = 'center'

	const handleClose = (event: any, reason: any) => {
		if (reason !== ReasonEnum.clickaway) {
			hideSnackbar()
		}
	}

	return (
		<Snackbar
			anchorOrigin={{ vertical, horizontal }}
			key={snackBarData.id}
			open={snackBarData.open}
			onClose={handleClose}
			ContentProps={{
				'aria-describedby': snackBarData.id
			}}
			sx={{
				'& .MuiSnackbarContent-root': {
					wordBreak: 'break-word',
					verticalAlign: 'bottom',
					marginRight: '20px',
					backgroundColor: '#892323',
					fontFamily: fontFamilyMonserrat,
					fontSize: 18,
					width: 700,
					minHeight: 90,
					paddingLeft: '20px',
					paddingRight: '20px',
					borderRadius: '10px',
					'& .MuiSnackbarContent-message': {
						'& > span': {
							display: 'flex',
							whiteSpace: 'break-spaces',
							flexDirection: 'row',
							alignItems: 'center',
							'& > span': {
								marginRight: '20px'
							},
							'& > svg': {
								cursor: 'pointer'
							}
						}
					}

				}
			}}
			message={
				<span id={snackBarData.id}>
					<span style={{ width: 650 }}>
						{snackBarData.message}
					</span>
					{!snackBarData.hideIcon && <SvgClose color={WHITE} onClick={hideSnackbar} />}
				</span>
			}
		/>
	)
}

const mapStateToProps = (state: any) => {
	return {
		snackBarData: state.snackBar
	}
}

const mapDispatchToProps = (dispatch: Dispatch<SnackbarTypes>) => {
	return {
		hideSnackbar: () => {
			dispatch(hideSnackbar())
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(UnstyledSnackBar)

