import React from 'react'
import { ComptesNomenclatureChargesProvider } from './context/ComptesNomenclatureChargesContext'
import EnteteComptesNomenclatureCharges from './components/EnteteComptesNomenclatureCharges'
import ListeComptesNomenclatureCharges from './components/ListeComptesNomenclatureCharges'
import PopinGestionComptesNomenclatureCharges from './components/PopinGestionComptesNomenclatureCharges'

const ComptesNomenclatureCharges = () => (
	<ComptesNomenclatureChargesProvider>
		<EnteteComptesNomenclatureCharges />
		<ListeComptesNomenclatureCharges />
		<PopinGestionComptesNomenclatureCharges />
	</ComptesNomenclatureChargesProvider>
)

export default ComptesNomenclatureCharges