import React, { useCallback, useContext, useMemo, useState } from 'react'
import { SousTab, TabOptions } from './TabsTypes'
import { Grid, MenuItem } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import { StyledItemTabBar, StyledMenu } from './StyledComponents'
import { AppContext } from '../../containers/context/AppContext'
import { ApplicationOptionsEnum } from '../../modules/menu/enums/ApplicationOptionsEnum'
import { PROFILS_ENUM } from '../../modules/utilisateurs/enums/filtresUtilisateursEnum'

interface ItemTabBarProps {
	tab: TabOptions
}

const ItemTabBar: React.FC<ItemTabBarProps> = ({ tab }) => {
	const { userInfos } = useContext(AppContext)
	const navigate = useNavigate()
	const location = useLocation()

	const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)

	const open = useMemo(() => Boolean(anchorEl), [anchorEl])
	const isTabSelected = useMemo(() => location.pathname.includes(tab.lien), [tab, location])
	const isSubTabSelected = useCallback((sousTab: SousTab) => location.pathname.includes(sousTab.lien), [location])

	const showSubMenu = (event: React.MouseEvent<HTMLDivElement>) => setAnchorEl(event.currentTarget)
	const handleClose = () => setAnchorEl(null)

	const handleSubTabClick = (event: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLLIElement>, subTab: SousTab) => {
		setAnchorEl(null)
		navigate(subTab.lien)
	}

	return (
		<Grid container item xs>
			<StyledItemTabBar
				selected={isTabSelected}
				onClick={(e) => {
					// S'il y a des sous-tabs, on affiche le sous-menu
					if (tab.sousTabs?.length) {
						showSubMenu(e)
					} else {
						// Sinon on redirige
						navigate(tab.lien, { state: { rafraichir: tab.rafraichir } })
					}
				}}
			>
				{tab.label}
			</StyledItemTabBar>
			<StyledMenu
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				transformOrigin={{
					vertical: 22,
					horizontal: 'left'
				}}
			>
				{tab.sousTabs?.map((sousTab, index) => (
					(!sousTab.needAdmin || (sousTab.needAdmin && userInfos.profils.split(', ').includes(PROFILS_ENUM.ADMINISTRATEUR, userInfos.application.split(', ').indexOf(ApplicationOptionsEnum.SALAIRES_GARDIENS)))) &&
					<MenuItem
						key={`list-item-${index}`}
						selected={isSubTabSelected(sousTab)}
						onClick={(event) => handleSubTabClick(event, sousTab)}
					>
						{sousTab.label}
					</MenuItem>
				))}
			</StyledMenu>
		</Grid>
	)
}

export default ItemTabBar