import React, { useState } from 'react'
import { Box, BoxProps, Grid, GridProps } from '@mui/material'
import { useDispatch } from 'react-redux'
import { DonneesPopinModel } from '../../form/types/popinGestionFormulaire'
import { displaySnackbar } from '../../snackBar/reducer/actions'
import { ERREUR_ID } from '../../snackBar/SnackBar'
import Dialog from '../Dialog'
import { fontFamilyBlackerDisplay, gecinaBlueMain, gecinaBrownMain, WHITE } from '../../../theme'
import Button from '../../button/Button'
import { buttonTypesEnum } from '../../button/enums/buttonTypesEnum'
import { styled } from '@mui/system'

const GridTitreContainer = styled((props: GridProps) => (<Grid {...props} />))(({ theme }) => (
	{ fontFamily: fontFamilyBlackerDisplay, fontSize: 22, borderBottom: '1px solid', padding: 0 }
))

const BoxTitreContainer = styled((props: BoxProps) => (<Box {...props} />))(({ theme }) => (
	{ paddingTop: '40px', padding: '40px', paddingBottom: '20px' }
))


interface AcceptingProps<FormModel> {
	donneesPopin: DonneesPopinModel
	onCloseDialog: (donneesModel: DonneesPopinModel) => void
	titreSuppression: string
	texteSuppression: string
	contenu: FormModel[]
	modificationContenu: (donneesModifiees: FormModel[]) => void
	suppressionContenu: (data: FormModel) => Promise<FormModel>
}

type PopinSuppressionContenuProps<FormModel> = AcceptingProps<FormModel>

const PopinSuppressionContenu = <FormModel, >(
	{
		donneesPopin,
		onCloseDialog,
		titreSuppression,
		texteSuppression,
		contenu,
		modificationContenu,
		suppressionContenu
	}: PopinSuppressionContenuProps<FormModel>
) => {
	const [enCoursDeSuppression, setEnCoursDeSuppression] = useState<boolean>(false)
	const dispatch = useDispatch()

	const closeDialog = () => onCloseDialog({
		open: false,
		donnees: undefined
	})

	const handleSuppressionContenu = () => {
		setEnCoursDeSuppression(true)
		suppressionContenu(donneesPopin.donnees)
			.then((response) => {
				// Ferme la snackBar auto si ça réussit
				dispatch(displaySnackbar({
					id: ERREUR_ID,
					message: '',
					open: false,
					hideIcon: false
				}))
				closeDialog()
				setEnCoursDeSuppression(false)

				let donneesFinales: FormModel[] = []
				// Récupération de l'index de la précédente donnée (dans le cas d'une modification)
				const responseIndex = contenu.findIndex((donnee) => {
					// Si nous n'avons pas modifié la ligne, on renvoie -1 pour ajouter la nouvelle ligne au tableau
					if (!donneesPopin.donnees) {
						return -1
					}

					if ('@id' in donnee && '@id' in donneesPopin.donnees) {
						return donnee['@id' as keyof typeof donnee] === donneesPopin.donnees['@id' as keyof typeof donneesPopin.donnees]
					}
					if ('id' in donnee && 'id' in donneesPopin.donnees) {
						return donnee['id' as keyof typeof donnee] === donneesPopin.donnees['id' as keyof typeof donneesPopin.donnees]
					}
				})

				// Si l'index a été retrouvé, on supprime l'ancienne donnée
				if (responseIndex >= 0) {
					donneesFinales = [
						...contenu.slice(0, responseIndex),
						...contenu.slice(responseIndex + 1)
					]
				}

				modificationContenu(donneesFinales)
			})
			.catch((error) => {
					console.error('Erreur lors de l\'envoi des données', error)
					console.error('Message', error.response)

					// On affiche l'erreur dans une popin
					if (error.response && error.response.data) {
						dispatch(displaySnackbar({
							id: ERREUR_ID,
							message: error.response.data,
							open: true,
							hideIcon: false
						}))
					}

					setEnCoursDeSuppression(false)
				}
			)
	}

	return (
		<Dialog
			open={donneesPopin.open}
			onCloseDialog={closeDialog}
			fullScreen={false}
			withPadding={false}
			maxWidth={false}
		>
			<Box justifyContent="center">
				<GridTitreContainer container>
					<BoxTitreContainer>
						{titreSuppression}
					</BoxTitreContainer>
				</GridTitreContainer>
				<Grid container sx={{ padding: '40px', display: 'block' }}>
					{texteSuppression}
					<Grid item container sx={{ marginTop: '60px' }} justifyContent="end">
						<Button bgcolor={WHITE} color={gecinaBlueMain} border={true} disabled={false} sx={{ marginRight: '24px' }} onClick={() => {
							dispatch(displaySnackbar({
								id: ERREUR_ID,
								message: '',
								open: false,
								hideIcon: false
							}))
							closeDialog()
						}}>
							Annuler
						</Button>
						<Button bgcolor={gecinaBlueMain} color={gecinaBrownMain} border={false} disabled={enCoursDeSuppression} type={buttonTypesEnum.submit} onClick={handleSuppressionContenu}>
							Supprimer
						</Button>
					</Grid>
				</Grid>
			</Box>
		</Dialog>

	)

}

export default PopinSuppressionContenu