import React, { createContext, Dispatch, SetStateAction, useEffect, useState } from 'react'
import { CommunApiPlatformModel, INITIAL_COMMUN_API } from '../../../types/communApiPlatformModel'
import { getListeTraitementSGNF } from '../api/traitementSGNFAPI'
import {
	TraitementSGNFModel,
	FiltresTraitementSGNFModel,
	INITIAL_FILTRE_TRAITEMENT_SG_NF, PopinTraitementSGNFModel, INITIAL_POPIN_TRAITEMENT_SG_NF
} from '../types/traitementSGNFModel'
import { FILTRES_TRAITEMENT_SG_NF } from '../enums/filtresTraitementSGNFEnum'
import { INITIAL_POPIN_NRP, PopinNaturesRubriquesPaieModel } from '../../naturesRubriquesPaie/types/naturesRubriquesPaieModel'

export interface TraitementSGNFContextProps {
	traitementSGNF: CommunApiPlatformModel<TraitementSGNFModel[]>,
	setTraitementSGNF: Dispatch<SetStateAction<CommunApiPlatformModel<TraitementSGNFModel[]>>>
	chargementTraitementSGNF: boolean,
	setChargementTraitementSGNF: Dispatch<SetStateAction<boolean>>,
	popinSuppression: PopinTraitementSGNFModel,
	setPopinSuppression: Dispatch<SetStateAction<PopinTraitementSGNFModel>>,
	filtresTraitementSGNF: FiltresTraitementSGNFModel,
	setFiltresTraitementSGNF: Dispatch<SetStateAction<FiltresTraitementSGNFModel>>
}

export const TraitementSGNFContext = createContext<TraitementSGNFContextProps>({
	traitementSGNF: INITIAL_COMMUN_API<TraitementSGNFModel[]>([]), // Partage des traitements SG / NF aux éléments enfants
	setTraitementSGNF: () => {}, // Mise à jour des traitements SG / NF
	chargementTraitementSGNF: true, // Détermine si les traitements SG / NF sont en cours de chargement
	setChargementTraitementSGNF: () => {}, // Modification du statut de chargement
	popinSuppression: INITIAL_POPIN_TRAITEMENT_SG_NF, // Ouverture de la popin de suppression d'un traitement SG / NF
	setPopinSuppression: () => {}, // Mise à jour du statut d'ouverture de la popin de suppression
	filtresTraitementSGNF: INITIAL_FILTRE_TRAITEMENT_SG_NF, // Partage du filtre sur les traitements SG / NF
	setFiltresTraitementSGNF: () => {} // Mise à jour du filtre sur les traitements SG / NF
})

export const TraitementSGNFProvider: React.FC = (
	{
		children
	}
) => {
	const [traitementSGNF, setTraitementSGNF] = useState<CommunApiPlatformModel<TraitementSGNFModel[]>>(INITIAL_COMMUN_API<TraitementSGNFModel[]>([]))
	const [chargementTraitementSGNF, setChargementTraitementSGNF] = useState(true)
	const [popinSuppression, setPopinSuppression] = useState<PopinTraitementSGNFModel>(INITIAL_POPIN_TRAITEMENT_SG_NF)
	const [filtresTraitementSGNF, setFiltresTraitementSGNF] = useState<FiltresTraitementSGNFModel>(INITIAL_FILTRE_TRAITEMENT_SG_NF)

	useEffect(
		() => {
			getListeTraitementSGNF(filtresTraitementSGNF[FILTRES_TRAITEMENT_SG_NF.PAGE])
				.then((response) => {
					setTraitementSGNF(response)
					setChargementTraitementSGNF(false)
				})
		}, []
	)

	return (
		<TraitementSGNFContext.Provider
			value={{
				traitementSGNF,
				setTraitementSGNF,
				chargementTraitementSGNF,
				setChargementTraitementSGNF,
				popinSuppression,
				setPopinSuppression,
				filtresTraitementSGNF,
				setFiltresTraitementSGNF
			}}
		>
			{children}
		</TraitementSGNFContext.Provider>
	)
}