import { statutFichierTraitementSGNFEnum, typeFichierTraitementSGNFEnum } from '../enums/fichierTraitementSGNFEnum'
import { FILTRES_TRAITEMENT_SG_NF } from '../enums/filtresTraitementSGNFEnum'
import { NaturesRubriquesPaieModel } from '../../naturesRubriquesPaie/types/naturesRubriquesPaieModel'

export interface TraitementSGNFModel {
	'@id': string | null,
	fichierDepose: string | null,
	type: string | null,
	recuLe: Date | null,
	statut: string | null,
	compteRendu: string | null,
	fichierGenere: string | null
}

export interface FiltresTraitementSGNFModel {
	[FILTRES_TRAITEMENT_SG_NF.FICHIER_INTEGRE]: string
	[FILTRES_TRAITEMENT_SG_NF.TYPE]: string | null,
	[FILTRES_TRAITEMENT_SG_NF.STATUT]: string | null,
	[FILTRES_TRAITEMENT_SG_NF.MATRICULE]: string,
	[FILTRES_TRAITEMENT_SG_NF.RECU_ENTRE_LE]: Date | null
	[FILTRES_TRAITEMENT_SG_NF.ET_LE]: Date | null,
	[FILTRES_TRAITEMENT_SG_NF.PAGE]: number
}

export interface PopinTraitementSGNFModel {
	open: boolean,
	traitementSGNF: TraitementSGNFModel | undefined
}

export const INITIAL_TRAITEMENT_SG_NF: TraitementSGNFModel = {
	'@id': '',
	fichierDepose: null,
	type: null,
	recuLe: null,
	statut: null,
	compteRendu: null,
	fichierGenere: null
}

export const INITIAL_POPIN_TRAITEMENT_SG_NF: PopinTraitementSGNFModel = {
	open: false,
	traitementSGNF: INITIAL_TRAITEMENT_SG_NF
}

export const INITIAL_FILTRE_TRAITEMENT_SG_NF: FiltresTraitementSGNFModel = {
	[FILTRES_TRAITEMENT_SG_NF.FICHIER_INTEGRE]: '',
	[FILTRES_TRAITEMENT_SG_NF.TYPE]: null,
	[FILTRES_TRAITEMENT_SG_NF.STATUT]: null,
	[FILTRES_TRAITEMENT_SG_NF.MATRICULE]: '',
	[FILTRES_TRAITEMENT_SG_NF.RECU_ENTRE_LE]: null,
	[FILTRES_TRAITEMENT_SG_NF.ET_LE]: null,
	[FILTRES_TRAITEMENT_SG_NF.PAGE]: 1
}