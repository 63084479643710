import { Box, Grid } from '@mui/material'
import { gecinaBlueMain } from '../../../theme'
import FiltresListeAvis from './FiltresListeAvis'
import React, { Dispatch } from 'react'

interface EnteteListeProps {
	setLoading: Dispatch<React.SetStateAction<boolean>>,
	rafraichir: boolean
}

const EnteteListe: React.FC<EnteteListeProps> = (
	{
		setLoading,
		rafraichir
	}
) => {
	return (
		<Box sx={{ background: gecinaBlueMain, minHeight: '192px', paddingX: '172px' }}>
			<Grid container item xs={12} justifyContent="space-between" sx={{ pt: 5 }}>
				<Box sx={{ fontFamily: 'BlackerDisplay', fontSize: 28, fontWeight: 900 }}>
					Avis de taxes foncières
				</Box>
			</Grid>
			<Grid container item xs={12} justifyContent="space-evenly" sx={{ pt: 5 }}>
				<FiltresListeAvis rafraichir={rafraichir} setLoading={setLoading} />
			</Grid>
		</Box>
	)
}

export default EnteteListe