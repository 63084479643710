import { DetailAvisResponseModel, TraitementAvisModel } from '../types/detailAvisModel'
import { detailAvisFormEnum, detailAvisResponseEnum, enteteTableauAffichageImmeubleLabelEnum, immeubleExterneEnum } from '../enums/detailAvisEnum'

export const mapperAvisPourTraitement = (avis: DetailAvisResponseModel): TraitementAvisModel => {
	return (
		{
			...avis,
			[detailAvisFormEnum.immeubles]: avis[detailAvisResponseEnum.immeubles].map((immeuble) => (
				{
					[detailAvisResponseEnum.adresse]: immeuble[detailAvisResponseEnum.adresse],
					[detailAvisResponseEnum.immeubleExterne]: {
						[immeubleExterneEnum.id]: 0,
						[immeubleExterneEnum.reference]: '',
						[immeubleExterneEnum.adresse]: '',
						[immeubleExterneEnum.ville]: ''
					},
					[detailAvisResponseEnum.totalCotisation]: immeuble[detailAvisResponseEnum.totalCotisation],
					[detailAvisFormEnum.ajustementFrais]: '0',
					[detailAvisResponseEnum.communeCotisation]: {
						[detailAvisResponseEnum.type]: detailAvisResponseEnum.communeCotisation,
						[detailAvisResponseEnum.caseVide]: enteteTableauAffichageImmeubleLabelEnum.cotisation,
						[detailAvisResponseEnum.communeCotisation]: immeuble[detailAvisResponseEnum.communeCotisation],
						[detailAvisResponseEnum.interCommCotisation]: immeuble[detailAvisResponseEnum.interCommCotisation],
						[detailAvisResponseEnum.departementCotisation]: immeuble[detailAvisResponseEnum.departementCotisation],
						[detailAvisResponseEnum.taxeSpecialeCotisation]: immeuble[detailAvisResponseEnum.taxeSpecialeCotisation],
						[detailAvisResponseEnum.syndicatCotisation]: immeuble[detailAvisResponseEnum.syndicatCotisation],
						[detailAvisResponseEnum.teomCotisation]: immeuble[detailAvisResponseEnum.teomCotisation],
						[detailAvisResponseEnum.gemapiCotisation]: immeuble[detailAvisResponseEnum.gemapiCotisation],
						[detailAvisResponseEnum.total]: parseFloat(immeuble[detailAvisResponseEnum.communeCotisation] as string) + parseFloat(immeuble[detailAvisResponseEnum.interCommCotisation] as string) + parseFloat(immeuble[detailAvisResponseEnum.departementCotisation] as string) + parseFloat(immeuble[detailAvisResponseEnum.taxeSpecialeCotisation] as string) + parseFloat(immeuble[detailAvisResponseEnum.syndicatCotisation] as string) + parseFloat(immeuble[detailAvisResponseEnum.teomCotisation] as string) + parseFloat(immeuble[detailAvisResponseEnum.gemapiCotisation] as string)
					},
					[detailAvisResponseEnum.frais]: {
						[detailAvisResponseEnum.type]: detailAvisResponseEnum.frais,
						[detailAvisResponseEnum.caseVide]: enteteTableauAffichageImmeubleLabelEnum.frais,
						[detailAvisResponseEnum.communeCotisation]: ((parseFloat(immeuble[detailAvisResponseEnum.communeCotisation] as string) + parseFloat(immeuble[detailAvisResponseEnum.interCommCotisation] as string)) * avis[detailAvisResponseEnum.tauxTaxes][detailAvisResponseEnum.commune] / 100).toFixed(2),
						[detailAvisResponseEnum.interCommCotisation]: 0,
						[detailAvisResponseEnum.departementCotisation]: (parseFloat(immeuble[detailAvisResponseEnum.departementCotisation] as string) * avis[detailAvisResponseEnum.tauxTaxes][detailAvisResponseEnum.departement] / 100).toFixed(2),
						[detailAvisResponseEnum.taxeSpecialeCotisation]: (parseFloat(immeuble[detailAvisResponseEnum.taxeSpecialeCotisation] as string) * avis[detailAvisResponseEnum.tauxTaxes][detailAvisResponseEnum.taxeSpeciale] / 100).toFixed(2),
						[detailAvisResponseEnum.syndicatCotisation]: (parseFloat(immeuble[detailAvisResponseEnum.syndicatCotisation] as string) * avis[detailAvisResponseEnum.tauxTaxes][detailAvisResponseEnum.syndicatCommune] / 100).toFixed(2),
						[detailAvisResponseEnum.teomCotisation]: (parseFloat(immeuble[detailAvisResponseEnum.teomCotisation] as string) * avis[detailAvisResponseEnum.tauxTaxes][detailAvisResponseEnum.teom] / 100).toFixed(2),
						[detailAvisResponseEnum.gemapiCotisation]: (parseFloat(immeuble[detailAvisResponseEnum.gemapiCotisation] as string) * avis[detailAvisResponseEnum.tauxTaxes][detailAvisResponseEnum.gemapi] / 100).toFixed(2),
						[detailAvisResponseEnum.total]: 0
					},
					[detailAvisResponseEnum.totalLigne]: {
						[detailAvisResponseEnum.type]: detailAvisResponseEnum.totalLigne,
						[detailAvisResponseEnum.caseVide]: enteteTableauAffichageImmeubleLabelEnum.total,
						[detailAvisResponseEnum.communeCotisation]: 0,
						[detailAvisResponseEnum.interCommCotisation]: 0,
						[detailAvisResponseEnum.departementCotisation]: 0,
						[detailAvisResponseEnum.taxeSpecialeCotisation]: 0,
						[detailAvisResponseEnum.syndicatCotisation]: 0,
						[detailAvisResponseEnum.teomCotisation]: 0,
						[detailAvisResponseEnum.gemapiCotisation]: 0,
						[detailAvisResponseEnum.total]: 0
					}
				}
			)),
			[detailAvisFormEnum.grandParis]: false,
			[detailAvisResponseEnum.communeCotisation]: {
				[detailAvisResponseEnum.type]: detailAvisResponseEnum.communeCotisation,
				[detailAvisResponseEnum.caseVide]: enteteTableauAffichageImmeubleLabelEnum.cotisation,
				[detailAvisResponseEnum.communeCotisation]: '0',
				[detailAvisResponseEnum.interCommCotisation]: '0',
				[detailAvisResponseEnum.departementCotisation]: '0',
				[detailAvisResponseEnum.taxeSpecialeCotisation]: '0',
				[detailAvisResponseEnum.syndicatCotisation]: '0',
				[detailAvisResponseEnum.teomCotisation]: '0',
				[detailAvisResponseEnum.gemapiCotisation]: '0',
				[detailAvisResponseEnum.total]: '0'

			},
			[detailAvisResponseEnum.frais]: {
				[detailAvisResponseEnum.type]: detailAvisResponseEnum.frais,
				[detailAvisResponseEnum.caseVide]: enteteTableauAffichageImmeubleLabelEnum.frais,
				[detailAvisResponseEnum.communeCotisation]: '0',
				[detailAvisResponseEnum.interCommCotisation]: '0',
				[detailAvisResponseEnum.departementCotisation]: '0',
				[detailAvisResponseEnum.taxeSpecialeCotisation]: '0',
				[detailAvisResponseEnum.syndicatCotisation]: '0',
				[detailAvisResponseEnum.teomCotisation]: '0',
				[detailAvisResponseEnum.gemapiCotisation]: '0',
				[detailAvisResponseEnum.total]: '0'

			},
			[detailAvisResponseEnum.totalLigne]: {
				[detailAvisResponseEnum.type]: detailAvisResponseEnum.totalLigne,
				[detailAvisResponseEnum.caseVide]: enteteTableauAffichageImmeubleLabelEnum.total,
				[detailAvisResponseEnum.communeCotisation]: '0',
				[detailAvisResponseEnum.interCommCotisation]: '0',
				[detailAvisResponseEnum.departementCotisation]: '0',
				[detailAvisResponseEnum.taxeSpecialeCotisation]: '0',
				[detailAvisResponseEnum.syndicatCotisation]: '0',
				[detailAvisResponseEnum.teomCotisation]: '0',
				[detailAvisResponseEnum.gemapiCotisation]: '0',
				[detailAvisResponseEnum.total]: '0'

			}
		}
	)
}