import React from 'react'
import { ComptesRecuperablesNonRecuperablesProvider } from './context/ComptesRecuperablesNonRecuperablesContext'
import EnteteComptesRecuperablesNonRecuperables from './components/EnteteComptesRecuperablesNonRecuperables'
import ListeComptesRecuperablesNonRecuperables from './components/ListeComptesRecuperablesNonRecuperables'
import PopinGestionComptesRecuperablesNonRecuperables from './components/PopinGestionComptesRecuperablesNonRecuperables'

const ComptesRecuperablesNonRecuperables = () => (
	<ComptesRecuperablesNonRecuperablesProvider>
		<EnteteComptesRecuperablesNonRecuperables />
		<ListeComptesRecuperablesNonRecuperables />
		<PopinGestionComptesRecuperablesNonRecuperables />
	</ComptesRecuperablesNonRecuperablesProvider>
)

export default ComptesRecuperablesNonRecuperables