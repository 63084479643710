import React, { useState } from 'react'
import { ChampDansFormulaire } from './types/typePourChampDansFormulaireModel'
import { InputAdornment } from '@mui/material'
import frLocale from 'date-fns/locale/fr'
import { useFormContext } from 'react-hook-form'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import CustomTextField from './CustomTextField'
import { fontFamilyMonserrat, gecinaBlueMain } from '../../theme'
import SvgCalendar from '../../icons/SvgCalendar'
import { filtresListeAvisFieldsEnum } from '../../modules/listeAvis/enums/listeAvisEnum'
import {FILTRES_MATRICULES_TAUX} from '../../modules/matriculesTaux/enums/filtresMatriculesTauxEnum'

interface AcceptingProps {
	id: string,
	label?: string,
	inputTextStyle?: string,
	selectFuture?: boolean,
	hasDefault?: boolean,
	manual?: boolean,
	setMinDate?: string,
	readonly?: boolean,
	minDate?: boolean,
	errorWhite?: boolean
}

type FormDatePickerFieldProps =
	ChampDansFormulaire
	& AcceptingProps

const FormDatePickerField: React.FC<FormDatePickerFieldProps> = (
	{
		register,
		label,
		minDate = false,
		errorWhite = false
	}
) => {
	const { formState: { errors }, setError, clearErrors, getValues, setValue } = useFormContext()

	const [open, setOpen] = useState<boolean>(false)

	const limiteurDateMin = () => {
		if (register.name === filtresListeAvisFieldsEnum.avantLe && getValues(filtresListeAvisFieldsEnum.recuLe) !== null) {
			//Devient la date min du champs "Et le"
			return getValues(filtresListeAvisFieldsEnum.recuLe)
		} else if (register.name === FILTRES_MATRICULES_TAUX.DATE_FIN && getValues(FILTRES_MATRICULES_TAUX.DATE_DEBUT) !== null){
			return getValues(FILTRES_MATRICULES_TAUX.DATE_DEBUT)
		}

		if (minDate) {
			return new Date()
		}

		return undefined
	}
	const limiteurDateMax = () => {
		if (register.name === filtresListeAvisFieldsEnum.recuLe && getValues(filtresListeAvisFieldsEnum.avantLe) !== null) {
			//Devient la date max du champs "Créée entre le"
			return getValues(filtresListeAvisFieldsEnum.avantLe)
		} else if (register.name === FILTRES_MATRICULES_TAUX.DATE_DEBUT && getValues(FILTRES_MATRICULES_TAUX.DATE_FIN) !== null){
			return getValues(FILTRES_MATRICULES_TAUX.DATE_FIN)
		}

		return undefined
	}

	return (
		<>
			{
				label && (
					<span
						style={{
							width: '100%',
							paddingRight: 15,
							paddingTop: 7,
							fontSize: 16,
							marginBottom: 5,
							marginLeft: 0,
							boxSizing: 'border-box',
							textAlign: 'left',
							fontFamily: fontFamilyMonserrat,
							fontWeight: 600
						}}
					>
						{label}
					</span>
				)
			}

			<LocalizationProvider
				dateAdapter={AdapterDateFns}
				locale={frLocale}
			>
				<DatePicker
					views={['year', 'month', 'day']}
					renderInput={(props) => (
						<CustomTextField
							{...props}
							register={register}
							id={`${register.name}-datepicker`}
							errorWhite={errorWhite}
							fromDatePicker
							InputProps={{
								...props.InputProps,
								startAdornment: (
									<InputAdornment
										position="start"
										onClick={() => setOpen(true)}
									>
										<SvgCalendar color={gecinaBlueMain} style={{ cursor: 'pointer' }} />
									</InputAdornment>
								)
							}}
						/>
					)}
					mask="__/__/____"
					inputFormat="dd/MM/yyyy"
					minDate={limiteurDateMin()}
					maxDate={limiteurDateMax()}
					value={getValues(register.name)}
					onChange={(newValue) => {
						clearErrors(register.name)
						const finalValue = new Date(newValue ?? '')
						if (isNaN(finalValue.valueOf())){
							setValue(register.name, null)
							setError(register.name, { type: 'custom', message: 'Champ requis' })
						} else if (minDate && finalValue < new Date()) {
							setError(register.name, { type: 'custom', message: 'Date invalide' })
						} else {
							setValue(register.name, finalValue)
						}
					}}
					open={open}
					onClose={() => {
						setOpen(false)
					}}
				/>
			</LocalizationProvider>
		</>
	)
}

export default FormDatePickerField
