import CustomTable from '../../../components/table/Table'
import { LigneImmeubleModel, TraitementAvisImmeubleModel } from '../types/detailAvisModel'
import React, { useCallback, useContext, useEffect } from 'react'
import { HeadRow } from '../../../components/table/types/tableModel'
import { gestionNombre, getKeyFromEnumValue } from '../../../utils/utils'
import { remplissageLigneSelonEnteteTableau } from '../../../components/table/utils/tableUtils'
import {
	colonneTableauTraitementAvisImmeuble,
	colonneTableauTraitementAvisImmeubleAvecTotal,
	detailAvisFormEnum,
	detailAvisResponseEnum,
	enteteTableauAffichageImmeubleEnum,
	enteteTableauAffichageImmeubleLabelEnum,
	LigneAdditionTotalEnum,
	nomLigne
} from '../enums/detailAvisEnum'
import { columnsEnum } from '../../../components/table/enums/columnsEnum'
import CelluleTableauTraitementAvis from './CelluleTableauTraitementAvis'
import { useFormContext } from 'react-hook-form'
import thousandsSeparator from '../../../utils/thousandsSeparator'
import CustomTextField from '../../../components/fields/CustomTextField'
import FormNumberFormatField from '../../../components/fields/FormNumberFormatField'
import { fontFamilyMonserrat } from '../../../theme'
import { TraitementAvisContext } from '../context/TraitementAvisContext'

interface BlocImmeubleProps {
	immeuble: TraitementAvisImmeubleModel,
	index: number
}

type AcceptingProps = BlocImmeubleProps

export const style = {
	width: 130,
	backgroundColor: 'transparent !important',
	'input': {
		width: 130,
		fontWeight: 600
	}
}

export const styleFrais = {
	width: 130,
	backgroundColor: 'transparent !important',
	':after, :before': {
		borderColor: 'transparent !important'
	},
	'input': {
		width: '100%',
		fontFamily: fontFamilyMonserrat,
		fontSize: 15,
		fontWeight: 500,
		WebkitTextFillColor: '#000000 !important'
	}
}

const TableauTraitementImmeuble: React.FC<AcceptingProps> = (
	{
		immeuble,
		index
	}
) => {
	const { formValues } = useContext(TraitementAvisContext)
	const { register, watch, setValue } = useFormContext()

	const calculeFraisTaxeSpeciale = (index: number) => {
		if (watch(detailAvisFormEnum.grandParis)) {
			const tauxTaxeSpecialeGp = Number(formValues[detailAvisResponseEnum.tauxTaxes][detailAvisResponseEnum.repartitionGpTaxeSpecialeGp] || 0)
				/ (Number(formValues[detailAvisResponseEnum.tauxTaxes][detailAvisResponseEnum.repartitionGpTaxeSpeciale] || 0)
					+ Number(formValues[detailAvisResponseEnum.tauxTaxes][detailAvisResponseEnum.repartitionGpTaxeSpecialeGp] || 0))
			const tauxTaxeSpeciale = (Number(formValues[detailAvisResponseEnum.tauxTaxes][detailAvisResponseEnum.repartitionGpTaxeSpeciale] || 0)
				/ (Number(formValues[detailAvisResponseEnum.tauxTaxes][detailAvisResponseEnum.repartitionGpTaxeSpeciale] || 0)
					+ Number(formValues[detailAvisResponseEnum.tauxTaxes][detailAvisResponseEnum.repartitionGpTaxeSpecialeGp] || 0)) || 0)
			const fraisTaxeSpeciale = (tauxTaxeSpeciale
				* gestionNombre(watch(`${detailAvisResponseEnum.immeubles}.${index}.${detailAvisResponseEnum.communeCotisation}.${detailAvisResponseEnum.taxeSpecialeCotisation}`))
				* Number(formValues[detailAvisResponseEnum.tauxTaxes][detailAvisResponseEnum.taxeSpeciale] || 0)
				/ 100 + tauxTaxeSpecialeGp
				* gestionNombre(watch(`${detailAvisResponseEnum.immeubles}.${index}.${detailAvisResponseEnum.communeCotisation}.${detailAvisResponseEnum.taxeSpecialeCotisation}`))
				* (formValues[detailAvisResponseEnum.tauxTaxes][detailAvisResponseEnum.taxeSpecialeGp] || 0) / 100)
			return thousandsSeparator(fraisTaxeSpeciale)
		} else {
			return thousandsSeparator(gestionNombre(watch(`${detailAvisResponseEnum.immeubles}.${index}.${detailAvisResponseEnum.communeCotisation}.${detailAvisResponseEnum.taxeSpecialeCotisation}`)) * Number(formValues[detailAvisResponseEnum.tauxTaxes][detailAvisResponseEnum.taxeSpeciale] || 0) / 100)
		}
	}

	useEffect(() => {
		setValue(`${detailAvisResponseEnum.immeubles}.${index}.${detailAvisResponseEnum.communeCotisation}.${[detailAvisResponseEnum.type]}`, detailAvisResponseEnum.communeCotisation)
		setValue(`${detailAvisResponseEnum.immeubles}.${index}.${detailAvisResponseEnum.communeCotisation}.${[detailAvisResponseEnum.caseVide]}`, enteteTableauAffichageImmeubleLabelEnum.cotisation)
		setValue(`${detailAvisResponseEnum.immeubles}.${index}.${detailAvisResponseEnum.frais}.${[detailAvisResponseEnum.type]}`, detailAvisResponseEnum.frais)
		setValue(`${detailAvisResponseEnum.immeubles}.${index}.${detailAvisResponseEnum.frais}.${[detailAvisResponseEnum.caseVide]}`, enteteTableauAffichageImmeubleLabelEnum.frais)
		setValue(`${detailAvisResponseEnum.immeubles}.${index}.${detailAvisResponseEnum.totalLigne}.${[detailAvisResponseEnum.type]}`, detailAvisResponseEnum.totalLigne)
		setValue(`${detailAvisResponseEnum.immeubles}.${index}.${detailAvisResponseEnum.totalLigne}.${[detailAvisResponseEnum.caseVide]}`, enteteTableauAffichageImmeubleLabelEnum.total)
	}, [])

	const entetesTableauTraitementAvis: HeadRow[] = [
		{
			label: enteteTableauAffichageImmeubleLabelEnum.caseVide,
			id: getKeyFromEnumValue<enteteTableauAffichageImmeubleEnum>(enteteTableauAffichageImmeubleEnum, enteteTableauAffichageImmeubleEnum.caseVide),
			render: (nomLigne: string) => <strong>{nomLigne}</strong>
		},
		{
			label: enteteTableauAffichageImmeubleLabelEnum.commune,
			id: columnsEnum.customColonne,
			render: (ligneTableau: LigneImmeubleModel) => {
				if (ligneTableau[detailAvisResponseEnum.type] === detailAvisResponseEnum.communeCotisation) {
					return <CelluleTableauTraitementAvis calculerTotaux={calculerTotaux} index={index} registerName={detailAvisResponseEnum.communeCotisation} />
				} else if (ligneTableau[detailAvisResponseEnum.type] === detailAvisResponseEnum.totalLigne) {
					return <CustomTextField
						sx={style}
						register={register(`${detailAvisResponseEnum.immeubles}.${index}.${detailAvisResponseEnum.totalLigne}.${detailAvisResponseEnum.communeCotisation}`)}
						id={`${detailAvisResponseEnum.immeubles}.${index}.${detailAvisResponseEnum.totalLigne}.${detailAvisResponseEnum.communeCotisation}`}
						inputProps={{
							disabled: true,
							value: thousandsSeparator(gestionNombre(watch(`${detailAvisResponseEnum.immeubles}.${index}.${detailAvisResponseEnum.totalLigne}.${detailAvisResponseEnum.communeCotisation}`)))
						}}
					/>
				}
				return <FormNumberFormatField
					sx={styleFrais}
					register={register(`${detailAvisResponseEnum.immeubles}.${index}.${detailAvisResponseEnum.frais}.${detailAvisResponseEnum.communeCotisation}`)}
					value={thousandsSeparator(((gestionNombre(watch(`${detailAvisResponseEnum.immeubles}.${index}.${detailAvisResponseEnum.communeCotisation}.${detailAvisResponseEnum.communeCotisation}`)) + gestionNombre(watch(`${detailAvisResponseEnum.immeubles}.${index}.${detailAvisResponseEnum.communeCotisation}.${detailAvisResponseEnum.interCommCotisation}`))) * formValues[detailAvisResponseEnum.tauxTaxes][detailAvisResponseEnum.commune] / 100) + gestionNombre(watch(`${detailAvisResponseEnum.immeubles}.${index}.${detailAvisFormEnum.ajustementFrais}`)))}
					// @ts-ignore TODO ts-ignore
					onChange={(value: string) => {
						setValue(`${detailAvisResponseEnum.immeubles}.${index}.${detailAvisResponseEnum.frais}.${detailAvisResponseEnum.communeCotisation}`, value)
						calculerTotaux()
					}}
					id={`${detailAvisResponseEnum.immeubles}.${index}.${detailAvisResponseEnum.frais}.${detailAvisResponseEnum.communeCotisation}`}
					decimalScale={2}
					disabled
				/>
			}
		},
		{
			label: enteteTableauAffichageImmeubleLabelEnum.interComm,
			id: columnsEnum.customColonne,
			render: (ligneTableau: LigneImmeubleModel) => {
				if (ligneTableau[detailAvisResponseEnum.type] === detailAvisResponseEnum.communeCotisation) {
					return <CelluleTableauTraitementAvis calculerTotaux={calculerTotaux} index={index} registerName={detailAvisResponseEnum.interCommCotisation} />
				} else if (ligneTableau[detailAvisResponseEnum.type] === detailAvisResponseEnum.totalLigne) {
					return <CustomTextField
						sx={style}
						id={`${detailAvisResponseEnum.immeubles}.${index}.${detailAvisResponseEnum.totalLigne}.${detailAvisResponseEnum.interCommCotisation}`}
						inputProps={{
							disabled: true,
							value: thousandsSeparator(gestionNombre(watch(`${detailAvisResponseEnum.immeubles}.${index}.${detailAvisResponseEnum.totalLigne}.${detailAvisResponseEnum.interCommCotisation}`)))
						}}
					/>
				}
				return <>-</>
			}
		},
		{
			label: enteteTableauAffichageImmeubleLabelEnum.departement,
			id: columnsEnum.customColonne,
			render: (ligneTableau: LigneImmeubleModel) => {
				if (ligneTableau[detailAvisResponseEnum.type] === detailAvisResponseEnum.communeCotisation) {
					return <CelluleTableauTraitementAvis calculerTotaux={calculerTotaux} index={index} registerName={detailAvisResponseEnum.departementCotisation} />
				} else if (ligneTableau[detailAvisResponseEnum.type] === detailAvisResponseEnum.totalLigne) {
					return <CustomTextField
						sx={style}
						id={`${detailAvisResponseEnum.immeubles}.${index}.${detailAvisResponseEnum.totalLigne}.${detailAvisResponseEnum.departementCotisation}`}
						inputProps={{
							disabled: true,
							value: thousandsSeparator(gestionNombre(watch(`${detailAvisResponseEnum.immeubles}.${index}.${detailAvisResponseEnum.totalLigne}.${detailAvisResponseEnum.departementCotisation}`)))
						}}
					/>
				}
				return <FormNumberFormatField
					sx={styleFrais}
					register={register(`${detailAvisResponseEnum.immeubles}.${index}.${detailAvisResponseEnum.frais}.${detailAvisResponseEnum.departementCotisation}`)}
					value={thousandsSeparator(gestionNombre(watch(`${detailAvisResponseEnum.immeubles}.${index}.${detailAvisResponseEnum.communeCotisation}.${detailAvisResponseEnum.departementCotisation}`)) * formValues[detailAvisResponseEnum.tauxTaxes][detailAvisResponseEnum.departement] / 100)}
					// @ts-ignore TODO ts-ignore
					onChange={(value: string) => {
						setValue(`${detailAvisResponseEnum.immeubles}.${index}.${detailAvisResponseEnum.frais}.${detailAvisResponseEnum.departementCotisation}`, value)
						calculerTotaux()
					}}
					id={`${detailAvisResponseEnum.immeubles}.${index}.${detailAvisResponseEnum.frais}.${detailAvisResponseEnum.departementCotisation}`}
					decimalScale={2}
					disabled
				/>
			}
		},
		{
			label: enteteTableauAffichageImmeubleLabelEnum.taxeSpe,
			id: columnsEnum.customColonne,
			render: (ligneTableau: LigneImmeubleModel) => {
				if (ligneTableau[detailAvisResponseEnum.type] === detailAvisResponseEnum.communeCotisation) {
					return <CelluleTableauTraitementAvis calculerTotaux={calculerTotaux} index={index} registerName={detailAvisResponseEnum.taxeSpecialeCotisation} />
				} else if (ligneTableau[detailAvisResponseEnum.type] === detailAvisResponseEnum.totalLigne) {
					return <CustomTextField
						sx={style}
						id={`${detailAvisResponseEnum.immeubles}.${index}.${detailAvisResponseEnum.totalLigne}.${detailAvisResponseEnum.taxeSpecialeCotisation}`}
						inputProps={{
							disabled: true,
							value: thousandsSeparator(gestionNombre(watch(`${detailAvisResponseEnum.immeubles}.${index}.${detailAvisResponseEnum.totalLigne}.${detailAvisResponseEnum.taxeSpecialeCotisation}`)))
						}}
					/>
				}
				return <FormNumberFormatField
					sx={styleFrais}
					register={register(`${detailAvisResponseEnum.immeubles}.${index}.${detailAvisResponseEnum.frais}.${detailAvisResponseEnum.taxeSpecialeCotisation}`)}
					value={calculeFraisTaxeSpeciale(index)}
					// @ts-ignore TODO ts-ignore
					onChange={(value: string) => {
						setValue(`${detailAvisResponseEnum.immeubles}.${index}.${detailAvisResponseEnum.frais}.${detailAvisResponseEnum.taxeSpecialeCotisation}`, value)
						calculerTotaux()
					}}
					id={`${detailAvisResponseEnum.immeubles}.${index}.${detailAvisResponseEnum.frais}.${detailAvisResponseEnum.taxeSpecialeCotisation}`}
					decimalScale={2}
					disabled
				/>
			}
		},
		{
			label: enteteTableauAffichageImmeubleLabelEnum.syndicat,
			id: columnsEnum.customColonne,
			render: (ligneTableau: LigneImmeubleModel) => {
				if (ligneTableau[detailAvisResponseEnum.type] === detailAvisResponseEnum.communeCotisation) {
					return <CelluleTableauTraitementAvis calculerTotaux={calculerTotaux} index={index} registerName={detailAvisResponseEnum.syndicatCotisation} />
				} else if (ligneTableau[detailAvisResponseEnum.type] === detailAvisResponseEnum.totalLigne) {
					return <CustomTextField
						sx={style}
						id={`${detailAvisResponseEnum.immeubles}.${index}.${detailAvisResponseEnum.totalLigne}.${detailAvisResponseEnum.syndicatCotisation}`}
						inputProps={{
							disabled: true,
							value: thousandsSeparator(gestionNombre(watch(`${detailAvisResponseEnum.immeubles}.${index}.${detailAvisResponseEnum.totalLigne}.${detailAvisResponseEnum.syndicatCotisation}`)))
						}}
					/>
				}
				return <FormNumberFormatField
					sx={styleFrais}
					register={register(`${detailAvisResponseEnum.immeubles}.${index}.${detailAvisResponseEnum.frais}.${detailAvisResponseEnum.syndicatCotisation}`)}
					value={thousandsSeparator(gestionNombre(watch(`${detailAvisResponseEnum.immeubles}.${index}.${detailAvisResponseEnum.communeCotisation}.${detailAvisResponseEnum.syndicatCotisation}`)) * formValues[detailAvisResponseEnum.tauxTaxes][detailAvisResponseEnum.syndicatCommune] / 100)}
					// @ts-ignore TODO ts-ignore
					onChange={(value: string) => {
						setValue(`${detailAvisResponseEnum.immeubles}.${index}.${detailAvisResponseEnum.frais}.${detailAvisResponseEnum.syndicatCotisation}`, value)
						calculerTotaux()
					}}
					id={`${detailAvisResponseEnum.immeubles}.${index}.${detailAvisResponseEnum.frais}.${detailAvisResponseEnum.syndicatCotisation}`}
					decimalScale={2}
					disabled
				/>
			}
		},
		{
			label: enteteTableauAffichageImmeubleLabelEnum.teom,
			id: columnsEnum.customColonne,
			render: (ligneTableau: LigneImmeubleModel) => {
				if (ligneTableau[detailAvisResponseEnum.type] === detailAvisResponseEnum.communeCotisation) {
					return <CelluleTableauTraitementAvis calculerTotaux={calculerTotaux} index={index} registerName={detailAvisResponseEnum.teomCotisation} />
				} else if (ligneTableau[detailAvisResponseEnum.type] === detailAvisResponseEnum.totalLigne) {
					return <CustomTextField
						sx={style}
						id={`${detailAvisResponseEnum.immeubles}.${index}.${detailAvisResponseEnum.totalLigne}.${detailAvisResponseEnum.teomCotisation}`}
						inputProps={{
							disabled: true,
							value: thousandsSeparator(gestionNombre(watch(`${detailAvisResponseEnum.immeubles}.${index}.${detailAvisResponseEnum.totalLigne}.${detailAvisResponseEnum.teomCotisation}`)))
						}}
					/>
				}
				return <FormNumberFormatField
					sx={styleFrais}
					register={register(`${detailAvisResponseEnum.immeubles}.${index}.${detailAvisResponseEnum.frais}.${detailAvisResponseEnum.teomCotisation}`)}
					value={thousandsSeparator(gestionNombre(watch(`${detailAvisResponseEnum.immeubles}.${index}.${detailAvisResponseEnum.communeCotisation}.${detailAvisResponseEnum.teomCotisation}`)) * formValues[detailAvisResponseEnum.tauxTaxes][detailAvisResponseEnum.teom] / 100)}
					// @ts-ignore TODO ts-ignore
					onChange={(value: string) => {
						setValue(`${detailAvisResponseEnum.immeubles}.${index}.${detailAvisResponseEnum.frais}.${detailAvisResponseEnum.teomCotisation}`, value)
						calculerTotaux()
					}}
					id={`${detailAvisResponseEnum.immeubles}.${index}.${detailAvisResponseEnum.frais}.${detailAvisResponseEnum.teomCotisation}`}
					decimalScale={2}
					disabled
				/>
			}
		},
		{
			label: enteteTableauAffichageImmeubleLabelEnum.gemapi,
			id: columnsEnum.customColonne,
			render: (ligneTableau: LigneImmeubleModel) => {
				if (ligneTableau[detailAvisResponseEnum.type] === detailAvisResponseEnum.communeCotisation) {
					return <CelluleTableauTraitementAvis calculerTotaux={calculerTotaux} index={index} registerName={detailAvisResponseEnum.gemapiCotisation} />
				} else if (ligneTableau[detailAvisResponseEnum.type] === detailAvisResponseEnum.totalLigne) {
					return <CustomTextField
						sx={style}
						register={register(`${detailAvisResponseEnum.immeubles}.${index}.${detailAvisResponseEnum.totalLigne}.${detailAvisResponseEnum.gemapiCotisation}`)}
						id={`${detailAvisResponseEnum.immeubles}.${index}.${detailAvisResponseEnum.totalLigne}.${detailAvisResponseEnum.gemapiCotisation}`}
						inputProps={{
							disabled: true,
							value: thousandsSeparator(gestionNombre(watch(`${detailAvisResponseEnum.immeubles}.${index}.${detailAvisResponseEnum.totalLigne}.${detailAvisResponseEnum.gemapiCotisation}`)))
						}}
					/>
				}
				return <FormNumberFormatField
					sx={styleFrais}
					register={register(`${detailAvisResponseEnum.immeubles}.${index}.${detailAvisResponseEnum.frais}.${detailAvisResponseEnum.gemapiCotisation}`)}
					value={thousandsSeparator(gestionNombre(watch(`${detailAvisResponseEnum.immeubles}.${index}.${detailAvisResponseEnum.communeCotisation}.${detailAvisResponseEnum.gemapiCotisation}`)) * formValues[detailAvisResponseEnum.tauxTaxes][detailAvisResponseEnum.gemapi] / 100)}
					// @ts-ignore TODO ts-ignore
					onChange={(value: string) => {
						setValue(`${detailAvisResponseEnum.immeubles}.${index}.${detailAvisResponseEnum.frais}.${detailAvisResponseEnum.gemapiCotisation}`, value)
						calculerTotaux()
					}}
					id={`${detailAvisResponseEnum.immeubles}.${index}.${detailAvisResponseEnum.frais}.${detailAvisResponseEnum.gemapiCotisation}`}
					decimalScale={2}
					disabled
				/>
			}
		},
		{
			label: enteteTableauAffichageImmeubleLabelEnum.total,
			id: columnsEnum.customColonne,
			render: (ligneTableau: LigneImmeubleModel) => {
				return <CustomTextField
					sx={style}
					id={`${detailAvisResponseEnum.immeubles}.${index}.${ligneTableau[detailAvisResponseEnum.type]}.${detailAvisResponseEnum.total}`}
					inputProps={{
						disabled: true,
						value: thousandsSeparator(gestionNombre(watch(`${detailAvisResponseEnum.immeubles}.${index}.${ligneTableau[detailAvisResponseEnum.type]}.${detailAvisResponseEnum.total}`)), true)
					}}
				/>
			}
		}
	]

	// Somme de toutes les colonnes selon la ligne du tableau passé
	const sommeLigne = useCallback((ligne: string) => {
		const totalLigne = Object.keys(colonneTableauTraitementAvisImmeuble).reduce((acc, valeur) => acc + gestionNombre(watch(`${detailAvisResponseEnum.immeubles}.${index}.${ligne}.${valeur}`)), 0)
		setValue(`${detailAvisResponseEnum.immeubles}.${index}.${ligne}.${detailAvisResponseEnum.total}`, totalLigne.toFixed(0))
	}, [])

	const sommeColonne = useCallback((colonne: string) => {
		const totalColonne = Object.keys(LigneAdditionTotalEnum).reduce((acc, valeur) => acc + gestionNombre(watch(`${detailAvisResponseEnum.immeubles}.${index}.${valeur}.${colonne}`)), 0)
		setValue(`${detailAvisResponseEnum.immeubles}.${index}.${detailAvisResponseEnum.totalLigne}.${colonne}`, totalColonne)
	}, [])

	const calculerTotaux = () => {
		Object.keys(nomLigne).forEach((ligne) => sommeLigne(ligne))
		Object.keys(colonneTableauTraitementAvisImmeubleAvecTotal).forEach((colonne) => sommeColonne(colonne))
		Object.keys(nomLigne).forEach((ligne) => {
			Object.keys(colonneTableauTraitementAvisImmeubleAvecTotal).forEach((colonne) => {
				const totalCase = formValues[detailAvisResponseEnum.immeubles].reduce((acc, valeur, index) => acc + gestionNombre(watch(`${detailAvisResponseEnum.immeubles}.${index}.${ligne}.${colonne}`)), 0)
				setValue(`${ligne}.${colonne}`, totalCase)
			})
		})
	}

	useEffect(() => {
		calculerTotaux()
	}, [formValues])


	return (
		<CustomTable
			id={`immeuble ${immeuble.adresse}`}
			hasSort={false}
			headRows={entetesTableauTraitementAvis}
			rows={remplissageLigneSelonEnteteTableau<LigneImmeubleModel>([immeuble[detailAvisResponseEnum.communeCotisation], immeuble[detailAvisResponseEnum.frais], immeuble[detailAvisResponseEnum.totalLigne]], entetesTableauTraitementAvis)}
			hasPagination={false}
			allBorders
			tableStyle={{
				'& td': {
					width: 120
				}
			}}
		/>
	)
}

export default TableauTraitementImmeuble