import { FILTRES_MATRICULES_TAUX } from '../enums/filtresMatriculesTauxEnum'

export interface MatriculesTauxModel {
	'@id': string | null,
	matricule: string,
	referenceImmeuble: string,
	tauxRecupResidentiel: string,
	dateDebut: Date,
	dateFin: Date | null,
	nomModificateur: string,
	idModificateur: string
}

export interface FiltresMatriculesTauxModel {
	[FILTRES_MATRICULES_TAUX.MATRICULE]: string
	[FILTRES_MATRICULES_TAUX.REFERENCE_IMMEUBLE]: string
	[FILTRES_MATRICULES_TAUX.PAGE]: number
}

export interface PopinMatriculesTauxModel {
	open: boolean,
	matriculesTaux: MatriculesTauxModel | undefined
}

export const INITIAL_MATRICULES_TAUX: MatriculesTauxModel = {
	'@id': '',
	matricule: '',
	referenceImmeuble: '',
	tauxRecupResidentiel: '',
	dateDebut: new Date(),
	dateFin: null,
	nomModificateur: '',
	idModificateur: ''
}

export const INITIAL_POPIN_MT: PopinMatriculesTauxModel = {
	open: false,
	matriculesTaux: INITIAL_MATRICULES_TAUX
}

export const INITIAL_FILTRE_MT: FiltresMatriculesTauxModel = {
	[FILTRES_MATRICULES_TAUX.MATRICULE]: '',
	[FILTRES_MATRICULES_TAUX.REFERENCE_IMMEUBLE]: '',
	[FILTRES_MATRICULES_TAUX.PAGE]: 1
}