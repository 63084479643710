import React, { useContext } from 'react'
import { ComptesNomenclatureChargesContext } from '../context/ComptesNomenclatureChargesContext'
import { EMPTY_FORM, FILTRES_COMPTES_NOMENCLATURE_CHARGES } from '../enums/filtresComptesNomenclatureChargesEnum'
import PopinGestionFormulaire from '../../../components/form/PopinGestionFormulaire'
import { ChampsPopinModel } from '../../../components/form/types/popinGestionFormulaire'
import { deleteCompteNomenclatureCharge, postCompteNomenclatureCharge, putCompteNomenclatureCharge } from '../api/comptesNomenclatureChargesAPI'
import PopinSuppressionContenu from '../../../components/dialog/components/PopinSuppressionContenu'

//Champs de la popin
const POPIN_FIELDS: ChampsPopinModel[] = [
	{
		colonnes: 1,
		champs: [
			{
				id: FILTRES_COMPTES_NOMENCLATURE_CHARGES.ID,
				type: 'hidden'
			},
			{
				id: FILTRES_COMPTES_NOMENCLATURE_CHARGES.COMPTE,
				type: 'text',
				maxLength: 20,
				label: 'Compte *',
				disableOnUpdate: true
			}
		]
	},
	{
		colonnes: 2,
		champs: [
			{
				id: FILTRES_COMPTES_NOMENCLATURE_CHARGES.TENCODE,
				type: 'text',
				maxLength: 20,
				label: 'TenCode *'
			},
			{
				id: FILTRES_COMPTES_NOMENCLATURE_CHARGES.CODEPOSTE,
				type: 'text',
				maxLength: 20,
				label: 'CodePoste *'
			}
		]
	}
]

const PopinGestionComptesNomenclatureCharges = () => {
	const {
		ouverturePopin,
		setOuverturePopin,
		comptesNomenclatureCharges,
		setComptesNomenclatureCharges,
		popinSuppression,
		setPopinSuppression
	} = useContext(ComptesNomenclatureChargesContext)

	return (
		<>
			{/* Popin permettant d'ajouter / modifier un CNC */}
			<PopinGestionFormulaire
				champsPopin={POPIN_FIELDS}
				formulaireVide={EMPTY_FORM}
				donneesPopin={{
					open: ouverturePopin.open,
					donnees: ouverturePopin.compteNomenclatureCharges
				}}
				onCloseDialog={(closeData) => setOuverturePopin({
					open: closeData.open,
					compteNomenclatureCharges: closeData.donnees
				})}
				contenu={comptesNomenclatureCharges['hydra:member']}
				modificationContenu={(nouveauContenu) => setComptesNomenclatureCharges({
					'hydra:totalItems': ouverturePopin.compteNomenclatureCharges ? comptesNomenclatureCharges['hydra:totalItems'] : comptesNomenclatureCharges['hydra:totalItems']+1,
					'hydra:member': nouveauContenu
				})}
				titreCreation="Ajouter la nomenclature d’un nouveau compte"
				titreModification="Modifier la nomenclature du compte"
				envoiDonnees={ouverturePopin.compteNomenclatureCharges ? putCompteNomenclatureCharge : postCompteNomenclatureCharge}
			/>

			{/* Popin permettant de supprimer un CNC */}
			<PopinSuppressionContenu
				donneesPopin={{
					open: popinSuppression.open,
					donnees: popinSuppression.compteNomenclatureCharges
				}}
				onCloseDialog={(closeData) => setPopinSuppression({
					open: closeData.open,
					compteNomenclatureCharges: closeData.donnees
				})}
				titreSuppression="Suppression du paramétrage"
				texteSuppression="Confirmer la suppression du paramétrage ?"
				contenu={comptesNomenclatureCharges['hydra:member']}
				modificationContenu={(nouveauContenu) => setComptesNomenclatureCharges({
					'hydra:totalItems': comptesNomenclatureCharges['hydra:totalItems']-1,
					'hydra:member': nouveauContenu
				})}
				suppressionContenu={deleteCompteNomenclatureCharge}
			/>
		</>
	)

}

export default PopinGestionComptesNomenclatureCharges