import React, { useContext, useMemo } from 'react'
import { HeadRow } from '../../../components/table/types/tableModel'
import { getKeyFromEnumValue } from '../../../utils/utils'
import { Box, IconButton } from '@mui/material'
import { remplissageLigneSelonEnteteTableau } from '../../../components/table/utils/tableUtils'
import CustomTable from '../../../components/table/Table'
import { enteteTableauListeNaturesRubriquesPaieEnum } from '../enums/enteteTableauListeNaturesRubriquesPaieEnum'
import { NaturesRubriquesPaieModel } from '../types/naturesRubriquesPaieModel'
import { NaturesRubriquesPaieContext } from '../context/NaturesRubriquesPaieContext'
import Loader from '../../../components/loader/Loader'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { gecinaBrownMain } from '../../../theme'
import { columnsEnum } from '../../../components/table/enums/columnsEnum'
import { FILTRES_NATURES_RUBRIQUES_PAIE } from '../enums/filtresNaturesRubriquesPaieEnum'
import { getListeNaturesRubriquesPaie } from '../api/naturesRubriquesPaieAPI'

const ListeNaturesRubriquesPaie = () => {
	const {
		naturesRubriquesPaie,
		setNaturesRubriquesPaie,
		chargementNaturesRubriquesPaie,
		setOuverturePopin,
		setPopinSuppression,
		filtresNRP,
		setFiltresNRP
	} = useContext(NaturesRubriquesPaieContext)

	const modifierNRP = (naturesRubriquesPaie: NaturesRubriquesPaieModel) => setOuverturePopin({
		open: true,
		naturesRubriquesPaie
	})

	const suppressionNRP = (naturesRubriquesPaie: NaturesRubriquesPaieModel) => setPopinSuppression({
		open: true,
		naturesRubriquesPaie
	})

	const entetesTableau: HeadRow[] = useMemo(
		() => (
			[
				{
					label: enteteTableauListeNaturesRubriquesPaieEnum.compte,
					id: getKeyFromEnumValue<enteteTableauListeNaturesRubriquesPaieEnum>(enteteTableauListeNaturesRubriquesPaieEnum, enteteTableauListeNaturesRubriquesPaieEnum.compte)
				},
				{
					label: enteteTableauListeNaturesRubriquesPaieEnum.natureChargeResidentiel,
					id: getKeyFromEnumValue<enteteTableauListeNaturesRubriquesPaieEnum>(enteteTableauListeNaturesRubriquesPaieEnum, enteteTableauListeNaturesRubriquesPaieEnum.natureChargeResidentiel)
				},
				{
					label: enteteTableauListeNaturesRubriquesPaieEnum.natureChargeBureau,
					id: getKeyFromEnumValue<enteteTableauListeNaturesRubriquesPaieEnum>(enteteTableauListeNaturesRubriquesPaieEnum, enteteTableauListeNaturesRubriquesPaieEnum.natureChargeBureau)
				},
				{
					label: '',
					id: columnsEnum.customColonne,
					width: '15%',
					render: (ligneTableau: NaturesRubriquesPaieModel) => (
						<Box
							className="elementCache"
							sx={{
								visibility: 'hidden',
								textAlign: 'right',

								'& svg': {
									color: gecinaBrownMain
								}
							}}
						>
							<IconButton aria-label="edit" title="Modification du compte" onClick={() => modifierNRP(ligneTableau)}>
								<EditIcon />
							</IconButton>

							<IconButton aria-label="delete" title="Suppression du compte" onClick={() => suppressionNRP(ligneTableau)}>
								<DeleteIcon />
							</IconButton>
						</Box>
					)
				}
			]
		), []
	)

	const auChangementDePage = (
		(nouvellePage: number) => {
			const data = {
				...filtresNRP,
				[FILTRES_NATURES_RUBRIQUES_PAIE.PAGE]: nouvellePage
			}

			getListeNaturesRubriquesPaie(nouvellePage, filtresNRP[FILTRES_NATURES_RUBRIQUES_PAIE.COMPTE])
				.then((response) => {
					setNaturesRubriquesPaie(response)
					setFiltresNRP(data)
				})
				.finally(() => window.scrollTo(0, 0))
		}
	)

	if (chargementNaturesRubriquesPaie) {
		return (
			<Box height="150px">
				<Loader size={50} />
			</Box>
		)
	}

	return (
		<Box sx={{ paddingX: '172px' }}>
			<CustomTable
				id="listeComptesRecuperablesNonRecuperables"
				headRows={entetesTableau}
				rows={remplissageLigneSelonEnteteTableau<NaturesRubriquesPaieModel>(naturesRubriquesPaie['hydra:member'], entetesTableau)}
				total={naturesRubriquesPaie['hydra:totalItems']}
				borderBottom
				auCliqueSurLigne={() => {
				}}
				hasPagination
				auChangementDePage={auChangementDePage}
				page={filtresNRP[FILTRES_NATURES_RUBRIQUES_PAIE.PAGE] - 1}
				hasSort={false}
			/>
		</Box>
	)
}

export default ListeNaturesRubriquesPaie