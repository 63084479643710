import React, { useContext } from 'react'
import './../App.css'
import { Route, Routes, useLocation } from 'react-router-dom'
import liens from '../enum/liens'
import ListeAvis from '../modules/listeAvis/ListeAvis'
import ListeTaxes from '../modules/listeTaxes/ListeTaxes'
import GestionTaux from '../modules/gestionTaux/GestionTaux'
import UnstyledSnackBar from '../components/snackBar/SnackBar'
import axios from 'axios'
import localStorageEnum from '../enum/localstorageEnum'
import HttpStatusCode from '../enum/httpStatusCode'
import { useQuery } from 'react-query'
import { fetchToken } from './api/authentificationApi'
import TraitementAvis from '../modules/traitementAvis/TraitementAvis'
import ComptesNomenclatureCharges from '../modules/comptesNomenclatureCharges/ComptesNomenclatureCharges'
import ComptesRecuperablesNonRecuperables from '../modules/comptesRecuperablesNonRecuperables/ComptesRecuperablesNonRecuperables'
import NaturesRubriquesPaie from '../modules/naturesRubriquesPaie/NaturesRubriquesPaie'
import Menu from '../modules/menu/Menu'
import ComptesTauxRecuperabiliteSouspostes from '../modules/comptesTauxRecuperabiliteSouspostes/ComptesTauxRecuperabiliteSouspostes'
import ImmeublesQuotesPartsResidentiel from '../modules/immeublesQuotesPartsResidentiel/ImmeublesQuotesPartsResidentiel'
import MatriculesTaux from '../modules/matriculesTaux/MatriculesTaux'
import NonAutorise from '../modules/nonAutorise/NonAutorise'
import { AppContext } from './context/AppContext'
import Utilisateurs from '../modules/utilisateurs/Utilisateurs'
import TraitementSGNF from '../modules/TraitementSGNF/TraitementSGNF'

function App() {
	const location = useLocation()
	const { userInfos, setUserInfos } = useContext(AppContext)

	//Permet de réinitialiser le croll à chaque changement de page
	React.useEffect(() => {
		window.scrollTo(0, 0)
	}, [location])

	axios.interceptors.request.use(function (config) {
		config.headers && (config.headers['Authorization'] = `Bearer ${localStorage.getItem(localStorageEnum.accessToken)}`)
		return config
	})

	axios.interceptors.response.use((response) => response, (error) => {
		if (error.response.status === HttpStatusCode.UNAUTHORIZED) {
			window.location.pathname = liens.login
		}

		return Promise.reject(error)
	})

	useQuery('session', () => {if (location.pathname !== liens.nonAutorise) return fetchToken(setUserInfos)},
		{
			refetchInterval: 900000
		}
	)

	if (userInfos.name.length === 0 && location.pathname !== liens.nonAutorise) {
		return <></>
	}

	return (
		<div style={{ height: '100%' }}>
			{location.pathname !== liens.nonAutorise && (
				<Routes>
					<Route path="/*" element={<Menu />} />
				</Routes>
			)}

			<Routes>
				<Route path={liens.avis} element={<ListeAvis />} />
				<Route path={liens.detailAvis} element={<TraitementAvis />} />
				<Route path={liens.taxes} element={<ListeTaxes />} />
				<Route path={liens.taux} element={<GestionTaux />} />
				<Route path={liens.nomenclature} element={<ComptesNomenclatureCharges />} />
				<Route path={liens.comptes} element={<ComptesRecuperablesNonRecuperables />} />
				<Route path={liens.natures} element={<NaturesRubriquesPaie />} />
				<Route path={liens.codes} element={<ComptesTauxRecuperabiliteSouspostes />} />
				<Route path={liens.quotes} element={<ImmeublesQuotesPartsResidentiel />} />
				<Route path={liens.matricules} element={<MatriculesTaux />} />
				<Route path={liens.nonAutorise} element={<NonAutorise />} />
				<Route path={liens.utilisateurs} element={<Utilisateurs />} />
				<Route path={liens.traitements} element={<TraitementSGNF />} />
			</Routes>
			<UnstyledSnackBar />
		</div>
	)
}

export default App
