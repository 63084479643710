import React, { useState } from 'react'
import { Box, Grid } from '@mui/material'
import { WHITE } from '../../theme'
import liens from '../../enum/liens'
import Tooltip from '../../components/tooltip/Tooltip'
import { useNavigate } from 'react-router-dom'
import UserInfo from './components/UserInfo'
import NavBar from './components/NavBar'
import { styled } from '@mui/system'

const Menu: React.FC = () => {
	const navigate = useNavigate()
	const [openVersionNumber, setOpenVersionNumber] = useState<boolean>(false)

	return (
		<MenuContainer container alignItems="center">
			<Grid container item xs flexDirection="column">
				<LogoContainer
					onClick={() => navigate(liens.avis)}
					onMouseEnter={() => setOpenVersionNumber(true)}
					onMouseLeave={() => setOpenVersionNumber(false)}
				>
					<Tooltip
						title={<span>v1.2.13.2</span>}
						open={openVersionNumber}>
						<img src="/img/logo_gecina.png" height={28} />
					</Tooltip>
				</LogoContainer>
				<NavBar />
			</Grid>
			<UserContainer>
				<UserInfo />
			</UserContainer>
		</MenuContainer>
	)
}

const MenuContainer = styled(Grid)({
	backgroundColor: WHITE,
	width: '100%',
	minHeight: '80px',
	position: 'sticky',
	top: 0,
	zIndex: 1,
	paddingLeft: 20,
	paddingRight: 78
})

const LogoContainer = styled(Box)({
	margin: '10px 0',
	cursor: 'pointer',
	width: 'fit-content',
	zIndex: 1
})

const UserContainer = styled(Grid)({
	width: 'fit-content'
})

export default Menu
