import axios from 'axios'
import { CommunApiPlatformModel } from '../../../types/communApiPlatformModel'
import { ImmeublesQuotesPartsResidentielModel } from '../types/immeublesQuotesPartsResidentielModel'

export const getListeImmeublesQuotesPartsResidentiel = (page: number, referenceImmeuble?: string): Promise<CommunApiPlatformModel<ImmeublesQuotesPartsResidentielModel[]>> => axios.get(`/api/immeubles_quotes_parts_residentiels?page=${page}${referenceImmeuble ? `&referenceImmeuble=${referenceImmeuble}` : ''}`)
	.then((response) => response.data)

export const postImmeublesQuotesPartsResidentiel = (data: ImmeublesQuotesPartsResidentielModel): Promise<ImmeublesQuotesPartsResidentielModel> => axios.post('/api/immeubles_quotes_parts_residentiels', data)
	.then((response) => response.data)

export const putImmeublesQuotesPartsResidentiel = (data: ImmeublesQuotesPartsResidentielModel): Promise<ImmeublesQuotesPartsResidentielModel> => axios.put('/api/updateImmeublesQuotesPartsResidentiel', data)
	.then((response) => response.data)

export const deleteImmeublesQuotesPartsResidentiel = (data: ImmeublesQuotesPartsResidentielModel): Promise<ImmeublesQuotesPartsResidentielModel> => axios.delete(`/api/immeubles_quotes_parts_residentiels/${data['@id']!.split('/api/immeubles_quotes_parts_residentiels/')[1]}`)
	.then((response) => response.data)