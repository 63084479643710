import axios from 'axios'
import { DetailAvisPostModel, DetailAvisResponseModel, ImmeubleExterneModel, TraitementAvisModel } from '../types/detailAvisModel'
import { ResponseType } from '../../gestionTaux/types/TauxResponseModel'
import { detailAvisPostEnum } from '../enums/detailAvisEnum'

export const getAvisDetail = (data: DetailAvisPostModel): Promise<DetailAvisResponseModel> => axios.post('/api/avis/detail', data)
	.then((response) => response.data)

export const getRefImmeubleExt = (ref: string): Promise<ImmeubleExterneModel[]> => axios.post('/api/avis/ref', JSON.stringify({ ref: ref }))
	.then((response) => response.data)

export const checkTaxesFinancieresExistante = (idImmeuble: number, anneeRedditionAvis: number): Promise<ResponseType> => axios.post('/api/taxes/check/immeuble', { idImmeuble: idImmeuble, anneeRedditionAvis: anneeRedditionAvis })

export const creationTaxesFoncieres = (avis: TraitementAvisModel): Promise<ResponseType> => axios.post('/api/taxes/creation', { avis: avis })

export const deleteAvis = (data: DetailAvisPostModel): Promise<DetailAvisPostModel> => axios.delete(`/api/avis?fichier=${data[detailAvisPostEnum.fichier]}&annee=${data[detailAvisPostEnum.anneeRedition]}`)
	.then((response) => response.data)