import { Autocomplete, Box, Grid, MenuItem } from '@mui/material'
import { fontFamilyBlackerDisplay, gecinaBlueMain, WHITE } from '../../../theme'
import React, { useContext, useEffect, useState } from 'react'
import { ImmeubleExterneModel, TraitementAvisImmeubleModel } from '../types/detailAvisModel'
import FormNumberFormatField from '../../../components/fields/FormNumberFormatField'
import { champsFormulaire, detailAvisFormEnum, detailAvisResponseEnum, immeubleExterneEnum } from '../enums/detailAvisEnum'
import CustomTextField from '../../../components/fields/CustomTextField'
import { useFormContext } from 'react-hook-form'
import TableauTraitementImmeuble from './TableauTraitementImmeuble'
import thousandsSeparator from '../../../utils/thousandsSeparator'
import { getRefImmeubleExt } from '../api/traitementAvisAPI'
import { gestionNombre } from '../../../utils/utils'
import FormSelectField from '../../../components/fields/FormSelectField'
import { buttonTypesEnum } from '../../../components/button/enums/buttonTypesEnum'
import Button from '../../../components/button/Button'
import { TraitementAvisContext } from '../context/TraitementAvisContext'

interface BlocImmeubleProps {
	immeuble: TraitementAvisImmeubleModel,
	index: number
}

type AcceptingProps = BlocImmeubleProps

const BlocImmeuble: React.FC<AcceptingProps> = (
	{
		immeuble,
		index
	}
) => {
	const { register, setValue, watch, clearErrors, unregister } = useFormContext()
	const [listeRef, setListeRef] = useState<ImmeubleExterneModel[]>([])
	const [loading, setLoading] = useState<boolean>(false)
	const { formValues, setFormValues } = useContext(TraitementAvisContext)

	useEffect(() => setValue(`${champsFormulaire.recherche}.${index}`, ''), [])

	//Recupere les ref des immeubles externes
	const recuperationRefImmeuble = () => {
		if (watch(`${champsFormulaire.recherche}.${index}`).length) {
			setLoading(true)
			getRefImmeubleExt(watch(`${champsFormulaire.recherche}.${index}`))
				.then((response) => {
					setListeRef(response)
				})
				.finally(() => setLoading(false))
		}
	}

	return (
		<Box sx={{ width: '100%' }}>
			<Box sx={{ paddingTop: '42px' }}>
				<CustomTextField
					sx={{ backgroundColor: 'transparent !important', '& input': { fontFamily: fontFamilyBlackerDisplay, fontSize: 22, fontWeight: 900, color: gecinaBlueMain } }}
					register={register(`${detailAvisResponseEnum.immeubles}.${index}.${detailAvisResponseEnum.adresse}`, {
						value: immeuble.adresse
					})}
					id={detailAvisFormEnum.totalCotisations}
					inputProps={{
						disabled: true,
						value: immeuble.adresse
					}}
				/>
			</Box>
			<Grid mt="22px" flexDirection="row" display="flex" width="100%" height="100px" alignItems="center">
				<Box sx={{ width: 300, color: gecinaBlueMain, minHeight: 100 }}>
					<Autocomplete
						id={`${detailAvisResponseEnum.immeubles}.${index}.${detailAvisResponseEnum.immeubleExterne}.${immeubleExterneEnum.reference}`}
						freeSolo
						// @ts-ignore TODO ts-ignore
						onChange={(e, ref: ImmeubleExterneModel) => {
							clearErrors()
							setValue(`${champsFormulaire.recherche}.${index}`, `${ref[immeubleExterneEnum.reference]} - ${ref[immeubleExterneEnum.adresse]} - ${ref[immeubleExterneEnum.ville]}`)
							setValue(`${detailAvisResponseEnum.immeubles}.${index}.${detailAvisResponseEnum.immeubleExterne}.${immeubleExterneEnum.reference}`, ref[immeubleExterneEnum.reference])
							setValue(`${detailAvisResponseEnum.immeubles}.${index}.${detailAvisResponseEnum.immeubleExterne}.${immeubleExterneEnum.adresse}`, ref[immeubleExterneEnum.adresse])
							setValue(`${detailAvisResponseEnum.immeubles}.${index}.${detailAvisResponseEnum.immeubleExterne}.${immeubleExterneEnum.id}`, ref[immeubleExterneEnum.id])
							setValue(`${detailAvisResponseEnum.immeubles}.${index}.${detailAvisResponseEnum.immeubleExterne}.${immeubleExterneEnum.ville}`, ref[immeubleExterneEnum.ville])
						}}
						openOnFocus
						options={loading ? [] : listeRef}
						// @ts-ignore TODO ts-ignore
						getOptionLabel={(option) => `${option[immeubleExterneEnum.reference]} - ${option[immeubleExterneEnum.adresse]} - ${option[immeubleExterneEnum.ville]}`}
						renderInput={(params) =>
							<div ref={params.InputProps.ref}>
								<CustomTextField
									register={register(`${champsFormulaire.recherche}.${index}`, {
										required: 'Champ requis'
									})}
									label="Immeuble *"
									{...params}
									inputProps={{
										...params.inputProps,
										onKeyUp: () => recuperationRefImmeuble(),
										value: watch(`${champsFormulaire.recherche}.${index}`),
										onChange: () => {
										}
									}}
									placeholder="Ex : 277"
									sx={{ height: 51, width: 300, border: `1px solid ${gecinaBlueMain}` }}
								/>
							</div>
						}
					/>
				</Box>
				<Box sx={{ width: 150, ml: '20px', color: gecinaBlueMain, minHeight: 100 }}>
					<FormNumberFormatField
						sx={{ height: 51, width: 150, backgroundColor: WHITE }}
						register={register(`${detailAvisResponseEnum.immeubles}.${index}.${detailAvisFormEnum.totalCotisation}`, {
							required: 'Champ requis',
							value: gestionNombre(immeuble[detailAvisResponseEnum.totalCotisation])
						})}
						value={thousandsSeparator(gestionNombre(watch(`${detailAvisResponseEnum.immeubles}.${index}.${detailAvisFormEnum.totalCotisation}`)), true)}
						// @ts-ignore
						onChange={(value: string) => setValue(`${detailAvisResponseEnum.immeubles}.${index}.${detailAvisFormEnum.totalCotisation}`, value)}
						id={`${detailAvisResponseEnum.immeubles}.${index}.${detailAvisFormEnum.totalCotisation}`}
						label="Total cotisation *"
						decimalScale={0}
						withBorder
						maxLength={11}
					/>
				</Box>
				<Box sx={{
					width: 250,
					ml: '20px',
					color: gecinaBlueMain,
					minHeight: 100,
					'& > div': {
						width: '150px !important',
						alignItems: 'center',
						padding: '0 7px',
						height: '51px',
						backgroundColor: 'white',
						border: '1px solid #122941',
						borderColor: 'inherit'
					}
				}}>
					<FormSelectField
						register={register(`${detailAvisResponseEnum.immeubles}.${index}.${detailAvisFormEnum.ajustementFrais}`, {
								value: immeuble[detailAvisFormEnum.ajustementFrais]
							}
						)}
						id={detailAvisFormEnum.ajustementFrais}
						label="Ajustement frais commune"
					>
						{
							['+5', '+4', '+3', '+2', '+1', '0', '-1', '-2', '-3', '-4', '-5'].map(
								(ajustement) => (
									<MenuItem value={parseInt(ajustement)} key={ajustement}>
										{ajustement}
									</MenuItem>
								)
							)
						}
					</FormSelectField>
				</Box>
				<Box>
					<Button
						sx={{
							height: 51,
							p: 0,
							width: 195,
							fontWeight: 600,
							lineHeight: '18.29px'
						}}
						border
						disabled={false}
						bgcolor="transparent"
						color={gecinaBlueMain}
						type={buttonTypesEnum.button}
						onClick={() => {
							unregister(`${detailAvisResponseEnum.immeubles}.${index}`)
							unregister(`${champsFormulaire.recherche}.${index}`)
							setValue(champsFormulaire.recherche, watch(champsFormulaire.recherche).filter((recherche: string, num: number) => num !== index))
							setValue(detailAvisResponseEnum.immeubles, watch(detailAvisResponseEnum.immeubles).filter((immeubleValue: TraitementAvisImmeubleModel, num: number) => num !== index))
							setFormValues({
									...formValues,
									[detailAvisResponseEnum.immeubles]: [
										...(formValues[detailAvisResponseEnum.immeubles].filter((immeubleValue, num) => num !== index))
									]
								}
							)
						}}
					>
						Supprimer l'immeuble
					</Button>
				</Box>
			</Grid>
			<TableauTraitementImmeuble immeuble={immeuble} index={index} />
		</Box>
	)
}

export default BlocImmeuble