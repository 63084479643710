import {
	FILTRES_COMPTES_TAUX_RECUPERABILITE_SOUSPOSTES
} from '../enums/filtresComptesTauxRecuperabiliteSouspostesEnum'

export interface ComptesTauxRecuperabiliteSouspostesModel {
	'@id': string | null,
	compte: string,
	tauxRecupResidentiel: string,
	tauxRecupBureau: string,
	dateDebut: Date,
	dateFin: Date | null,
	nomModificateur: string,
	idModificateur: string
}

export interface FiltresComptesTauxRecuperabiliteSouspostesModel {
	[FILTRES_COMPTES_TAUX_RECUPERABILITE_SOUSPOSTES.COMPTE]: string
	[FILTRES_COMPTES_TAUX_RECUPERABILITE_SOUSPOSTES.PAGE]: number
}

export interface PopinComptesTauxRecuperabiliteSouspostesModel {
	open: boolean,
	comptesTauxRecuperabiliteSouspostes: ComptesTauxRecuperabiliteSouspostesModel | undefined
}

export const INITIAL_COMPTES_TAUX_RECUPERABILITE_SOUSPOSTES: ComptesTauxRecuperabiliteSouspostesModel = {
	'@id': '',
	compte: '',
	tauxRecupResidentiel: '',
	tauxRecupBureau: '',
	dateDebut: new Date(),
	dateFin: null,
	nomModificateur: '',
	idModificateur: ''
}

export const INITIAL_POPIN_CTRS: PopinComptesTauxRecuperabiliteSouspostesModel = {
	open: false,
	comptesTauxRecuperabiliteSouspostes: INITIAL_COMPTES_TAUX_RECUPERABILITE_SOUSPOSTES
}

export const INITIAL_FILTRE_CTRS: FiltresComptesTauxRecuperabiliteSouspostesModel = {
	[FILTRES_COMPTES_TAUX_RECUPERABILITE_SOUSPOSTES.COMPTE]: '',
	[FILTRES_COMPTES_TAUX_RECUPERABILITE_SOUSPOSTES.PAGE]: 1
}