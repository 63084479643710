import React, { useContext } from 'react'
import { TraitementSGNFContext } from '../context/TraitementSGNFContext'
import PopinSuppressionContenu from '../../../components/dialog/components/PopinSuppressionContenu'
import { deleteNaturesRubriquesPaie } from '../api/traitementSGNFAPI'

const PopinGestionTraitementSGNF = () => {
	const {
		traitementSGNF,
		setTraitementSGNF,
		popinSuppression,
		setPopinSuppression
	} = useContext(TraitementSGNFContext)

	return (
		<>
			{/* Popin permettant de supprimer un traitement SG / NF */}
			<PopinSuppressionContenu
				donneesPopin={{
					open: popinSuppression.open,
					donnees: popinSuppression.traitementSGNF
				}}
				onCloseDialog={(closeData) => setPopinSuppression({
					open: closeData.open,
					traitementSGNF: closeData.donnees
				})}
				titreSuppression="Suppression du fichier"
				texteSuppression="Confirmez-vous la suppression du fichier sélectionné ?"
				contenu={traitementSGNF['hydra:member']}
				modificationContenu={(nouveauContenu) => setTraitementSGNF({
					'hydra:totalItems': nouveauContenu.length,
					'hydra:member': nouveauContenu
				})}
				suppressionContenu={deleteNaturesRubriquesPaie}
			/>
		</>
	)

}

export default PopinGestionTraitementSGNF