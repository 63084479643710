import React, { useEffect, useState } from 'react'
import { Box, FormControlLabel, Grid, MenuItem, Radio, RadioGroup } from '@mui/material'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { ChampsPopinModel, DonneesPopinModel } from './types/popinGestionFormulaire'
import { displaySnackbar } from '../snackBar/reducer/actions'
import { ERREUR_ID } from '../snackBar/SnackBar'
import Dialog from '../dialog/Dialog'
import { fontFamilyBlackerDisplay, fontFamilyMonserrat, gecinaBlueMain, gecinaBrownMain, WHITE } from '../../theme'
import TextEnum from '../../enum/textEnum'
import TextField from '../fields/FormTextField'
import Button from '../button/Button'
import { buttonTypesEnum } from '../button/enums/buttonTypesEnum'
import FormSelectField from '../fields/FormSelectField'
import FormDatePickerField from '../fields/FormDatePickerField'
import CustomCheckbox from '../checkbox/CustomCheckbox'
import { detailAvisFormEnum } from '../../modules/traitementAvis/enums/detailAvisEnum'
import { FILTRES_UTILISATEURS } from '../../modules/utilisateurs/enums/filtresUtilisateursEnum'

interface AcceptingProps<FormModel> {
	champsPopin: ChampsPopinModel[]
	formulaireVide: Partial<FormModel>
	donneesPopin: DonneesPopinModel
	onCloseDialog: (donneesModel: DonneesPopinModel) => void
	contenu: FormModel[]
	modificationContenu: (donneesModifiees: FormModel[]) => void
	titreCreation: string
	titreModification: string
	envoiDonnees: (data: FormModel) => Promise<FormModel>
}

type PopinGestionFormulaireProps<FormModel> = AcceptingProps<FormModel>

const PopinGestionFormulaire = <FormModel, >(
	{
		champsPopin,
		donneesPopin,
		onCloseDialog,
		contenu,
		modificationContenu,
		formulaireVide,
		titreCreation,
		titreModification,
		envoiDonnees
	}: PopinGestionFormulaireProps<FormModel>
) => {
	const methods = useForm<any>({ defaultValues: donneesPopin.donnees ?? formulaireVide })
	const { register, handleSubmit, reset, formState: { errors }, control, watch, setValue } = methods
	const dispatch = useDispatch()
	const [saveLoad, setSaveLoad] = useState<boolean>(false)

	const closeDialog = () => {
		onCloseDialog({
			open: false,
			donnees: undefined
		})
	}

	const onSubmit = (values: FormModel) => {
		setSaveLoad(true)
		envoiDonnees(values)
			.then((response) => {
				// Ferme la snackBar auto si ça réussit
				dispatch(displaySnackbar(
					{
						id: ERREUR_ID,
						message: '',
						open: false,
						hideIcon: false
					}))
				closeDialog()
				setSaveLoad(false)

				let donneesFinales: FormModel[] = []
				if (donneesPopin.donnees) {
					// Récupération de l'index de la précédente donnée (dans le cas d'une modification)
					const responseIndex = contenu.findIndex((donnee) => {
						// Si nous n'avons pas modifié la ligne, on renvoie -1 pour ajouter la nouvelle ligne au tableau
						if (!donneesPopin.donnees) {
							return -1
						}

						if ('@id' in donnee && '@id' in donneesPopin.donnees) {
							return donnee['@id' as keyof typeof donnee] === donneesPopin.donnees['@id' as keyof typeof donneesPopin.donnees]
						}
						if ('id' in donnee && 'id' in donneesPopin.donnees) {
							return donnee['id' as keyof typeof donnee] === donneesPopin.donnees['id' as keyof typeof donneesPopin.donnees]
						}
					})

					// Si l'index a été retrouvé, on replace l'ancienne donnée par la nouvelle reçue du back
					if (responseIndex >= 0) {
						donneesFinales = [
							...contenu.slice(0, responseIndex),
							response,
							...contenu.slice(responseIndex + 1)
						]
					}
				}

				// Sinon, on l'ajoute à la fin des anciennes infos
				if (donneesFinales.length === 0) {
					donneesFinales = [
						...contenu,
						response
					]
				}

				modificationContenu(donneesFinales)
			})
			.catch(
				(error) => {
					console.error('Erreur lors de l\'envoi des données', error)
					console.error('Message', error.response)

					// On affiche l'erreur dans une popin
					if (error.response && error.response.data) {
						dispatch(displaySnackbar(
							{
								id: ERREUR_ID,
								message: typeof error.response.data === 'string' ? error.response.data : error.response.data['hydra:description'],
								open: true,
								hideIcon: false
							})
						)
					}

					setSaveLoad(false)
				}
			)
	}

	//On reset le form a chaque ouverture de la popin.
	useEffect(() => reset(donneesPopin.donnees ?? formulaireVide), [donneesPopin.open])

	return (
		<Dialog
			open={donneesPopin.open}
			onCloseDialog={closeDialog}
			fullScreen={false}
			withPadding={false}
			maxWidth={false}
		>
			<Box justifyContent="center">
				<Grid container sx={{ fontFamily: fontFamilyBlackerDisplay, fontSize: 22, borderBottom: '1px solid', padding: 0 }}>
					{
						donneesPopin.donnees === undefined ?
							<Box sx={{ paddingTop: '40px', paddingX: '40px', paddingBottom: '20px' }}>
								{titreCreation}
							</Box>
							:
							<Box sx={{ paddingTop: '40px', paddingX: '40px', paddingBottom: '20px' }}>
								{titreModification}
							</Box>
					}
				</Grid>
				<Grid container sx={{ padding: '40px', display :'block' }}>
					<FormProvider {...methods}>
						<form onSubmit={handleSubmit(onSubmit)}>
							{
								champsPopin.map((champPopin, index) => (
									<Grid container item flexDirection="row" key={`${champPopin.colonnes}-${index}`}>
										{
											champPopin.champs.map((champ, index) => (
												<React.Fragment key={`${champ.id}-${index}`}>
													{
														champ.type === 'hidden' &&
														<input type="hidden" id={champ.id} />
													}
													{
														champ.type === 'radio' &&
														<Grid item sx={{ width: 345, marginRight: '20px', mt: '15px' }}>
															<label
																style={{
																	width: '100%',
																	paddingTop: 7,
																	fontSize: 16,
																	marginBottom: 5,
																	marginLeft: 0,
																	boxSizing: 'border-box',
																	textAlign: 'left',
																	fontFamily: fontFamilyMonserrat,
																	fontWeight: 600
																}}
															>
																{champ.label}
															</label>

															<Controller
																control={control}
																name={champ.id}
																render={({ field: { onChange, value } }) => (
																	<RadioGroup value={value} onChange={(e) => onChange(e.target.value)} row>
																		{
																			champ.options && champ.options.map((option) => (<FormControlLabel value={option.value} control={<Radio />} label={option.label} />))
																		}
																	</RadioGroup>
																)}
															/>
														</Grid>
													}
													{
														champ.type === 'checkbox' &&
														<Grid item sx={{ width: 345, marginRight: '20px', mt: '15px', '& .Mui-checked': { backgroundColor: 'white !important'}}}>
															<label
																style={{
																	width: '100%',
																	paddingTop: 7,
																	fontSize: 16,
																	marginBottom: 5,
																	marginLeft: 0,
																	boxSizing: 'border-box',
																	textAlign: 'left',
																	fontFamily: fontFamilyMonserrat,
																	fontWeight: 600
																}}
															>
																{champ.label}
															</label>

															<Controller
																control={control}
																name={champ.id}
																render={({ field: { onChange, value, ...props } }) => (
																		<CustomCheckbox
																			id={champ.id}
																			{...props}
																			checked={value}
																			onChange={(e) => {
																				if (champ.onChange) champ.onChange(e)

																				onChange(e)
																			}}
																		/>
																	)
																}
															/>
														</Grid>
													}
													{
														champ.type === 'text' &&
														<Grid item sx={{ width: 345, marginRight: '20px', mt: '15px', opacity: champ.disableOnUpdate && !!donneesPopin.donnees ? '0.5' : '1' }}>
															<TextField
																sx={{ padding: '15px', fontSize: 13, mb: 0 }}
																register={register(champ.id, {
																	required: TextEnum.requis,
																	...champ.optionsProps
																})}
																maxLength={champ.maxLength}
																errors={errors}
																id={champ.id}
																label={champ.label}
																placeholder={champ.placeholder}
																withBorder
																inputProps={
																	champ.disableOnUpdate ? { readOnly: !!donneesPopin.donnees } : {}
																}
															/>
														</Grid>
													}
													{
														champ.type === 'number' &&
														<Grid item sx={{ width: 345, marginRight: '20px', mt: '15px' }}>
															<TextField
																sx={{ padding: '15px', fontSize: 13, mb: 0 }}
																register={register(champ.id, {
																	required: TextEnum.requis,
																	...champ.optionsProps
																})}
																errors={errors}
																id={champ.id}
																label={champ.label}
																placeholder={champ.placeholder}
																withBorder
																inputProps={
																	champ.disableOnUpdate ? { readOnly: !!donneesPopin.donnees } : {}
																}
															/>
														</Grid>
													}
													{
														champ.type === 'select' &&
														<Grid
															item
															sx={{
																width: 345,
																marginRight: '20px',
																mt: '15px',
																display: champ.id === FILTRES_UTILISATEURS.PROFIL ? 'none' : 'block',

																'& .MuiInput-root': {
																	border: `1px solid ${gecinaBlueMain}`,
																	p: '15px'
																}
															}}
															id={`${champ.id}-container`}
														>
															<FormSelectField
																register={register(champ.id, {
																	required: TextEnum.requis,
																	...champ.optionsProps
																})}
																id={champ.id}
																label={champ.label}
															>
																{
																	champ.options && champ.options.map(
																		(option, index) => (
																			<MenuItem value={option.value} key={`${option.label}-${option.value}-${index}`}>
																				{option.label}
																			</MenuItem>
																		)
																	)
																}
															</FormSelectField>
														</Grid>
													}
													{
														champ.type === 'date' && (
															<Grid
																item
																sx={{
																	width: 345,
																	marginRight: '20px',
																	mt: '15px',

																	'& .MuiInput-root': {
																		border: `1px solid ${gecinaBlueMain}`,
																		p: '15px'
																	}
															}}
															>
																<FormDatePickerField
																	register={register(champ.id, {
																		required: (champ.label && champ.label.includes('*'))?TextEnum.requis:'',
																		...champ.optionsProps
																	})}
																	id={champ.id}
																	label={champ.label}
																/>
															</Grid>
														)
													}
												</React.Fragment>
											))
										}
									</Grid>
								))
							}

							<Grid item container sx={{ marginTop: '60px' }} justifyContent="end">
								<Button bgcolor={WHITE} color={gecinaBlueMain} border={true} disabled={false} sx={{ marginRight: '24px' }} onClick={() => {
									dispatch(displaySnackbar(
										{
											id: ERREUR_ID,
											message: '',
											open: false,
											hideIcon: false
										}))
									closeDialog()
								}}>
									Annuler
								</Button>
								<Button bgcolor={gecinaBlueMain} color={gecinaBrownMain} border={false} disabled={saveLoad} type={buttonTypesEnum.submit}>
									{donneesPopin.donnees === undefined ?
										'Ajouter'
										:
										'Modifier'
									}
								</Button>
							</Grid>

						</form>
					</FormProvider>
				</Grid>
			</Box>
		</Dialog>

	)

}

export default PopinGestionFormulaire