import { filtresListeAvisFieldsEnum, filtresListeAvisPostEnum, filtresListeAvisResponseEnum, statutListeAvisEnum } from '../enums/listeAvisEnum'
import { getKeyFromEnumValue } from '../../../utils/utils'

export interface FiltresListeAvisFormModel {
	[filtresListeAvisFieldsEnum.reference]: string,
	[filtresListeAvisFieldsEnum.recuLe]: Date | string | null,
	[filtresListeAvisFieldsEnum.avantLe]: Date | string | null,
	[filtresListeAvisFieldsEnum.statut]: string,
	[filtresListeAvisFieldsEnum.dateSortDesc]: boolean
}

export const INITIAL_VALEURS_FORMULAIRE = {
	[filtresListeAvisFieldsEnum.avantLe]: null,
	[filtresListeAvisFieldsEnum.recuLe]: null,
	[filtresListeAvisFieldsEnum.statut]: getKeyFromEnumValue<statutListeAvisEnum>(statutListeAvisEnum, statutListeAvisEnum.all),
	[filtresListeAvisFieldsEnum.reference]: '',
	[filtresListeAvisFieldsEnum.dateSortDesc]: true
}

export interface FiltresListeAvisPostModel {
	[filtresListeAvisPostEnum.reference]?: string,
	[filtresListeAvisPostEnum.statut]?: string,
	[filtresListeAvisPostEnum.recuLe]?: string,
	[filtresListeAvisPostEnum.avantLe]?: string,
	[filtresListeAvisPostEnum.dateSortDesc]?: boolean,
	[filtresListeAvisPostEnum.page]?: number
}

export interface ListeAvisResponseModel {
	[filtresListeAvisResponseEnum.id]: number,
	[filtresListeAvisResponseEnum.reference]: string,
	[filtresListeAvisResponseEnum.recuLe]: string,
	[filtresListeAvisResponseEnum.traiteLe]: string,
	[filtresListeAvisResponseEnum.statut]: statutListeAvisEnum,
	[filtresListeAvisResponseEnum.fichierDepose]: string,
	[filtresListeAvisResponseEnum.dateGeneration]: string,
	[filtresListeAvisResponseEnum.departement]: string,
	[filtresListeAvisResponseEnum.dateComptable]: string,
	[filtresListeAvisResponseEnum.dateExigibilite]: string,
	[filtresListeAvisResponseEnum.anneeReddition]: number,
	[filtresListeAvisResponseEnum.departement]: string,
	[filtresListeAvisResponseEnum.commune]: string,
	[filtresListeAvisResponseEnum.dateTransmission]: string
}

export interface PageListeAvisModel {
	[filtresListeAvisResponseEnum.current]: number,
	[filtresListeAvisResponseEnum.last]: number
}

export interface FiltresListeAvisResponseModel {
	[filtresListeAvisResponseEnum.list]: ListeAvisResponseModel[],
	[filtresListeAvisResponseEnum.total]: number,
	[filtresListeAvisResponseEnum.page]: PageListeAvisModel
}

export const INITIAL_REPONSE_LISTE_AVIS: FiltresListeAvisResponseModel = {
	[filtresListeAvisResponseEnum.list]: [],
	[filtresListeAvisResponseEnum.total]: 0,
	[filtresListeAvisResponseEnum.page]: {
		[filtresListeAvisResponseEnum.current]: 0,
		[filtresListeAvisResponseEnum.last]: 0
	}
}
