import { INITIAL_POPIN_SUPPRESSION_AVIS, ListeAvisContextModel, PopinSuppressionAvisModel } from '../types/listeAvisModel'
import { createContext, useState } from 'react'
import { FiltresListeAvisPostModel, FiltresListeAvisResponseModel, INITIAL_REPONSE_LISTE_AVIS } from '../types/filtresListeAvisModel'

export const ListeAvisContext = createContext<ListeAvisContextModel>({
	ouverturePopin: INITIAL_POPIN_SUPPRESSION_AVIS,
	setOuverturePopin: () => {},
	dateSortDesc: true,
	setDateSortDesc: () => {},
	donneesFormulaire: {},
	setDonneesFormulaire: () => {},
	listeAvis: INITIAL_REPONSE_LISTE_AVIS,
	setListeAvis: () => {}
})

export const ListeAvisProvider: React.FC = ({ children }) => {
	const [donneesFormulaire, setDonneesFormulaire] = useState<FiltresListeAvisPostModel>({})
	const [dateSortDesc, setDateSortDesc] = useState<boolean>(true)
	const [ouverturePopin, setOuverturePopin] = useState<PopinSuppressionAvisModel>(INITIAL_POPIN_SUPPRESSION_AVIS)
	const [listeAvis, setListeAvis] = useState<FiltresListeAvisResponseModel>(INITIAL_REPONSE_LISTE_AVIS)

	return (
		<ListeAvisContext.Provider
			value={{
				donneesFormulaire,
				setDonneesFormulaire,
				dateSortDesc,
				setDateSortDesc,
				listeAvis,
				setListeAvis,
				ouverturePopin,
				setOuverturePopin
			}}
		>
			{children}
		</ListeAvisContext.Provider>
	)
}
