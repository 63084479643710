import { FiltresListeAvisPostModel, FiltresListeAvisResponseModel } from './filtresListeAvisModel'
import { Dispatch, SetStateAction } from 'react'
import { detailAvisPostEnum } from '../../traitementAvis/enums/detailAvisEnum'
import { DetailAvisPostModel } from '../../traitementAvis/types/detailAvisModel'

export interface ListeAvisContextModel {
	ouverturePopin: PopinSuppressionAvisModel,
	setOuverturePopin: Dispatch<SetStateAction<PopinSuppressionAvisModel>>,
	donneesFormulaire: FiltresListeAvisPostModel,
	setDonneesFormulaire: (donneesFormulaire: FiltresListeAvisPostModel) => void,
	listeAvis: FiltresListeAvisResponseModel,
	setListeAvis: (listeProcedures: FiltresListeAvisResponseModel) => void,
	dateSortDesc: boolean,
	setDateSortDesc: (bool: boolean) => void
}


export interface PopinSuppressionAvisModel {
	open: boolean,
	avis: DetailAvisPostModel
}

export const INITIAL_POPIN_SUPPRESSION_AVIS: PopinSuppressionAvisModel = {
	open: false,
	avis: {
		[detailAvisPostEnum.fichier]: '',
		[detailAvisPostEnum.anneeRedition]: 0
	}
}