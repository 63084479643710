import React, { useCallback, useMemo } from 'react'
import { Input, TextFieldProps } from '@mui/material'
import { TextFieldAcceptingProps } from './FormTextField'
import { ChampDansFormulaire } from './types/typePourChampDansFormulaireModel'
import { FieldErrors, useFormContext, UseFormRegisterReturn } from 'react-hook-form'
import { fontFamilyMonserrat, gecinaBlueMain, gecinaErrorColor, WHITE } from '../../theme'
import ErrorComponent from './ErrorComponent'
import flatten, { unflatten } from 'flat'
import { verificationErreursFormulaire } from '../../utils/utils'

interface AcceptingProps {
	errorWhite?: boolean,
	fromDatePicker?: boolean,
	withBorder?: boolean
}

type CustomTextFieldProps = Partial<ChampDansFormulaire> & TextFieldAcceptingProps & TextFieldProps & AcceptingProps

export const CUSTOM_TEXT_FIELD_STYLE = (erreurRemontee: string) => ({
	width: '100%',
	mt: '5px',
	marginBottom: '10px',
	backgroundColor: WHITE,
	padding: '0 7px',
	border: erreurRemontee.length > 0 && `1px solid ${gecinaErrorColor}`,

	'& .MuiSelect-select': {
		fontFamily: fontFamilyMonserrat,
		fontSize: 15,
		fontWeight: 500
	},
	'input': {
		fontFamily: fontFamilyMonserrat,
		fontSize: 15,
		fontWeight: 500
	},
	'input::-webkit-input-placeholder': {
		fontSize: 13,
		fontWeight: 500,
		fontFamily: fontFamilyMonserrat,
		lineHeight: '20px'
	},
	'input::-moz-placeholder': {
		fontSize: 13,
		fontWeight: 500,
		fontFamily: fontFamilyMonserrat,
		lineHeight: '20px'
	},
	'input:moz-placeholder': {
		fontSize: 13,
		fontWeight: 500,
		fontFamily: fontFamilyMonserrat,
		lineHeight: '20px'
	},
	'input:-ms-input-placeholder': {
		fontSize: 13,
		fontWeight: 500,
		fontFamily: fontFamilyMonserrat,
		lineHeight: '20px'
	},
	':before': {
		borderColor: erreurRemontee.length > 0 ? gecinaErrorColor : WHITE
	},
	':hover:not(.Mui-disabled):before': {
		borderWidth: '1px',
		borderColor: `${WHITE} !important`
	},
	':after': {
		borderWidth: '1px',
		borderColor: `${WHITE} !important`
	}
})

const CustomTextField = (
	{
		id,
		multiline,
		rows,
		maxLength,
		placeholder,
		register,
		inputProps,
		InputProps,
		inputRef,
		label,
		sx,
		errorWhite = false,
		fromDatePicker = false,
		withBorder = false
	}: CustomTextFieldProps
) => {
	const { formState: { errors } } = useFormContext()

	const CUSTOM_STYLE = () => ({
		...sx
	})

	const erreurRemontee = useMemo(() => verificationErreursFormulaire(errors, register), [Object.keys(errors).length])

	return (
		<>
			{
				label && (
					<span
						style={{
							width: '100%',
							paddingTop: 7,
							fontSize: 16,
							marginBottom: 5,
							marginLeft: 0,
							boxSizing: 'border-box',
							textAlign: 'left',
							fontFamily: fontFamilyMonserrat,
							fontWeight: 600
						}}
					>
						{label}
					</span>
				)
			}
			<Input
				{...register}
				color="primary"
				multiline={multiline}
				rows={rows}
				inputProps={{
					maxLength: maxLength,
					...inputProps
				}}
				inputRef={inputRef}
				startAdornment={InputProps?.startAdornment || undefined}
				sx={[
					withBorder && {
						border: `1px solid ${gecinaBlueMain}`
					},
					CUSTOM_TEXT_FIELD_STYLE(erreurRemontee),
					CUSTOM_STYLE()
				]}
				id={id}
				placeholder={placeholder}
				disableUnderline
				onBlur={() => {
				}}
			/>

			{
				erreurRemontee.length > 0 && (
					<ErrorComponent errorWhite={errorWhite}>
						{erreurRemontee}
					</ErrorComponent>
				)
			}
		</>
	)
}

export default CustomTextField
