import React from 'react'
import { IconsModel } from './types/iconsModel'

const SvgCheckmark = (
	{
		color,
		width = 24,
		height = 24,
		...props
	}: IconsModel
) => (
	<svg width={width} height={height} viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path d="M1.76038 5.97854L6.03585 10.254L13.1617 1.70306" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
	</svg>

)

export default SvgCheckmark