import React from 'react'
import { DialogTitle, DialogContent } from '@mui/material'
import MuiDialog from '@mui/material/Dialog'


interface AcceptingProps {
	onCloseDialog: () => void,
	open: boolean,
	children: React.ReactChild,
	title?: string,
	fullScreen?: boolean,
	maxWidth?: false | 'sm' | 'xs' | 'md' | 'lg' | 'xl' | undefined,
	withPadding?: boolean,
	hideBackdrop?: boolean
}

type DialogProps = AcceptingProps

const Dialog: React.FC<DialogProps> = (
	{
		onCloseDialog,
		open,
		children,
		title,
		fullScreen = true,
		maxWidth = 'sm',
		withPadding = true,
		hideBackdrop = false
	}
) => {

	const handleClose = () => {
		onCloseDialog()
	}
	return (
		<MuiDialog
			open={open}
			onClose={handleClose}
			fullScreen={fullScreen}
			maxWidth={maxWidth}
			hideBackdrop={hideBackdrop}
			sx={{
				'& .MuiPaper-root': {
					height: 'fit-content'
				}
			}}
			BackdropProps={{
				classes: {
					// root: classes.backDrop
				}
			}}
		>
			{
				title &&
				<DialogTitle>
					{title}
				</DialogTitle>
			}
			<DialogContent
				sx={[
					{
						height: '100%',
						padding: withPadding ? '20px 24px' : 0
					}
				]
				}
			>
				{children}
			</DialogContent>
		</MuiDialog>
	)
}

export default Dialog
