import React from 'react'
import { MatriculesTauxProvider } from './context/MatriculesTauxContext'
import EnteteMatriculesTaux from './components/EnteteMatriculesTaux'
import ListeMatriculesTaux from './components/ListeMatriculesTaux'
import PopinGestionMatriculesTaux from './components/PopinGestionMatriculesTaux'

const MatriculesTaux = () => (
	<MatriculesTauxProvider>
		<EnteteMatriculesTaux />

		<ListeMatriculesTaux />

		<PopinGestionMatriculesTaux />
	</MatriculesTauxProvider>
)

export default MatriculesTaux