export enum FILTRES_COMPTES_NOMENCLATURE_CHARGES {
	ID = '@id',
	COMPTE = 'compte',
	TENCODE = 'tencode',
	CODEPOSTE = 'codeposte',
	DATE_DEBUT = 'dateDebut',
	DATE_FIN = 'dateFin',
	NOM_MODIFICATEUR = 'nomModificateur',
	ID_MODIFICATEUR = 'idModificateur',
	PAGE = 'page'
}

export const EMPTY_FORM = {
	[FILTRES_COMPTES_NOMENCLATURE_CHARGES.ID]: null,
	[FILTRES_COMPTES_NOMENCLATURE_CHARGES.COMPTE]: '',
	[FILTRES_COMPTES_NOMENCLATURE_CHARGES.TENCODE]: '',
	[FILTRES_COMPTES_NOMENCLATURE_CHARGES.CODEPOSTE]: ''
}