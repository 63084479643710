import PopinSuppressionContenu from '../../../components/dialog/components/PopinSuppressionContenu'
import React, { useContext } from 'react'
import { deleteAvis } from '../../traitementAvis/api/traitementAvisAPI'
import { detailAvisPostEnum } from '../../traitementAvis/enums/detailAvisEnum'
import { DetailAvisPostModel } from '../../traitementAvis/types/detailAvisModel'
import { useNavigate } from 'react-router-dom'
import { displaySnackbar } from '../../../components/snackBar/reducer/actions'
import { ERREUR_ID } from '../../../components/snackBar/SnackBar'
import { useDispatch } from 'react-redux'
import { ListeAvisContext } from '../context/ListeAvisContext'
import { INITIAL_POPIN_SUPPRESSION_AVIS } from '../types/listeAvisModel'

const PopinSuppressionAvis = () => {
    const { setOuverturePopin, ouverturePopin } = useContext(ListeAvisContext)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const deleteAndClose = (contenu: DetailAvisPostModel): DetailAvisPostModel => {
        deleteAvis(contenu)
            .then((data) => {
                navigate(0)
                return data
            })
            .catch((error) => {
                if (error.response && error.response.data) {
                    dispatch(displaySnackbar({
                        id: ERREUR_ID,
                        message: error.response.data,
                        open: true,
                        hideIcon: false
                    }))
                }
            })
        return contenu
    }
    return (
        <PopinSuppressionContenu
            donneesPopin={{
                open: ouverturePopin.open,
                donnees: ouverturePopin.avis
            }}
            onCloseDialog={() => setOuverturePopin(INITIAL_POPIN_SUPPRESSION_AVIS)}
            titreSuppression="Suppression d'un avis"
            texteSuppression="Confirmez-vous la suppression de l'avis ?"
            suppressionContenu={(contenu) => Promise.resolve(deleteAndClose(contenu))}
            contenu={[{[detailAvisPostEnum.fichier]: ouverturePopin.avis[detailAvisPostEnum.fichier], [detailAvisPostEnum.anneeRedition]: ouverturePopin.avis[detailAvisPostEnum.anneeRedition]}]}
            modificationContenu={() => {}}
        />
    )
}

export default PopinSuppressionAvis