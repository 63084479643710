import axios from 'axios'
import { CommunApiPlatformModel } from '../../../types/communApiPlatformModel'
import { UtilisateursModel } from '../types/utilisateursModel'

export const getListeUtilisateurs = (page: number, email?: string, nom?: string): Promise<CommunApiPlatformModel<UtilisateursModel[]>> => axios.get(`/api/utilisateurs?page=${page}${email ? `&email=${email}` : ''}${nom ? `&nom=${nom}` : ''}`)
	.then((response) => response.data)

export const postUtilisateurs = (data: UtilisateursModel): Promise<UtilisateursModel> => axios.post('/api/utilisateurs', data)
	.then((response) => response.data)

export const putUtilisateurs = (data: UtilisateursModel): Promise<UtilisateursModel> => axios.put(`/api/utilisateurs/${data['@id']!.split('/api/utilisateurs/')[1]}`, data)
	.then((response) => response.data)

export const deleteUtilisateurs = (data: UtilisateursModel): Promise<UtilisateursModel> => axios.delete(`/api/utilisateurs/${data['@id']!.split('/api/utilisateurs/')[1]}`)
	.then((response) => response.data)