import { FILTRES_NATURES_RUBRIQUES_PAIE } from '../enums/filtresNaturesRubriquesPaieEnum'

export interface NaturesRubriquesPaieModel {
	'@id': string | null,
	compte: string,
	natureChargeResidentiel: string,
	natureChargeBureau: string,
	dateDebut: Date,
	dateFin: Date | null,
	nomModificateur: string,
	idModificateur: string
}

export interface FiltresNaturesRubriquesPaieModel {
	[FILTRES_NATURES_RUBRIQUES_PAIE.COMPTE]: string
	[FILTRES_NATURES_RUBRIQUES_PAIE.PAGE]: number
}

export interface PopinNaturesRubriquesPaieModel {
	open: boolean,
	naturesRubriquesPaie: NaturesRubriquesPaieModel | undefined
}

export const INITIAL_NATURES_RUBRIQUES_PAIE: NaturesRubriquesPaieModel = {
	'@id': '',
	compte: '',
	natureChargeResidentiel: '',
	natureChargeBureau: '',
	dateDebut: new Date(),
	dateFin: null,
	nomModificateur: '',
	idModificateur: ''
}

export const INITIAL_POPIN_NRP: PopinNaturesRubriquesPaieModel = {
	open: false,
	naturesRubriquesPaie: INITIAL_NATURES_RUBRIQUES_PAIE
}

export const INITIAL_FILTRE_NRP: FiltresNaturesRubriquesPaieModel = {
	[FILTRES_NATURES_RUBRIQUES_PAIE.COMPTE]: '',
	[FILTRES_NATURES_RUBRIQUES_PAIE.PAGE]: 1
}