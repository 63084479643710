import { Grid } from '@mui/material'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import TextField from '../../../components/fields/FormTextField'
import { buttonTypesEnum } from '../../../components/button/enums/buttonTypesEnum'
import { fontFamilyMonserrat, gecinaBrownMain, WHITE } from '../../../theme'
import Button from '../../../components/button/Button'
import AutorenewIcon from '@mui/icons-material/Autorenew'
import React, { useContext } from 'react'
import { styled } from '@mui/system'
import { FiltresUtilisateursModel } from '../types/utilisateursModel'
import { UtilisateursContext } from '../context/UtilisateursContext'
import { FILTRES_UTILISATEURS } from '../enums/filtresUtilisateursEnum'
import { getListeUtilisateurs } from '../api/utilisateursAPI'

export const SpanVide = styled((props: React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>) => (<span {...props} />))(({ theme }) => (
	{
		width: '100%',
		paddingRight: 15,
		paddingTop: 7,
		fontSize: 14,
		marginLeft: 0,
		boxSizing: 'border-box',
		textAlign: 'left',
		fontFamily: fontFamilyMonserrat,
		fontWeight: 500
	}
))

const INITIAL_VALEURS_FORMULAIRE = {
	[FILTRES_UTILISATEURS.EMAIL]: ''
}

const FiltresUtilisateurs = () => {
	const { setChargementUtilisateurs, setUtilisateurs, setOuverturePopin, filtresUtilisateurs, setFiltresUtilisateurs } = useContext(UtilisateursContext)
	const methods = useForm<FiltresUtilisateursModel>({
		defaultValues: INITIAL_VALEURS_FORMULAIRE
	})
	const { register, handleSubmit, reset } = methods

	const onSubmit: SubmitHandler<FiltresUtilisateursModel> = (values) => {
		setChargementUtilisateurs(true)
		const data = {
			...filtresUtilisateurs,
			[FILTRES_UTILISATEURS.PAGE]: 1
		}

		// Récupération de la liste des procédures selon les valeurs du filtre
		return getListeUtilisateurs(1, values.email, values.nom)
			.then((response) => {
				setUtilisateurs(response)
				setChargementUtilisateurs(false)
			})
	}

	const ajoutUtilisateur = () => setOuverturePopin({ open: true, utilisateurs: undefined })

	return (
		<FormProvider {...methods}>
			<form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
				<Grid container spacing={2}>
					<Grid item xs={3}>
						<TextField
							register={register(FILTRES_UTILISATEURS.EMAIL, {
								maxLength: {
									value: 255,
									message: 'Le mail est limité à 255 caractères'
								}
							})}
							maxLength={255}
							id={FILTRES_UTILISATEURS.EMAIL}
							label="Utilisateur"
							placeholder="Saisir un email"
						/>
					</Grid>
					<Grid item xs={3}>
						<TextField
							register={register(FILTRES_UTILISATEURS.NOM, {
								maxLength: {
									value: 255,
									message: 'Le nom est limité à 255 caractères'
								}
							})}
							maxLength={255}
							id={FILTRES_UTILISATEURS.NOM}
							label="Nom"
							placeholder="Saisir un Nom"
						/>
					</Grid>

					<Grid container item xs={3} spacing={2} sx={{ m: 0 }} alignContent="flex-start">
						<SpanVide> </SpanVide>

						<Grid item xs={7} sx={{ pt: '0px !important', height: 'fit-content' }}>
							<Button
								sx={{
									height: '32px',
									p: 0,
									minWidth: '100%',
									fontWeight: 600
								}}
								bgcolor={gecinaBrownMain}
								color={WHITE}
								border={false}
								disabled={false}
								type={buttonTypesEnum.submit}
							>
								Filtrer
							</Button>
						</Grid>

						<Grid item xs={3} md={2} sx={{ pt: '0px !important', height: 'fit-content' }}>
							<Button
								sx={{
									height: 32,
									p: 0,
									minWidth: '100%'
								}}
								bgcolor={gecinaBrownMain}
								color={WHITE}
								border={false}
								disabled={false}
								type={buttonTypesEnum.submit}
								onClick={() => reset(INITIAL_VALEURS_FORMULAIRE)}
							>
								<AutorenewIcon />
							</Button>
						</Grid>
					</Grid>

					<Grid container item xs={3} md={2} spacing={2} sx={{ m: 0, ml: 'auto' }} alignContent="flex-start">
						<SpanVide> </SpanVide>

						<Grid item xs={12} sx={{ pt: '0px !important', height: 'fit-content' }}>
							<Button
								sx={{
									height: '32px',
									p: 0,
									minWidth: '100%',
									fontWeight: 600
								}}
								bgcolor={gecinaBrownMain}
								color={WHITE}
								border={false}
								disabled={false}
								type={buttonTypesEnum.button}
								onClick={ajoutUtilisateur}
							>
								Ajouter un utilisateur
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</form>
		</FormProvider>
	)
}

export default FiltresUtilisateurs