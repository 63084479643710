import React from 'react'
import { IconsModel } from './types/iconsModel'

const SvgClose = (
	{
		color,
		width = 24,
		height = 24,
		style,
		...props
	}: IconsModel
) => (
	<svg width={width} height={height} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path d="M21.8238 24.5L12.0077 14.6748L2.19164 24.5L0 22.3098L9.83151 12.5L0 2.69023L2.19164 0.5L12.0077 10.3252L21.8238 0.515425L24 2.69023L14.1839 12.5L24 22.3098L21.8238 24.5Z" fill={color} />
	</svg>

)

export default SvgClose