import { FiltresListeTaxesEnum, statutListeTaxesEnum } from '../enums/listeTaxesEnum'
import { statutListeAvisEnum } from '../../listeAvis/enums/listeAvisEnum'
import { getKeyFromEnumValue } from '../../../utils/utils'

export interface FiltresListeTaxeModel {
	[FiltresListeTaxesEnum.annee]: number,
	[FiltresListeTaxesEnum.reference]: string,
	[FiltresListeTaxesEnum.status]: string,
	[FiltresListeTaxesEnum.page]: number
	[FiltresListeTaxesEnum.avis]: string

}

export const INITIAL_VALEURS_FORMULAIRE = {
	[FiltresListeTaxesEnum.annee]: 0,
	[FiltresListeTaxesEnum.reference]: '',
	[FiltresListeTaxesEnum.status]: getKeyFromEnumValue(statutListeTaxesEnum, statutListeTaxesEnum.all),
	[FiltresListeTaxesEnum.page]: 1,
	[FiltresListeTaxesEnum.avis]: ''
}

export interface ListeTaxesModel {
	annee_reddition: number,
	immeuble: number,
	cotisations: number
	frais: number,
	total: number,
	statut: string,
	date: Date | null,
	n_depense: number | null,
	date_validation: string | null,
	date_generation: string | null,
	date_transmission: string | null,
	referenceAvis: string
}

export interface ListeTaxesPageModel {
	list: ListeTaxesModel[],
	total: number,
	page: PageListeTaxesModel
}

export interface PageListeTaxesModel {
	current: number,
	last: number
}

export interface ListeTaxesContextModel {
	filtres: FiltresListeTaxeModel,
	setFiltres: (filtres: FiltresListeTaxeModel) => void,
	annees: number[],
	setAnnees: (annes: number[]) => void
	listePaginee: ListeTaxesPageModel,
	setListePaginee: (liste: ListeTaxesPageModel) => void
}

export const TAXE_LISTE_VIDE: ListeTaxesPageModel = {
	list: [],
	total: 0,
	page: { current: 1, last: 1 }
}

export interface AutocompleteImmeubleModel {
	reference: string,
	adresse: string,
	ville: string
}
