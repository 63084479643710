import React, { useContext } from 'react'
import { MatriculesTauxContext } from '../context/MatriculesTauxContext'
import { EMPTY_FORM, FILTRES_MATRICULES_TAUX } from '../enums/filtresMatriculesTauxEnum'
import PopinGestionFormulaire from '../../../components/form/PopinGestionFormulaire'
import { ChampsPopinModel } from '../../../components/form/types/popinGestionFormulaire'
import PopinSuppressionContenu from '../../../components/dialog/components/PopinSuppressionContenu'
import { deleteMatriculesTaux, postMatriculesTaux, putMatriculesTaux } from '../api/matriculesTauxAPI'
import { ComptesTauxRecuperabiliteSouspostesModel } from '../../comptesTauxRecuperabiliteSouspostes/types/comptesTauxRecuperabiliteSouspostesModel'

//Champs de la popin
const POPIN_FIELDS = (tauxRecupResidentiel: ComptesTauxRecuperabiliteSouspostesModel[]): ChampsPopinModel[] => ([
	{
		colonnes: 1,
		champs: [
			{
				id: FILTRES_MATRICULES_TAUX.ID,
				type: 'hidden'
			},
			{
				id: FILTRES_MATRICULES_TAUX.MATRICULE,
				type: 'text',
				maxLength: 20,
				label: 'Matricule *',
				disableOnUpdate: true
			}
		]
	},
	{
		colonnes: 2,
		champs: [
			{
				id: FILTRES_MATRICULES_TAUX.REFERENCE_IMMEUBLE,
				type: 'text',
				maxLength: 20,
				label: 'Référence d\'immeuble *',
				disableOnUpdate: true
			},
			{
				id: FILTRES_MATRICULES_TAUX.TAUX_RECUP_RESIDENTIEL,
				type: 'select',
				label: 'Taux récup. résidentiel *',
				options: tauxRecupResidentiel.sort((a, b) => parseFloat(a.tauxRecupResidentiel) - parseFloat(b.tauxRecupResidentiel)).map((taux) => ({
					label: `${taux.tauxRecupResidentiel}%`,
					value: taux.tauxRecupResidentiel
				}))
			}
		]
	},
	{
		colonnes: 3,
		champs: [
			{
				id: FILTRES_MATRICULES_TAUX.DATE_DEBUT,
				type: 'date',
				label: 'Date début *'
			},
			{
				id: FILTRES_MATRICULES_TAUX.DATE_FIN,
				type: 'date',
				label: 'Date fin'
			}
		]
	}
])

const PopinGestionMatriculesTaux = () => {
	const {
		ouverturePopin,
		setOuverturePopin,
		matriculesTaux,
		setMatriculesTaux,
		popinSuppression,
		setPopinSuppression,
		tauxRecupResidentiel
	} = useContext(MatriculesTauxContext)

	return (
		<>
			{/* Popin permettant d'ajouter / modifier un MT */}
			<PopinGestionFormulaire
				champsPopin={POPIN_FIELDS(tauxRecupResidentiel)}
				formulaireVide={EMPTY_FORM(tauxRecupResidentiel)}
				donneesPopin={{
					open: ouverturePopin.open,
					donnees: ouverturePopin.matriculesTaux
				}}
				onCloseDialog={(closeData) => setOuverturePopin({
					open: closeData.open,
					matriculesTaux: closeData.donnees
				})}
				contenu={matriculesTaux['hydra:member']}
				modificationContenu={(nouveauContenu) => setMatriculesTaux({
					'hydra:totalItems': ouverturePopin.matriculesTaux ? matriculesTaux['hydra:totalItems'] : matriculesTaux['hydra:totalItems']+1,
					'hydra:member': nouveauContenu
				})}
				titreCreation="Ajouter un nouveau paramétrage"
				titreModification="Modifier le paramétrage"
				envoiDonnees={ouverturePopin.matriculesTaux ? putMatriculesTaux : postMatriculesTaux}
			/>

			{/* Popin permettant de supprimer un MT */}
			<PopinSuppressionContenu
				donneesPopin={{
					open: popinSuppression.open,
					donnees: popinSuppression.matriculesTaux
				}}
				onCloseDialog={(closeData) => setPopinSuppression({
					open: closeData.open,
					matriculesTaux: closeData.donnees
				})}
				titreSuppression="Suppression le paramétrage"
				texteSuppression="Confirmer la suppression du paramétrage ?"
				contenu={matriculesTaux['hydra:member']}
				modificationContenu={(nouveauContenu) => setMatriculesTaux({
					'hydra:totalItems': matriculesTaux['hydra:totalItems']-1,
					'hydra:member': nouveauContenu
				})}
				suppressionContenu={deleteMatriculesTaux}
			/>
		</>
	)

}

export default PopinGestionMatriculesTaux