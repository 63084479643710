import React, { useContext, useMemo } from 'react'
import { HeadRow } from '../../../components/table/types/tableModel'
import { getKeyFromEnumValue } from '../../../utils/utils'
import { Box, IconButton } from '@mui/material'
import { remplissageLigneSelonEnteteTableau } from '../../../components/table/utils/tableUtils'
import CustomTable from '../../../components/table/Table'
import { ENTETE_TABLEAU_LISTE_CNC_ENUM } from '../enums/ENTETE_TABLEAU_LISTE_CNC_ENUM'
import { ComptesNomenclatureChargesModel } from '../types/comptesNomenclatureChargesModel'
import { ComptesNomenclatureChargesContext } from '../context/ComptesNomenclatureChargesContext'
import Loader from '../../../components/loader/Loader'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { gecinaBrownMain } from '../../../theme'
import { columnsEnum } from '../../../components/table/enums/columnsEnum'
import { FILTRES_COMPTES_NOMENCLATURE_CHARGES } from '../enums/filtresComptesNomenclatureChargesEnum'
import { getListeComptesNomenclatureCharges } from '../api/comptesNomenclatureChargesAPI'

const ListeComptesNomenclatureCharges = () => {
	const {
		comptesNomenclatureCharges,
		setComptesNomenclatureCharges,
		chargementComptesNomenclatureCharges,
		setOuverturePopin,
		setPopinSuppression,
		filtresCNC,
		setFiltresCNC
	} = useContext(ComptesNomenclatureChargesContext)

	const modifierCNC = (compteNomenclatureCharges: ComptesNomenclatureChargesModel) => setOuverturePopin({
		open: true,
		compteNomenclatureCharges: compteNomenclatureCharges
	})

	const suppressionCNC = (comptesNomenclatureCharges: ComptesNomenclatureChargesModel) => setPopinSuppression({
		open: true,
		compteNomenclatureCharges: comptesNomenclatureCharges
	})

	const entetesTableau: HeadRow[] = useMemo(() => (
		[
			{
				label: ENTETE_TABLEAU_LISTE_CNC_ENUM.compte,
				id: getKeyFromEnumValue<ENTETE_TABLEAU_LISTE_CNC_ENUM>(ENTETE_TABLEAU_LISTE_CNC_ENUM, ENTETE_TABLEAU_LISTE_CNC_ENUM.compte)
			},
			{
				label: ENTETE_TABLEAU_LISTE_CNC_ENUM.tencode,
				id: getKeyFromEnumValue<ENTETE_TABLEAU_LISTE_CNC_ENUM>(ENTETE_TABLEAU_LISTE_CNC_ENUM, ENTETE_TABLEAU_LISTE_CNC_ENUM.tencode)
			},
			{
				label: ENTETE_TABLEAU_LISTE_CNC_ENUM.codeposte,
				id: getKeyFromEnumValue<ENTETE_TABLEAU_LISTE_CNC_ENUM>(ENTETE_TABLEAU_LISTE_CNC_ENUM, ENTETE_TABLEAU_LISTE_CNC_ENUM.codeposte)
			},
			{
				label: '',
				id: columnsEnum.customColonne,
				width: '15%',
				render: (ligneTableau: ComptesNomenclatureChargesModel) => (
					<Box
						className="elementCache"
						sx={{
							visibility: 'hidden',
							textAlign: 'right',

							'& svg': {
								color: gecinaBrownMain
							}
						}}
					>
						<IconButton aria-label="edit" title="Modification du compte" onClick={() => modifierCNC(ligneTableau)}>
							<EditIcon />
						</IconButton>

						<IconButton aria-label="delete" title="Suppression du compte" onClick={() => suppressionCNC(ligneTableau)}>
							<DeleteIcon />
						</IconButton>

					</Box>
				)
			}
		]
	), [])

	const auChangementDePage = ((nouvellePage: number) => {
		const data = {
			...filtresCNC,
			[FILTRES_COMPTES_NOMENCLATURE_CHARGES.PAGE]: nouvellePage
		}

		getListeComptesNomenclatureCharges(nouvellePage, filtresCNC[FILTRES_COMPTES_NOMENCLATURE_CHARGES.COMPTE])
			.then((response) => {
				setComptesNomenclatureCharges(response)
				setFiltresCNC(data)
			})
			.finally(() => window.scrollTo(0, 0))
	})

	if (chargementComptesNomenclatureCharges) {
		return (
			<Box height="150px">
				<Loader size={50} />
			</Box>
		)
	}

	return (
		<Box sx={{ paddingX: '172px' }}>
			<CustomTable
				id="listeProcedures"
				headRows={entetesTableau}
				rows={remplissageLigneSelonEnteteTableau<ComptesNomenclatureChargesModel>(comptesNomenclatureCharges['hydra:member'], entetesTableau)}
				total={comptesNomenclatureCharges['hydra:totalItems']}
				borderBottom
				auCliqueSurLigne={() => {
				}}
				hasPagination
				auChangementDePage={auChangementDePage}
				page={filtresCNC[FILTRES_COMPTES_NOMENCLATURE_CHARGES.PAGE] - 1}
				hasSort={false}
			/>
		</Box>
	)
}

export default ListeComptesNomenclatureCharges