import { createContext, useState } from 'react'
import { ListeTaxesContextModel, INITIAL_VALEURS_FORMULAIRE, FiltresListeTaxeModel, TAXE_LISTE_VIDE, ListeTaxesPageModel } from '../types/filtresListeTaxeModel'

export const ListeTaxesContext=createContext<ListeTaxesContextModel>({
	filtres: INITIAL_VALEURS_FORMULAIRE,
	setFiltres: () => {},
	annees: [],
	setAnnees: () => {},
	listePaginee: TAXE_LISTE_VIDE,
	setListePaginee: () => {}
})

export const ListeTaxesProvider: React.FC = ({ children }) => {
	const [filtres,setFiltres] = useState<FiltresListeTaxeModel>(INITIAL_VALEURS_FORMULAIRE)
	const [annees,setAnnees] = useState<number[]>([])
	const [listePaginee,setListePaginee] = useState<ListeTaxesPageModel>(TAXE_LISTE_VIDE)

	return (
		<ListeTaxesContext.Provider
			value={{
				filtres,
				setFiltres,
				annees,
				setAnnees,
				listePaginee,
				setListePaginee
			}}
		>
			{children}
		</ListeTaxesContext.Provider>
	)
}