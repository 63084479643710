import React, { FC, useContext, useEffect, useMemo, useState } from 'react'
import { Grid, SelectChangeEvent } from '@mui/material'
import TabBar from '../../../components/tabs/TabBar'
import { APPLICATION_OPTIONS } from '../utils/menuConstants'
import Select from '../../../components/select/Select'
import { ApplicationOptionsEnum } from '../enums/ApplicationOptionsEnum'
import { AppOption } from '../types/MenuTypes'
import { useLocation, useNavigate } from 'react-router-dom'
import { AppContext } from '../../../containers/context/AppContext'
import liens from '../../../enum/liens'

const NavBar: FC = () => {
	const navigate = useNavigate()
	const location = useLocation()
	const { userInfos } = useContext(AppContext)
	const filteredAppOptions = APPLICATION_OPTIONS.filter((item) => userInfos.application.includes(item.value))

	if (filteredAppOptions.length === 0) {
		navigate(liens.nonAutorise)
		return <></>
	}

	// La première application est sélectionnée par défaut
	const [app, setApp] = useState<AppOption>(filteredAppOptions[0])

	// Sélectionne l'app, en fonction de l'url
	useEffect(() => {
		const selectedApp = APPLICATION_OPTIONS.find((appOption) => appOption.tabs.find((tab) => location.pathname.includes(tab.lien)))
		// Si on est sur une page qui n'est pas dans notre application, on redirige l'utilisateur sur la page adéquat
		if (selectedApp && filteredAppOptions.find((filteredAppOption) => filteredAppOption.value === selectedApp.value)) {
			setApp(selectedApp)
		} else {
			navigate(app.tabs[0].lien)
		}
	}, [location])

	const onSelectChange = (event: SelectChangeEvent<unknown>) => {
		const option = filteredAppOptions.find((appOption) => appOption.value === event.target.value)
		if (!!option) {
			setApp(option)
			// Redirection sur le premier onglet
			navigate(option.tabs[0].lien, { state: { rafraichir: true } })
		}
	}

	return (
		<Grid container item spacing="80px" alignItems="center">
			<Grid item>
				<Select options={filteredAppOptions} value={app.value} onChange={onSelectChange} />
			</Grid>
			<Grid container item xs>
				<TabBar tabs={app.tabs} />
			</Grid>
		</Grid>
	)
}

export default NavBar