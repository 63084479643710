import { detailAvisFormEnum, detailAvisPostEnum, detailAvisResponseEnum, enteteTableauAffichageImmeubleLabelEnum, immeubleExterneEnum } from '../enums/detailAvisEnum'

export interface DetailAvisPostModel {
	[detailAvisPostEnum.fichier]: string,
	[detailAvisPostEnum.anneeRedition]: number
}

export interface DetailAvisResponseModel {
	[detailAvisResponseEnum.id]: number,
	[detailAvisResponseEnum.fraisGestion]: string,
	[detailAvisResponseEnum.montantAPayer]: string,
	[detailAvisResponseEnum.immeubles]: DetailAvisImmeubleModel[]
	[detailAvisResponseEnum.tauxTaxes]: tauxTaxesModel
}

export interface tauxTaxesModel {
	[detailAvisResponseEnum.annee]: number,
	[detailAvisResponseEnum.departement]: number,
	[detailAvisResponseEnum.commune]: number,
	[detailAvisResponseEnum.gemapi]: number,
	[detailAvisResponseEnum.repartitionGpTaxeSpeciale]: number,
	[detailAvisResponseEnum.repartitionGpTaxeSpecialeGp]: number,
	[detailAvisResponseEnum.syndicatCommune]: number,
	[detailAvisResponseEnum.taxeSpeciale]: number,
	[detailAvisResponseEnum.taxeSpecialeGp]: number,
	[detailAvisResponseEnum.teom]: number,
}

export interface DetailAvisImmeubleModel {
	[detailAvisResponseEnum.adresse]: string,
	[detailAvisResponseEnum.totalCotisation]: string,
	[detailAvisFormEnum.ajustementFrais]: string,
	[detailAvisResponseEnum.communeCotisation]: string | number,
	[detailAvisResponseEnum.interCommCotisation]: string | number,
	[detailAvisResponseEnum.departementCotisation]: string | number,
	[detailAvisResponseEnum.taxeSpecialeCotisation]: string | number,
	[detailAvisResponseEnum.syndicatCotisation]: string | number,
	[detailAvisResponseEnum.teomCotisation]: string | number,
	[detailAvisResponseEnum.gemapiCotisation]: string | number,
}

export interface TraitementAvisModel {
	[detailAvisResponseEnum.id]: number
	[detailAvisResponseEnum.fraisGestion]: string,
	[detailAvisResponseEnum.montantAPayer]: string,
	grandParis: boolean,
	[detailAvisResponseEnum.immeubles]: TraitementAvisImmeubleModel[],
	[detailAvisResponseEnum.tauxTaxes]: tauxTaxesModel,
	[detailAvisResponseEnum.communeCotisation]: LigneImmeubleModel,
	[detailAvisResponseEnum.frais]: LigneImmeubleModel,
	[detailAvisResponseEnum.totalLigne]: LigneImmeubleModel,

}

export const TRAITEMENT_IMMEUBLE_VIDE: TraitementAvisImmeubleModel = {
	[detailAvisResponseEnum.adresse]: 'Immeuble complémentaire',
	[detailAvisResponseEnum.immeubleExterne]: {
		[immeubleExterneEnum.id]: 0,
		[immeubleExterneEnum.reference]: '',
		[immeubleExterneEnum.adresse]: '',
		[immeubleExterneEnum.ville]: ''
	},
	[detailAvisResponseEnum.totalCotisation]: '0',
	[detailAvisFormEnum.ajustementFrais]: '0',
	[detailAvisResponseEnum.totalLigne]: {
		[detailAvisResponseEnum.type]: detailAvisResponseEnum.totalLigne,
		[detailAvisResponseEnum.caseVide]: enteteTableauAffichageImmeubleLabelEnum.total,
		[detailAvisResponseEnum.communeCotisation]: 0,
		[detailAvisResponseEnum.interCommCotisation]: 0,
		[detailAvisResponseEnum.departementCotisation]: 0,
		[detailAvisResponseEnum.taxeSpecialeCotisation]: 0,
		[detailAvisResponseEnum.syndicatCotisation]: 0,
		[detailAvisResponseEnum.teomCotisation]: 0,
		[detailAvisResponseEnum.gemapiCotisation]: 0,
		[detailAvisResponseEnum.total]: 0
	},
	[detailAvisResponseEnum.frais]: {
		[detailAvisResponseEnum.type]: detailAvisResponseEnum.frais,
		[detailAvisResponseEnum.caseVide]: enteteTableauAffichageImmeubleLabelEnum.frais,
		[detailAvisResponseEnum.communeCotisation]: '0',
		[detailAvisResponseEnum.interCommCotisation]: 0,
		[detailAvisResponseEnum.departementCotisation]: '0',
		[detailAvisResponseEnum.taxeSpecialeCotisation]: '0',
		[detailAvisResponseEnum.syndicatCotisation]: '0',
		[detailAvisResponseEnum.teomCotisation]: '0',
		[detailAvisResponseEnum.gemapiCotisation]: '0',
		[detailAvisResponseEnum.total]: 0
	},
	[detailAvisResponseEnum.communeCotisation]: {
		[detailAvisResponseEnum.type]: detailAvisResponseEnum.communeCotisation,
		[detailAvisResponseEnum.caseVide]: enteteTableauAffichageImmeubleLabelEnum.cotisation,
		[detailAvisResponseEnum.communeCotisation]: '0',
		[detailAvisResponseEnum.interCommCotisation]: 0,
		[detailAvisResponseEnum.departementCotisation]: 0,
		[detailAvisResponseEnum.taxeSpecialeCotisation]: '0',
		[detailAvisResponseEnum.syndicatCotisation]: 0,
		[detailAvisResponseEnum.teomCotisation]: '0',
		[detailAvisResponseEnum.gemapiCotisation]: '0',
		[detailAvisResponseEnum.total]: 0
	}

}
export const INITAL_TRAITEMENT_AVIS: TraitementAvisModel = {
	[detailAvisResponseEnum.id]: 0,
	[detailAvisResponseEnum.fraisGestion]: '0',
	[detailAvisResponseEnum.montantAPayer]: '0',
	grandParis: false,
	[detailAvisResponseEnum.immeubles]: [],
	[detailAvisResponseEnum.tauxTaxes]: {
		[detailAvisResponseEnum.annee]: 0,
		[detailAvisResponseEnum.departement]: 0,
		[detailAvisResponseEnum.commune]: 0,
		[detailAvisResponseEnum.gemapi]: 0,
		[detailAvisResponseEnum.repartitionGpTaxeSpeciale]: 0,
		[detailAvisResponseEnum.repartitionGpTaxeSpecialeGp]: 0,
		[detailAvisResponseEnum.syndicatCommune]: 0,
		[detailAvisResponseEnum.taxeSpeciale]: 0,
		[detailAvisResponseEnum.taxeSpecialeGp]: 0,
		[detailAvisResponseEnum.teom]: 0
	},
	[detailAvisResponseEnum.communeCotisation]: {
		[detailAvisResponseEnum.type]: '',
		[detailAvisResponseEnum.caseVide]: '',
		[detailAvisResponseEnum.communeCotisation]: '',
		[detailAvisResponseEnum.interCommCotisation]: '',
		[detailAvisResponseEnum.departementCotisation]: '',
		[detailAvisResponseEnum.taxeSpecialeCotisation]: '',
		[detailAvisResponseEnum.syndicatCotisation]: '',
		[detailAvisResponseEnum.teomCotisation]: '',
		[detailAvisResponseEnum.gemapiCotisation]: '',
		[detailAvisResponseEnum.total]: ''
	},
	[detailAvisResponseEnum.frais]: {
		[detailAvisResponseEnum.type]: '',
		[detailAvisResponseEnum.caseVide]: '',
		[detailAvisResponseEnum.communeCotisation]: '',
		[detailAvisResponseEnum.interCommCotisation]: '',
		[detailAvisResponseEnum.departementCotisation]: '',
		[detailAvisResponseEnum.taxeSpecialeCotisation]: '',
		[detailAvisResponseEnum.syndicatCotisation]: '',
		[detailAvisResponseEnum.teomCotisation]: '',
		[detailAvisResponseEnum.gemapiCotisation]: '',
		[detailAvisResponseEnum.total]: ''
	},
	[detailAvisResponseEnum.totalLigne]: {
		[detailAvisResponseEnum.type]: '',
		[detailAvisResponseEnum.caseVide]: '',
		[detailAvisResponseEnum.communeCotisation]: '',
		[detailAvisResponseEnum.interCommCotisation]: '',
		[detailAvisResponseEnum.departementCotisation]: '',
		[detailAvisResponseEnum.taxeSpecialeCotisation]: '',
		[detailAvisResponseEnum.syndicatCotisation]: '',
		[detailAvisResponseEnum.teomCotisation]: '',
		[detailAvisResponseEnum.gemapiCotisation]: '',
		[detailAvisResponseEnum.total]: ''
	}
}

export interface TraitementAvisContextModel {
	setFormValues: (formValues: TraitementAvisModel) => void
	formValues: TraitementAvisModel
}

export interface TraitementAvisImmeubleModel {
	[detailAvisResponseEnum.immeubleExterne]: ImmeubleExterneModel
	[detailAvisResponseEnum.adresse]: string,
	[detailAvisResponseEnum.totalCotisation]: string,
	[detailAvisFormEnum.ajustementFrais]: string,
	[detailAvisResponseEnum.communeCotisation]: LigneImmeubleModel,
	[detailAvisResponseEnum.frais]: LigneImmeubleModel,
	[detailAvisResponseEnum.totalLigne]: LigneImmeubleModel
}

export interface LigneImmeubleModel {
	[detailAvisResponseEnum.type]: string,
	[detailAvisResponseEnum.caseVide]: string,
	[detailAvisResponseEnum.communeCotisation]: string | number,
	[detailAvisResponseEnum.interCommCotisation]: string | number,
	[detailAvisResponseEnum.departementCotisation]: string | number,
	[detailAvisResponseEnum.taxeSpecialeCotisation]: string | number,
	[detailAvisResponseEnum.syndicatCotisation]: string | number,
	[detailAvisResponseEnum.teomCotisation]: string | number,
	[detailAvisResponseEnum.gemapiCotisation]: string | number,
	[detailAvisResponseEnum.total]: string | number
}

export interface ImmeubleExterneModel {
	[immeubleExterneEnum.id]: number,
	[immeubleExterneEnum.reference]: string,
	[immeubleExterneEnum.adresse]: string,
	[immeubleExterneEnum.ville]: string
}