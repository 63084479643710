import { styled } from '@mui/system'
import { gecinaBlueMain, gecinaBrownMain } from '../../theme'
import { Menu, menuClasses, menuItemClasses, paperClasses } from '@mui/material'
import styles from '../../styles'

interface StyledItemTabBarProps {
	selected: boolean
}

export const StyledItemTabBar = styled('div')<StyledItemTabBarProps>(({ selected }) => ({
	color: gecinaBlueMain,
	height: 56,
	position: 'relative',
	minWidth: 200,
	textAlign: 'center',
	'&:hover': {
		cursor: 'pointer'
	},
	...(selected && {
		color: gecinaBrownMain,
		'&::after': {
			bottom: 0,
			width: '100%',
			height: 4,
			content: '\' \'',
			display: 'inline-block',
			background: gecinaBrownMain,
			position: 'absolute',
			right: 0
		}
	})
}))

export const StyledMenu = styled(Menu)(({ theme }) => ({
	[`& .${paperClasses.root}`]: {
		borderRadius: 0,
		boxShadow: '-2px -2px 20px rgba(0, 0, 0, 0.25)',
		[`& .${menuClasses.list}`]: {
			padding: '15px 0'
		},
		[`& .${menuItemClasses.root}`]: {
			...styles(theme).text8,
			lineHeight: '17px',
			padding: '15px 20px',
			'&:hover': {
				...styles(theme).backgroundGrey,
				color: gecinaBlueMain
			}
		}
	}
}))