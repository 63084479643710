import React from 'react'
import { ComptesTauxRecuperabiliteSouspostesProvider } from './context/ComptesTauxRecuperabiliteSouspostesContext'
import EnteteComptesTauxRecuperabiliteSouspostes from './components/EnteteComptesTauxRecuperabiliteSouspostes'
import ListeComptesTauxRecuperabiliteSouspostes from './components/ListeComptesTauxRecuperabiliteSouspostes'
import PopinGestionComptesTauxRecuperabiliteSouspostes from './components/PopinGestionComptesTauxRecuperabiliteSouspostes'

const ComptesTauxRecuperabiliteSouspostes = () => (
	<ComptesTauxRecuperabiliteSouspostesProvider>
		<EnteteComptesTauxRecuperabiliteSouspostes />

		<ListeComptesTauxRecuperabiliteSouspostes />

		<PopinGestionComptesTauxRecuperabiliteSouspostes />
	</ComptesTauxRecuperabiliteSouspostesProvider>
)

export default ComptesTauxRecuperabiliteSouspostes