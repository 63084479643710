import axios from 'axios'
import { CommunApiPlatformModel } from '../../../types/communApiPlatformModel'
import { ComptesRecuperablesNonRecuperablesModel } from '../types/comptesRecuperablesNonRecuperablesModel'

export const getListeComptesRecuperablesNonRecuperables = (page: number, compte?: string): Promise<CommunApiPlatformModel<ComptesRecuperablesNonRecuperablesModel[]>> => axios.get(`/api/comptes_recuperables_non_recuperables?page=${page}${compte ? `&compte=${compte}` : ''}`)
	.then((response) => response.data)

export const postComptesRecuperablesNonRecuperables = (data: ComptesRecuperablesNonRecuperablesModel): Promise<ComptesRecuperablesNonRecuperablesModel> => axios.post('/api/comptes_recuperables_non_recuperables', data)
	.then((response) => response.data)

export const putComptesRecuperablesNonRecuperables = (data: ComptesRecuperablesNonRecuperablesModel): Promise<ComptesRecuperablesNonRecuperablesModel> => axios.put('/api/updateComptesRecuperablesNonRecuperables', data)
	.then((response) => response.data)

export const deleteComptesRecuperablesNonRecuperables = (data: ComptesRecuperablesNonRecuperablesModel): Promise<ComptesRecuperablesNonRecuperablesModel> => axios.delete(`/api/comptes_recuperables_non_recuperables/${data['@id']!.split('/api/comptes_recuperables_non_recuperables/')[1]}`)
	.then((response) => response.data)