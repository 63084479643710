import React from 'react'
import { styled, Select as MuiSelect, Input, MenuItem, SelectChangeEvent } from '@mui/material'
import { SelectOption } from './SelectTypes'
import { fontFamilyMonserrat, gecinaBlueMain } from '../../theme'
import styles from '../../styles'
import { makeStyles } from 'tss-react/mui'

interface SelectProps {
	options: SelectOption[]
	value: any
	onChange: (event: SelectChangeEvent<unknown>) => void
}

const Select: React.FC<SelectProps> = (
	{
		options,
		value,
		onChange
	}) => {
	const { classes } = useStyles()
	return (
		<CustomSelect
			input={<Input disableUnderline={true} />}
			value={value}
			onChange={onChange}
			MenuProps={{ classes: { paper: classes.dropdownStyle } }}
		>
			{options.map((option, key) => (<CustomMenuItem key={`option-${key}-${option.value}`} value={option.value}>{option.label}</CustomMenuItem>))}
		</CustomSelect>
	)
}

const CustomSelect = styled(MuiSelect)({
	fontFamily: fontFamilyMonserrat,
	fontWeight: 700,
	fontSize: 18,
	lineHeight: '22px'
})

const CustomMenuItem = styled(MenuItem)(({ theme }) => ({
	...styles(theme).text8,
	lineHeight: '17px',
	padding: '15px 20px',
	'&:hover': {
		...styles(theme).backgroundGrey,
		color: gecinaBlueMain
	}
}))

const useStyles = makeStyles()({
	dropdownStyle: {
		borderRadius: 0,
		boxShadow: '-2px -2px 20px rgba(0, 0, 0, 0.25)'
	}
})

export default Select