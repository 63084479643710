import axios, { AxiosResponse } from 'axios'
import { FetchTokenModel } from '../types/fetchTokenModel'
import fetchMethodEnum from '../../enum/fetchMethodEnum'
import localStorageEnum from '../../enum/localstorageEnum'
import liens from '../../enum/liens'
import { Dispatch, SetStateAction } from 'react'
import { UserModel } from '../types/UserModel'

export const fetchToken = (setUserInfo: Dispatch<SetStateAction<UserModel>>): Promise<FetchTokenModel> =>
	axios(liens.token)
		.then((res) => {
			localStorage.setItem(localStorageEnum.accessToken, res.data.token)
			localStorage.setItem(localStorageEnum.informationsUtilisateur, JSON.stringify(res.data))
			setUserInfo(res.data)
			return res.data
		})
		.catch((e) => {
			console.error('e', e)
			console.error('response', e.response)

			if (e.response && e.response.status === 401) {
				window.location.pathname = liens.nonAutorise
			} else {
				fetchValidation(setUserInfo)
			}
		})

export const fetchValidation = (setUserInfo: Dispatch<SetStateAction<UserModel>>): Promise<AxiosResponse<void> | void> =>
	axios({ method: fetchMethodEnum.post, url: liens.validate })
		.then((res) => {
			if (!res.data) {
				localStorage.removeItem(localStorageEnum.accessToken)
				window.location.pathname = liens.login
			}

			const prevUserInfos: UserModel = JSON.parse(localStorage.getItem(localStorageEnum.informationsUtilisateur) || '')

			setUserInfo({
				...prevUserInfos,
				...res.data
			})
		})
		.catch((e) => {
			console.error('Error validate', e)
			localStorage.removeItem(localStorageEnum.accessToken)
			window.location.pathname = liens.login
		})

