import React, { useContext, useMemo } from 'react'
import { ListeTaxesContext } from '../context/ListeTaxesContext'
import { Box } from '@mui/material'
import CustomTable from '../../../components/table/Table'
import { remplissageLigneSelonEnteteTableau } from '../../../components/table/utils/tableUtils'
import { getKeyFromEnumValue } from '../../../utils/utils'
import { HeadRow } from '../../../components/table/types/tableModel'
import { tableTypeEnum } from '../../../components/table/enums/tableTypeEnum'
import { getListeTaxes } from '../api/listeTaxesApi'
import { enteteTableauAffichageListeEnum, FiltresListeTaxesEnum } from '../enums/listeTaxesEnum'
import { columnsEnum } from '../../../components/table/enums/columnsEnum'
import { LigneImmeubleModel } from '../../traitementAvis/types/detailAvisModel'
import { detailAvisResponseEnum } from '../../traitementAvis/enums/detailAvisEnum'
import CelluleTableauTraitementAvis from '../../traitementAvis/components/CelluleTableauTraitementAvis'
import CustomTextField from '../../../components/fields/CustomTextField'
import thousandsSeparator from '../../../utils/thousandsSeparator'
import FormNumberFormatField from '../../../components/fields/FormNumberFormatField'
import { style, styleFrais } from '../../traitementAvis/components/TableauTraitementImmeuble'
import { ListeTaxesModel } from '../types/filtresListeTaxeModel'
import { filtresListeAvisResponseEnum, statutListeAvisEnum } from '../../listeAvis/enums/listeAvisEnum'
import Tooltip from '../../../components/tooltip/Tooltip'

const AffichageListe = () => {

	const { filtres, setFiltres, annees, listePaginee, setListePaginee } = useContext(ListeTaxesContext)
	const [tooltipOpen, setTooltipOpen] = React.useState<{ index: number, open: boolean }>({ index: 0, open: false })

	const entetesTableau: HeadRow[] =
		[
			{
				label: enteteTableauAffichageListeEnum.annee_reddition,
				id: getKeyFromEnumValue<enteteTableauAffichageListeEnum>(enteteTableauAffichageListeEnum, enteteTableauAffichageListeEnum.annee_reddition)
			},
			{
				label: enteteTableauAffichageListeEnum.immeuble,
				id: getKeyFromEnumValue<enteteTableauAffichageListeEnum>(enteteTableauAffichageListeEnum, enteteTableauAffichageListeEnum.immeuble)
			},
			{
				label: enteteTableauAffichageListeEnum.referenceAvis,
				id: getKeyFromEnumValue<enteteTableauAffichageListeEnum>(enteteTableauAffichageListeEnum, enteteTableauAffichageListeEnum.referenceAvis)
			},
			{
				label: enteteTableauAffichageListeEnum.total,
				id: getKeyFromEnumValue<enteteTableauAffichageListeEnum>(enteteTableauAffichageListeEnum, enteteTableauAffichageListeEnum.total),
				render: (total: number) => {
					if (total !== null) {
						return <>{thousandsSeparator(total)} € HT</>
					}

					return <></>
				}
			},
			{
				label: enteteTableauAffichageListeEnum.statut,
				id: getKeyFromEnumValue<enteteTableauAffichageListeEnum>(enteteTableauAffichageListeEnum, enteteTableauAffichageListeEnum.statut)
			},
			{
				label: enteteTableauAffichageListeEnum.date,
				id: columnsEnum.customColonne,
				render: (ligneTableau: ListeTaxesModel) => {
					return (
						<Box
							sx={{
								'& .MuiTooltip-popper': {
									width: 'max-content'
								},
								'& .conteneurTooltip': {
									visibility: 'hidden'
								},
								'&:hover .conteneurTooltip': {
									visibility: 'visible'
								}
							}}
						>
							{ligneTableau.date}
							<Box className="conteneurTooltip">
								{
									ligneTableau.date !== null && <Tooltip
										title={
											<>
												<span>Date de validation : {ligneTableau.date_validation}</span>
												<br />
												<span>Date de génération : {ligneTableau.date_generation}</span>
												<br />
												<span>Date de transmission : {ligneTableau.date_transmission}</span>
											</>
										}
										open
									/>
								}
							</Box>
						</Box>
					)
				}
			},
			{
				label: enteteTableauAffichageListeEnum.n_depense,
				id: getKeyFromEnumValue<enteteTableauAffichageListeEnum>(enteteTableauAffichageListeEnum, enteteTableauAffichageListeEnum.n_depense)
			}
		]

	const auChangementDePage = (
		(nouvellePage: number) => {
			const data = {
				...filtres,
				[FiltresListeTaxesEnum.page]: nouvellePage
			}

			getListeTaxes(data)
				.then((response) => {
					setListePaginee(response)
					setFiltres(data)
				})
				.finally(() => window.scrollTo(0, 0))
		}
	)

	return (
		<Box sx={{ paddingX: '172px' }}>
			<CustomTable
				id="listeProcedures"
				headRows={entetesTableau}
				rows={remplissageLigneSelonEnteteTableau<ListeTaxesModel>(listePaginee.list, entetesTableau)}
				total={listePaginee.total}
				auChangementDePage={auChangementDePage}
				page={(listePaginee[FiltresListeTaxesEnum.page].current ?? 1) - 1}
				hasSort={false}
				borderBottom
			/>
		</Box>
	)
}

export default AffichageListe