import { FILTRES_COMPTES_RECUPERABLES_NON_RECUPERABLES } from '../enums/filtresComptesRecuperablesNonRecuperablesEnum'

export interface ComptesRecuperablesNonRecuperablesModel {
	'@id': string | null,
	compte: string,
	compteRecuperable: string,
	compteNonRecuperable: string,
	rubriqueAnalytique: string,
	dateDebut: Date,
	dateFin: Date | null,
	nomModificateur: string,
	idModificateur: string
}

export interface FiltresComptesRecuperablesNonRecuperablesModel {
	[FILTRES_COMPTES_RECUPERABLES_NON_RECUPERABLES.COMPTE]: string
	[FILTRES_COMPTES_RECUPERABLES_NON_RECUPERABLES.PAGE]: number
}

export interface PopinComptesRecuperablesNonRecuperablesModel {
	open: boolean,
	comptesRecuperablesNonRecuperables: ComptesRecuperablesNonRecuperablesModel | undefined
}

export const INITIAL_COMPTES_RECUPERABLES_NON_RECUPERABLES: ComptesRecuperablesNonRecuperablesModel = {
	'@id': '',
	compte: '',
	compteRecuperable: '',
	compteNonRecuperable: '',
	rubriqueAnalytique: '',
	dateDebut: new Date(),
	dateFin: null,
	nomModificateur: '',
	idModificateur: ''
}

export const INITIAL_POPIN_CRNR: PopinComptesRecuperablesNonRecuperablesModel = {
	open: false,
	comptesRecuperablesNonRecuperables: INITIAL_COMPTES_RECUPERABLES_NON_RECUPERABLES
}

export const INITIAL_FILTRE_CRNR: FiltresComptesRecuperablesNonRecuperablesModel = {
	[FILTRES_COMPTES_RECUPERABLES_NON_RECUPERABLES.COMPTE]: '',
	[FILTRES_COMPTES_RECUPERABLES_NON_RECUPERABLES.PAGE]: 1
}