export interface TauxResponseModel {
	id: number | null,
	annee: number | null,
	commune: number | null,
	departement: number | null,
	taxe_speciale_affichage: string | null
	taxe_speciale_gp_affichage: string | null
	taxe_speciale: number | null,
	taxe_speciale_gp: number | null,
	repartition_gp_taxe_speciale: number | null,
	repartition_gp_taxe_speciale_gp: number | null,
	syndicat_commune: number | null,
	teom: number | null,
	gemapi: number | null
}

export interface PaginationTauxResponseModel {
	current: number,
	last: number
}

export interface ResponseType {
	status: number,
	data: string
}

export interface TauxResponsePageModel {
	list: TauxResponseModel[],
	total: number,
	page: PaginationTauxResponseModel;
}

export interface TauxPopinStateModel{
	open:boolean,
	taux?:TauxResponseModel
}

export interface TauxListData {
	page: number
}

export const INITIAL_TAUX_PAGE_LIST: TauxResponsePageModel = {
	list: [],
	total: 0,
	page: {
		current: 1,
		last: 0
	}
}
export const EMPTY_TAUX: TauxResponseModel = {
	id: null,
	annee: null,
	commune: null,
	departement: null,
	taxe_speciale: null,
	taxe_speciale_gp: null,
	repartition_gp_taxe_speciale: null,
	repartition_gp_taxe_speciale_gp: null,
	syndicat_commune: null,
	teom: null,
	gemapi: null,
	taxe_speciale_affichage:null,
	taxe_speciale_gp_affichage:null
}