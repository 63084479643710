import { Grid } from '@mui/material'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import TextField from '../../../components/fields/FormTextField'
import { buttonTypesEnum } from '../../../components/button/enums/buttonTypesEnum'
import { fontFamilyMonserrat, gecinaBrownMain, WHITE } from '../../../theme'
import Button from '../../../components/button/Button'
import AutorenewIcon from '@mui/icons-material/Autorenew'
import React, { useContext } from 'react'
import { FILTRES_COMPTES_RECUPERABLES_NON_RECUPERABLES } from '../enums/filtresComptesRecuperablesNonRecuperablesEnum'
import { ComptesRecuperablesNonRecuperablesContext } from '../context/ComptesRecuperablesNonRecuperablesContext'
import { FiltresComptesRecuperablesNonRecuperablesModel } from '../types/comptesRecuperablesNonRecuperablesModel'
import { getListeComptesRecuperablesNonRecuperables } from '../api/comptesRecuperablesNonRecuperablesAPI'
import { SpanVide } from '../../comptesNomenclatureCharges/components/FiltresComptesNomenclatureCharges'

const INITIAL_VALEURS_FORMULAIRE = {
	[FILTRES_COMPTES_RECUPERABLES_NON_RECUPERABLES.COMPTE]: ''
}

const FiltresComptesRecuperablesNonRecuperables = () => {
	const { setChargementComptesRecuperablesNonRecuperables, setComptesRecuperablesNonRecuperables, setOuverturePopin, filtresCRNR, setFiltresCRNR } = useContext(ComptesRecuperablesNonRecuperablesContext)
	const methods = useForm<FiltresComptesRecuperablesNonRecuperablesModel>({
		defaultValues: INITIAL_VALEURS_FORMULAIRE
	})
	const { register, handleSubmit, reset } = methods

	const onSubmit: SubmitHandler<FiltresComptesRecuperablesNonRecuperablesModel> = (values) => {
		setChargementComptesRecuperablesNonRecuperables(true)
		const data = {
			...filtresCRNR,
			[FILTRES_COMPTES_RECUPERABLES_NON_RECUPERABLES.PAGE]: 1
		}
		setFiltresCRNR(data)

		// Récupération de la liste des comptes selon les valeurs du filtre
		return getListeComptesRecuperablesNonRecuperables(1, values.compte)
			.then((response) => {
				setComptesRecuperablesNonRecuperables(response)
				setChargementComptesRecuperablesNonRecuperables(false)
			})
	}

	const ajoutCompte = () => setOuverturePopin({ open: true, comptesRecuperablesNonRecuperables: undefined })

	return (
		<FormProvider {...methods}>
			<form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
				<Grid container spacing={2}>
					<Grid item xs={3}>
						<TextField
							register={register(FILTRES_COMPTES_RECUPERABLES_NON_RECUPERABLES.COMPTE, {
								maxLength: {
									value: 20,
									message: 'Le compte est limité à 20 caractères'
								}
							})}
							maxLength={20}
							id={FILTRES_COMPTES_RECUPERABLES_NON_RECUPERABLES.COMPTE}
							label="Compte"
							placeholder="Saisir un n° de compte"
						/>
					</Grid>

					<Grid container item xs={3} spacing={2} sx={{ m: 0 }} alignContent="flex-start">
						<SpanVide> </SpanVide>

						<Grid item xs={7} sx={{ pt: '0px !important', height: 'fit-content' }}>
							<Button
								sx={{
									height: '32px',
									p: 0,
									minWidth: '100%',
									fontWeight: 600
								}}
								bgcolor={gecinaBrownMain}
								color={WHITE}
								border={false}
								disabled={false}
								type={buttonTypesEnum.submit}
							>
								Filtrer
							</Button>
						</Grid>

						<Grid item xs={3} md={2} sx={{ pt: '0px !important', height: 'fit-content' }}>
							<Button
								sx={{
									height: 32,
									p: 0,
									minWidth: '100%'
								}}
								bgcolor={gecinaBrownMain}
								color={WHITE}
								border={false}
								disabled={false}
								type={buttonTypesEnum.submit}
								onClick={() => reset(INITIAL_VALEURS_FORMULAIRE)}
							>
								<AutorenewIcon />
							</Button>
						</Grid>
					</Grid>

					<Grid container item xs={3} spacing={2} sx={{ m: 0, ml: 'auto' }} alignContent="flex-start">
						<SpanVide> </SpanVide>

						<Grid item xs={12} sx={{ pt: '0px !important', height: 'fit-content' }}>
							<Button
								sx={{
									height: '32px',
									p: 0,
									minWidth: '100%',
									fontWeight: 600
								}}
								bgcolor={gecinaBrownMain}
								color={WHITE}
								border={false}
								disabled={false}
								type={buttonTypesEnum.button}
								onClick={ajoutCompte}
							>
								Ajouter un compte
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</form>
		</FormProvider>
	)
}

export default FiltresComptesRecuperablesNonRecuperables