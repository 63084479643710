import React from 'react'
import { TraitementSGNFProvider } from './context/TraitementSGNFContext'
import EnteteTraitementSGNF from './components/EnteteTraitementSGNF'
import ListeTraitementSGNF from './components/ListeTraitementSGNF'
import PopinGestionTraitementSGNF from './components/PopinGestionTraitementSGNF'

const TraitementSGNF = () => (
	<TraitementSGNFProvider>
		<EnteteTraitementSGNF />

		<ListeTraitementSGNF />

		<PopinGestionTraitementSGNF />
	</TraitementSGNFProvider>
)

export default TraitementSGNF