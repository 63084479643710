import React, { createContext, Dispatch, SetStateAction, useEffect, useState } from 'react'
import { CommunApiPlatformModel, INITIAL_COMMUN_API } from '../../../types/communApiPlatformModel'
import { getListeNaturesRubriquesPaie } from '../api/naturesRubriquesPaieAPI'
import {
	NaturesRubriquesPaieModel,
	FiltresNaturesRubriquesPaieModel,
	INITIAL_FILTRE_NRP,
	INITIAL_POPIN_NRP,
	PopinNaturesRubriquesPaieModel
} from '../types/naturesRubriquesPaieModel'
import { FILTRES_NATURES_RUBRIQUES_PAIE } from '../enums/filtresNaturesRubriquesPaieEnum'

export interface NaturesRubriquesPaieContextProps {
	naturesRubriquesPaie: CommunApiPlatformModel<NaturesRubriquesPaieModel[]>,
	setNaturesRubriquesPaie: Dispatch<SetStateAction<CommunApiPlatformModel<NaturesRubriquesPaieModel[]>>>
	chargementNaturesRubriquesPaie: boolean,
	setChargementNaturesRubriquesPaie: Dispatch<SetStateAction<boolean>>,
	ouverturePopin: PopinNaturesRubriquesPaieModel,
	setOuverturePopin: Dispatch<SetStateAction<PopinNaturesRubriquesPaieModel>>,
	popinSuppression: PopinNaturesRubriquesPaieModel,
	setPopinSuppression: Dispatch<SetStateAction<PopinNaturesRubriquesPaieModel>>,
	filtresNRP: FiltresNaturesRubriquesPaieModel,
	setFiltresNRP: Dispatch<SetStateAction<FiltresNaturesRubriquesPaieModel>>
}

export const NaturesRubriquesPaieContext = createContext<NaturesRubriquesPaieContextProps>({
	naturesRubriquesPaie: INITIAL_COMMUN_API<NaturesRubriquesPaieModel[]>([]), // Partage des comptes récupérables / non récupérables aux éléments enfants
	setNaturesRubriquesPaie: () => {}, // Mise à jour des CRNR
	chargementNaturesRubriquesPaie: true, // Détermine si les CRNR sont en cours de chargement
	setChargementNaturesRubriquesPaie: () => {}, // Modification du statut de chargement
	ouverturePopin: INITIAL_POPIN_NRP, // Ouverture de la popin de création / modification d'un CRNR
	setOuverturePopin: () => {}, // Mise à jour du statut d'ouverture de la popin de création / modification
	popinSuppression: INITIAL_POPIN_NRP, // Ouverture de la popin de suppression d'un CRNR
	setPopinSuppression: () => {}, // Mise à jour du statut d'ouverture de la popin de suppression
	filtresNRP: INITIAL_FILTRE_NRP, // Partage du filtre sur les CRNR
	setFiltresNRP: () => {} // Mise à jour du filtre sur les CRNR
})

export const NaturesRubriquesPaieProvider: React.FC = (
	{
		children
	}
) => {
	const [naturesRubriquesPaie, setNaturesRubriquesPaie] = useState<CommunApiPlatformModel<NaturesRubriquesPaieModel[]>>(INITIAL_COMMUN_API<NaturesRubriquesPaieModel[]>([]))
	const [chargementNaturesRubriquesPaie, setChargementNaturesRubriquesPaie] = useState(true)
	const [ouverturePopin, setOuverturePopin] = useState<PopinNaturesRubriquesPaieModel>(INITIAL_POPIN_NRP)
	const [popinSuppression, setPopinSuppression] = useState<PopinNaturesRubriquesPaieModel>(INITIAL_POPIN_NRP)
	const [filtresNRP, setFiltresNRP] = useState<FiltresNaturesRubriquesPaieModel>(INITIAL_FILTRE_NRP)

	useEffect(
		() => {
			getListeNaturesRubriquesPaie(filtresNRP[FILTRES_NATURES_RUBRIQUES_PAIE.PAGE])
				.then((response) => {
					setNaturesRubriquesPaie(response)
					setChargementNaturesRubriquesPaie(false)
				})
		}, []
	)

	return (
		<NaturesRubriquesPaieContext.Provider
			value={{
				naturesRubriquesPaie,
				setNaturesRubriquesPaie,
				chargementNaturesRubriquesPaie,
				setChargementNaturesRubriquesPaie,
				ouverturePopin,
				setOuverturePopin,
				popinSuppression,
				setPopinSuppression,
				filtresNRP,
				setFiltresNRP
			}}
		>
			{children}
		</NaturesRubriquesPaieContext.Provider>
	)
}