export enum FILTRES_COMPTES_TAUX_RECUPERABILITE_SOUSPOSTES {
	ID = '@id',
	COMPTE = 'compte',
	TAUX_RECUPERABILITE_RESIDENTIEL = 'tauxRecupResidentiel',
	TAUX_RECUPERABILITE_BUREAU = 'tauxRecupBureau',
	CODE_SOUS_POSTE = 'codeSousPoste',
	DATE_DEBUT = 'dateDebut',
	DATE_FIN = 'dateFin',
	NOM_MODIFICATEUR = 'nomModificateur',
	ID_MODIFICATEUR = 'idModificateur',
	PAGE = 'page'
}

export const EMPTY_FORM = {
	[FILTRES_COMPTES_TAUX_RECUPERABILITE_SOUSPOSTES.ID]: null,
	[FILTRES_COMPTES_TAUX_RECUPERABILITE_SOUSPOSTES.COMPTE]: '',
	[FILTRES_COMPTES_TAUX_RECUPERABILITE_SOUSPOSTES.TAUX_RECUPERABILITE_RESIDENTIEL]: '',
	[FILTRES_COMPTES_TAUX_RECUPERABILITE_SOUSPOSTES.TAUX_RECUPERABILITE_BUREAU]: '100',
	[FILTRES_COMPTES_TAUX_RECUPERABILITE_SOUSPOSTES.CODE_SOUS_POSTE]: ''
}