import { Autocomplete, Grid, MenuItem } from '@mui/material'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import FormSelectField from '../../../components/fields/FormSelectField'
import { buttonTypesEnum } from '../../../components/button/enums/buttonTypesEnum'
import { fontFamilyMonserrat, gecinaBlueMain, gecinaBrownMain, WHITE } from '../../../theme'
import Button from '../../../components/button/Button'
import AutorenewIcon from '@mui/icons-material/Autorenew'
import { AutocompleteImmeubleModel, FiltresListeTaxeModel, INITIAL_VALEURS_FORMULAIRE } from '../types/filtresListeTaxeModel'
import React, { Dispatch, useContext, useEffect, useState } from 'react'
import { ListeTaxesContext } from '../context/ListeTaxesContext'
import { FiltresListeTaxesEnum, statutListeTaxesEnum } from '../enums/listeTaxesEnum'
import { getListeTaxes, listeImmeubles } from '../api/listeTaxesApi'
import FormTextField from '../../../components/fields/FormTextField'
import { CSVLink } from 'react-csv'
import { headersExportCSV } from '../enums/exportListe'
import thousandsSeparator from '../../../utils/thousandsSeparator'
import TextField from '../../../components/fields/FormTextField'

interface FiltresListeTaxesProps {
	setLoading: Dispatch<React.SetStateAction<boolean>>
}

const FiltresListeTaxes: React.FC<FiltresListeTaxesProps> = (
	{
		setLoading
	}
) => {
	const { filtres, setFiltres, annees, setListePaginee, listePaginee } = useContext(ListeTaxesContext)
	const [immeubles, setImmeubles] = useState<AutocompleteImmeubleModel[]>([])
	const methods = useForm<any>({
		defaultValues: { ...INITIAL_VALEURS_FORMULAIRE, [FiltresListeTaxesEnum.annee]: Math.max(...annees) }
	})
	const { register, handleSubmit, reset, getValues, watch, setValue } = methods


	const onSubmit: SubmitHandler<FiltresListeTaxeModel> = (values) => {
		setLoading(true)
		setFiltres(values)
		getListeTaxes(values).then(
			(response) => {
				setListePaginee(response)
				setLoading(false)
			}
		)
	}

	//Recupere les utilisateurs okta depuis le back
	const recuperationImmeuble = () => {
		// On lance la recherche à partir de 1 caractère dans le champ de recherche
		if (getValues(FiltresListeTaxesEnum.reference).length >= 1) {
			listeImmeubles(getValues(FiltresListeTaxesEnum.reference))
				.then((response) => {
					setImmeubles(response)
				})
		} else {
			//Sinon on vide la liste de choix et on cache les champs vide
			setImmeubles([])
		}
	}

	return (
		<FormProvider {...methods}>
			<form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
				<Grid container spacing={2}>
					<Grid item xs>
						<FormSelectField
							register={register(FiltresListeTaxesEnum.annee)}
							id={FiltresListeTaxesEnum.annee}
							label="Année de reddition"
							value={Math.max(...annees)}
						>
							{
								annees.map((annee) => (
									<MenuItem value={annee} key={annee}>
										{
											annee.toString()
										}
									</MenuItem>
								))
							}
						</FormSelectField>
					</Grid>

					<Grid item container xs flexDirection={'column'}>
						<Autocomplete
							id={FiltresListeTaxesEnum.reference}
							freeSolo
							sx={{ position: 'relative', height: 37 }}
							options={immeubles}
							// @ts-ignore TODO ts-ignore
							getOptionLabel={(option) => ('adresse' in option && 'ville' in option && `${option['reference']} - ${option['adresse']} - ${option['ville']} `) || ''}
							// @ts-ignore TODO ts-ignore
							onChange={(e, immeuble: AutocompleteImmeubleModel) => {
								setValue(FiltresListeTaxesEnum.reference, immeuble?.reference ?? '')
							}}
							renderInput={(params) =>
								<div ref={params.InputProps.ref}>
									<FormTextField
										register={register(FiltresListeTaxesEnum.reference)}
										label="Immeuble"
										{...params}
										inputProps={{
											...params.inputProps,
											value: watch(FiltresListeTaxesEnum.reference),
											onKeyUp: () => recuperationImmeuble()
										}}
										placeholder="Saisir une référence"
										sx={{ border: `1px solid ${gecinaBlueMain}` }}
									/>
								</div>
							}
						/>
					</Grid>

					<Grid item xs>
						<TextField
							register={register(FiltresListeTaxesEnum.avis, {
								maxLength: {
									value: 20,
									message: 'Le référence est limitée à 20 caractères'
								}
							})}
							maxLength={20}
							id={FiltresListeTaxesEnum.avis}
							label="Référence avis"
							placeholder="Saisir une référence d'avis"
						/>
					</Grid>

					<Grid item xs>
						<FormSelectField
							register={register(FiltresListeTaxesEnum.status)}
							id={FiltresListeTaxesEnum.status}
							label="Statut"
						>
							{
								Object.keys(statutListeTaxesEnum).map(
									(statut) => (
										<MenuItem value={statut} key={statut}>
											{statutListeTaxesEnum[statut as keyof typeof statutListeTaxesEnum]}
										</MenuItem>
									)
								)
							}
						</FormSelectField>
					</Grid>

					<Grid container item xs spacing={2} sx={{ m: 0 }} alignContent="flex-start">
						<span
							style={{
								width: '100%',
								paddingRight: 15,
								paddingTop: 7,
								fontSize: 14,
								marginLeft: 0,
								boxSizing: 'border-box',
								textAlign: 'left',
								fontFamily: fontFamilyMonserrat,
								fontWeight: 500
							}}
						> </span>

						<Grid item xs={5} sx={{ pt: '0px !important', height: 'fit-content' }}>
							<Button
								sx={{
									height: '32px',
									p: 0,
									minWidth: '100%',
									fontWeight: 600
								}}
								bgcolor={gecinaBrownMain}
								color={WHITE}
								border={false}
								disabled={false}
								type={buttonTypesEnum.submit}
							>
								Filtrer
							</Button>
						</Grid>

						<Grid item xs={2} sx={{ pt: '0px !important', height: 'fit-content' }}>
							<Button
								sx={{
									height: 32,
									p: 0,
									minWidth: '100%'
								}}
								bgcolor={gecinaBrownMain}
								color={WHITE}
								border={false}
								disabled={false}
								type={buttonTypesEnum.submit}
								onClick={() => reset()}
							>
								<AutorenewIcon />
							</Button>
						</Grid>

						<Grid item xs={5} sx={{ pt: '0px !important', height: 'fit-content' }}>
							<CSVLink
								filename="Export_taxes_foncieres.csv"
								separator=";"
								style={{
									height: 32,
									padding: 7,
									minWidth: 'fit-content',
									fontWeight: 600,
									backgroundColor: gecinaBrownMain,
									color: WHITE,
									fontSize: 15,
									lineHeight: 1,
									display: 'inline-block',
									boxSizing: 'border-box',
									textDecoration: 'none'
								}}
								data={listePaginee.list.map(
									(list) => ({
										reddition: list.annee_reddition,
										immeuble: list.immeuble,
										cotisations: thousandsSeparator(list.cotisations),
										frais: thousandsSeparator(list.frais),
										total: thousandsSeparator(list.total),
										referenceAvis: list.referenceAvis,
										statut: list.statut,
										dateValidation: list.date_validation ? list.date_validation.split(' ').length > 0 ? list.date_validation!.split(' ')[0] : list.date_validation : '',
										dateGeneration: list.date_generation ? list.date_generation.split(' ').length > 0 ? list.date_generation!.split(' ')[0] : list.date_generation : '',
										dateTransmission: list.date_transmission ? list.date_transmission.split(' ').length > 0 ? list.date_transmission!.split(' ')[0] : list.date_transmission : ''
									})
								)}
								headers={[
									{
										label: 'Reddition',
										key: 'reddition'
									},
									{
										label: 'Immeuble',
										key: 'immeuble'
									},
									{
										label: 'Cotisations',
										key: 'cotisations'
									},
									{
										label: 'Frais',
										key: 'frais'
									},
									{
										label: 'Total à payer',
										key: 'total'
									},
									{
										label: 'Référence de l\'avis',
										key: 'referenceAvis'
									},
									{
										label: 'Statut',
										key: 'statut'
									},
									{
										label: 'Date de validation',
										key: 'dateValidation'
									},
									{
										label: 'Date de génération',
										key: 'dateGeneration'
									},
									{
										label: 'Date de transmission',
										key: 'dateTransmission'
									}
								]}
							>
								Exporter
							</CSVLink>
						</Grid>
					</Grid>
				</Grid>
			</form>
		</FormProvider>
	)
}

export default FiltresListeTaxes