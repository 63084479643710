enum FetchMethodEnum {
	get = 'GET',
	post = 'POST',
	put = 'PUT',
	delete = 'DELETE'
}

export enum types {
	json = 'json',
	blob = 'blob',
	response = 'response'
}

export default FetchMethodEnum