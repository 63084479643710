import React, { useContext, useMemo } from 'react'
import { HeadRow } from '../../../components/table/types/tableModel'
import { getKeyFromEnumValue } from '../../../utils/utils'
import { Box, IconButton } from '@mui/material'
import { remplissageLigneSelonEnteteTableau } from '../../../components/table/utils/tableUtils'
import CustomTable from '../../../components/table/Table'
import { enteteTableauListeCRNREnum } from '../enums/enteteTableauListeCRNREnum'
import { ComptesRecuperablesNonRecuperablesModel } from '../types/comptesRecuperablesNonRecuperablesModel'
import { ComptesRecuperablesNonRecuperablesContext } from '../context/ComptesRecuperablesNonRecuperablesContext'
import Loader from '../../../components/loader/Loader'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { gecinaBrownMain } from '../../../theme'
import { columnsEnum } from '../../../components/table/enums/columnsEnum'
import { FILTRES_COMPTES_RECUPERABLES_NON_RECUPERABLES } from '../enums/filtresComptesRecuperablesNonRecuperablesEnum'
import { getListeComptesRecuperablesNonRecuperables } from '../api/comptesRecuperablesNonRecuperablesAPI'

const ListeComptesRecuperablesNonRecuperables = () => {
	const {
		comptesRecuperablesNonRecuperables,
		setComptesRecuperablesNonRecuperables,
		chargementComptesRecuperablesNonRecuperables,
		setOuverturePopin,
		setPopinSuppression,
		filtresCRNR,
		setFiltresCRNR
	} = useContext(ComptesRecuperablesNonRecuperablesContext)

	const modifierCRNR = (comptesRecuperablesNonRecuperables: ComptesRecuperablesNonRecuperablesModel) => setOuverturePopin({
		open: true,
		comptesRecuperablesNonRecuperables
	})

	const suppressionCRNR = (comptesRecuperablesNonRecuperables: ComptesRecuperablesNonRecuperablesModel) => setPopinSuppression({
		open: true,
		comptesRecuperablesNonRecuperables
	})

	const entetesTableau: HeadRow[] = useMemo(() => (
		[
			{
				label: enteteTableauListeCRNREnum.compte,
				id: getKeyFromEnumValue<enteteTableauListeCRNREnum>(enteteTableauListeCRNREnum, enteteTableauListeCRNREnum.compte)
			},
			{
				label: enteteTableauListeCRNREnum.compteRecuperable,
				id: getKeyFromEnumValue<enteteTableauListeCRNREnum>(enteteTableauListeCRNREnum, enteteTableauListeCRNREnum.compteRecuperable)
			},
			{
				label: enteteTableauListeCRNREnum.compteNonRecuperable,
				id: getKeyFromEnumValue<enteteTableauListeCRNREnum>(enteteTableauListeCRNREnum, enteteTableauListeCRNREnum.compteNonRecuperable)
			},
			{
				label: enteteTableauListeCRNREnum.rubriqueAnalytique,
				id: getKeyFromEnumValue<enteteTableauListeCRNREnum>(enteteTableauListeCRNREnum, enteteTableauListeCRNREnum.rubriqueAnalytique)
			},
			{
				label: '',
				id: columnsEnum.customColonne,
				width: '15%',
				render: (ligneTableau: ComptesRecuperablesNonRecuperablesModel) => (
					<Box
						className="elementCache"
						sx={{
							visibility: 'hidden',
							textAlign: 'right',

							'& svg': {
								color: gecinaBrownMain
							}
						}}
					>
						<IconButton aria-label="edit" title="Modification du compte" onClick={() => modifierCRNR(ligneTableau)}>
							<EditIcon />
						</IconButton>

						<IconButton aria-label="delete" title="Suppression du compte" onClick={() => suppressionCRNR(ligneTableau)}>
							<DeleteIcon />
						</IconButton>
					</Box>
				)
			}
		]
	), [])

	const auChangementDePage = ((nouvellePage: number) => {
		const data = {
			...filtresCRNR,
			[FILTRES_COMPTES_RECUPERABLES_NON_RECUPERABLES.PAGE]: nouvellePage
		}

		getListeComptesRecuperablesNonRecuperables(nouvellePage, filtresCRNR[FILTRES_COMPTES_RECUPERABLES_NON_RECUPERABLES.COMPTE])
			.then((response) => {
				setComptesRecuperablesNonRecuperables(response)
				setFiltresCRNR(data)
			})
			.finally(() => window.scrollTo(0, 0))
	})

	if (chargementComptesRecuperablesNonRecuperables) {
		return (
			<Box height="150px">
				<Loader size={50} />
			</Box>
		)
	}

	return (
		<Box sx={{ paddingX: '172px' }}>
			<CustomTable
				id="listeComptesRecuperablesNonRecuperables"
				headRows={entetesTableau}
				rows={remplissageLigneSelonEnteteTableau<ComptesRecuperablesNonRecuperablesModel>(comptesRecuperablesNonRecuperables['hydra:member'], entetesTableau)}
				total={comptesRecuperablesNonRecuperables['hydra:totalItems']}
				borderBottom
				auCliqueSurLigne={() => {
				}}
				hasPagination
				auChangementDePage={auChangementDePage}
				page={filtresCRNR[FILTRES_COMPTES_RECUPERABLES_NON_RECUPERABLES.PAGE] - 1}
				hasSort={false}
			/>
		</Box>
	)
}

export default ListeComptesRecuperablesNonRecuperables