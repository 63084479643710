import EnteteListe from './components/EnteteListe'
import { useContext, useEffect, useState } from 'react'
import { ListeTaxesContext } from './context/ListeTaxesContext'
import { getAnnees, getListeTaxes } from './api/listeTaxesApi'
import { FiltresListeTaxesEnum, statutListeTaxesEnum } from './enums/listeTaxesEnum'
import { getKeyFromEnumValue } from '../../utils/utils'
import { Box } from '@mui/material'
import AffichageListe from '../listeTaxes/components/AffichageListe'
import Loader from '../../components/loader/Loader'

const ListeTaxes = () => {
	//loader est utilisé pour ne pas afficher les filtres tant qu'on a pas les années pour le select
	const [loader, setLoader] = useState<boolean>(true)
	//loading est utilisé pour les chargement de la liste
	const [loading, setLoading] = useState<boolean>(false)
	const { annees, setAnnees, filtres, setFiltres, setListePaginee } = useContext(ListeTaxesContext)

	useEffect(() => {
		getAnnees()
			.then((response) => {
				setAnnees(response)
				const newFiltres = {
					[FiltresListeTaxesEnum.annee]: Math.max(...response),
					[FiltresListeTaxesEnum.reference]: '',
					[FiltresListeTaxesEnum.status]: getKeyFromEnumValue(statutListeTaxesEnum, statutListeTaxesEnum.all),
					[FiltresListeTaxesEnum.page]: 1,
					[FiltresListeTaxesEnum.avis]: ''

				}
				setFiltres(
					newFiltres
				)
				getListeTaxes(newFiltres).then(
					(response) => {
						setListePaginee(response)
						setLoader(false)
					}
				)
			})
			.catch(() => setLoader(false))
	}, [])

	return (
		<>
			{!loader ?
				annees.length ?
					<>
						<EnteteListe setLoading={setLoading} />
						<Box sx={{ mt: '10px' }}>
							{loading ?
								<Loader />
								:
								<AffichageListe />
							}
						</Box>
					</>
					:
					<></>
				:
				<Loader />
			}
		</>
	)
}

export default ListeTaxes