import { FILTRES_IMMEUBLES_QUOTES_PARTS_RESIDENTIEL } from '../enums/filtresImmeublesQuotesPartsResidentielEnum'

export interface ImmeublesQuotesPartsResidentielModel {
	'@id': string | null,
	referenceImmeuble: string,
	quotePartResidentiel: string,
	dateDebut: Date,
	dateFin: Date | null,
	nomModificateur: string,
	idModificateur: string
}

export interface FiltresImmeublesQuotesPartsResidentielModel {
	[FILTRES_IMMEUBLES_QUOTES_PARTS_RESIDENTIEL.REFERENCE_IMMEUBLE]: string
	[FILTRES_IMMEUBLES_QUOTES_PARTS_RESIDENTIEL.PAGE]: number
}

export interface PopinImmeublesQuotesPartsResidentielModel {
	open: boolean,
	immeublesQuotesPartsResidentiel: ImmeublesQuotesPartsResidentielModel | undefined
}

export const INITIAL_IMMEUBLES_QUOTES_PARTS_RESIDENTIEL: ImmeublesQuotesPartsResidentielModel = {
	'@id': '',
	referenceImmeuble: '',
	quotePartResidentiel: '',
	dateDebut: new Date(),
	dateFin: null,
	nomModificateur: '',
	idModificateur: ''
}

export const INITIAL_POPIN_IQPR: PopinImmeublesQuotesPartsResidentielModel = {
	open: false,
	immeublesQuotesPartsResidentiel: INITIAL_IMMEUBLES_QUOTES_PARTS_RESIDENTIEL
}

export const INITIAL_FILTRE_IQPR: FiltresImmeublesQuotesPartsResidentielModel = {
	[FILTRES_IMMEUBLES_QUOTES_PARTS_RESIDENTIEL.REFERENCE_IMMEUBLE]: '',
	[FILTRES_IMMEUBLES_QUOTES_PARTS_RESIDENTIEL.PAGE]: 1
}