import { Grid, MenuItem } from '@mui/material'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { filtresListeAvisFieldsEnum, filtresListeAvisPostEnum, statutListeAvisEnum } from '../enums/listeAvisEnum'
import TextField from '../../../components/fields/FormTextField'
import FormSelectField from '../../../components/fields/FormSelectField'
import FormDatePickerField from '../../../components/fields/FormDatePickerField'
import { buttonTypesEnum } from '../../../components/button/enums/buttonTypesEnum'
import { fontFamilyMonserrat, gecinaBrownMain, WHITE } from '../../../theme'
import Button from '../../../components/button/Button'
import AutorenewIcon from '@mui/icons-material/Autorenew'
import { FiltresListeAvisFormModel, FiltresListeAvisPostModel, INITIAL_VALEURS_FORMULAIRE, ListeAvisResponseModel } from '../types/filtresListeAvisModel'
import React, { Dispatch, useContext, useEffect } from 'react'
import { ListeAvisContext } from '../context/ListeAvisContext'
import { getListeAvis } from '../api/listeAvisAPI'
import { mapperListeAvisVersBack } from '../utils/filtresListeAvisUtils'
import { useLocation } from 'react-router-dom'

interface FiltresListeAvisProps {
	setLoading: Dispatch<React.SetStateAction<boolean>>,
	rafraichir: boolean
}

const FiltresListeAvis: React.FC<FiltresListeAvisProps> = (
	{
		setLoading,
		rafraichir
	}
) => {
	const { donneesFormulaire, setListeAvis, setDonneesFormulaire, dateSortDesc } = useContext(ListeAvisContext)
	const methods = useForm<any>({
		defaultValues: INITIAL_VALEURS_FORMULAIRE
	})
	const { register, handleSubmit, reset, getValues } = methods

	useEffect(() => {
		//Ne charge la liste uniquement si nous n'avons pas déjà des données dans le formulaire
		if ((!(filtresListeAvisPostEnum.page in donneesFormulaire)) || rafraichir) {
			setTimeout(() => onSubmit(getValues()), 1000)
		}
	}, [])

	const onSubmit: SubmitHandler<FiltresListeAvisFormModel> = (values) => {
		setLoading(true)
		// Mapping des valeurs du formulaire pour matcher avec le back
		const data: FiltresListeAvisPostModel = mapperListeAvisVersBack({...values, [filtresListeAvisFieldsEnum.dateSortDesc]: dateSortDesc}, 0)
		// Récupération de la liste des procédures selon les valeurs du filtre
		return getListeAvis(data)
			.then((response) => {
				setListeAvis(response)
				setDonneesFormulaire(data)
				setLoading(false)
			})
	}

	return (
		<FormProvider {...methods}>
			<form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
				<Grid container spacing={2}>
					<Grid item xs>
						<TextField
							register={register(filtresListeAvisFieldsEnum.reference, {
								maxLength: {
									value: 20,
									message: 'Le référence est limitée à 20 caractères'
								}
							})}
							maxLength={20}
							id={filtresListeAvisFieldsEnum.reference}
							label="Référence"
							placeholder="Saisir une référence"
						/>
					</Grid>

					<Grid item xs>
						<FormSelectField
							register={register(filtresListeAvisFieldsEnum.statut)}
							id={filtresListeAvisFieldsEnum.statut}
							label="Statut"
						>
							{
								Object.keys(statutListeAvisEnum).map(
									(statut) => (
										<MenuItem value={statut} key={statut}>
											{statutListeAvisEnum[statut as keyof typeof statutListeAvisEnum]}
										</MenuItem>
									)
								)
							}
						</FormSelectField>
					</Grid>

					<Grid item xs>
						<FormDatePickerField
							register={register(filtresListeAvisFieldsEnum.recuLe)}
							id={filtresListeAvisFieldsEnum.recuLe}
							label="Reçu entre le"
							errorWhite
						/>
					</Grid>

					<Grid item xs>
						<FormDatePickerField
							register={register(filtresListeAvisFieldsEnum.avantLe)}
							id={filtresListeAvisFieldsEnum.avantLe}
							label="Et le"
							errorWhite
						/>
					</Grid>

					<Grid container item xs spacing={2} sx={{ m: 0 }} alignContent="flex-start">
						<span
							style={{
								width: '100%',
								paddingRight: 15,
								paddingTop: 7,
								fontSize: 14,
								marginLeft: 0,
								boxSizing: 'border-box',
								textAlign: 'left',
								fontFamily: fontFamilyMonserrat,
								fontWeight: 500
							}}
						> </span>

						<Grid item xs={9} sx={{ pt: '0px !important', height: 'fit-content' }}>
							<Button
								sx={{
									height: '32px',
									p: 0,
									minWidth: '100%',
									fontWeight: 600
								}}
								bgcolor={gecinaBrownMain}
								color={WHITE}
								border={false}
								disabled={false}
								type={buttonTypesEnum.submit}
							>
								Filtrer
							</Button>
						</Grid>

						<Grid item xs={3} sx={{ pt: '0px !important', height: 'fit-content' }}>
							<Button
								sx={{
									height: 32,
									p: 0,
									minWidth: '100%'
								}}
								bgcolor={gecinaBrownMain}
								color={WHITE}
								border={false}
								disabled={false}
								type={buttonTypesEnum.submit}
								onClick={() => reset(INITIAL_VALEURS_FORMULAIRE)}
							>
								<AutorenewIcon />
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</form>
		</FormProvider>
	)
}

export default FiltresListeAvis