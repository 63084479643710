import axios from 'axios'
import { CommunApiPlatformModel } from '../../../types/communApiPlatformModel'
import { TraitementSGNFModel } from '../types/traitementSGNFModel'
import { statutFichierTraitementSGNFEnum } from '../enums/fichierTraitementSGNFEnum'

export const getListeTraitementSGNF = (page: number, fichierIntegre?: string, type?: string, statut?: string, matricule?: string, recuEntreLe?: Date, etLe?: Date): Promise<CommunApiPlatformModel<TraitementSGNFModel[]>> =>
	axios.get(`/api/salaires_gardiens?page=${page}
		${fichierIntegre ? `&fichierDepose=${fichierIntegre}` : ''}
		${type && type !== 'TOUT' ? `&type=${type}` : ''}
		${statut && statut !== 'TOUT' ? `&statut=${statutFichierTraitementSGNFEnum[statut as keyof typeof statutFichierTraitementSGNFEnum]}` : ''}
		${matricule ? `&matricule=${matricule}` : ''}
		${recuEntreLe ? `&recuEntreLe=${recuEntreLe.toISOString()}` : ''}
		${etLe ? `&etLe=${etLe.toISOString()}` : ''}
	`)
		.then((response) => response.data)

export const deleteNaturesRubriquesPaie = (data: TraitementSGNFModel): Promise<TraitementSGNFModel> => axios.delete(`/api/salaires_gardiens/${data['@id']!.split('/api/salaires_gardiens/')[1]}`)
	.then((response) => response.data)

export const telechargementFichierDepose = (fichierDepose: string, type: string): Promise<Blob> => axios.get(`/api/fichierDepose?nom=${fichierDepose}&type=${type}`, {responseType: 'blob'}).then((response) => response.data)
export const telechargementFichierGenere = (fichierDepose: string, type: string): Promise<Blob> => axios.get(`/api/fichierGenere?nom=${fichierDepose}&type=${type}`, {responseType: 'blob'}).then((response) => response.data)