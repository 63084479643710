import React from 'react'
import { TextFieldProps } from '@mui/material'
import CustomTextField from './CustomTextField'
import { ChampDansFormulaire } from './types/typePourChampDansFormulaireModel'
import { fontFamilyMonserrat } from '../../theme'
import ErrorComponent from './ErrorComponent'

export interface TextFieldAcceptingProps {
	id: string
	maxLength?: number,
	withBorder?: boolean
}

type FormTextFieldProps = ChampDansFormulaire & TextFieldAcceptingProps & TextFieldProps

const FormTextField = (
	{
		id,
		label,
		placeholder,
		multiline,
		maxLength,
		rows = 5,
		register,
		inputProps,
		errors,
		sx,
		withBorder = false
	}: FormTextFieldProps
) => {
	return (
		<>
			{
				label && (
					<span
						style={{
							width: '100%',
							paddingTop: 7,
							fontSize: 16,
							marginBottom: 5,
							marginLeft: 0,
							boxSizing: 'border-box',
							textAlign: 'left',
							fontFamily: fontFamilyMonserrat,
							fontWeight: 600
						}}
					>
						{label}
					</span>
				)
			}

			<CustomTextField
				sx={sx}
				id={id}
				multiline={multiline}
				rows={rows}
				maxLength={maxLength}
				placeholder={placeholder}
				withBorder={withBorder}
				register={register}
				inputProps={{ ...inputProps }}
			/>

		</>
	)
}

export default FormTextField
