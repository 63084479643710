import { Box } from '@mui/material'
import { TauxPopinStateModel, TauxResponseModel, TauxResponsePageModel } from '../types/TauxResponseModel'
import { HeadRow } from '../../../components/table/types/tableModel'
import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import { getKeyFromEnumValue } from '../../../utils/utils'
import { GestionTauxTableauLabelEnum } from '../enums/gestionTauxTableauLabelEnum'
import CustomTable from '../../../components/table/Table'
import { remplissageLigneSelonEnteteTableau } from '../../../components/table/utils/tableUtils'
import SvgEdit from '../../../icons/SvgEdit'
import SvgTrash from '../../../icons/SvgTrash'
import { supressionTauxApi } from '../api/gestionTauxAPI'
import { gecinaBlueMain } from '../../../theme'
import { numberTabFormat } from '../api/getionTauxUtils'
import { useDispatch } from 'react-redux'
import { displaySnackbar } from '../../../components/snackBar/reducer/actions'
import TableauxEnum from '../../../enum/tableauxEnum'

interface ListeTauxProps {
	taux: TauxResponsePageModel,
	setTaux: Dispatch<SetStateAction<TauxResponsePageModel>>
	setPopinState: Dispatch<SetStateAction<TauxPopinStateModel>>
}

type AcceptingProps = ListeTauxProps

const ListeTaux: React.FC<AcceptingProps> = (
	{
		taux,
		setTaux,
		setPopinState
	}
) => {
	const dispatch = useDispatch()

	const supressionTaux = (tauxId: number) => {
		supressionTauxApi(tauxId)
			.then(() =>
				// Après la suppression, on retire l'élément du tableau
				setTaux((prevTaux) => ({
					...prevTaux,
					list: taux.list.filter((t: TauxResponseModel) =>
						t.id !== tauxId
					)
				}))
			)
			.catch(() => {
				dispatch(displaySnackbar(
					{
						id: 'ERR_SUPPRESSION',
						message: 'Suppression impossible. Ces taux sont référencés dans un ou plusieurs avis.',
						open: true,
						hideIcon: false
					})
				)
			})
	}


	const entetesTableau: HeadRow[] =
		[
			{
				label: GestionTauxTableauLabelEnum.annee,
				id: getKeyFromEnumValue<GestionTauxTableauLabelEnum>(GestionTauxTableauLabelEnum, GestionTauxTableauLabelEnum.annee)
			},
			{
				label: GestionTauxTableauLabelEnum.commune,
				id: getKeyFromEnumValue<GestionTauxTableauLabelEnum>(GestionTauxTableauLabelEnum, GestionTauxTableauLabelEnum.commune),
				render: (value: number) => (
					<>
						{numberTabFormat(value)}
					</>
				)
			},
			{
				label: GestionTauxTableauLabelEnum.departement,
				id: getKeyFromEnumValue<GestionTauxTableauLabelEnum>(GestionTauxTableauLabelEnum, GestionTauxTableauLabelEnum.departement),
				render: (value: number) => (
					<>
						{numberTabFormat(value)}
					</>
				)
			},
			{
				label: GestionTauxTableauLabelEnum.taxe_speciale_gp_affichage,
				id: getKeyFromEnumValue<GestionTauxTableauLabelEnum>(GestionTauxTableauLabelEnum, GestionTauxTableauLabelEnum.taxe_speciale_gp_affichage)
			},
			{
				label: GestionTauxTableauLabelEnum.taxe_speciale_affichage,
				id: getKeyFromEnumValue<GestionTauxTableauLabelEnum>(GestionTauxTableauLabelEnum, GestionTauxTableauLabelEnum.taxe_speciale_affichage)
			},
			{
				label: GestionTauxTableauLabelEnum.syndicat_commune,
				id: getKeyFromEnumValue<GestionTauxTableauLabelEnum>(GestionTauxTableauLabelEnum, GestionTauxTableauLabelEnum.syndicat_commune),
				render: (value: number) => (
					<>
						{numberTabFormat(value)}
					</>
				)
			},
			{
				label: GestionTauxTableauLabelEnum.teom,
				id: getKeyFromEnumValue<GestionTauxTableauLabelEnum>(GestionTauxTableauLabelEnum, GestionTauxTableauLabelEnum.teom),
				render: (value: number) => (
					<>
						{numberTabFormat(value)}
					</>
				)
			},
			{
				label: GestionTauxTableauLabelEnum.gemapi,
				id: getKeyFromEnumValue<GestionTauxTableauLabelEnum>(GestionTauxTableauLabelEnum, GestionTauxTableauLabelEnum.gemapi),
				render: (value: number) => (
					<>
						{numberTabFormat(value)}
					</>
				)
			},
			{
				//COLONNE POUR LES BOTUONS EDITER/SUPPRIMER
				label: GestionTauxTableauLabelEnum.id,
				id: getKeyFromEnumValue<GestionTauxTableauLabelEnum>(GestionTauxTableauLabelEnum, GestionTauxTableauLabelEnum.id),
				render: (tauxId: number) => (
					<>
						<SvgEdit width={17} height={17} style={{ cursor: 'pointer' }} color={gecinaBlueMain} onClick={() => (setPopinState({
							open: true,
							taux: taux.list.find((t: TauxResponseModel) => t.id === tauxId) ?? undefined
						}))} />
						<SvgTrash style={{ cursor: 'pointer' }} color={gecinaBlueMain} onClick={() => supressionTaux(tauxId)} />
					</>
				)
			}
		]


	return (
		<Box sx={{ paddingX: '172px' }}>
			<CustomTable
				id={TableauxEnum.listeTaux}
				headRows={entetesTableau}
				rows={remplissageLigneSelonEnteteTableau<TauxResponseModel>(taux.list, entetesTableau)}
				total={taux.total}
				page={(taux.page.current ?? 1) - 1}
				hasSort={false}
				borderBottom
			/>

		</Box>
	)
}

export default ListeTaux