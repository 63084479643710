import { createTheme as createMuiTheme } from '@mui/material/styles'

export const WHITE = 'white'
export const gecinaBlueMain = '#122941'
export const gecinaBlue90 = '#293e54'
export const gecinaBlue80 = '#415367'
export const gecinaBlue70 = '#586979'
export const gecinaBlue60 = '#717f8d'
export const gecinaBlue50 = '#8894a0'
export const gecinaBlue40 = '#a0a9b3'
export const gecinaBlue30 = '#b7bec6'
export const gecinaBlue20 = '#d0d4d9'
export const gecinaBlue10 = '#e7e9ec'
export const gecinaBlue5 = '#f3f4f5'

export const gecinaBrownMain = '#B3A688'
export const gecinaBrown90 = '#baae93'
export const gecinaBrown80 = '#c2b8a0'
export const gecinaBrown70 = '#c9c0ab'
export const gecinaBrown60 = '#d1cab8'
export const gecinaBrown50 = '#d9d2c3'
export const gecinaBrown40 = '#e1dbcf'
export const gecinaBrown30 = '#e8e4db'
export const gecinaBrown20 = '#f0ede7'
export const gecinaBrown10 = '#f7f6f3'
export const gecinaBrown5 = '#fbfaf9'

export const gecinaGreenMain = '#00ACA9'
export const gecinaGreen90 = '#1eb4b1'
export const gecinaGreen80 = '#33bcba'
export const gecinaGreen70 = '#4cc4c2'
export const gecinaGreen60 = '#66cdcb'
export const gecinaGreen50 = '#7fd5d3'
export const gecinaGreen40 = '#99dddc'
export const gecinaGreen30 = '#00ACA9'
export const gecinaGreen20 = '#cceeed'
export const gecinaGreen10 = '#e5f6f6'
export const gecinaGreen5 = '#f2fbfa'

export const gecinaPurpleMain = '#6254D7'
export const gecinaPurple90 = '#6254D7'
export const gecinaPurple80 = '#8176df'
export const gecinaPurple70 = '#9187e3'
export const gecinaPurple60 = '#a198e7'
export const gecinaPurple50 = '#b0a9eb'
export const gecinaPurple40 = '#c0bbef'
export const gecinaPurple30 = '#cfcbf3'
export const gecinaPurple20 = 'rgba(98, 84, 215, 0.2)'
export const gecinaPurple10 = 'rgba(98, 84, 215, 0.1)'
export const gecinaPurple5 = '#f7f6fd'

export const gecinaErrorColor = '#B92D00'

export const fontFamilyMonserrat = '\'Montserrat\', sans-serif'
export const fontFamilyBlackerDisplay = '\'BlackerDisplay\', sans-serif'

export const createTheme = (isVisualHandicap: boolean) => createMuiTheme({
	typography: {
		title0: {
			fontSize: 72,
			fontWeight: 900,
			fontFamily: fontFamilyBlackerDisplay,
			lineHeight: '0.96',
			color: gecinaBlueMain
		},
		title1: {
			fontSize: 48,
			fontWeight: 900,
			fontFamily: fontFamilyBlackerDisplay,
			lineHeight: '1.33',
			color: gecinaBlueMain
		},
		title2: {
			fontSize: 32,
			fontWeight: 900,
			fontFamily: fontFamilyBlackerDisplay,
			lineHeight: '1.13',
			color: gecinaBlueMain
		},
		title3: {
			fontSize: 24,
			fontWeight: 900,
			fontFamily: fontFamilyBlackerDisplay,
			lineHeight: '1.17',
			color: gecinaBlueMain
		},
		title4: {
			fontSize: 16,
			fontWeight: 'bold' as 'bold',
			fontFamily: fontFamilyMonserrat,
			lineHeight: '1.5',
			color: gecinaBlueMain
		},
		title5: {
			fontSize: 14,
			fontWeight: 'bold' as 'bold',
			fontFamily: fontFamilyMonserrat,
			lineHeight: '1.43',
			color: gecinaBlueMain
		},
		title6: {
			fontSize: 28,
			fontWeight: 900,
			fontFamily: fontFamilyBlackerDisplay,
			lineHeight: '1.21',
			color: gecinaBlueMain
		},
		title7: {
			fontSize: 16,
			fontWeight: 900,
			fontFamily: fontFamilyBlackerDisplay,
			lineHeight: '1.38',
			color: gecinaBlueMain
		},
		title8: {
			fontSize: 20,
			fontWeight: 'bold' as 'bold',
			fontFamily: fontFamilyMonserrat,
			lineHeight: 1,
			color: gecinaBlueMain
		},
		title9: {
			fontSize: 32,
			fontWeight: 'normal' as 'normal',
			fontFamily: fontFamilyMonserrat,
			lineHeight: 1.5,
			color: gecinaBlueMain
		},
		title10: {
			fontSize: 14,
			fontWeight: 900,
			fontFamily: fontFamilyBlackerDisplay,
			lineHeight: '1.17',
			color: gecinaBlueMain
		},
		title11: {
			fontSize: 55,
			fontWeight: 900,
			fontFamily: fontFamilyBlackerDisplay,
			lineHeight: '60px',
			color: gecinaBlueMain
		},
		text0: {
			fontSize: 20,
			fontWeight: 600,
			fontFamily: fontFamilyMonserrat,
			lineHeight: '1.4',
			color: gecinaBlueMain
		},
		text0_opacity: {
			fontSize: 20,
			fontWeight: 600,
			fontFamily: fontFamilyMonserrat,
			lineHeight: '1.4',
			opacity: 0.6,
			color: gecinaBlueMain
		},
		text1_opacity: {
			fontSize: 16,
			fontWeight: 500,
			fontFamily: fontFamilyMonserrat,
			lineHeight: '1.5',
			opacity: 0.6,
			color: gecinaBlueMain
		},
		text1: {
			fontSize: 16,
			fontWeight: 500,
			fontFamily: fontFamilyMonserrat,
			lineHeight: '1.5',
			color: gecinaBlueMain
		},
		text2_opacity: {
			fontSize: 14,
			fontWeight: 500,
			fontFamily: fontFamilyMonserrat,
			lineHeight: '1.57',
			opacity: 0.6,
			color: gecinaBlueMain
		},
		text2: {
			fontSize: 14,
			fontWeight: 500,
			fontFamily: fontFamilyMonserrat,
			lineHeight: '1.57',
			color: gecinaBlueMain
		},
		text3_opacity: {
			fontSize: 12,
			fontWeight: 500,
			fontFamily: fontFamilyMonserrat,
			lineHeight: '1.67',
			opacity: 0.6,
			color: gecinaBlueMain
		},
		text3: {
			fontSize: 12,
			fontWeight: 500,
			fontFamily: fontFamilyMonserrat,
			lineHeight: '1.67',
			color: gecinaBlueMain
		},
		text4_opacity: {
			fontSize: 12,
			fontWeight: 600,
			fontFamily: fontFamilyMonserrat,
			lineHeight: '1.67',
			opacity: 0.6,
			color: gecinaBlueMain
		},
		text4: {
			fontSize: 12,
			fontWeight: 600,
			fontFamily: fontFamilyMonserrat,
			lineHeight: '1.67',
			color: gecinaBlueMain
		},
		text5: {
			fontSize: 12,
			fontWeight: 'bold' as 'bold',
			fontFamily: fontFamilyMonserrat,
			lineHeight: 1,
			letterSpacing: '1.2px',
			color: gecinaBlueMain
		},
		text6: {
			fontSize: 11,
			fontWeight: 500,
			fontFamily: fontFamilyMonserrat,
			lineHeight: 1,
			color: gecinaBlueMain
		},
		text6_opacity: {
			fontSize: 11,
			fontWeight: 500,
			fontFamily: fontFamilyMonserrat,
			lineHeight: 1,
			opacity: 0.7,
			color: gecinaBlueMain
		},
		text7: {
			fontSize: 16,
			fontWeight: 600,
			fontFamily: fontFamilyMonserrat,
			lineHeight: '1.25',
			color: gecinaBlueMain
		},
		text8: {
			fontSize: 14,
			fontWeight: 600,
			fontFamily: fontFamilyMonserrat,
			lineHeight: '1.43',
			color: gecinaBlueMain
		},
		text9: {
			fontSize: 10,
			fontWeight: 500,
			fontFamily: fontFamilyMonserrat,
			lineHeight: '1.6',
			color: gecinaBlueMain
		},
		text9_opacity: {
			fontSize: 10,
			fontWeight: 500,
			fontFamily: fontFamilyMonserrat,
			lineHeight: '1.6',
			opacity: 0.6,
			color: gecinaBlueMain
		},
		text10: {
			fontSize: 24,
			fontWeight: 'bold' as 'bold',
			fontFamily: fontFamilyMonserrat,
			lineHeight: '1',
			color: gecinaBlueMain
		},
		text11: {
			fontSize: 20,
			fontWeight: 'bold' as 'bold',
			fontFamily: fontFamilyMonserrat,
			lineHeight: '1.2',
			color: gecinaBlueMain
		},
		text11_opacity: {
			fontSize: 20,
			fontWeight: 'bold' as 'bold',
			fontFamily: fontFamilyMonserrat,
			lineHeight: '1.2',
			opacity: 0.6,
			color: gecinaBlueMain
		},
		text12: {
			fontSize: 20,
			fontWeight: 'normal' as 'normal',
			fontFamily: fontFamilyMonserrat,
			lineHeight: '1',
			color: gecinaBlueMain
		},
		text13: {
			fontSize: 20,
			fontWeight: 500,
			fontFamily: fontFamilyMonserrat,
			lineHeight: '1.4',
			color: gecinaBlueMain
		},
		text13_opacity: {
			fontSize: 20,
			fontWeight: 500,
			fontFamily: fontFamilyMonserrat,
			lineHeight: '1.4',
			opacity: 0.6,
			color: gecinaBlueMain
		},
		text14: {
			fontSize: 10,
			fontWeight: 600,
			fontFamily: fontFamilyMonserrat,
			lineHeight: 'normal',
			color: gecinaBlueMain
		},
		text15: {
			fontSize: 14,
			fontWeight: 'normal' as 'normal',
			fontFamily: fontFamilyMonserrat,
			lineHeight: 'normal',
			color: gecinaBlueMain
		},
		text16_opacity: {
			fontSize: 8,
			fontWeight: 500,
			fontFamily: fontFamilyMonserrat,
			lineHeight: 'normal',
			opacity: 0.6,
			color: gecinaBlueMain
		},
		text17: {
			fontSize: 13,
			fontWeight: 500,
			fontFamily: fontFamilyMonserrat,
			lineHeight: '1.54',
			color: gecinaBlueMain
		},
		text18: {
			fontSize: 18,
			fontWeight: 'bold' as 'bold',
			fontFamily: fontFamilyMonserrat,
			lineHeight: 'normal',
			color: gecinaBlueMain
		},
		text19: {
			fontSize: 20,
			fontWeight: 900,
			fontFamily: fontFamilyBlackerDisplay,
			lineHeight: '1.2',
			color: gecinaBlueMain
		},
		text20: {
			fontSize: 16,
			fontWeight: 'normal' as 'normal',
			fontFamily: fontFamilyMonserrat,
			lineHeight: '1.5',
			color: gecinaBlueMain
		},
		text21: {
			fontSize: 24,
			fontWeight: 600,
			fontFamily: fontFamilyMonserrat,
			lineHeight: '1.17',
			color: gecinaBlueMain
		},
		text22: {
			fontSize: 8,
			fontWeight: 600,
			fontFamily: fontFamilyMonserrat,
			lineHeight: 'normal',
			color: gecinaBlueMain
		},
		text23: {
			fontSize: 9,
			fontWeight: 600,
			fontFamily: fontFamilyMonserrat,
			lineHeight: 'normal',
			color: gecinaBlueMain
		},
		text24: {
			fontSize: 15,
			fontWeight: 500,
			fontFamily: fontFamilyMonserrat,
			lineHeight: '1.47',
			color: gecinaBlueMain
		},
		text25: {
			fontSize: 30,
			fontWeight: 900,
			fontFamily: fontFamilyBlackerDisplay,
			lineHeight: '1.33',
			color: gecinaBlueMain
		},
		text26: {
			fontSize: 50,
			fontWeight: 900,
			fontFamily: fontFamilyBlackerDisplay,
			lineHeight: '1.3',
			color: gecinaBlueMain
		},
		text27: {
			fontSize: 18,
			fontWeight: 500,
			fontFamily: fontFamilyMonserrat,
			lineHeight: '1.33',
			color: gecinaBlueMain
		},
		text28: {
			fontSize: 25,
			fontWeight: 900,
			fontFamily: fontFamilyBlackerDisplay,
			lineHeight: '1.2',
			color: gecinaBlueMain
		},
		text29: {
			fontSize: 15,
			fontWeight: 600,
			fontFamily: fontFamilyMonserrat,
			lineHeight: '1.2',
			color: gecinaBlueMain
		},
		text30: {
			fontSize: 15,
			fontWeight: 900,
			fontFamily: fontFamilyMonserrat,
			lineHeight: '1.2',
			color: gecinaBlueMain
		},
		text31: {
			fontSize: 22,
			fontWeight: 800,
			fontFamily: fontFamilyMonserrat,
			lineHeight: '27px',
			color: gecinaBlueMain
		}
	},
	palette: {
		primary: {
			main: isVisualHandicap ? gecinaBlueMain : gecinaGreenMain
		},
		secondary: {
			main: gecinaBrownMain
		},
		green: {
			main: gecinaGreenMain,
			90: gecinaGreen90,
			80: gecinaGreen80,
			70: gecinaGreen70,
			60: gecinaGreen60,
			50: gecinaGreen50,
			40: gecinaGreen40,
			30: gecinaGreen30,
			20: gecinaGreen20,
			10: gecinaGreen10,
			5: gecinaGreen5
		},
		purple: {
			main: gecinaPurpleMain,
			90: gecinaPurple90,
			80: gecinaPurple80,
			70: gecinaPurple70,
			60: gecinaPurple60,
			50: gecinaPurple50,
			40: gecinaPurple40,
			30: gecinaPurple30,
			20: gecinaPurple20,
			10: gecinaPurple10,
			5: gecinaPurple5
		},
		blue: {
			main: gecinaBlueMain,
			90: gecinaBlue90,
			80: gecinaBlue80,
			70: gecinaBlue70,
			60: gecinaBlue60,
			50: gecinaBlue50,
			40: gecinaBlue40,
			30: gecinaBlue30,
			20: gecinaBlue20,
			10: gecinaBlue10,
			5: gecinaBlue5
		},
		brown: {
			main: gecinaBrownMain,
			90: gecinaBrown90,
			80: gecinaBrown80,
			70: gecinaBrown70,
			60: gecinaBrown60,
			50: gecinaBrown50,
			40: gecinaBrown40,
			30: gecinaBrown30,
			20: gecinaBrown20,
			10: gecinaBrown10,
			5: gecinaBrown5
		},
		accessibility: {
			main: isVisualHandicap ? gecinaBlueMain : gecinaGreenMain,
			90: isVisualHandicap ? gecinaBlue90 : gecinaGreen90,
			80: isVisualHandicap ? gecinaBlue80 : gecinaGreenMain,
			70: isVisualHandicap ? gecinaBlue70 : gecinaGreen70,
			60: isVisualHandicap ? gecinaBlue60 : gecinaGreen60,
			50: isVisualHandicap ? gecinaBlue50 : gecinaGreen50,
			40: isVisualHandicap ? gecinaBlue40 : gecinaGreen40,
			30: isVisualHandicap ? gecinaBlue30 : gecinaGreen30,
			20: isVisualHandicap ? gecinaBlue20 : gecinaGreen20,
			10: isVisualHandicap ? gecinaBlue10 : gecinaGreen10,
			5: isVisualHandicap ? gecinaBlue5 : gecinaGreen5
		},
		accessibilityGreenToWhite: {
			main: isVisualHandicap ? 'white' : gecinaGreenMain,
			70: isVisualHandicap ? 'white' : gecinaGreen70
		},
		accessibilityWhiteToBlue: {
			main: isVisualHandicap ? gecinaBlueMain : 'white'
		}
	},
	gecina: {
		blue: gecinaBlueMain,
		brown: gecinaBrownMain,
		green: gecinaGreenMain,
		purple: gecinaPurpleMain
	}
})

export type ThemeType = typeof createTheme
