import React, { Dispatch, ReactChild, SetStateAction, useState } from 'react'
import { INITIAL_USER, UserModel } from '../types/UserModel'

interface AppContextModel {
	userInfos: UserModel,
	setUserInfos: Dispatch<SetStateAction<UserModel>>
}

export const AppContext = React.createContext<AppContextModel>({
		userInfos: INITIAL_USER,
		setUserInfos: () => {
		}
	}
)

const AppProvider = (props: { children: ReactChild }) => {
	const [userInfos, setUserInfos] = useState<UserModel>(INITIAL_USER)

	return (
		<AppContext.Provider
			value={{
				userInfos,
				setUserInfos
			}}
		>
			{props.children}
		</AppContext.Provider>
	)
}

export default AppProvider
