import React, { useCallback, useContext, useMemo } from 'react'
import { HeadRow } from '../../../components/table/types/tableModel'
import { enteteTableauAffichageListeEnum, enteteTableauAffichageListeLabelEnum, filtresListeAvisPostEnum, filtresListeAvisResponseEnum, statutListeAvisEnum } from '../enums/listeAvisEnum'
import { tableTypeEnum } from '../../../components/table/enums/tableTypeEnum'
import { getKeyFromEnumValue } from '../../../utils/utils'
import { Box } from '@mui/material'
import { remplissageLigneSelonEnteteTableau } from '../../../components/table/utils/tableUtils'
import CustomTable from '../../../components/table/Table'
import { useNavigate } from 'react-router-dom'
import { ListeAvisResponseModel } from '../types/filtresListeAvisModel'
import { ListeAvisContext } from '../context/ListeAvisContext'
import liens from '../../../enum/liens'
import { getListeAvis } from '../api/listeAvisAPI'
import { columnsEnum } from '../../../components/table/enums/columnsEnum'
import Tooltip from '../../../components/tooltip/Tooltip'
import { formatDate } from '../../../utils/formatDate'
import { gecinaBlueMain } from '../../../theme'
import SvgTrash from '../../../icons/SvgTrash'
import { detailAvisPostEnum } from '../../traitementAvis/enums/detailAvisEnum'
import { DetailAvisPostModel } from '../../traitementAvis/types/detailAvisModel'

const AffichageListe = () => {

	const { listeAvis, donneesFormulaire, setListeAvis, setDonneesFormulaire, setOuverturePopin, dateSortDesc, setDateSortDesc } = useContext(ListeAvisContext)
	const navigate = useNavigate()

	const suppressionAvis = (avis: DetailAvisPostModel) => setOuverturePopin({
		open: true,
		avis: avis
	})

	const entetesTableau: HeadRow[] = useMemo(
		() => (
			[
				{
					label: enteteTableauAffichageListeLabelEnum.reference,
					id: getKeyFromEnumValue<enteteTableauAffichageListeEnum>(enteteTableauAffichageListeEnum, enteteTableauAffichageListeEnum.reference),
					hasSort: true
				},
				{
					label: enteteTableauAffichageListeLabelEnum.fichier,
					id: getKeyFromEnumValue<enteteTableauAffichageListeEnum>(enteteTableauAffichageListeEnum, enteteTableauAffichageListeEnum.fichier_depose)
				},
				{
					label: enteteTableauAffichageListeLabelEnum.recuLe,
					id: getKeyFromEnumValue<enteteTableauAffichageListeEnum>(enteteTableauAffichageListeEnum, enteteTableauAffichageListeEnum.date_reception),
					type: tableTypeEnum.date,
					onClick: () => {
						getListeAvis({...donneesFormulaire, [filtresListeAvisPostEnum.dateSortDesc]: !dateSortDesc})
							.then(response => {
								setListeAvis(response)
								setDonneesFormulaire({...donneesFormulaire, [filtresListeAvisPostEnum.dateSortDesc]: !dateSortDesc})
								setDateSortDesc(!dateSortDesc)
						})
					}
				},
				{
					label: enteteTableauAffichageListeLabelEnum.traiteLe,
					id: columnsEnum.customColonne,
					type: tableTypeEnum.date,
					render: (ligneTableau: ListeAvisResponseModel) => (
						<Box
							sx={{
								'& .MuiTooltip-popper': {
									width: 'max-content'
								},
								'& .conteneurTooltip': {
									visibility: 'hidden'
								},
								'&:hover .conteneurTooltip': {
									visibility: 'visible'
								}
							}}
						>
							{formatDate(new Date(ligneTableau[filtresListeAvisResponseEnum.traiteLe]))}
							<Box className="conteneurTooltip">
								{
									(ligneTableau[filtresListeAvisResponseEnum.statut] === statutListeAvisEnum.VALIDE
										|| ligneTableau[filtresListeAvisResponseEnum.statut] === statutListeAvisEnum.GENERE
										|| ligneTableau[filtresListeAvisResponseEnum.statut] === statutListeAvisEnum.TRANSMIS) && <Tooltip
										title={
											<>
												<span>Date de génération : {formatDate(new Date(ligneTableau[filtresListeAvisResponseEnum.dateGeneration]))}</span>
												<br />
												<span>Date de transmission : {formatDate(new Date(ligneTableau[filtresListeAvisResponseEnum.dateTransmission]))}</span>
											</>
										}
										open
									/>
								}
							</Box>
						</Box>
					)
				},
				{
					label: enteteTableauAffichageListeLabelEnum.statut,
					id: getKeyFromEnumValue<enteteTableauAffichageListeEnum>(enteteTableauAffichageListeEnum, enteteTableauAffichageListeEnum.statut)
				},
				{
					label: enteteTableauAffichageListeLabelEnum.icon,
					id: columnsEnum.customColonne,
					render: (ligneTableau: ListeAvisResponseModel) => (
						ligneTableau[filtresListeAvisResponseEnum.statut] === statutListeAvisEnum.A_TRAITER ?
							<Box
								sx={{
									'svg' : {
										fill: gecinaBlueMain
									}
								}}
								onClick={(event) => {
									event.stopPropagation()
									suppressionAvis({ [detailAvisPostEnum.fichier]: ligneTableau![filtresListeAvisResponseEnum.fichierDepose], [detailAvisPostEnum.anneeRedition]: ligneTableau![filtresListeAvisResponseEnum.anneeReddition] })}
								}
							>
								<SvgTrash style={{ cursor: 'pointer' }} />
							</Box>
							:
							<></>
					)
				}
			]
		), [dateSortDesc]
	)

	const auChangementDePage = (
		(nouvellePage: number) => {
			const data = {
				...donneesFormulaire,
				[filtresListeAvisPostEnum.page]: nouvellePage
			}

			getListeAvis(data)
				.then((response) => {
					setListeAvis(response)
					setDonneesFormulaire(data)
				})
				.finally(() => window.scrollTo(0, 0))
		}
	)

	const auCliqueSurLigne = useCallback(
		(avisSelectionnee: ListeAvisResponseModel) => {
			if (avisSelectionnee[filtresListeAvisResponseEnum.statut] === statutListeAvisEnum.A_TRAITER) {
				const avis = listeAvis[filtresListeAvisResponseEnum.list].filter((avis) => {
					return avis[filtresListeAvisResponseEnum.reference] === avisSelectionnee[filtresListeAvisResponseEnum.reference]
						&& avis[filtresListeAvisResponseEnum.fichierDepose] === avisSelectionnee[filtresListeAvisResponseEnum.fichierDepose]
						&& avis[filtresListeAvisResponseEnum.statut] === avisSelectionnee[filtresListeAvisResponseEnum.statut]
				})[0]
				navigate(liens.detailAvis, { state: { avis } })
			}
		}, [listeAvis]
	)

	return (
		<Box sx={{ paddingX: '172px' }}>
			<CustomTable
				id="listeProcedures"
				headRows={entetesTableau}
				rows={remplissageLigneSelonEnteteTableau<ListeAvisResponseModel>(listeAvis[filtresListeAvisResponseEnum.list], entetesTableau)}
				total={listeAvis[filtresListeAvisResponseEnum.total]}
				auChangementDePage={auChangementDePage}
				page={(listeAvis[filtresListeAvisResponseEnum.page][filtresListeAvisResponseEnum.current] ?? 1) - 1}
				auCliqueSurLigne={auCliqueSurLigne}
				hasSort={false}
				borderBottom
			/>
		</Box>
	)
}

export default AffichageListe