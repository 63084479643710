import axios from 'axios'
import { CommunApiPlatformModel } from '../../../types/communApiPlatformModel'
import { ComptesTauxRecuperabiliteSouspostesModel } from '../types/comptesTauxRecuperabiliteSouspostesModel'

export const getListeComptesTauxRecuperabiliteSouspostes = (page: number, pagination: boolean, compte?: string): Promise<CommunApiPlatformModel<ComptesTauxRecuperabiliteSouspostesModel[]>> => axios.get(`/api/comptes_taux_recuperabilite_souspostes?page=${page}&pagination=${pagination}${compte ? `&compte=${compte}` : ''}`)
	.then((response) => response.data)

export const postComptesTauxRecuperabiliteSouspostes = (data: ComptesTauxRecuperabiliteSouspostesModel): Promise<ComptesTauxRecuperabiliteSouspostesModel> => axios.post('/api/comptes_taux_recuperabilite_souspostes', {
	...data,
	taux_recup_bureau: parseFloat(data.tauxRecupBureau),
	taux_recup_residentiel: parseFloat(data.tauxRecupResidentiel)
})
	.then((response) => response.data)

export const putComptesTauxRecuperabiliteSouspostes = (data: ComptesTauxRecuperabiliteSouspostesModel): Promise<ComptesTauxRecuperabiliteSouspostesModel> => axios.put('/api/updateComptesTauxRecuperabiliteSouspostes', data)
	.then((response) => response.data)

export const deleteComptesTauxRecuperabiliteSouspostes = (data: ComptesTauxRecuperabiliteSouspostesModel): Promise<ComptesTauxRecuperabiliteSouspostesModel> => axios.delete(`/api/comptes_taux_recuperabilite_souspostes/${data['@id']!.split('/api/comptes_taux_recuperabilite_souspostes/')[1]}`)
	.then((response) => response.data)