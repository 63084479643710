import { Box } from '@mui/material'
import CustomTable from '../../../components/table/Table'
import { LigneImmeubleModel } from '../types/detailAvisModel'
import React, { useContext } from 'react'
import { HeadRow } from '../../../components/table/types/tableModel'
import { gestionNombre, getKeyFromEnumValue } from '../../../utils/utils'
import { remplissageLigneSelonEnteteTableau } from '../../../components/table/utils/tableUtils'
import { detailAvisResponseEnum, enteteTableauAffichageImmeubleEnum, enteteTableauAffichageImmeubleLabelEnum } from '../enums/detailAvisEnum'
import { columnsEnum } from '../../../components/table/enums/columnsEnum'
import { useFormContext } from 'react-hook-form'
import CustomTextField from '../../../components/fields/CustomTextField'
import thousandsSeparator from '../../../utils/thousandsSeparator'
import { style, styleFrais } from './TableauTraitementImmeuble'
import FormNumberFormatField from '../../../components/fields/FormNumberFormatField'
import { fontFamilyBlackerDisplay, gecinaBlueMain } from '../../../theme'
import { TraitementAvisContext } from '../context/TraitementAvisContext'


const TableauTotaux = () => {
	const { formValues } = useContext(TraitementAvisContext)
	const { register, watch } = useFormContext()

	const entetesTableauTraitementAvis: HeadRow[] = [
		{
			label: enteteTableauAffichageImmeubleLabelEnum.caseVide,
			id: getKeyFromEnumValue<enteteTableauAffichageImmeubleEnum>(enteteTableauAffichageImmeubleEnum, enteteTableauAffichageImmeubleEnum.caseVide),
			render: (nomLigne: string) => <strong>{nomLigne}</strong>
		},
		{
			label: enteteTableauAffichageImmeubleLabelEnum.commune,
			id: columnsEnum.customColonne,
			render: (ligneTableau: LigneImmeubleModel) => {
				if (ligneTableau[detailAvisResponseEnum.type] === detailAvisResponseEnum.totalLigne) {
					return <CustomTextField
						sx={style}
						register={register(`${detailAvisResponseEnum.totalLigne}.${detailAvisResponseEnum.communeCotisation}`)}
						id={`${detailAvisResponseEnum.totalLigne}.${detailAvisResponseEnum.communeCotisation}`}
						inputProps={{
							disabled: true,
							value: thousandsSeparator(gestionNombre(watch(`${detailAvisResponseEnum.totalLigne}.${detailAvisResponseEnum.communeCotisation}`)))
						}}
					/>
				}
				return (
					<FormNumberFormatField
						sx={styleFrais}
						register={register(`${ligneTableau[detailAvisResponseEnum.type]}.${detailAvisResponseEnum.communeCotisation}`)}
						value={thousandsSeparator(gestionNombre(watch(`${ligneTableau[detailAvisResponseEnum.type]}.${detailAvisResponseEnum.communeCotisation}`)))}
						id={`${ligneTableau[detailAvisResponseEnum.type]}.${detailAvisResponseEnum.communeCotisation}`}
						decimalScale={2}
						disabled
					/>
				)
			}
		},
		{
			label: enteteTableauAffichageImmeubleLabelEnum.interComm,
			id: columnsEnum.customColonne,
			render: (ligneTableau: LigneImmeubleModel) => {
				if (ligneTableau[detailAvisResponseEnum.type] === detailAvisResponseEnum.totalLigne) {
					return <CustomTextField
						sx={style}
						register={register(`${detailAvisResponseEnum.totalLigne}.${detailAvisResponseEnum.interCommCotisation}`)}
						id={`${detailAvisResponseEnum.totalLigne}.${detailAvisResponseEnum.interCommCotisation}`}
						inputProps={{
							disabled: true,
							value: thousandsSeparator(gestionNombre(watch(`${detailAvisResponseEnum.totalLigne}.${detailAvisResponseEnum.interCommCotisation}`)))
						}}
					/>
				} else if (ligneTableau[detailAvisResponseEnum.type] === detailAvisResponseEnum.communeCotisation) {
					return (
						<FormNumberFormatField
							sx={styleFrais}
							register={register(`${ligneTableau[detailAvisResponseEnum.type]}.${detailAvisResponseEnum.interCommCotisation}`)}
							value={thousandsSeparator(gestionNombre(watch(`${ligneTableau[detailAvisResponseEnum.type]}.${detailAvisResponseEnum.interCommCotisation}`)))}
							id={`${ligneTableau[detailAvisResponseEnum.type]}.${detailAvisResponseEnum.interCommCotisation}`}
							decimalScale={2}
							disabled
						/>
					)
				}
				return <>-</>
			}
		},
		{
			label: enteteTableauAffichageImmeubleLabelEnum.departement,
			id: columnsEnum.customColonne,
			render: (ligneTableau: LigneImmeubleModel) => {
				if (ligneTableau[detailAvisResponseEnum.type] === detailAvisResponseEnum.totalLigne) {
					return <CustomTextField
						sx={style}
						register={register(`${detailAvisResponseEnum.totalLigne}.${detailAvisResponseEnum.departementCotisation}`)}
						id={`${detailAvisResponseEnum.totalLigne}.${detailAvisResponseEnum.departementCotisation}`}
						inputProps={{
							disabled: true,
							value: thousandsSeparator(gestionNombre(watch(`${detailAvisResponseEnum.totalLigne}.${detailAvisResponseEnum.departementCotisation}`)))
						}}
					/>
				}
				return (
					<FormNumberFormatField
						sx={styleFrais}
						register={register(`${ligneTableau[detailAvisResponseEnum.type]}.${detailAvisResponseEnum.departementCotisation}`)}
						value={thousandsSeparator(gestionNombre(watch(`${ligneTableau[detailAvisResponseEnum.type]}.${detailAvisResponseEnum.departementCotisation}`)))}
						id={`${ligneTableau[detailAvisResponseEnum.type]}.${detailAvisResponseEnum.departementCotisation}`}
						decimalScale={2}
						disabled
					/>
				)
			}
		},
		{
			label: enteteTableauAffichageImmeubleLabelEnum.taxeSpe,
			id: columnsEnum.customColonne,
			render: (ligneTableau: LigneImmeubleModel) => {
				if (ligneTableau[detailAvisResponseEnum.type] === detailAvisResponseEnum.totalLigne) {
					return <CustomTextField
						sx={style}
						register={register(`${detailAvisResponseEnum.totalLigne}.${detailAvisResponseEnum.taxeSpecialeCotisation}`)}
						id={`${detailAvisResponseEnum.totalLigne}.${detailAvisResponseEnum.taxeSpecialeCotisation}`}
						inputProps={{
							disabled: true,
							value: thousandsSeparator(gestionNombre(watch(`${detailAvisResponseEnum.totalLigne}.${detailAvisResponseEnum.taxeSpecialeCotisation}`)))
						}}
					/>
				}
				return (
					<FormNumberFormatField
						sx={styleFrais}
						register={register(`${ligneTableau[detailAvisResponseEnum.type]}.${detailAvisResponseEnum.taxeSpecialeCotisation}`)}
						value={thousandsSeparator(gestionNombre(watch(`${ligneTableau[detailAvisResponseEnum.type]}.${detailAvisResponseEnum.taxeSpecialeCotisation}`)))}
						id={`${ligneTableau[detailAvisResponseEnum.type]}.${detailAvisResponseEnum.taxeSpecialeCotisation}`}
						decimalScale={2}
						disabled
					/>
				)
			}
		},
		{
			label: enteteTableauAffichageImmeubleLabelEnum.syndicat,
			id: columnsEnum.customColonne,
			render: (ligneTableau: LigneImmeubleModel) => {
				if (ligneTableau[detailAvisResponseEnum.type] === detailAvisResponseEnum.totalLigne) {
					return <CustomTextField
						sx={style}
						register={register(`${detailAvisResponseEnum.totalLigne}.${detailAvisResponseEnum.syndicatCotisation}`)}
						id={`${detailAvisResponseEnum.totalLigne}.${detailAvisResponseEnum.syndicatCotisation}`}
						inputProps={{
							disabled: true,
							value: thousandsSeparator(gestionNombre(watch(`${detailAvisResponseEnum.totalLigne}.${detailAvisResponseEnum.syndicatCotisation}`)))
						}}
					/>
				}
				return (
					<FormNumberFormatField
						sx={styleFrais}
						register={register(`${ligneTableau[detailAvisResponseEnum.type]}.${detailAvisResponseEnum.syndicatCotisation}`)}
						value={thousandsSeparator(gestionNombre(watch(`${ligneTableau[detailAvisResponseEnum.type]}.${detailAvisResponseEnum.syndicatCotisation}`)))}
						id={`${ligneTableau[detailAvisResponseEnum.type]}.${detailAvisResponseEnum.syndicatCotisation}`}
						decimalScale={2}
						disabled
					/>
				)
			}
		},
		{
			label: enteteTableauAffichageImmeubleLabelEnum.teom,
			id: columnsEnum.customColonne,
			render: (ligneTableau: LigneImmeubleModel) => {
				if (ligneTableau[detailAvisResponseEnum.type] === detailAvisResponseEnum.totalLigne) {
					return <CustomTextField
						sx={style}
						register={register(`${detailAvisResponseEnum.totalLigne}.${detailAvisResponseEnum.teomCotisation}`)}
						id={`${detailAvisResponseEnum.totalLigne}.${detailAvisResponseEnum.teomCotisation}`}
						inputProps={{
							disabled: true,
							value: thousandsSeparator(gestionNombre(watch(`${detailAvisResponseEnum.totalLigne}.${detailAvisResponseEnum.teomCotisation}`)))
						}}
					/>
				}
				return (
					<FormNumberFormatField
						sx={styleFrais}
						register={register(`${ligneTableau[detailAvisResponseEnum.type]}.${detailAvisResponseEnum.teomCotisation}`)}
						value={thousandsSeparator(gestionNombre(watch(`${ligneTableau[detailAvisResponseEnum.type]}.${detailAvisResponseEnum.teomCotisation}`)))}
						id={`${ligneTableau[detailAvisResponseEnum.type]}.${detailAvisResponseEnum.teomCotisation}`}
						decimalScale={2}
						disabled
					/>
				)
			}
		},
		{
			label: enteteTableauAffichageImmeubleLabelEnum.gemapi,
			id: columnsEnum.customColonne,
			render: (ligneTableau: LigneImmeubleModel) => {
				if (ligneTableau[detailAvisResponseEnum.type] === detailAvisResponseEnum.totalLigne) {
					return <CustomTextField
						sx={style}
						register={register(`${detailAvisResponseEnum.totalLigne}.${detailAvisResponseEnum.gemapiCotisation}`)}
						id={`${detailAvisResponseEnum.totalLigne}.${detailAvisResponseEnum.gemapiCotisation}`}
						inputProps={{
							disabled: true,
							value: thousandsSeparator(gestionNombre(watch(`${detailAvisResponseEnum.totalLigne}.${detailAvisResponseEnum.gemapiCotisation}`)))
						}}
					/>
				}
				return (
					<FormNumberFormatField
						sx={styleFrais}
						register={register(`${ligneTableau[detailAvisResponseEnum.type]}.${detailAvisResponseEnum.gemapiCotisation}`)}
						value={thousandsSeparator(gestionNombre(watch(`${ligneTableau[detailAvisResponseEnum.type]}.${detailAvisResponseEnum.gemapiCotisation}`)))}
						id={`${ligneTableau[detailAvisResponseEnum.type]}.${detailAvisResponseEnum.gemapiCotisation}`}
						decimalScale={2}
						disabled
					/>
				)
			}
		},
		{
			label: enteteTableauAffichageImmeubleLabelEnum.total,
			id: columnsEnum.customColonne,
			render: (ligneTableau: LigneImmeubleModel) => {
				return <CustomTextField
					sx={style}
					id={`${ligneTableau[detailAvisResponseEnum.type]}.${detailAvisResponseEnum.total}`}
					inputProps={{
						disabled: true,
						value: thousandsSeparator(gestionNombre(watch(`${ligneTableau[detailAvisResponseEnum.type]}.${detailAvisResponseEnum.total}`)))
					}}
				/>
			}
		}
	]


	return (
		<Box sx={{ width: '100%' }}>
			<Box sx={{
				color: gecinaBlueMain,
				paddingTop: '42px',
				fontFamily: fontFamilyBlackerDisplay,
				fontSize: 22,
				fontWeight: 900,
				mt: '60px',
				mb: '40px'
			}}>
				Total de l'avis
			</Box>
			<CustomTable
				id="Totaux"
				hasSort={false}
				headRows={entetesTableauTraitementAvis}
				rows={remplissageLigneSelonEnteteTableau<LigneImmeubleModel>([formValues[detailAvisResponseEnum.communeCotisation], formValues[detailAvisResponseEnum.frais], formValues[detailAvisResponseEnum.totalLigne]], entetesTableauTraitementAvis)}
				hasPagination={false}
				allBorders
			/>
		</Box>
	)
}

export default TableauTotaux