// Enumération des champs du formulaire pour filtrer les avis
export enum filtresListeAvisFieldsEnum {
	reference = 'reference',
	statut = 'statut',
	recuLe = 'recuLe',
	avantLe = 'avantLe',
	dateSortDesc = 'dateSortDesc'
}

// Enumération des statuts des avis
export enum statutListeAvisEnum {
	all = 'Tous',
	A_TRAITER = 'A traiter',
	VALIDE = 'Validé',
	GENERE = 'Généré',
	TRANSMIS = 'Transmis',
	ERREUR = 'En erreur'
}

// Enumération des clés du POST des filtres de récupération de la liste des avis
export enum filtresListeAvisPostEnum {
	reference = 'reference',
	statut = 'statut',
	recuLe = 'recuLe',
	avantLe = 'avantLe',
	dateSortDesc = 'dateSortDesc',
	page = 'page'
}

// Enum pour les clés de la réponse de la récupération de la liste des procédures
export enum filtresListeAvisResponseEnum {
	list = 'list',

	// Éléments dans l'objet "list"
	id = 'id',
	reference = 'reference',
	recuLe = 'date_reception',
	fichierDepose = 'fichier_depose',
	statut = 'statut',
	traiteLe = 'date_validation',
	dateGeneration = 'date_generation',
	dateTransmission = 'date_transmission',
	departement = 'departement',
	commune = 'commune',
	dateComptable = 'date_comptable',
	dateExigibilite = 'date_exgibilite',
	anneeReddition = 'annee_reddition',

	total = 'total',
	page = 'page',

	// Éléments dans l'objet "page"
	current = 'current',
	last = 'last'
}

export enum enteteTableauAffichageListeLabelEnum {
	reference = 'Référence',
	fichier = 'Fichier',
	recuLe = 'Reçu le',
	traiteLe = 'Traité le',
	statut = 'Statut',
	date_generation = 'date_generation',
	date_transmission = 'date_transmission',
	icon = ''
}

export enum enteteTableauAffichageListeEnum {
	reference = 'reference',
	fichier_depose = 'fichier_depose',
	date_reception = 'date_reception',
	date_validation = 'date_validation',
	statut = 'statut',
	date_generation = 'date_generation',
	date_transmission = 'date_transmission',
	icon = 'icon'
}

