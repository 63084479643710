import { FILTRES_COMPTES_NOMENCLATURE_CHARGES } from '../enums/filtresComptesNomenclatureChargesEnum'

export interface ComptesNomenclatureChargesModel {
	'@id': string | null,
	compte: string,
	tencode: string,
	codeposte: string,
	date_debut: Date,
	date_fin: Date | null,
	nom_modificateur: string,
	id_modificateur: string
}

export interface FiltresComptesNomenclatureChargesModel {
	[FILTRES_COMPTES_NOMENCLATURE_CHARGES.COMPTE]: string
	[FILTRES_COMPTES_NOMENCLATURE_CHARGES.PAGE]: number
}

export interface PopinComptesNomenclatureChargesModel {
	open: boolean,
	compteNomenclatureCharges: ComptesNomenclatureChargesModel | undefined
}

export const INITIAL_COMPTES_NOMENCLATURE_CHARGES: ComptesNomenclatureChargesModel = {
	'@id': '',
	codeposte: '',
	compte: '',
	date_debut: new Date(),
	date_fin: null,
	nom_modificateur: '',
	tencode: '',
	id_modificateur: ''
}

export const INITIAL_POPIN_CNC: PopinComptesNomenclatureChargesModel = {
	open: false,
	compteNomenclatureCharges: INITIAL_COMPTES_NOMENCLATURE_CHARGES
}

export const INITIAL_FILTRE_CNC: FiltresComptesNomenclatureChargesModel = {
	[FILTRES_COMPTES_NOMENCLATURE_CHARGES.COMPTE]: '',
	[FILTRES_COMPTES_NOMENCLATURE_CHARGES.PAGE]: 1
}