import { styled } from '@mui/system'
import { Checkbox, CheckboxProps } from '@mui/material'
import { gecinaBlueMain, gecinaBrownMain } from '../../theme'
import { ChampDansFormulaire } from '../fields/types/typePourChampDansFormulaireModel'

const CustomCheckbox = styled((props: CheckboxProps & Partial<ChampDansFormulaire>) => (
	<Checkbox {...props} />
))(({ theme }) => ({
	width: 30,
	height: 30,
	'&.Mui-checked': {
		color: gecinaBlueMain,
		backgroundColor: gecinaBrownMain
	}

}))

export default CustomCheckbox