import { DialogCommonProps } from '../../../components/dialog/types/dialogModel'
import { EMPTY_TAUX, TauxResponseModel } from '../types/TauxResponseModel'
import React, { Dispatch, useEffect, useState } from 'react'
import Dialog from '../../../components/dialog/Dialog'
import { Box, Grid } from '@mui/material'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { TauxReponseEnum } from '../enums/gestionTauxTableauLabelEnum'
import TextField from '../../../components/fields/FormTextField'
import { fontFamilyBlackerDisplay, gecinaBlueMain, gecinaBrownMain, WHITE } from '../../../theme'
import Button from '../../../components/button/Button'
import { transformNumber, validateFrenchNumber } from '../../../utils/utils'
import { updateTaux } from '../api/gestionTauxAPI'
import { buttonTypesEnum } from '../../../components/button/enums/buttonTypesEnum'
import { displaySnackbar } from '../../../components/snackBar/reducer/actions'
import { useDispatch } from 'react-redux'
import ResponseEnum from '../../../enum/responseEnum'
import TextEnum from '../../../enum/textEnum'
import { ERREUR_ID } from '../../../components/snackBar/SnackBar'

interface AcceptingProps {
	taux?: TauxResponseModel,
	setRafraichir: Dispatch<React.SetStateAction<boolean>>,
	rafraichir: boolean
}

type PopinEditTauxProps = DialogCommonProps & AcceptingProps

//Champs de la popin
const POPIN_FIELDS = [
	{
		columns: 1,
		fields: [
			{
				id: TauxReponseEnum.id,
				type: 'hidden'
			},
			{
				id: TauxReponseEnum.annee,
				type: 'text',
				maxLength: 4,
				label: 'Année de reddition *',
				placeholder: 'Saisir une année',
				disableOnUpdate: true
			}
		]
	},
	{
		columns: 2,
		fields: [
			{
				id: TauxReponseEnum.commune,
				type: 'text',
				maxLength: 10,
				label: 'Commune *',
				placeholder: 'Saisir le taux de la taxe commune',
				disableOnUpdate: false
			},
			{
				id: TauxReponseEnum.departement,
				type: 'text',
				maxLength: 10,
				label: 'Département *',
				placeholder: 'Saisir le taux de la taxe département',
				disableOnUpdate: false
			}
		]
	},
	{
		columns: 2,
		fields: [
			{
				id: TauxReponseEnum.taxe_speciale,
				type: 'text',
				maxLength: 10,
				label: 'Taxe spéciale *',
				placeholder: 'Saisir le taux de la taxe spéciale',
				disableOnUpdate: false
			},
			{
				id: TauxReponseEnum.taxe_speciale_gp,
				type: 'text',
				maxLength: 10,
				label: 'Taxe spéciale GP *',
				placeholder: 'Saisir le taux de la taxe spéciale GP',
				disableOnUpdate: false
			}
		]
	},
	{
		columns: 2,
		fields: [
			{
				id: TauxReponseEnum.repartition_gp_taxe_speciale,
				type: 'text',
				maxLength: 10,
				label: 'Répartition GP - TS *',
				placeholder: 'Saisir la répartition GP - TS',
				disableOnUpdate: false
			},
			{
				id: TauxReponseEnum.repartition_gp_taxe_speciale_gp,
				type: 'text',
				maxLength: 10,
				label: 'Répartition GP - TS/GP *',
				placeholder: 'Saisir la répartition GP - TS/GP',
				disableOnUpdate: false
			}
		]
	},
	{
		columns: 2,
		fields: [
			{
				id: TauxReponseEnum.syndicat_commune,
				type: 'text',
				maxLength: 10,
				label: 'Syndicat de commune *',
				placeholder: 'Saisir le taux de syndicat de comm.',
				disableOnUpdate: false
			},
			{
				id: TauxReponseEnum.teom,
				type: 'text',
				maxLength: 10,
				label: 'TEOM *',
				placeholder: 'Saisir le taux de la TEOM',
				disableOnUpdate: false
			}
		]
	},
	{
		columns: 1,
		fields: [
			{
				id: TauxReponseEnum.gemapi,
				type: 'text',
				maxLength: 10,
				label: 'GEMAPI *',
				placeholder: 'Saisir le taux de GEMAPI',
				disableOnUpdate: false
			}
		]
	}
]

const PopinEditTauxProps: React.FC<PopinEditTauxProps> = (
	{
		taux,
		open,
		onCloseDialog,
		setRafraichir,
		rafraichir
	}
) => {

	const methods = useForm<TauxResponseModel>({
		defaultValues: taux ?? EMPTY_TAUX
	})

	const { register, handleSubmit, reset, formState: { errors } } = methods
	const dispatch = useDispatch()
	const [saveLoad, setSaveLoad] = useState<boolean>(false)

	const onSubmit: SubmitHandler<TauxResponseModel> = (values: TauxResponseModel) => {
		setSaveLoad(true)
		updateTaux(values)
			.then((data) => {

				if (data.toString() !== ResponseEnum.OK) {
					dispatch(displaySnackbar(
						{
							id: ERREUR_ID,
							message: data.toString(),
							open: true,
							hideIcon: false
						})
					)
					setSaveLoad(false)
				} else {
					//Ferme la snackBar auto si ça réussit
					dispatch(displaySnackbar(
						{
							id: ERREUR_ID,
							message: '',
							open: false,
							hideIcon: false
						}))
					onCloseDialog()
					setSaveLoad(false)
					setRafraichir(!rafraichir)
				}
			})
	}

	useEffect(() =>
		//On reset le form a chaque ouverture de la popin.
		reset(taux ?? EMPTY_TAUX), [open])


	return (

		<Dialog
			open={open}
			onCloseDialog={onCloseDialog}
			fullScreen={false}
			withPadding={false}
			maxWidth={false}
		>
			<Box justifyContent="center">
				<Grid container sx={{ fontFamily: fontFamilyBlackerDisplay, fontSize: 22, borderBottom: '1px solid', padding: 0 }}>
					{
						taux === undefined ?
							<Box sx={{ paddingTop: '40px', paddingLeft: '40px', paddingBottom: '20px' }}>
								Ajouter les taux d’une année
							</Box>
							:
							<Box sx={{ paddingTop: '40px', paddingLeft: '40px', paddingBottom: '20px' }}>
								Modifier les taux d’une année
							</Box>
					}
				</Grid>
				<Grid container sx={{ padding: '40px' }}>
					<FormProvider {...methods}>
						<form onSubmit={handleSubmit(onSubmit)}>
							{
								POPIN_FIELDS.map((column) => (
									<Grid container item flexDirection={'row'}>
										{
											column.fields.map((field, index) => (
												<>
													{
														field.type === 'hidden' &&
														<input type={'hidden'} id={field.id} />
													}
													{
														field.type === 'text' &&
														<Grid item sx={{ width: 345, marginRight: '20px', mt: '15px' }}>
															<TextField sx={{ padding: '15px', fontSize: 13, mb: 0 }}
															           register={register(field.id, {
																           required: TextEnum.requis,
																           validate: {
																	           isNumber: v => !validateFrenchNumber(v) || 'Veuillez rentrer un nombre valide'
																           },
																           setValueAs: v => transformNumber(v)
															           })}
															           maxLength={field.maxLength}
															           errors={errors}
															           id={field.id}
															           label={field.label}
															           placeholder={field.placeholder}
															           withBorder
															           inputProps={
																           field.disableOnUpdate ? { readOnly: !!taux } : {}
															           }
															/>
														</Grid>
													}
												</>
											))
										}
									</Grid>
								))
							}

							<Grid item container sx={{ marginTop: '60px' }} justifyContent={'end'}>
								<Button bgcolor={WHITE} color={gecinaBlueMain} border={true} disabled={false} sx={{ marginRight: '24px' }} onClick={() => {
									dispatch(displaySnackbar(
										{
											id: ERREUR_ID,
											message: '',
											open: false,
											hideIcon: false
										}))
									onCloseDialog()
								}}>
									Annuler
								</Button>
								<Button bgcolor={gecinaBlueMain} color={gecinaBrownMain} border={false} disabled={saveLoad} type={buttonTypesEnum.submit}>
									{taux === undefined ?
										'Ajouter'
										:
										'Enregister'
									}
								</Button>
							</Grid>

						</form>
					</FormProvider>
				</Grid>
			</Box>
		</Dialog>

	)

}

export default PopinEditTauxProps