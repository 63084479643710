import React from 'react'
import NumberFormat from 'react-number-format'
import { fontFamilyMonserrat, gecinaBlueMain, gecinaErrorColor, WHITE } from '../../theme'
import { Input } from '@mui/material'
import { useFormContext } from 'react-hook-form'

// Component used to format entered number with thousand separator
const FormNumberFormatField = (componentProps: any) => {
	let thousandSeparator = ''
	if (!('thousandSeparator' in componentProps)) {
		thousandSeparator = ' '
	} else {
		thousandSeparator = componentProps.thousandSeparator
	}

	const { formState: { errors } } = useFormContext()

	return (
		<>
			{
				componentProps.label && (
					<span
						style={{
							width: '100%',
							paddingTop: 7,
							fontSize: 16,
							marginBottom: 5,
							marginLeft: 0,
							boxSizing: 'border-box',
							textAlign: 'left',
							fontFamily: fontFamilyMonserrat,
							fontWeight: 600
						}}
					>
						{componentProps.label}
					</span>
				)
			}

			<NumberFormat
				customInput={Input}
				id={componentProps.id}
				isNumericString
				{...componentProps.register}
				sx={[
					{
						width: '100%',
						mt: '5px',
						padding: '0 7px',
						'input': {
							fontFamily: fontFamilyMonserrat,
							fontSize: 15,
							fontWeight: 500
						},
						':before': {
							borderColor: WHITE
						},
						':hover:not(.Mui-disabled):before': {
							borderWidth: '1px',
							borderColor: `${WHITE} !important`
						},
						':after': {
							borderWidth: '1px',
							borderColor: `${WHITE} !important`
						},
						'.Mui-disabled': {
							color: gecinaBlueMain,
							WebkitTextFillColor: '#000000 !important'
						},
						':hover:(.Mui-disabled):before': {
							color: gecinaBlueMain,
							borderWidth: '1px',
							borderColor: 'transparent !important'
						},
						':(.Mui-disabled):before': {
							borderWidth: '1px',
							borderColor: 'transparent !important'
						},
						...componentProps.sx
					},
					componentProps.disabled && {
						':before': {
							borderColor: 'transparent'
						}
					},
					componentProps.withBorder && {
						border: `1px solid ${gecinaBlueMain}`
					},
					{
						borderColor: componentProps.register ? `${componentProps.register.name in errors ? gecinaErrorColor : 'inherit'}` : 'inherit'
					}
				]}
				onValueChange={(values) => ('onChange' in componentProps) ? componentProps.onChange(values.value || '0') : {}}
				thousandSeparator={thousandSeparator}
				decimalSeparator=","
				value={componentProps.value}
				defaultValue={componentProps.defaultValue || componentProps.value}
				disabled={componentProps.disabled || false}
				allowNegative={componentProps.allowNegative || false}
				decimalScale={componentProps.decimalScale || 0}
				suffix={componentProps.suffix || ''}
				isAllowed={(floatValue) => componentProps.maxLength ? floatValue.value.length <= componentProps.maxLength : true}
			/>
		</>
	)
}

export default FormNumberFormatField
