import React, { useContext, useMemo } from 'react'
import { HeadRow } from '../../../components/table/types/tableModel'
import { getKeyFromEnumValue } from '../../../utils/utils'
import { Box, IconButton } from '@mui/material'
import { remplissageLigneSelonEnteteTableau } from '../../../components/table/utils/tableUtils'
import CustomTable from '../../../components/table/Table'
import { ENTETE_TABLEAU_LISTE_UTILISATEURS_ENUM } from '../enums/ENTETE_TABLEAU_LISTE_UTILISATEURS_ENUM'
import { DroitsModel, UtilisateursModel } from '../types/utilisateursModel'
import { UtilisateursContext } from '../context/UtilisateursContext'
import Loader from '../../../components/loader/Loader'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { gecinaBrownMain } from '../../../theme'
import { columnsEnum } from '../../../components/table/enums/columnsEnum'
import { APPLICATIONS_ENUM, APPLICATIONS_LABEL_ENUM, FILTRES_UTILISATEURS, PROFILS_ENUM, PROFILS_LABEL_ENUM } from '../enums/filtresUtilisateursEnum'
import { getListeUtilisateurs } from '../api/utilisateursAPI'

const ListeUtilisateurs = () => {
	const {
		utilisateurs,
		setUtilisateurs,
		chargementUtilisateurs,
		setOuverturePopin,
		setPopinSuppression,
		filtresUtilisateurs,
		setFiltresUtilisateurs
	} = useContext(UtilisateursContext)

	const modifierUtilisateur = (utilisateurs: UtilisateursModel) => setOuverturePopin({
		open: true,
		utilisateurs: {
			'@id': utilisateurs['@id'],
			[FILTRES_UTILISATEURS.PRENOM]: utilisateurs.prenom,
			[FILTRES_UTILISATEURS.NOM]: utilisateurs.nom,
			[FILTRES_UTILISATEURS.EMAIL]: utilisateurs.email,
			[FILTRES_UTILISATEURS.ADMINISTRATION]: !!utilisateurs.droits.find((droit) => droit.application === APPLICATIONS_ENUM.ADMINISTRATION),
			[FILTRES_UTILISATEURS.TAXES_FONCIERES]: !!utilisateurs.droits.find((droit) => droit.application === APPLICATIONS_ENUM.TAXES_FONCIERES),
			[FILTRES_UTILISATEURS.SALAIRES_GARDIENS]: !!utilisateurs.droits.find((droit) => droit.application === APPLICATIONS_ENUM.SALAIRES_GARDIENS),
			[FILTRES_UTILISATEURS.PROFIL]: utilisateurs.droits.find((droit) => droit.profil === PROFILS_ENUM.ADMINISTRATEUR) ? PROFILS_ENUM.ADMINISTRATEUR : PROFILS_ENUM.UTILISATEUR
		}
	})

	const suppressionUtilisateurs = (utilisateurs: UtilisateursModel) => setPopinSuppression({
		open: true,
		utilisateurs: {
			'@id': utilisateurs['@id'],
			[FILTRES_UTILISATEURS.PRENOM]: utilisateurs.prenom,
			[FILTRES_UTILISATEURS.NOM]: utilisateurs.nom,
			[FILTRES_UTILISATEURS.EMAIL]: utilisateurs.email,
			[FILTRES_UTILISATEURS.ADMINISTRATION]: !!utilisateurs.droits.find((droit) => droit.application.includes(APPLICATIONS_ENUM.ADMINISTRATION)),
			[FILTRES_UTILISATEURS.TAXES_FONCIERES]: !!utilisateurs.droits.find((droit) => droit.application.includes(APPLICATIONS_ENUM.TAXES_FONCIERES)),
			[FILTRES_UTILISATEURS.SALAIRES_GARDIENS]: !!utilisateurs.droits.find((droit) => droit.application.includes(APPLICATIONS_ENUM.SALAIRES_GARDIENS)),
			[FILTRES_UTILISATEURS.PROFIL]: utilisateurs.droits.find((droit) => droit.profil === PROFILS_ENUM.ADMINISTRATEUR) ? PROFILS_ENUM.ADMINISTRATEUR : PROFILS_ENUM.UTILISATEUR
		}
	})

	const entetesTableau: HeadRow[] = useMemo(() => (
		[
			{
				label: ENTETE_TABLEAU_LISTE_UTILISATEURS_ENUM.nom,
				id: getKeyFromEnumValue<ENTETE_TABLEAU_LISTE_UTILISATEURS_ENUM>(ENTETE_TABLEAU_LISTE_UTILISATEURS_ENUM, ENTETE_TABLEAU_LISTE_UTILISATEURS_ENUM.nom)
			},
			{
				label: ENTETE_TABLEAU_LISTE_UTILISATEURS_ENUM.prenom,
				id: getKeyFromEnumValue<ENTETE_TABLEAU_LISTE_UTILISATEURS_ENUM>(ENTETE_TABLEAU_LISTE_UTILISATEURS_ENUM, ENTETE_TABLEAU_LISTE_UTILISATEURS_ENUM.prenom)
			},
			{
				label: ENTETE_TABLEAU_LISTE_UTILISATEURS_ENUM.email,
				id: getKeyFromEnumValue<ENTETE_TABLEAU_LISTE_UTILISATEURS_ENUM>(ENTETE_TABLEAU_LISTE_UTILISATEURS_ENUM, ENTETE_TABLEAU_LISTE_UTILISATEURS_ENUM.email)
			},
			{
				label: ENTETE_TABLEAU_LISTE_UTILISATEURS_ENUM.droits,
				id: getKeyFromEnumValue<ENTETE_TABLEAU_LISTE_UTILISATEURS_ENUM>(ENTETE_TABLEAU_LISTE_UTILISATEURS_ENUM, ENTETE_TABLEAU_LISTE_UTILISATEURS_ENUM.droits),
				render: (droits: DroitsModel[]) => {
					// On map la liste des droits pour récupérer uniquement les applications + le profil si c'est différent de ADMINISTRATEUR
					const listeDroits = droits.map((droit) => `${APPLICATIONS_LABEL_ENUM[droit.application]}${droit.profil !== PROFILS_ENUM.ADMINISTRATEUR ? ` (${PROFILS_LABEL_ENUM[droit.profil]})` : ''}`).join(', ')
					return (<Box>{listeDroits}</Box>)
				}
			},
			{
				label: '',
				id: columnsEnum.customColonne,
				width: '15%',
				render: (ligneTableau: UtilisateursModel) => (
					<Box
						className="elementCache"
						sx={{
							visibility: 'hidden',
							textAlign: 'right',

							'& svg': {
								color: gecinaBrownMain
							}
						}}
					>
						<IconButton aria-label="edit" title="Modification du compte" onClick={() => modifierUtilisateur(ligneTableau)}>
							<EditIcon />
						</IconButton>

						<IconButton aria-label="delete" title="Suppression du compte" onClick={() => suppressionUtilisateurs(ligneTableau)}>
							<DeleteIcon />
						</IconButton>

					</Box>
				)
			}
		]
	), [])

	const auChangementDePage = ((nouvellePage: number) => {
		const data = {
			...filtresUtilisateurs,
			[FILTRES_UTILISATEURS.PAGE]: nouvellePage
		}

		getListeUtilisateurs(nouvellePage, filtresUtilisateurs[FILTRES_UTILISATEURS.EMAIL])
			.then((response) => {
				setUtilisateurs(response)
				setFiltresUtilisateurs(data)
			})
			.finally(() => window.scrollTo(0, 0))
	})

	if (chargementUtilisateurs) {
		return (
			<Box height="150px">
				<Loader size={50} />
			</Box>
		)
	}

	return (
		<Box sx={{ paddingX: '172px' }}>
			<CustomTable
				id="listeUtilisateurs"
				headRows={entetesTableau}
				rows={remplissageLigneSelonEnteteTableau<UtilisateursModel>(utilisateurs['hydra:member'], entetesTableau)}
				total={utilisateurs['hydra:totalItems']}
				borderBottom
				auCliqueSurLigne={() => {
				}}
				hasPagination
				auChangementDePage={auChangementDePage}
				page={filtresUtilisateurs[FILTRES_UTILISATEURS.PAGE] - 1}
				hasSort={false}
			/>
		</Box>
	)
}

export default ListeUtilisateurs