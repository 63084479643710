import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import SvgProfile from '../../../icons/SvgProfile'
import { gecinaBlueMain } from '../../../theme'
import localStorageEnum from '../../../enum/localstorageEnum'
import { makeStyles } from 'tss-react/mui'
import styles from '../../../styles'

const UserInfo: React.FC = () => {
	const { classes } = useStyles()
	const [nomUtilisateur, setNomUtilisateur] = useState<string>()

	// Récupération de l'utilisateur connecté
	useEffect(() => {
		if (!nomUtilisateur && !!localStorage.getItem(localStorageEnum.informationsUtilisateur)) {
			setNomUtilisateur(JSON.parse(localStorage.getItem(localStorageEnum.informationsUtilisateur) || '{}').name)
		}
	}, [])

	return (
		<Grid container item alignItems="center">
			<SvgProfile color={gecinaBlueMain} height={40} width={40} />
			<Grid container item xs className={classes.texteStyle}>
				{nomUtilisateur || ''}
			</Grid>
		</Grid>
	)
}

const useStyles = makeStyles()(theme => ({
	...styles(theme),
	texteStyle: {
		...theme.typography.text1,
		lineHeight: '20px',
		paddingLeft: 15
	}
}))

export default UserInfo