import { Box, Grid } from '@mui/material'
import { gecinaBlueMain } from '../../../theme'
import React from 'react'
import FiltresImmeublesQuotesPartsResidentiel from './FiltresImmeublesQuotesPartsResidentiel'

const EnteteImmeublesQuotesPartsResidentiel = () => (
	<Box sx={{ background: gecinaBlueMain, minHeight: '192px', paddingX: '172px' }}>
		<Grid container item xs={12} justifyContent="space-between" sx={{ pt: 5 }}>
			<Box sx={{ fontFamily: 'BlackerDisplay', fontSize: 28, fontWeight: 900 }}>
				Paramétrage - Quotes parts résidentiel par immeuble
			</Box>
		</Grid>

		<Grid container item xs={12} justifyContent="space-evenly" sx={{ pt: 5 }}>
			<FiltresImmeublesQuotesPartsResidentiel />
		</Grid>
	</Box>
)

export default EnteteImmeublesQuotesPartsResidentiel