import { Grid } from '@mui/material'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import TextField from '../../../components/fields/FormTextField'
import { buttonTypesEnum } from '../../../components/button/enums/buttonTypesEnum'
import { fontFamilyMonserrat, gecinaBrownMain, WHITE } from '../../../theme'
import Button from '../../../components/button/Button'
import AutorenewIcon from '@mui/icons-material/Autorenew'
import React, { useContext } from 'react'
import { FILTRES_MATRICULES_TAUX } from '../enums/filtresMatriculesTauxEnum'
import { MatriculesTauxContext } from '../context/MatriculesTauxContext'
import { FiltresMatriculesTauxModel } from '../types/matriculesTauxModel'
import { getListeMatriculesTaux } from '../api/matriculesTauxAPI'

const INITIAL_VALEURS_FORMULAIRE = {
	[FILTRES_MATRICULES_TAUX.MATRICULE]: '',
	[FILTRES_MATRICULES_TAUX.REFERENCE_IMMEUBLE]: ''
}

const FiltresMatriculesTaux = () => {
	const { setChargementMatriculesTaux, setMatriculesTaux, setOuverturePopin, filtresMT, setFiltresMT } = useContext(MatriculesTauxContext)
	const methods = useForm<FiltresMatriculesTauxModel>({
		defaultValues: INITIAL_VALEURS_FORMULAIRE
	})
	const { register, handleSubmit, reset } = methods

	const onSubmit: SubmitHandler<FiltresMatriculesTauxModel> = (values) => {
		setChargementMatriculesTaux(true)
		const data = {
			...filtresMT,
			[FILTRES_MATRICULES_TAUX.PAGE]: 1
		}
		setFiltresMT(data)
		// Récupération de la liste des comptes selon les valeurs du filtre
		return getListeMatriculesTaux(1, values.matricule, values.referenceImmeuble)
			.then((response) => {
				setMatriculesTaux(response)
				setChargementMatriculesTaux(false)
			})
	}

	const ajoutMatricule = () => setOuverturePopin({ open: true, matriculesTaux: undefined })

	return (
		<FormProvider {...methods}>
			<form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
				<Grid container spacing={2}>
					<Grid item xs={3}>
						<TextField
							register={register(FILTRES_MATRICULES_TAUX.MATRICULE, {
								maxLength: {
									value: 20,
									message: 'Le matricule est limitée à 20 caractères'
								}
							})}
							maxLength={20}
							id={FILTRES_MATRICULES_TAUX.MATRICULE}
							label="Matricule"
							placeholder="Saisir un matricule"
						/>
					</Grid>
					<Grid item xs={3}>
						<TextField
							register={register(FILTRES_MATRICULES_TAUX.REFERENCE_IMMEUBLE, {
								maxLength: {
									value: 20,
									message: 'L\'immeuble est limitée à 20 caractères'
								}
							})}
							maxLength={20}
							id={FILTRES_MATRICULES_TAUX.REFERENCE_IMMEUBLE}
							label="Immeuble"
							placeholder="Saisir un code immeuble"
						/>
					</Grid>

					<Grid container item xs={2} spacing={2} sx={{ m: 0 }} alignContent="flex-start">
						<span
							style={{
								width: '100%',
								paddingRight: 15,
								paddingTop: 7,
								fontSize: 14,
								marginLeft: 0,
								boxSizing: 'border-box',
								textAlign: 'left',
								fontFamily: fontFamilyMonserrat,
								fontWeight: 500
							}}
						> </span>

						<Grid item xs={7} sx={{ pt: '0px !important', height: 'fit-content' }}>
							<Button
								sx={{
									height: '32px',
									p: 0,
									minWidth: '100%',
									fontWeight: 600
								}}
								bgcolor={gecinaBrownMain}
								color={WHITE}
								border={false}
								disabled={false}
								type={buttonTypesEnum.submit}
							>
								Filtrer
							</Button>
						</Grid>

						<Grid item xs={3} md={2} sx={{ pt: '0px !important', height: 'fit-content' }}>
							<Button
								sx={{
									height: 32,
									p: 0,
									minWidth: '100%'
								}}
								bgcolor={gecinaBrownMain}
								color={WHITE}
								border={false}
								disabled={false}
								type={buttonTypesEnum.submit}
								onClick={() => reset(INITIAL_VALEURS_FORMULAIRE)}
							>
								<AutorenewIcon />
							</Button>
						</Grid>
					</Grid>

					<Grid container item xs={3} spacing={2} sx={{ m: 0, ml: 'auto' }} alignContent="flex-start">
						<span
							style={{
								width: '100%',
								paddingRight: 15,
								paddingTop: 7,
								fontSize: 14,
								marginLeft: 0,
								boxSizing: 'border-box',
								textAlign: 'left',
								fontFamily: fontFamilyMonserrat,
								fontWeight: 500
							}}
						> </span>

						<Grid item xs={12} sx={{ pt: '0px !important', height: 'fit-content' }}>
							<Button
								sx={{
									height: '32px',
									p: 0,
									minWidth: '100%',
									fontWeight: 600
								}}
								bgcolor={gecinaBrownMain}
								color={WHITE}
								border={false}
								disabled={false}
								type={buttonTypesEnum.button}
								onClick={ajoutMatricule}
							>
								Ajouter un paramétrage
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</form>
		</FormProvider>
	)
}

export default FiltresMatriculesTaux