export enum APPLICATIONS_ENUM {
	ADMINISTRATION = 'ADMINISTRATION',
	TAXES_FONCIERES = 'TAXES_FONCIERES',
	SALAIRES_GARDIENS = 'SALAIRES_GARDIENS'
}

export enum APPLICATIONS_LABEL_ENUM {
	ADMINISTRATION = 'Administration',
	TAXES_FONCIERES = 'Taxes foncières',
	SALAIRES_GARDIENS = 'Salaires gardiens'
}

export enum PROFILS_ENUM {
	UTILISATEUR = 'UTILISATEUR',
	ADMINISTRATEUR = 'ADMINISTRATEUR'
}

export enum PROFILS_LABEL_ENUM {
	UTILISATEUR = 'Utilisateur',
	ADMINISTRATEUR = 'Administrateur'
}

export enum FILTRES_UTILISATEURS {
	ID = '@id',
	NOM = 'nom',
	PRENOM = 'prenom',
	EMAIL = 'email',
	ADMINISTRATION = 'administration',
	TAXES_FONCIERES = 'taxesFoncieres',
	SALAIRES_GARDIENS = 'salairesGardiens',
	PROFIL = 'profil',
	PAGE = 'page'
}

export const EMPTY_FORM = {
	[FILTRES_UTILISATEURS.ID]: null,
	[FILTRES_UTILISATEURS.NOM]: '',
	[FILTRES_UTILISATEURS.PRENOM]: '',
	[FILTRES_UTILISATEURS.EMAIL]: '',
	[FILTRES_UTILISATEURS.ADMINISTRATION]: false,
	[FILTRES_UTILISATEURS.TAXES_FONCIERES]: false,
	[FILTRES_UTILISATEURS.SALAIRES_GARDIENS]: false,
	[FILTRES_UTILISATEURS.PROFIL]: PROFILS_ENUM.ADMINISTRATEUR
}