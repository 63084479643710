import React, { useContext, useMemo } from 'react'
import { HeadRow } from '../../../components/table/types/tableModel'
import { getKeyFromEnumValue } from '../../../utils/utils'
import { Box, IconButton } from '@mui/material'
import { remplissageLigneSelonEnteteTableau } from '../../../components/table/utils/tableUtils'
import CustomTable from '../../../components/table/Table'
import { enteteTableauListeImmeublesQuotesPartsResidentielEnum } from '../enums/enteteTableauListeImmeublesQuotesPartsResidentielEnum'
import { ImmeublesQuotesPartsResidentielModel } from '../types/immeublesQuotesPartsResidentielModel'
import { ImmeublesQuotesPartsResidentielContext } from '../context/ImmeublesQuotesPartsResidentielContext'
import Loader from '../../../components/loader/Loader'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { gecinaBrownMain } from '../../../theme'
import { columnsEnum } from '../../../components/table/enums/columnsEnum'
import { FILTRES_IMMEUBLES_QUOTES_PARTS_RESIDENTIEL } from '../enums/filtresImmeublesQuotesPartsResidentielEnum'
import { getListeImmeublesQuotesPartsResidentiel } from '../api/immeublesQuotesPartsResidentielAPI'

const ListeImmeublesQuotesPartsResidentiel = () => {
	const {
		immeublesQuotesPartsResidentiel,
		setImmeublesQuotesPartsResidentiel,
		chargementImmeublesQuotesPartsResidentiel,
		setOuverturePopin,
		setPopinSuppression,
		filtresIQPR,
		setFiltresIQPR
	} = useContext(ImmeublesQuotesPartsResidentielContext)

	const modifierIQPR = (immeublesQuotesPartsResidentiel: ImmeublesQuotesPartsResidentielModel) => setOuverturePopin({
		open: true,
		immeublesQuotesPartsResidentiel
	})

	const suppressionIQPR = (immeublesQuotesPartsResidentiel: ImmeublesQuotesPartsResidentielModel) => setPopinSuppression({
		open: true,
		immeublesQuotesPartsResidentiel
	})

	const entetesTableau: HeadRow[] = useMemo(
		() => (
			[
				{
					label: enteteTableauListeImmeublesQuotesPartsResidentielEnum.referenceImmeuble,
					id: getKeyFromEnumValue<enteteTableauListeImmeublesQuotesPartsResidentielEnum>(enteteTableauListeImmeublesQuotesPartsResidentielEnum, enteteTableauListeImmeublesQuotesPartsResidentielEnum.referenceImmeuble)
				},
				{
					label: enteteTableauListeImmeublesQuotesPartsResidentielEnum.quotePartResidentiel,
					id: getKeyFromEnumValue<enteteTableauListeImmeublesQuotesPartsResidentielEnum>(enteteTableauListeImmeublesQuotesPartsResidentielEnum, enteteTableauListeImmeublesQuotesPartsResidentielEnum.quotePartResidentiel)
				},
				{
					label: '',
					id: columnsEnum.customColonne,
					width: '15%',
					render: (ligneTableau: ImmeublesQuotesPartsResidentielModel) => (
						<Box
							className="elementCache"
							sx={{
								visibility: 'hidden',
								textAlign: 'right',

								'& svg': {
									color: gecinaBrownMain
								}
							}}
						>
							<IconButton aria-label="edit" title="Modification du compte" onClick={() => modifierIQPR(ligneTableau)}>
								<EditIcon />
							</IconButton>

							<IconButton aria-label="delete" title="Suppression du compte" onClick={() => suppressionIQPR(ligneTableau)}>
								<DeleteIcon />
							</IconButton>
						</Box>
					)
				}
			]
		), []
	)

	const auChangementDePage = (
		(nouvellePage: number) => {
			const data = {
				...filtresIQPR,
				[FILTRES_IMMEUBLES_QUOTES_PARTS_RESIDENTIEL.PAGE]: nouvellePage
			}

			getListeImmeublesQuotesPartsResidentiel(nouvellePage, filtresIQPR[FILTRES_IMMEUBLES_QUOTES_PARTS_RESIDENTIEL.REFERENCE_IMMEUBLE])
				.then((response) => {
					setImmeublesQuotesPartsResidentiel(response)
					setFiltresIQPR(data)
				})
				.finally(() => window.scrollTo(0, 0))
		}
	)

	if (chargementImmeublesQuotesPartsResidentiel) {
		return (
			<Box height="150px">
				<Loader size={50} />
			</Box>
		)
	}

	return (
		<Box sx={{ paddingX: '172px' }}>
			<CustomTable
				id="listeComptesRecuperablesNonRecuperables"
				headRows={entetesTableau}
				rows={remplissageLigneSelonEnteteTableau<ImmeublesQuotesPartsResidentielModel>(immeublesQuotesPartsResidentiel['hydra:member'], entetesTableau)}
				total={immeublesQuotesPartsResidentiel['hydra:totalItems']}
				borderBottom
				auCliqueSurLigne={() => {
				}}
				hasPagination
				auChangementDePage={auChangementDePage}
				page={filtresIQPR[FILTRES_IMMEUBLES_QUOTES_PARTS_RESIDENTIEL.PAGE] - 1}
				hasSort={false}
			/>
		</Box>
	)
}

export default ListeImmeublesQuotesPartsResidentiel