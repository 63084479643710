import React, { useContext } from 'react'
import { ComptesTauxRecuperabiliteSouspostesContext } from '../context/ComptesTauxRecuperabiliteSouspostesContext'
import { EMPTY_FORM, FILTRES_COMPTES_TAUX_RECUPERABILITE_SOUSPOSTES } from '../enums/filtresComptesTauxRecuperabiliteSouspostesEnum'
import PopinGestionFormulaire from '../../../components/form/PopinGestionFormulaire'
import { ChampsPopinModel } from '../../../components/form/types/popinGestionFormulaire'
import PopinSuppressionContenu from '../../../components/dialog/components/PopinSuppressionContenu'
import { deleteComptesTauxRecuperabiliteSouspostes, postComptesTauxRecuperabiliteSouspostes, putComptesTauxRecuperabiliteSouspostes } from '../api/comptesTauxRecuperabiliteSouspostesAPI'

//Champs de la popin
const POPIN_FIELDS: ChampsPopinModel[] = [
	{
		colonnes: 1,
		champs: [
			{
				id: FILTRES_COMPTES_TAUX_RECUPERABILITE_SOUSPOSTES.ID,
				type: 'hidden'
			},
			{
				id: FILTRES_COMPTES_TAUX_RECUPERABILITE_SOUSPOSTES.COMPTE,
				type: 'text',
				maxLength: 20,
				label: 'Compte *',
				disableOnUpdate: true
			}
		]
	},
	{
		colonnes: 2,
		champs: [
			{
				id: FILTRES_COMPTES_TAUX_RECUPERABILITE_SOUSPOSTES.TAUX_RECUPERABILITE_RESIDENTIEL,
				type: 'text',
				maxLength: 20,
				label: 'Taux récupérabilité résidentiel *',
				disableOnUpdate: true,
				optionsProps: {
					min: {
						value: 0,
						message: 'Veuillez saisir un pourcentage compris entre 0 et 100'
					},
					max: {
						value: 100,
						message: 'Veuillez saisir un pourcentage compris entre 0 et 100'
					},
					pattern: {
						value: /^(?!0\d|$)\d*(\.\d{1,2})?$/,
						message: 'Veuillez saisir un pourcentage avec au plus 2 décimales'
					}
				}
			},
			{
				id: FILTRES_COMPTES_TAUX_RECUPERABILITE_SOUSPOSTES.TAUX_RECUPERABILITE_BUREAU,
				type: 'text',
				maxLength: 20,
				label: 'Taux récupérabilité bureau *',
				disableOnUpdate: true,
				optionsProps: {
					min: {
						value: 0,
						message: 'Veuillez saisir un pourcentage compris entre 0 et 100'
					},
					max: {
						value: 100,
						message: 'Veuillez saisir un pourcentage compris entre 0 et 100'
					},
					pattern: {
						value: /^(?!0\d|$)\d*(\.\d{1,2})?$/,
						message: 'Veuillez saisir un pourcentage avec au plus 2 décimales'
					}
				}
			}
		]
	},
	{
		colonnes: 2,
		champs: [
			{
				id: FILTRES_COMPTES_TAUX_RECUPERABILITE_SOUSPOSTES.CODE_SOUS_POSTE,
				type: 'text',
				maxLength: 20,
				label: 'Code sous poste *'
			}
		]
	}
]

const PopinGestionComptesTauxRecuperabiliteSouspostes = () => {
	const {
		ouverturePopin,
		setOuverturePopin,
		comptesTauxRecuperabiliteSouspostes,
		setComptesTauxRecuperabiliteSouspostes,
		popinSuppression,
		setPopinSuppression
	} = useContext(ComptesTauxRecuperabiliteSouspostesContext)

	return (
		<>
			{/* Popin permettant d'ajouter / modifier un CTRS */}
			<PopinGestionFormulaire
				champsPopin={POPIN_FIELDS}
				formulaireVide={EMPTY_FORM}
				donneesPopin={{
					open: ouverturePopin.open,
					donnees: ouverturePopin.comptesTauxRecuperabiliteSouspostes
				}}
				onCloseDialog={(closeData) => setOuverturePopin({
					open: closeData.open,
					comptesTauxRecuperabiliteSouspostes: closeData.donnees
				})}
				contenu={comptesTauxRecuperabiliteSouspostes['hydra:member']}
				modificationContenu={(nouveauContenu) => setComptesTauxRecuperabiliteSouspostes({
					'hydra:totalItems': ouverturePopin.comptesTauxRecuperabiliteSouspostes ? comptesTauxRecuperabiliteSouspostes['hydra:totalItems'] : comptesTauxRecuperabiliteSouspostes['hydra:totalItems']+1,
					'hydra:member': nouveauContenu
				})}
				titreCreation="Ajout d'un code sous poste par compte et taux de récupérabilité"
				titreModification="Modification d'un code sous poste par compte et taux de récupérabilité"
				envoiDonnees={ouverturePopin.comptesTauxRecuperabiliteSouspostes ? putComptesTauxRecuperabiliteSouspostes : postComptesTauxRecuperabiliteSouspostes}
			/>

			{/* Popin permettant de supprimer un CTRS */}
			<PopinSuppressionContenu
				donneesPopin={{
					open: popinSuppression.open,
					donnees: popinSuppression.comptesTauxRecuperabiliteSouspostes
				}}
				onCloseDialog={(closeData) => setPopinSuppression({
					open: closeData.open,
					comptesTauxRecuperabiliteSouspostes: closeData.donnees
				})}
				titreSuppression="Suppression d'un code sous poste par compte et taux de récupérabilité"
				texteSuppression="Confirmer la suppression du code sous poste par compte et taux de récupérabilité ?"
				contenu={comptesTauxRecuperabiliteSouspostes['hydra:member']}
				modificationContenu={(nouveauContenu) => setComptesTauxRecuperabiliteSouspostes({
					'hydra:totalItems': comptesTauxRecuperabiliteSouspostes['hydra:totalItems']-1,
					'hydra:member': nouveauContenu
				})}
				suppressionContenu={deleteComptesTauxRecuperabiliteSouspostes}
			/>
		</>
	)

}

export default PopinGestionComptesTauxRecuperabiliteSouspostes