import React, { createContext, Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'
import { CommunApiPlatformModel, INITIAL_COMMUN_API } from '../../../types/communApiPlatformModel'
import { getListeMatriculesTaux } from '../api/matriculesTauxAPI'
import {
	MatriculesTauxModel,
	FiltresMatriculesTauxModel,
	INITIAL_FILTRE_MT,
	INITIAL_POPIN_MT,
	PopinMatriculesTauxModel
} from '../types/matriculesTauxModel'
import { FILTRES_MATRICULES_TAUX } from '../enums/filtresMatriculesTauxEnum'
import { getListeComptesTauxRecuperabiliteSouspostes } from '../../comptesTauxRecuperabiliteSouspostes/api/comptesTauxRecuperabiliteSouspostesAPI'
import { ComptesTauxRecuperabiliteSouspostesModel } from '../../comptesTauxRecuperabiliteSouspostes/types/comptesTauxRecuperabiliteSouspostesModel'

export interface MatriculesTauxContextProps {
	matriculesTaux: CommunApiPlatformModel<MatriculesTauxModel[]>,
	setMatriculesTaux: Dispatch<SetStateAction<CommunApiPlatformModel<MatriculesTauxModel[]>>>
	chargementMatriculesTaux: boolean,
	setChargementMatriculesTaux: Dispatch<SetStateAction<boolean>>,
	ouverturePopin: PopinMatriculesTauxModel,
	setOuverturePopin: Dispatch<SetStateAction<PopinMatriculesTauxModel>>,
	popinSuppression: PopinMatriculesTauxModel,
	setPopinSuppression: Dispatch<SetStateAction<PopinMatriculesTauxModel>>,
	filtresMT: FiltresMatriculesTauxModel,
	setFiltresMT: Dispatch<SetStateAction<FiltresMatriculesTauxModel>>,
	tauxRecupResidentiel: ComptesTauxRecuperabiliteSouspostesModel[]
}

export const MatriculesTauxContext = createContext<MatriculesTauxContextProps>({
	matriculesTaux: INITIAL_COMMUN_API<MatriculesTauxModel[]>([]), // Partage des comptes récupérables / non récupérables aux éléments enfants
	setMatriculesTaux: () => {}, // Mise à jour des MT
	chargementMatriculesTaux: true, // Détermine si les MT sont en cours de chargement
	setChargementMatriculesTaux: () => {}, // Modification du statut de chargement
	ouverturePopin: INITIAL_POPIN_MT, // Ouverture de la popin de création / modification d'un MT
	setOuverturePopin: () => {}, // Mise à jour du statut d'ouverture de la popin de création / modification
	popinSuppression: INITIAL_POPIN_MT, // Ouverture de la popin de suppression d'un MT
	setPopinSuppression: () => {}, // Mise à jour du statut d'ouverture de la popin de suppression
	filtresMT: INITIAL_FILTRE_MT, // Partage du filtre sur les MT
	setFiltresMT: () => {}, // Mise à jour du filtre sur les MT,
	tauxRecupResidentiel: []
})

export const MatriculesTauxProvider: React.FC = (
	{
		children
	}
) => {
	const [matriculesTaux, setMatriculesTaux] = useState<CommunApiPlatformModel<MatriculesTauxModel[]>>(INITIAL_COMMUN_API<MatriculesTauxModel[]>([]))
	const [chargementMatriculesTaux, setChargementMatriculesTaux] = useState(true)
	const [ouverturePopin, setOuverturePopin] = useState<PopinMatriculesTauxModel>(INITIAL_POPIN_MT)
	const [popinSuppression, setPopinSuppression] = useState<PopinMatriculesTauxModel>(INITIAL_POPIN_MT)
	const [filtresMT, setFiltresMT] = useState<FiltresMatriculesTauxModel>(INITIAL_FILTRE_MT)
	const [tauxRecupResidentiel, setTauxRecupResidentiel] = useState<ComptesTauxRecuperabiliteSouspostesModel[]>([])

	useEffect(
		() => {
			getListeMatriculesTaux(filtresMT[FILTRES_MATRICULES_TAUX.PAGE])
				.then((response) => {
					setMatriculesTaux(response)
					setChargementMatriculesTaux(false)
				})
		}, []
	)

	const fetchTauxRecupResidentiel = useCallback(async () => {
		const tauxRecupResidentiel = await getListeComptesTauxRecuperabiliteSouspostes(0, false)
		const tauxUnique = tauxRecupResidentiel['hydra:member'].reduce((cumulTaux: ComptesTauxRecuperabiliteSouspostesModel[], taux: ComptesTauxRecuperabiliteSouspostesModel) => {
			if (cumulTaux.find((tauxDansCumul) => tauxDansCumul.tauxRecupResidentiel === taux.tauxRecupResidentiel)) {
				return cumulTaux
			}

			cumulTaux.push(taux)
			return cumulTaux
		}, [])

		setTauxRecupResidentiel(tauxUnique)
	}, [])

	// Chargement des taux_recup_residentiel
	useEffect(() => {
		fetchTauxRecupResidentiel()
	}, [fetchTauxRecupResidentiel])

	return (
		<MatriculesTauxContext.Provider
			value={{
				matriculesTaux,
				setMatriculesTaux,
				chargementMatriculesTaux,
				setChargementMatriculesTaux,
				ouverturePopin,
				setOuverturePopin,
				popinSuppression,
				setPopinSuppression,
				filtresMT,
				setFiltresMT,
				tauxRecupResidentiel
			}}
		>
			{children}
		</MatriculesTauxContext.Provider>
	)
}