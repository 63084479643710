import EnteteTaux from './components/EnteteTaux'
import React, { useEffect, useState } from 'react'
import { INITIAL_TAUX_PAGE_LIST, TauxPopinStateModel, TauxResponsePageModel } from './types/TauxResponseModel'
import Loader from '../../components/loader/Loader'
import ListeTaux from './components/ListeTaux'
import { getListeTaux } from './api/gestionTauxAPI'
import PopinEditTaux from './components/PopinEditTaux'

const GestionTaux = () => {

	const [taux, setTaux] = useState<TauxResponsePageModel>(INITIAL_TAUX_PAGE_LIST)
	const [loading, setLoading] = useState<boolean>(true)
	const [popinState, setPopinState] = useState<TauxPopinStateModel>({ open: false })
	const [rafraichir, setRafraichir] = useState<boolean>(false)

	useEffect(() => {
		getListeTaux(taux.page.current)
			.then((response: TauxResponsePageModel) => {
				setTaux(response)
				setLoading(false)
			})
	}, [rafraichir])

	return (
		<>
			<EnteteTaux setPopinState={setPopinState} />
			{loading ?
				<Loader />
				:
				<ListeTaux taux={taux} setTaux={setTaux} setPopinState={setPopinState} />
			}
			<PopinEditTaux onCloseDialog={() => (setPopinState({ open: false, taux: undefined }))} open={popinState.open} taux={popinState.taux} setRafraichir={setRafraichir} rafraichir={rafraichir} />
		</>
	)
}
export default GestionTaux