import { Box, Grid } from '@mui/material'
import { gecinaBlueMain } from '../../../theme'
import React from 'react'
import FiltresComptesTauxRecuperabiliteSouspostes from './FiltresComptesTauxRecuperabiliteSouspostes'

const EnteteComptesTauxRecuperabiliteSouspostes = () => (
	<Box sx={{ background: gecinaBlueMain, minHeight: '192px', paddingX: '172px' }}>
		<Grid container item xs={12} justifyContent="space-between" sx={{ pt: 5 }}>
			<Box sx={{ fontFamily: 'BlackerDisplay', fontSize: 28, fontWeight: 900 }}>
				Paramétrage - Codes sous-poste par compte et taux de récupérabilité
			</Box>
		</Grid>

		<Grid container item xs={12} justifyContent="space-evenly" sx={{ pt: 5 }}>
			<FiltresComptesTauxRecuperabiliteSouspostes />
		</Grid>
	</Box>
)

export default EnteteComptesTauxRecuperabiliteSouspostes