import React, { useContext, useMemo } from 'react'
import { HeadRow } from '../../../components/table/types/tableModel'
import { getKeyFromEnumValue } from '../../../utils/utils'
import { Box, IconButton } from '@mui/material'
import { remplissageLigneSelonEnteteTableau } from '../../../components/table/utils/tableUtils'
import CustomTable from '../../../components/table/Table'
import { enteteTableauListeComptesTauxRecuperabiliteSouspostesEnum } from '../enums/enteteTableauListeNaturesRubriquesPaieEnum'
import { ComptesTauxRecuperabiliteSouspostesModel } from '../types/comptesTauxRecuperabiliteSouspostesModel'
import { ComptesTauxRecuperabiliteSouspostesContext } from '../context/ComptesTauxRecuperabiliteSouspostesContext'
import Loader from '../../../components/loader/Loader'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { gecinaBrownMain } from '../../../theme'
import { columnsEnum } from '../../../components/table/enums/columnsEnum'
import { FILTRES_COMPTES_TAUX_RECUPERABILITE_SOUSPOSTES } from '../enums/filtresComptesTauxRecuperabiliteSouspostesEnum'
import { getListeComptesTauxRecuperabiliteSouspostes } from '../api/comptesTauxRecuperabiliteSouspostesAPI'

const ListeComptesTauxRecuperabiliteSouspostes = () => {
	const {
		comptesTauxRecuperabiliteSouspostes,
		setComptesTauxRecuperabiliteSouspostes,
		chargementComptesTauxRecuperabiliteSouspostes,
		setOuverturePopin,
		setPopinSuppression,
		filtresCTRS,
		setFiltresCTRS
	} = useContext(ComptesTauxRecuperabiliteSouspostesContext)

	const modifierCTRS = (comptesTauxRecuperabiliteSouspostes: ComptesTauxRecuperabiliteSouspostesModel) => setOuverturePopin({
		open: true,
		comptesTauxRecuperabiliteSouspostes: comptesTauxRecuperabiliteSouspostes
	})

	const suppressionCTRS = (comptesTauxRecuperabiliteSouspostes: ComptesTauxRecuperabiliteSouspostesModel) => setPopinSuppression({
		open: true,
		comptesTauxRecuperabiliteSouspostes: comptesTauxRecuperabiliteSouspostes
	})

	const entetesTableau: HeadRow[] = useMemo(
		() => (
			[
				{
					label: enteteTableauListeComptesTauxRecuperabiliteSouspostesEnum.compte,
					id: getKeyFromEnumValue<enteteTableauListeComptesTauxRecuperabiliteSouspostesEnum>(enteteTableauListeComptesTauxRecuperabiliteSouspostesEnum, enteteTableauListeComptesTauxRecuperabiliteSouspostesEnum.compte)
				},
				{
					label: enteteTableauListeComptesTauxRecuperabiliteSouspostesEnum.tauxRecupResidentiel,
					id: getKeyFromEnumValue<enteteTableauListeComptesTauxRecuperabiliteSouspostesEnum>(enteteTableauListeComptesTauxRecuperabiliteSouspostesEnum, enteteTableauListeComptesTauxRecuperabiliteSouspostesEnum.tauxRecupResidentiel)
				},
				{
					label: enteteTableauListeComptesTauxRecuperabiliteSouspostesEnum.tauxRecupBureau,
					id: getKeyFromEnumValue<enteteTableauListeComptesTauxRecuperabiliteSouspostesEnum>(enteteTableauListeComptesTauxRecuperabiliteSouspostesEnum, enteteTableauListeComptesTauxRecuperabiliteSouspostesEnum.tauxRecupBureau)
				},
				{
					label: enteteTableauListeComptesTauxRecuperabiliteSouspostesEnum.codeSousPoste,
					id: getKeyFromEnumValue<enteteTableauListeComptesTauxRecuperabiliteSouspostesEnum>(enteteTableauListeComptesTauxRecuperabiliteSouspostesEnum, enteteTableauListeComptesTauxRecuperabiliteSouspostesEnum.codeSousPoste)
				},
				{
					label: '',
					id: columnsEnum.customColonne,
					width: '15%',
					render: (ligneTableau: ComptesTauxRecuperabiliteSouspostesModel) => (
						<Box
							className="elementCache"
							sx={{
								visibility: 'hidden',
								textAlign: 'right',

								'& svg': {
									color: gecinaBrownMain
								}
							}}
						>
							<IconButton aria-label="edit" title="Modification du compte" onClick={() => modifierCTRS(ligneTableau)}>
								<EditIcon />
							</IconButton>

							<IconButton aria-label="delete" title="Suppression du compte" onClick={() => suppressionCTRS(ligneTableau)}>
								<DeleteIcon />
							</IconButton>
						</Box>
					)
				}
			]
		), []
	)

	const auChangementDePage = (
		(nouvellePage: number) => {
			const data = {
				...filtresCTRS,
				[FILTRES_COMPTES_TAUX_RECUPERABILITE_SOUSPOSTES.PAGE]: nouvellePage
			}

			getListeComptesTauxRecuperabiliteSouspostes(nouvellePage, true, filtresCTRS[FILTRES_COMPTES_TAUX_RECUPERABILITE_SOUSPOSTES.COMPTE])
				.then((response) => {
					setComptesTauxRecuperabiliteSouspostes(response)
					setFiltresCTRS(data)
				})
				.finally(() => window.scrollTo(0, 0))
		}
	)

	if (chargementComptesTauxRecuperabiliteSouspostes) {
		return (
			<Box height="150px">
				<Loader size={50} />
			</Box>
		)
	}

	return (
		<Box sx={{ paddingX: '172px' }}>
			<CustomTable
				id="listeComptesTauxRecuperabiliteSouspostes"
				headRows={entetesTableau}
				rows={remplissageLigneSelonEnteteTableau<ComptesTauxRecuperabiliteSouspostesModel>(comptesTauxRecuperabiliteSouspostes['hydra:member'], entetesTableau)}
				total={comptesTauxRecuperabiliteSouspostes['hydra:totalItems']}
				borderBottom
				auCliqueSurLigne={() => {
				}}
				hasPagination
				auChangementDePage={auChangementDePage}
				page={filtresCTRS[FILTRES_COMPTES_TAUX_RECUPERABILITE_SOUSPOSTES.PAGE] - 1}
				hasSort={false}
			/>
		</Box>
	)
}

export default ListeComptesTauxRecuperabiliteSouspostes