import React from 'react'
import { NaturesRubriquesPaieProvider } from './context/NaturesRubriquesPaieContext'
import EnteteNaturesRubriquesPaie from './components/EnteteNaturesRubriquesPaie'
import ListeNaturesRubriquesPaie from './components/ListeNaturesRubriquesPaie'
import PopinGestionNaturesRubriquesPaie from './components/PopinGestionNaturesRubriquesPaie'

const NaturesRubriquesPaie = () => (
	<NaturesRubriquesPaieProvider>
		<EnteteNaturesRubriquesPaie />

		<ListeNaturesRubriquesPaie />

		<PopinGestionNaturesRubriquesPaie />
	</NaturesRubriquesPaieProvider>
)

export default NaturesRubriquesPaie