import { DISPLAY_SNACKBAR, HIDE_SNACKBAR } from './actionTypes'
import { SnackBarModel } from '../types/snackBarModel'
import { SnackbarTypes } from './types'

export const displaySnackbar = (snackBar: SnackBarModel): SnackbarTypes => {
	return ({
		type: DISPLAY_SNACKBAR,
		payload: snackBar
	})
}

export const hideSnackbar = (): SnackbarTypes => {
	return ({
		type: HIDE_SNACKBAR
	})
}
