import { Box, Grid } from '@mui/material'
import { gecinaBlueMain } from '../../../theme'
import FiltresListeTaxes from './FiltresListeTaxes'
import React, { Dispatch } from 'react'


interface EnteteListeProps {
	setLoading: Dispatch<React.SetStateAction<boolean>>
}

const EnteteListe: React.FC<EnteteListeProps> = (
	{
		setLoading
	}
) => {
	return (
		<Box sx={{ background: gecinaBlueMain, minHeight: 192, paddingX: '172px' }}>
			<Grid container item xs={12} justifyContent="space-between" sx={{ pt: 5 }}>
				<Box sx={{ fontFamily: 'BlackerDisplay', fontSize: 28, fontWeight: 900 }}>
					Taxes foncières
				</Box>
			</Grid>
			<Grid container item xs={12} justifyContent="space-evenly" sx={{ pt: 5 }}>
				<FiltresListeTaxes setLoading={setLoading} />
			</Grid>
		</Box>
	)
}

export default EnteteListe