import React, { useContext, useMemo } from 'react'
import { HeadRow } from '../../../components/table/types/tableModel'
import { getKeyFromEnumValue } from '../../../utils/utils'
import { Box, IconButton } from '@mui/material'
import { remplissageLigneSelonEnteteTableau } from '../../../components/table/utils/tableUtils'
import CustomTable from '../../../components/table/Table'
import { enteteTableauListeMatriculesTauxEnum } from '../enums/enteteTableauListeMatriculesTauxEnum'
import { MatriculesTauxModel } from '../types/matriculesTauxModel'
import { MatriculesTauxContext } from '../context/MatriculesTauxContext'
import Loader from '../../../components/loader/Loader'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { gecinaBrownMain } from '../../../theme'
import { columnsEnum } from '../../../components/table/enums/columnsEnum'
import { FILTRES_MATRICULES_TAUX } from '../enums/filtresMatriculesTauxEnum'
import { getListeMatriculesTaux } from '../api/matriculesTauxAPI'

const ListeMatriculesTaux = () => {
	const {
		matriculesTaux,
		setMatriculesTaux,
		chargementMatriculesTaux,
		setOuverturePopin,
		setPopinSuppression,
		filtresMT,
		setFiltresMT
	} = useContext(MatriculesTauxContext)

	const modifierMT = (matriculesTaux: MatriculesTauxModel) => setOuverturePopin({
		open: true,
		matriculesTaux
	})

	const suppressionMT = (matriculesTaux: MatriculesTauxModel) => setPopinSuppression({
		open: true,
		matriculesTaux
	})

	const entetesTableau: HeadRow[] = useMemo(
		() => (
			[
				{
					label: enteteTableauListeMatriculesTauxEnum.matricule,
					id: getKeyFromEnumValue<enteteTableauListeMatriculesTauxEnum>(enteteTableauListeMatriculesTauxEnum, enteteTableauListeMatriculesTauxEnum.matricule)
				},
				{
					label: enteteTableauListeMatriculesTauxEnum.referenceImmeuble,
					id: getKeyFromEnumValue<enteteTableauListeMatriculesTauxEnum>(enteteTableauListeMatriculesTauxEnum, enteteTableauListeMatriculesTauxEnum.referenceImmeuble)
				},
				{
					label: enteteTableauListeMatriculesTauxEnum.dateDebut,
					id: getKeyFromEnumValue<enteteTableauListeMatriculesTauxEnum>(enteteTableauListeMatriculesTauxEnum, enteteTableauListeMatriculesTauxEnum.dateDebut),
					render: (ligneTableau) => <Box>{new Date(ligneTableau).toLocaleDateString()}</Box>
				},
				{
					label: enteteTableauListeMatriculesTauxEnum.dateFin,
					id: getKeyFromEnumValue<enteteTableauListeMatriculesTauxEnum>(enteteTableauListeMatriculesTauxEnum, enteteTableauListeMatriculesTauxEnum.dateFin),
					render: (ligneTableau) => <Box>{ligneTableau?new Date(ligneTableau).toLocaleDateString():''}</Box>
				},
				{
					label: enteteTableauListeMatriculesTauxEnum.tauxRecupResidentiel,
					id: getKeyFromEnumValue<enteteTableauListeMatriculesTauxEnum>(enteteTableauListeMatriculesTauxEnum, enteteTableauListeMatriculesTauxEnum.tauxRecupResidentiel)
				},
				{
					label: '',
					id: columnsEnum.customColonne,
					width: '15%',
					render: (ligneTableau: MatriculesTauxModel) => (
						<Box
							className="elementCache"
							sx={{
								visibility: 'hidden',
								textAlign: 'right',

								'& svg': {
									color: gecinaBrownMain
								}
							}}
						>
							<IconButton aria-label="edit" title="Modification du paramétrage" onClick={() => modifierMT(ligneTableau)}>
								<EditIcon />
							</IconButton>

							<IconButton aria-label="delete" title="Suppression du paramétrage" onClick={() => suppressionMT(ligneTableau)}>
								<DeleteIcon />
							</IconButton>
						</Box>
					)
				}
			]
		), []
	)

	const auChangementDePage = (
		(nouvellePage: number) => {
			const data = {
				...filtresMT,
				[FILTRES_MATRICULES_TAUX.PAGE]: nouvellePage
			}

			getListeMatriculesTaux(nouvellePage, filtresMT[FILTRES_MATRICULES_TAUX.MATRICULE], filtresMT[FILTRES_MATRICULES_TAUX.REFERENCE_IMMEUBLE])
				.then((response) => {
					setMatriculesTaux(response)
					setFiltresMT(data)
				})
				.finally(() => window.scrollTo(0, 0))
		}
	)

	if (chargementMatriculesTaux) {
		return (
			<Box height="150px">
				<Loader size={50} />
			</Box>
		)
	}

	return (
		<Box sx={{ paddingX: '172px' }}>
			<CustomTable
				id="listeMatriculesTaux"
				headRows={entetesTableau}
				rows={remplissageLigneSelonEnteteTableau<MatriculesTauxModel>(matriculesTaux['hydra:member'], entetesTableau)}
				total={matriculesTaux['hydra:totalItems']}
				borderBottom
				auCliqueSurLigne={() => {
				}}
				hasPagination
				auChangementDePage={auChangementDePage}
				page={filtresMT[FILTRES_MATRICULES_TAUX.PAGE] - 1}
				hasSort={false}
			/>
		</Box>
	)
}

export default ListeMatriculesTaux