import { FiltresListeAvisFormModel, FiltresListeAvisPostModel, INITIAL_VALEURS_FORMULAIRE } from '../types/filtresListeAvisModel'
import { filtresListeAvisFieldsEnum, filtresListeAvisPostEnum } from '../enums/listeAvisEnum'
import { formatDate } from '../../../utils/formatDate'

export const mapperListeAvisVersBack = (values: FiltresListeAvisFormModel, page: number): FiltresListeAvisPostModel => (
	{
		[filtresListeAvisPostEnum.reference]: values[filtresListeAvisFieldsEnum.reference].length > 0 ? values[filtresListeAvisFieldsEnum.reference] : undefined,
		[filtresListeAvisPostEnum.statut]: values[filtresListeAvisFieldsEnum.statut].length > 0 ? values[filtresListeAvisFieldsEnum.statut] : undefined,
		[filtresListeAvisPostEnum.recuLe]: values[filtresListeAvisFieldsEnum.recuLe] ? new Date(values[filtresListeAvisFieldsEnum.recuLe]!).toISOString().split('T')[0] : undefined,
		[filtresListeAvisPostEnum.avantLe]: values[filtresListeAvisFieldsEnum.avantLe] ? new Date(values[filtresListeAvisFieldsEnum.avantLe]!).toISOString().split('T')[0] : undefined,
		[filtresListeAvisPostEnum.page]: page + 1,
		[filtresListeAvisPostEnum.dateSortDesc]: values[filtresListeAvisFieldsEnum.dateSortDesc]
	}
)

export const mapperBackVersListeAvis = (values: FiltresListeAvisPostModel): FiltresListeAvisFormModel => (
	{
		[filtresListeAvisFieldsEnum.reference]: values && values[filtresListeAvisPostEnum.reference] && values[filtresListeAvisPostEnum.reference]!.length > 0 ? (values[filtresListeAvisPostEnum.reference] as string) : INITIAL_VALEURS_FORMULAIRE[filtresListeAvisFieldsEnum.reference],
		[filtresListeAvisFieldsEnum.dateSortDesc]: values && values[filtresListeAvisPostEnum.dateSortDesc] ? values[filtresListeAvisPostEnum.dateSortDesc]! : INITIAL_VALEURS_FORMULAIRE[filtresListeAvisFieldsEnum.dateSortDesc],
		[filtresListeAvisFieldsEnum.statut]: values && values[filtresListeAvisPostEnum.statut] && values[filtresListeAvisPostEnum.statut]!.length > 0 ? (values[filtresListeAvisPostEnum.statut] as string) : INITIAL_VALEURS_FORMULAIRE[filtresListeAvisFieldsEnum.statut],
		[filtresListeAvisFieldsEnum.recuLe]: values && values[filtresListeAvisPostEnum.recuLe] ? new Date(values[filtresListeAvisPostEnum.recuLe]!) : INITIAL_VALEURS_FORMULAIRE[filtresListeAvisFieldsEnum.recuLe],
		[filtresListeAvisFieldsEnum.avantLe]: values && values[filtresListeAvisPostEnum.avantLe] ? formatDate(new Date(values[filtresListeAvisPostEnum.avantLe]!)) : INITIAL_VALEURS_FORMULAIRE[filtresListeAvisFieldsEnum.avantLe]
	}
)
