import { Grid, MenuItem } from '@mui/material'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import TextField from '../../../components/fields/FormTextField'
import { buttonTypesEnum } from '../../../components/button/enums/buttonTypesEnum'
import { fontFamilyMonserrat, gecinaBrownMain, WHITE } from '../../../theme'
import Button from '../../../components/button/Button'
import AutorenewIcon from '@mui/icons-material/Autorenew'
import React, { useContext } from 'react'
import { FILTRES_TRAITEMENT_SG_NF } from '../enums/filtresTraitementSGNFEnum'
import { TraitementSGNFContext } from '../context/TraitementSGNFContext'
import { FiltresTraitementSGNFModel } from '../types/traitementSGNFModel'
import { getListeTraitementSGNF } from '../api/traitementSGNFAPI'
import FormSelectField from '../../../components/fields/FormSelectField'
import { statutFichierTraitementSGNFEnum, typeFichierTraitementSGNFEnum } from '../enums/fichierTraitementSGNFEnum'
import FormDatePickerField from '../../../components/fields/FormDatePickerField'
import { getKeyFromEnumValue } from '../../../utils/utils'

const INITIAL_VALEURS_FORMULAIRE = {
	[FILTRES_TRAITEMENT_SG_NF.ID]: null,
	[FILTRES_TRAITEMENT_SG_NF.FICHIER_INTEGRE]: '',
	[FILTRES_TRAITEMENT_SG_NF.TYPE]: getKeyFromEnumValue<typeFichierTraitementSGNFEnum>(typeFichierTraitementSGNFEnum, typeFichierTraitementSGNFEnum.TOUT),
	[FILTRES_TRAITEMENT_SG_NF.STATUT]: getKeyFromEnumValue<statutFichierTraitementSGNFEnum>(statutFichierTraitementSGNFEnum, statutFichierTraitementSGNFEnum.TOUT),
	[FILTRES_TRAITEMENT_SG_NF.MATRICULE]: '',
	[FILTRES_TRAITEMENT_SG_NF.RECU_ENTRE_LE]: null,
	[FILTRES_TRAITEMENT_SG_NF.ET_LE]: null
}

const FiltresTraitementSGNF = () => {
	const { setChargementTraitementSGNF, setTraitementSGNF, filtresTraitementSGNF, setFiltresTraitementSGNF } = useContext(TraitementSGNFContext)
	const methods = useForm<FiltresTraitementSGNFModel>({
		defaultValues: INITIAL_VALEURS_FORMULAIRE
	})
	const { register, handleSubmit, reset } = methods

	const onSubmit: SubmitHandler<FiltresTraitementSGNFModel> = (values) => {
		setChargementTraitementSGNF(true)
		const data = {
			...filtresTraitementSGNF,
			[FILTRES_TRAITEMENT_SG_NF.PAGE]: 1
		}
		setFiltresTraitementSGNF(data)

		// Récupération de la liste des comptes selon les valeurs du filtre
		return getListeTraitementSGNF(1, values.fichierIntegre, values.type ?? undefined, values.statut ?? undefined, values.matricule, values.recuEntreLe ?? undefined, values.etLe ?? undefined)
			.then((response) => {
				setTraitementSGNF(response)
				setChargementTraitementSGNF(false)
			})
	}

	return (
		<FormProvider {...methods}>
			<form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
				<Grid container>
					<Grid container item xs={8} spacing={2}>
						<Grid item xs={4}>
							<TextField
								register={register(FILTRES_TRAITEMENT_SG_NF.FICHIER_INTEGRE)}
								id={FILTRES_TRAITEMENT_SG_NF.FICHIER_INTEGRE}
								label="Fichier"
								placeholder="Saisir une partie du nom"
							/>
						</Grid>
						<Grid item xs={4}>
							<FormSelectField
								register={register(FILTRES_TRAITEMENT_SG_NF.TYPE)}
								id={FILTRES_TRAITEMENT_SG_NF.TYPE}
								label="Type"
							>
								{
									Object.keys(typeFichierTraitementSGNFEnum).map(
										(type) => (
											<MenuItem value={type} key={type}>
												{typeFichierTraitementSGNFEnum[type as keyof typeof typeFichierTraitementSGNFEnum]}
											</MenuItem>
										)
									)
								}
							</FormSelectField>
						</Grid>
						<Grid item xs={4}>
							<FormSelectField
								register={register(FILTRES_TRAITEMENT_SG_NF.STATUT)}
								id={FILTRES_TRAITEMENT_SG_NF.STATUT}
								label="Statut"
							>
								{
									Object.keys(statutFichierTraitementSGNFEnum).map(
										(statut) => (
											<MenuItem value={statut} key={statut}>
												{statutFichierTraitementSGNFEnum[statut as keyof typeof statutFichierTraitementSGNFEnum]}
											</MenuItem>
										)
									)
								}
							</FormSelectField>
						</Grid>
						<Grid item xs={4}>
							<TextField
								register={register(FILTRES_TRAITEMENT_SG_NF.MATRICULE)}
								id={FILTRES_TRAITEMENT_SG_NF.MATRICULE}
								label="Matricule"
								placeholder="Saisir le matricule"
							/>
						</Grid>
						<Grid item xs={4}>
							<FormDatePickerField
								register={register(FILTRES_TRAITEMENT_SG_NF.RECU_ENTRE_LE)}
								id={FILTRES_TRAITEMENT_SG_NF.RECU_ENTRE_LE}
								label="Reçu entre le"
								errorWhite
							/>
						</Grid>

						<Grid item xs={4}>
							<FormDatePickerField
								register={register(FILTRES_TRAITEMENT_SG_NF.ET_LE)}
								id={FILTRES_TRAITEMENT_SG_NF.ET_LE}
								label="Et le"
								errorWhite
							/>
						</Grid>
					</Grid>
					<Grid container item xs={4}>
						<Grid container item xs={9} spacing={2} sx={{ m: 0 }} alignContent="flex-start">
							<span
								style={{
									width: '100%',
									paddingRight: 15,
									paddingTop: 7,
									fontSize: 14,
									marginLeft: 0,
									boxSizing: 'border-box',
									textAlign: 'left',
									fontFamily: fontFamilyMonserrat,
									fontWeight: 500
								}}
							> </span>

							<Grid item xs={7} sx={{ pt: '0px !important', height: 'fit-content' }}>
								<Button
									sx={{
										height: '32px',
										p: 0,
										minWidth: '100%',
										fontWeight: 600
									}}
									bgcolor={gecinaBrownMain}
									color={WHITE}
									border={false}
									disabled={false}
									type={buttonTypesEnum.submit}
								>
									Filtrer
								</Button>
							</Grid>

							<Grid item xs={3} md={2} sx={{ pt: '0px !important', height: 'fit-content' }}>
								<Button
									sx={{
										height: 32,
										p: 0,
										minWidth: '100%'
									}}
									bgcolor={gecinaBrownMain}
									color={WHITE}
									border={false}
									disabled={false}
									type={buttonTypesEnum.submit}
									onClick={() => reset(INITIAL_VALEURS_FORMULAIRE)}
								>
									<AutorenewIcon />
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</form>
		</FormProvider>
	)
}

export default FiltresTraitementSGNF