export enum FILTRES_NATURES_RUBRIQUES_PAIE {
	ID = '@id',
	COMPTE = 'compte',
	NATURE_CHARGE_RESIDENTIEL = 'natureChargeResidentiel',
	NATURE_CHARGE_BUREAU = 'natureChargeBureau',
	DATE_DEBUT = 'dateDebut',
	DATE_FIN = 'dateFin',
	NOM_MODIFICATEUR = 'nomModificateur',
	ID_MODIFICATEUR = 'idModificateur',
	PAGE = 'page'
}

export enum NATURE_VALUES {
	NR = 'NR',
	R = 'R'
}

export const EMPTY_FORM = {
	[FILTRES_NATURES_RUBRIQUES_PAIE.ID]: null,
	[FILTRES_NATURES_RUBRIQUES_PAIE.COMPTE]: '',
	[FILTRES_NATURES_RUBRIQUES_PAIE.NATURE_CHARGE_RESIDENTIEL]: NATURE_VALUES.NR,
	[FILTRES_NATURES_RUBRIQUES_PAIE.NATURE_CHARGE_BUREAU]: NATURE_VALUES.NR
}