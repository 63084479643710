import React, { createContext, Dispatch, SetStateAction, useEffect, useState } from 'react'
import { CommunApiPlatformModel, INITIAL_COMMUN_API } from '../../../types/communApiPlatformModel'
import { getListeImmeublesQuotesPartsResidentiel } from '../api/immeublesQuotesPartsResidentielAPI'
import {
	ImmeublesQuotesPartsResidentielModel,
	FiltresImmeublesQuotesPartsResidentielModel,
	INITIAL_FILTRE_IQPR,
	INITIAL_POPIN_IQPR,
	PopinImmeublesQuotesPartsResidentielModel
} from '../types/immeublesQuotesPartsResidentielModel'
import { FILTRES_IMMEUBLES_QUOTES_PARTS_RESIDENTIEL } from '../enums/filtresImmeublesQuotesPartsResidentielEnum'

export interface ImmeublesQuotesPartsResidentielContextProps {
	immeublesQuotesPartsResidentiel: CommunApiPlatformModel<ImmeublesQuotesPartsResidentielModel[]>,
	setImmeublesQuotesPartsResidentiel: Dispatch<SetStateAction<CommunApiPlatformModel<ImmeublesQuotesPartsResidentielModel[]>>>
	chargementImmeublesQuotesPartsResidentiel: boolean,
	setChargementImmeublesQuotesPartsResidentiel: Dispatch<SetStateAction<boolean>>,
	ouverturePopin: PopinImmeublesQuotesPartsResidentielModel,
	setOuverturePopin: Dispatch<SetStateAction<PopinImmeublesQuotesPartsResidentielModel>>,
	popinSuppression: PopinImmeublesQuotesPartsResidentielModel,
	setPopinSuppression: Dispatch<SetStateAction<PopinImmeublesQuotesPartsResidentielModel>>,
	filtresIQPR: FiltresImmeublesQuotesPartsResidentielModel,
	setFiltresIQPR: Dispatch<SetStateAction<FiltresImmeublesQuotesPartsResidentielModel>>
}

export const ImmeublesQuotesPartsResidentielContext = createContext<ImmeublesQuotesPartsResidentielContextProps>({
	immeublesQuotesPartsResidentiel: INITIAL_COMMUN_API<ImmeublesQuotesPartsResidentielModel[]>([]), // Partage des comptes récupérables / non récupérables aux éléments enfants
	setImmeublesQuotesPartsResidentiel: () => {}, // Mise à jour des CRNR
	chargementImmeublesQuotesPartsResidentiel: true, // Détermine si les CRNR sont en cours de chargement
	setChargementImmeublesQuotesPartsResidentiel: () => {}, // Modification du statut de chargement
	ouverturePopin: INITIAL_POPIN_IQPR, // Ouverture de la popin de création / modification d'un CRNR
	setOuverturePopin: () => {}, // Mise à jour du statut d'ouverture de la popin de création / modification
	popinSuppression: INITIAL_POPIN_IQPR, // Ouverture de la popin de suppression d'un CRNR
	setPopinSuppression: () => {}, // Mise à jour du statut d'ouverture de la popin de suppression
	filtresIQPR: INITIAL_FILTRE_IQPR, // Partage du filtre sur les CRNR
	setFiltresIQPR: () => {} // Mise à jour du filtre sur les CRNR
})

export const ImmeublesQuotesPartsResidentielProvider: React.FC = (
	{
		children
	}
) => {
	const [immeublesQuotesPartsResidentiel, setImmeublesQuotesPartsResidentiel] = useState<CommunApiPlatformModel<ImmeublesQuotesPartsResidentielModel[]>>(INITIAL_COMMUN_API<ImmeublesQuotesPartsResidentielModel[]>([]))
	const [chargementImmeublesQuotesPartsResidentiel, setChargementImmeublesQuotesPartsResidentiel] = useState(true)
	const [ouverturePopin, setOuverturePopin] = useState<PopinImmeublesQuotesPartsResidentielModel>(INITIAL_POPIN_IQPR)
	const [popinSuppression, setPopinSuppression] = useState<PopinImmeublesQuotesPartsResidentielModel>(INITIAL_POPIN_IQPR)
	const [filtresIQPR, setFiltresIQPR] = useState<FiltresImmeublesQuotesPartsResidentielModel>(INITIAL_FILTRE_IQPR)

	useEffect(
		() => {
			getListeImmeublesQuotesPartsResidentiel(filtresIQPR[FILTRES_IMMEUBLES_QUOTES_PARTS_RESIDENTIEL.PAGE])
				.then((response) => {
					setImmeublesQuotesPartsResidentiel(response)
					setChargementImmeublesQuotesPartsResidentiel(false)
				})
		}, []
	)

	return (
		<ImmeublesQuotesPartsResidentielContext.Provider
			value={{
				immeublesQuotesPartsResidentiel: immeublesQuotesPartsResidentiel,
				setImmeublesQuotesPartsResidentiel: setImmeublesQuotesPartsResidentiel,
				chargementImmeublesQuotesPartsResidentiel: chargementImmeublesQuotesPartsResidentiel,
				setChargementImmeublesQuotesPartsResidentiel: setChargementImmeublesQuotesPartsResidentiel,
				ouverturePopin,
				setOuverturePopin,
				popinSuppression,
				setPopinSuppression,
				filtresIQPR: filtresIQPR,
				setFiltresIQPR: setFiltresIQPR
			}}
		>
			{children}
		</ImmeublesQuotesPartsResidentielContext.Provider>
	)
}