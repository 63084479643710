import { TauxResponseModel } from '../modules/gestionTaux/types/TauxResponseModel'
import flatten from 'flat'
import { FieldErrors, FieldValues, UseFormRegister, UseFormRegisterReturn } from 'react-hook-form'

export const getKeyFromEnumValue = <EnumType>(
	enumParsed: { [key: string]: EnumType },
	enumValue: EnumType
) =>
	Object.keys(enumParsed).find(
		(enumParsedKey) => enumParsed[enumParsedKey] === enumValue
	) ?? ''


export const validateFrenchNumber = (text: number | null) => {
	return (text === 0 || text) ? isNaN(text!) : true
}

export const transformNumber = (text: string | number | null) => {
	if (typeof text !== 'number') {
		return text ? filterFloat(text.replace(',', '.')) : null
	} else return text
}

// ParseFloat, mais que renvoie NaN quand il y a une lettre dans le texte.
export const filterFloat = (value: string) => {
	if (/^(\-|\+)?([0-9]+(\.[0-9]+)?|Infinity)$/
		.test(value)) {
		return Number(value)
	}
	return NaN
}

export const verificationErreursFormulaire = (errors: FieldErrors<FieldValues>, register: UseFormRegisterReturn | undefined) => {
	const fieldInError = Object.keys(flatten(errors)).filter((error) => register && `${register.name}.message` === error)

	return flatten<typeof errors, { [key: string]: any }>(errors)[fieldInError[0]] || ''
}

export const gestionNombre = (nombre: string) => parseFloat(nombre) || 0