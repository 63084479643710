import React, { useContext } from 'react'
import { ImmeublesQuotesPartsResidentielContext } from '../context/ImmeublesQuotesPartsResidentielContext'
import { EMPTY_FORM, FILTRES_IMMEUBLES_QUOTES_PARTS_RESIDENTIEL } from '../enums/filtresImmeublesQuotesPartsResidentielEnum'
import PopinGestionFormulaire from '../../../components/form/PopinGestionFormulaire'
import { ChampsPopinModel } from '../../../components/form/types/popinGestionFormulaire'
import PopinSuppressionContenu from '../../../components/dialog/components/PopinSuppressionContenu'
import { deleteImmeublesQuotesPartsResidentiel, postImmeublesQuotesPartsResidentiel, putImmeublesQuotesPartsResidentiel } from '../api/immeublesQuotesPartsResidentielAPI'

//Champs de la popin
const POPIN_FIELDS: ChampsPopinModel[] = [
	{
		colonnes: 1,
		champs: [
			{
				id: FILTRES_IMMEUBLES_QUOTES_PARTS_RESIDENTIEL.ID,
				type: 'hidden'
			},
			{
				id: FILTRES_IMMEUBLES_QUOTES_PARTS_RESIDENTIEL.REFERENCE_IMMEUBLE,
				type: 'text',
				maxLength: 20,
				label: 'Immeuble *',
				disableOnUpdate: true
			}
		]
	},
	{
		colonnes: 2,
		champs: [
			{
				id: FILTRES_IMMEUBLES_QUOTES_PARTS_RESIDENTIEL.QUOTE_PART,
				type: 'number',
				label: 'Quote part résidentiel *',
				optionsProps: {
					min: {
						value: 0,
						message: 'Veuillez saisir un pourcentage avec au plus 4 décimales'
					},
					max: {
						value: 100,
						message: 'Veuillez saisir un pourcentage avec au plus 4 décimales'
					},
					pattern: {
						value: /^(?!0\d|$)\d*(\.\d{1,4})?$/,
						message: 'Veuillez saisir un pourcentage avec au plus 4 décimales'
					}
				}
			}
		]
	}
]

const PopinGestionImmeublesQuotesPartsResidentiel = () => {
	const {
		ouverturePopin,
		setOuverturePopin,
		immeublesQuotesPartsResidentiel,
		setImmeublesQuotesPartsResidentiel,
		popinSuppression,
		setPopinSuppression
	} = useContext(ImmeublesQuotesPartsResidentielContext)

	return (
		<>
			{/* Popin permettant d'ajouter / modifier un NRP */}
			<PopinGestionFormulaire
				champsPopin={POPIN_FIELDS}
				formulaireVide={EMPTY_FORM}
				donneesPopin={{
					open: ouverturePopin.open,
					donnees: ouverturePopin.immeublesQuotesPartsResidentiel
				}}
				onCloseDialog={(closeData) => setOuverturePopin({
					open: closeData.open,
					immeublesQuotesPartsResidentiel: closeData.donnees
				})}
				contenu={immeublesQuotesPartsResidentiel['hydra:member']}
				modificationContenu={(nouveauContenu) => setImmeublesQuotesPartsResidentiel({
					'hydra:totalItems': ouverturePopin.immeublesQuotesPartsResidentiel ? immeublesQuotesPartsResidentiel['hydra:totalItems'] : immeublesQuotesPartsResidentiel['hydra:totalItems']+1,
					'hydra:member': nouveauContenu
				})}
				titreCreation="Ajouter la quote part résidentiel d'un immeuble"
				titreModification="Modifier la quote part résidentiel de l'immeuble"
				envoiDonnees={ouverturePopin.immeublesQuotesPartsResidentiel ? putImmeublesQuotesPartsResidentiel : postImmeublesQuotesPartsResidentiel}
			/>

			{/* Popin permettant de supprimer un NRP */}
			<PopinSuppressionContenu
				donneesPopin={{
					open: popinSuppression.open,
					donnees: popinSuppression.immeublesQuotesPartsResidentiel
				}}
				onCloseDialog={(closeData) => setPopinSuppression({
					open: closeData.open,
					immeublesQuotesPartsResidentiel: closeData.donnees
				})}
				titreSuppression="Suppression de la quote part"
				texteSuppression="Confirmer la suppression de la quote part ?"
				contenu={immeublesQuotesPartsResidentiel['hydra:member']}
				modificationContenu={(nouveauContenu) => setImmeublesQuotesPartsResidentiel({
					'hydra:totalItems': immeublesQuotesPartsResidentiel['hydra:totalItems']-1,
					'hydra:member': nouveauContenu
				})}
				suppressionContenu={deleteImmeublesQuotesPartsResidentiel}
			/>
		</>
	)

}

export default PopinGestionImmeublesQuotesPartsResidentiel